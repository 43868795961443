.w-CreativesCreateEdit {
  background-color: $color-A12;

  .w-DisplayCreativeEditCreate-tabset {
    margin-top: 20px;
  }

  .w-VideoCreativeEditCreate-tabset {
    margin-top: 10px;
    .beta {
      position: absolute;
      top: 9px;
    }
  }

  .w-DisplayCreativeEditCreate-tabset,
  .w-VideoCreativeEditCreate-tabset {
    .ui-widget-content {
      height: 100%;
    }

    .classification {
      position: absolute;
      top: 0;
      bottom: 50px;
      left: 0;
      right: 0;
      display: block;
    }
  }

  .advanced-vid-settings {
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 12px;
    display: none;

    .variantNotice {
      display: none;
      margin-bottom: 5px;
    }

    .disabled {
      @include transparent($percent: 50);
      font-size: 11px;
      text-align: center;
      margin-top: 125px;
    }

    .selected {
      color: #043c6c;
      background: #def2fd;
    }

    .form-stack {
      display: none;
      background: none;
      padding-top: 10px;
    }

    .form-list {
      font-size: 13px;
    }

    .advanced-header {
      font: 'PNLB';
      color: #6699cc;
      background: #f3f4f9;
      padding: 6px;
      border-bottom: 1px solid $color-A6;
    }

    .advanced-item {
      cursor: pointer;
      padding: 6px;
      border-bottom: 1px solid $color-A6;

      &:hover {
        color: #043c6c;
        background: #ebf6fc;
      }
    }

    .advanced-settings-btn {
      @include _border-radius(4px);
      font: 12px 'PNLB';
      color: #525252;
      position: relative;
      float: right;
      padding: 6px 8px;
      margin: 10px 22px 0 0;
      cursor: pointer;
    }

    .adv-btn-on {
      background: #e0e0e0;
    }

    .adv-btn-off {
      background: none;
    }

    .advanced-arrow-left {
      @include sprite-icon($left: 11, $down: 9, $width: 13px, $height: 13px);
    }

    .advanced-arrow-right {
      @include sprite-icon($left: 11, $down: 9, $width: 13px, $height: 13px);
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: 'FlipH';
    }

    .variant-box {
      background: #ffffff;
      width: 220px;
      height: 310px;
      border: 1px solid $color-A6;
      overflow: auto;
    }

    .box-btns {
      margin: 130px 22px 0 22px;
    }

    .advanced-btn-enable {
      @extend .btn-primary;
      width: 15px;
      height: 12px;
      margin: 0 0 12px 0;
    }

    .advanced-btn-disable {
      @extend .btn-secondary;
      width: 15px;
      height: 12px;
      margin: 0 0 12px 0;
    }
  }

  .box-shading {
    background: #f5f5f5;
    -moz-box-shadow: inset 0 10px 8px -8px #e6e6e6, inset 0 -11px 8px -8px #e6e6e6;
    -webkit-box-shadow: inset 0 10px 8px -8px #e6e6e6, inset 0 -11px 8px -8px #e6e6e6;
    box-shadow: inset 0 10px 8px -8px #e6e6e6, inset 0 -11px 8px -8px #e6e6e6;
  }

  .hold-settings {
    position: absolute;
    margin-right: 20px;
    top: 95px;
    right: 0;
  }

  .settings {
    @include linear-gradient($from: $color-A12, $to: $color-A8);
    @include border-radius($radius: 3px);

    float: right;
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding: 5px 5px;
    border: 1px solid $color-A6;
    margin-top: 1px;
  }

  .settings-icon {
    @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
  }

  .view-loader {
    position: absolute;
    left: 30%;
    top: 50%;
  }

  .video-view-loader {
    position: absolute;
    left: 45%;
    top: 50%;
  }

  .search-loader {
    @include transparent($percent: 90);
    position: absolute;
    z-index: 2000;
  }

  .search-adjust {
    top: 120px;
    left: 50%;
    margin-left: -63px;
  }

  .w-head {
    display: inline-block;

    .title {
      font: 17.2px/28px $custom-font-semi-bold;
      color: $color-A3;
      margin-bottom: 3px;

      .icon {
        @include sprite-icon($left: 5, $down: 4, $width: 16px, $height: 16px);
        float: left;
        margin: 5px 7px 0 0;
      }
    }

    .basic-info {
      width: 580px;
      word-break: break-all;

      .label {
        font: 10.9px/13px 'PNLB';
        padding-top: 2px;
        color: $color-A3;
        float: left;
        width: 100%;
      }

      .info {
        width: 600px;
      }

      .value[data-bind='name'] {
        margin-top: 3px;
        margin-bottom: 9px;
      }
    }

    .display-info {
      width: 600px;
      word-break: break-all;
      float: left;
      margin: 0;

      color: $color-A3;
      font-size: 10px;

      .column {
        .concept {
          width: auto;
        }

        .label {
          font-size: 9px;
          margin-top: -2px;
        }

        .value {
          font: 10px/10px $custom-font-bold;
          padding: 2px 0 4px 4px;
          font-size: 11px;
        }
      }
    }
  }

  .w-body-vid {
    padding: 0 32px 50px 48px;
    position: relative !important;

    .form-footer {
      left: 91px !important;
    }
  }

  .body-foot {
    padding-bottom: 55px;
  }

  .body-foot {
    padding-bottom: 55px;
  }

  .form-footer {
    .action {
      .disabled,
      .save-disabled,
      .saveAndContinue-disabled,
      .save-action,
      .saveAndContinue-action {
        pointer-events: none;
      }
    }
  }

  .vast-validation-warning-style {
    overflow: visible;
    width: 622px;
    word-wrap: break-word;

    .info-box {
      margin-top: 0;
      margin-bottom: 0;

      .message {
        padding-right: 16px !important;
        width: 90% !important;
      }
    }
  }

  .unsecure-urls {
    .non-secure-call {
      width: 800px;
      word-wrap: break-word;
      overflow-y: hidden;

      div {
        height: auto;
        max-height: 100px;
        overflow: auto;
        font: normal 11px/12px $unified-font-regular;
      }
      ul {
        margin-bottom: 3px;
      }
      li.secure-list {
        padding: 2px 0;
        list-style: initial;
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 15px;
      }
      .vendor-name {
        font-family: $unified-font-bold;
      }
      .vendor-urls {
        background: rgba(255, 255, 255, 0.5);
        margin: 5px 0;
        padding: 5px 5px 10px 10px;
      }
      .title {
        color: #111111;
        font: 18px/22px $custom-font-semi-bold;
      }
      .beta {
        margin-top: 6px;
      }
    }
  }

  dd {
    width: 100%;
  }

  hr .divide {
    border: 0;
    height: 1px;
    background: #e3e3e3;
  }

  .status {
    .form-radio {
      width: 32%;
    }
  }

  .security-assets-select,
  .mraid-assets-select,
  .verify-assets-select {
    width: 180px;
    .form-radio {
      width: auto;
      padding-right: 15px;
    }
    &.mraid {
      margin-left: 70px;
    }
  }

  .vast-warning-box {
    li {
      list-style: disc inside none;
    }

    #icon {
      margin-top: 30px;
      margin-left: 44px;
    }
  }

  .add-creative {
    position: absolute;
    margin-right: 20px;
    top: 64px;
    right: 0;

    .add-action,
    .add-action-disabled {
      @extend .btn-add-primary;
    }

    .add-action-disabled {
      @include transparent($percent: 50);
    }
  }

  .advertiser-select {
    .form-list {
      min-width: 206px;
    }
  }

  .start-end-dates {
    float: left;

    .date-display {
      float: left;
      width: auto;
      font-size: 14px;
      border-bottom: 1px solid transparent;

      &:hover {
        @extend .isEditable;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .disabled {
      @include transparent($percent: 50);
      cursor: default;
      border-bottom: none;
    }
  }

  .date-picker {
    .form-stack {
      padding: 0;
    }

    .remove-schedule {
      float: right;
      margin-bottom: 0;
    }

    .schedule .title {
      font-family: $custom-font-semi-bold;
      font-weight: 100;
      font-size: 14px;
      color: $color-A3;
    }
  }

  .date-picker-calendar {
    width: 418px;
    height: 264px;
  }

  .mezzanine-file {
    label input[type='checkbox'] + span {
      font-family: $custom-font-semi-bold;
      font-size: 14px;
      margin: 2px 0 0 0;
    }
  }

  .skippable {
    padding-top: 8px !important;

    label input[type='checkbox'] + span {
      font-family: $custom-font-semi-bold;
      font-size: 14px;
      margin: 2px 0 0 0;
      position: absolute;
    }
    .t1-tooltip {
      position: absolute;
      top: -9px;
      left: 90px;
    }

    dd {
      padding-top: 20px;
    }

    .disabled {
      @include transparent($percent: 50);
      cursor: default;
    }
  }

  .video-assets-select {
    .form-radio {
      width: auto;
    }
    .mraid-text {
      padding-top: 5px;

      label {
        font-size: 12px;
        line-height: 14px;
        font-family: $custom-font-regular;
        color: $color-A4;
        padding-bottom: 15px;
        cursor: initial;
      }
      a {
        color: $color-D1;
      }
      b {
        font-family: $custom-font-bold;
      }
    }
  }

  .companion-label {
    font-family: $custom-font-semi-bold;
  }

  .w-vendorsList {
    margin: 0 -20px 0 -48px;

    .url {
      width: 200px;
      float: left;
    }
  }

  .optional-settings {
    .form-stack {
      margin-bottom: 0;
      padding: 15px 13px 16px 48px;
    }
  }

  .companion-holder {
    width: 100%;
  }

  .companion {
    width: 100%;
    margin-top: 10px;
    padding: 5px 0;
    position: relative;
    border-bottom: 1px solid $color-A6;
  }

  .companion-banner {
    margin-top: 20px;
  }

  .w-CompanionList {
    max-width: 100%;
    margin: 0;
    padding-right: 21px;

    .form-stack {
      margin-bottom: 0;
      padding: 15px 13px 16px 32px;
    }

    .text {
      @extend .text;
      font-family: $custom-font-regular;
    }

    .textarea {
      margin-top: 0;
      padding-right: 1px;
    }

    .companion-holder {
      width: 100%;
    }

    .base {
      margin-right: 15px;
      // .text {
      // 	@include calc("width", "100% - 8px");
      // }
    }

    .hidden {
      display: none;
    }

    .server {
      @extend .base;
      //width:87px; //from comp
      width: 182px;
    }

    .type {
      @extend .base;
      width: 87px;
    }

    .width {
      @extend .base;
      width: 67px;
    }

    .height {
      @extend .base;
      width: 67px;
    }

    .upload {
      @extend .base;
      width: 270px;

      &.companion-upload {
        div {
          padding-top: 0;
        }
        .file-upload-btn {
          height: 21px;
          line-height: 22px;
        }
        .file-name {
          height: 16px;
          width: auto;

          .text-input {
            top: -10px;
          }
        }
      }
    }

    .tag-upload {
      width: 409px;
    }

    .click {
      @extend .base;
      width: 120px;
      margin-right: 12px;
    }

    .landing {
      @extend .base;
      width: 120px;
      margin-right: 8px;
    }

    .add-companion {
      span {
        color: $color-D1;
        font-size: 11px;
        line-height: 25px;
        margin-left: 0;
        vertical-align: bottom;
        cursor: pointer;
      }
      &.disabled {
        @include transparent($percent: 50);
        cursor: default;
      }
    }

    .remove {
      @include sprite-icon($left: 7, $down: 0, $width: 16px, $height: 16px);
      position: absolute;
      right: 0;
      top: 16px;
      cursor: pointer;

      &.disabled {
        @include transparent($percent: 50);
        cursor: default;
      }
    }
  }

  .column-campaign {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  .column-video {
    @include width-colx($x: 9);
  }

  .column-tpas-ad-tag {
    @include width-colx($x: 7);
    margin-top: 15px;
  }

  .column .sub-column-A {
    @include width-colx($x: 1.6);
    float: left;
  }

  .column .sub-column-B {
    @include width-colx($x: 1);
    float: left;
  }

  dd.advertiser-select {
    min-height: 27px;
  }

  .vast-radio-row {
    height: 25px;
  }

  .w-CompanionList.upload-assets {
    .form-stack {
      padding: 30px 0 30px 48px;
    }
    .form-list {
      width: 528px;
    }
  }
}

.w-AddConcept {
  position: relative;
  z-index: 1000;

  .popup-container {
    @include rounded-shadow-box(
      $padding: 0,
      $background: $color-A12,
      $border-radius: 4px,
      $border-color: $color-A7,
      $shadow-color: $color-A7,
      $shadow-border: 0,
      $horizontal: 0,
      $vertical: 0,
      $blur: 4px
    );
    position: absolute;
    overflow: hidden;
    width: 100%;
    display: none;
  }

  .form-input {
    float: none;
    margin-bottom: 2px;
  }

  .popup-body,
  .popup-footer {
    padding: 14px;
  }

  .popup-footer {
    @extend .ui-dialog-buttonpane;
    overflow: hidden;
    margin: 0;

    .actions {
      margin-right: 0 !important;
      float: right;

      .action {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .ui-submit {
      &.disabled:hover {
        @include _linear-gradient($color-D8, $color-D9);
      }

      &.disabled:active {
        box-shadow: none;
        behavior: none !important;
      }
    }
  }
}

.w-DisplayAdd,
.w-FbxAdd,
.w-ComponentBasedAdd {
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;

  .clear-filter {
    margin-right: -36px;
  }

  .w-head {
    .title {
      padding: 18px 0 18px 30px;
      margin: 0;
      display: inline-block;
      width: 550px;
    }

    .advertiserName {
      cursor: text;
      position: relative;
      top: 7px;
      margin: 0 0 -13px 53px;
      font: 11px/21px $unified-font-bold;
      color: $color-D4;
      text-transform: uppercase;
      width: 500px;
    }
  }

  .w-body {
    background: $color-A12;
    top: 72px !important;
  }

  .details {
    padding-bottom: 75px;
  }

  .form-footer {
    left: 113px;
  }

  .w-container {
    border-top: 1px solid $color-A7;
  }

  .form-stack {
    padding: 22px 32px 0;
    margin: 0;
    background: #ffffff;

    .container {
      float: left;
    }
  }

  .form-list {
    box-sizing: border-box;

    &.bulk-3pas-template {
      margin-top: 23px;
    }

    .video-file-name {
      padding-top: 15px;
    }
  }

  .form-input .text,
  .form-input .textarea {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    margin: 0;
  }

  .form-input .textarea.resizable {
    resize: vertical;
  }

  .secure,
  .mraid,
  .verify {
    .t1-tooltip {
      position: absolute;
      top: -11px;
    }
  }

  .secure {
    .t1-tooltip {
      left: 85px;
    }
  }

  .mraid {
    .t1-tooltip {
      left: 48px;
    }
  }

  .verify {
    .t1-tooltip {
      left: 98px;
    }
  }

  .inline-box-guidance {
    margin: 20px 30px 0;
    display: none;
  }

  .beta {
    padding: 0 2px 0 5px;
  }

  .kb-link {
    text-decoration: none;

    .info-tip {
      color: #525252;
      opacity: 0.3;
    }
  }

  .upload-assets {
    margin: 22px 0 50px;
    border-top: 1px solid $color-A7;

    .form-list {
      width: 528px;
    }

    .form-stack {
      padding-bottom: 22px;
    }

    .info-box {
      margin: 13px 0 30px;
    }

    .inline-box-error {
      padding-top: 10px;
    }

    .directions {
      font: normal 13px/18px $unified-font-regular;
      margin: 30px 0 0 15px;
      display: block;
      clear: left;

      li {
        list-style-type: disc;

        .beta {
          padding: 0;
        }

        a {
          color: $color-D16;
          text-decoration: underline;
        }
      }

      .notes {
        padding-left: 20px;

        li {
          list-style-type: none;
        }
      }
    }
  }

  .w-review-list {
    top: 80px;

    .form-list dt {
      margin: 0;

      span {
        font-family: $custom-font-regular;
        font-weight: normal;
      }
    }

    .w-body.content {
      padding: 0;
      margin: 0;
    }

    .w-Pull-Down-Button {
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 32px;
    }

    .checkbox-input .checkbox {
      margin-right: 10px;
    }

    .w-head {
      padding-right: 20px;
    }

    .bulk3pas {
      .bulk-valid-grid {
        overflow: visible;
        height: 100%;
        min-height: 65px;
        cursor: pointer;

        .left-items #label {
          font: 10px/14px 'PNLB' !important;
        }

        strand-grid-item {
          font: 11px/23px 'AR' !important;

          &:hover {
            background-color: $color-E8;
          }

          &.disabled,
          .item-text.disabled {
            opacity: 0.5;
          }

          .item-text {
            .editable {
              border-bottom: 1px transparent solid;

              &:hover {
                cursor: pointer;
                border-bottom: 1px $color-A1 dotted;
              }
            }
          }

          .concept {
            cursor: default;
          }

          .expansion {
            display: flex;
            padding: 0 20px 20px 70px;
          }

          .expansion-section-head {
            color: $color-A4;
            font-size: 11px;
            padding: 10px 10px 10px 0;
            text-transform: uppercase;
          }

          .exp-row {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-right: 30px;
          }

          .exp-row:first-child {
            flex-grow: 0;
          }

          .exp-col {
            display: flex;
            max-height: 200px;

            strand-input {
              width: 250px;
            }

            creatives-classification-grid-drawer {
              background-color: $color-A10;
              flex: 1;
              margin-right: 20px;

              .container {
                float: initial !important;
              }
            }
          }
        }
      }

      strand-grid-item:hover {
        background-color: $color-E8 !important;
      }
    }

    .bulk-header-invalid {
      padding-top: 50px;
    }

    .t1as-header-invalid {
      padding-top: 50px !important;
    }

    .t1as-valid {
      .item-text {
        .editable {
          border-bottom: 1px transparent solid;

          &:hover {
            cursor: pointer;
            border-bottom: 1px $color-A1 dotted;
          }
        }
      }
    }

    .t1as-valid,
    .t1as-invalid {
      .content-holder {
        overflow: visible;
      }

      .column {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .concept {
        cursor: default;
      }

      .item-text {
        font: 11px/23px 'AR' !important;

        strand-dropdown {
          width: 100%;
          display: block !important;
        }

        strand-dropdown .button {
          height: 25px;
          width: 98% !important;
        }
      }

      select {
        width: 90%;
        margin: 0;
        padding: 0;
      }

      .text-input {
        margin: 0;
      }

      input[type='text'] {
        width: 90%;
      }

      // strand-Grid Styles
      strand-grid {
        overflow: visible;
        height: 100%;
        min-height: 65px;
        cursor: pointer;

        .left-items #label {
          font: 10px/14px 'PNLB' !important;
        }

        strand-grid-item {
          font: 11px/23px 'AR' !important;
          color: #000000;

          &:hover {
            background-color: $color-E8 !important;
          }

          .click-thru {
            overflow: visible;
          }

          .backup-file {
            overflow: visible;
          }

          .expansion {
            padding: 0 20px 20px 70px;
          }

          .expansion-section-head {
            color: $color-A4;
            font-size: 11px;
            padding: 10px 10px 10px 0;
            text-transform: uppercase;
          }

          .mraid-row {
            margin-bottom: 5px;

            .expansion-section-head {
              padding-bottom: 8px;
            }
          }

          .exp-row-hold {
            display: flex;
          }

          .exp-row {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-right: 30px;
          }

          .exp-row-full {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-right: 50px;
          }

          .exp-row:first-child {
            flex-grow: 0;
          }

          .exp-col {
            display: flex;
            max-height: 200px;

            strand-input {
              width: 250px;
            }
          }

          .tpas_ad_tag {
            width: 495px;
          }

          creatives-classification-grid-drawer {
            background-color: $color-A10;
            flex: 1;
            margin-right: 20px;

            strand-scroll-panel {
              background-color: $color-A12;
            }

            .container {
              float: initial !important;
            }
          }
        }
      }

      strand-input input {
        overflow: hidden !important;
        font: 13px 'PNR' !important;
      }

      strand-input input::-webkit-input-placeholder {
        font-style: normal;
      }

      strand-input input::-moz-placeholder {
        font-style: normal;
      }

      strand-input input:-ms-input-placeholder {
        font-style: normal;
      }
    }
  }
}

.bulk-3pas-review {
  padding: 25px;

  .info-box {
    border-color: #f85252;
    padding: 20px 15px;

    p {
      font-size: 12px;
      margin-top: 1em;
      max-width: 1010px;
      padding-left: 22px;
    }
  }

  .bulk-header {
    padding: 22px 0 10px 15px;

    dt {
      font-size: 16px;
    }
  }

  .bulk-3pas-grid-container {
    margin-bottom: 20px;
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: auto;

    #columnContainer > .strand-grid-item:first-of-type {
      color: #5d769c;
      font-weight: bold;
    }

    ._mm_item.strand-grid-item {
      padding: 0;
    }

    .columnContentWrapper.strand-grid-item {
      position: initial;
    }

    ._mm_column.strand-grid-item {
      height: 37px;
      padding: 10px;
      position: relative;
    }

    // error styling
    .columnContent > [field] > span::before {
      background-color: rgba(248, 82, 82, 0.2);
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
    }

    [field='status'] .status_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='name'] .name_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='end_date'] .end_date_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='ad_server_type'] .ad_server_type_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='click_url'] .click_url_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='external_identifier'] .external_identifier_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='concept_is_new'] .concept_is_new_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='concept_name'] .concept_name_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='concept_id'] .concept_id_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='file_type'] .file_type_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='tag_type'] .tag_type_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='ad_format'] .ad_format_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='is_mraid'] .is_mraid_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='expansion_direction'] .expansion_direction_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='expansion_trigger'] .expansion_trigger_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='width'] .width_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='height'] .height_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='tag'] .tag_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }

    [field='tpas_ad_tag_name'] .tpas_ad_tag_name_error {
      color: #f85252;
      font-weight: 700;

      &::before {
        content: '';
      }
    }
  }
}

.w-DisplayConcept {
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;

  .clear-filter {
    margin-right: 0;
    margin-left: -40px;
  }

  .conceptModalP {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  .conceptModalHeader {
    margin-bottom: 15px;
  }

  .concept-modal-header-warning-icon {
    color: #ffcc33;
  }

  .conceptModalTop {
    padding: 30px;
  }

  .w-head {
    .title {
      padding: 18px 0 18px 30px;
      margin: 0;
      display: inline-block;
      width: 550px;
    }

    .advertiserName {
      cursor: text;
      position: relative;
      top: 7px;
      margin: 0 0 -13px 53px;
      font: 11px/21px $unified-font-bold;
      color: $color-D4;
      text-transform: uppercase;
      width: 500px;
    }
  }

  .w-body {
    background: $color-A12;
    border-top: 1px solid $color-A7;
  }

  .form-stack {
    padding: 22px 32px 0;
    margin: 0;
  }

  .form-list {
    box-sizing: border-box;
  }

  .form-input .text,
  .form-input .textarea {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    margin: 0;
  }

  .form-input .textarea.resizable {
    resize: vertical;
  }

  .secure {
    .t1-tooltip {
      position: absolute;
      top: -11px;
      left: 56px;
    }
  }

  .w-review-list {
    top: 80px;

    .form-list dt {
      margin: 0;

      span {
        font-family: $custom-font-regular;
        font-weight: normal;
      }
    }

    .w-body.content {
      padding: 0;
      margin: 0;
    }

    .w-Pull-Down-Button {
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 32px;
    }

    .checkbox-input .checkbox {
      margin-right: 10px;
    }

    .w-head {
      padding-right: 20px;
    }

    .t1as-valid,
    .t1as-invalid {
      .content-holder {
        overflow: visible;
      }

      .column {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-text.disabled {
        opacity: 0.5;
      }

      .item {
        font: 11px/23px 'AR';
      }

      select {
        width: 90%;
        margin: 0;
        padding: 0;
      }

      .text-input {
        margin: 0;
      }

      .chzn-container {
        font: 11px/23px 'AR';
        width: 100%;
      }

      .chzn-drop {
        margin-top: 1px;
      }

      .chzn-container-single .chzn-single span {
        margin-right: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      input[type='text'] {
        width: 90%;
      }

      .click-thru {
        overflow: visible;
      }

      .backup-file {
        overflow: visible;
        max-height: 25px;
      }
    }
  }
}

.w-customAlert {
  overflow: visible !important;

  .body-content {
    margin-top: 20px;
  }

  .text-input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    width: 100%;
  }

  .chzn-container {
    width: 100%;

    .chzn-drop {
      width: 100% !important;
    }
  }

  .select-max {
    margin: 0;
    max-height: 25px;
  }

  .input-holder {
    margin-top: 10px;
  }

  .info-box {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px 0 0;
  }

  .move-top {
    margin-top: 5px;
  }
}

.w-review-error-tooltip {
  background-color: $color-C1;

  .tipsy-inner {
    color: $color-A12;
    background-color: $color-C1;
    padding: 8px;
    word-wrap: break-word;
    white-space: normal;
    max-width: 850px;
  }

  .tipsy-arrow-c {
    position: absolute;

    .tipsy-arrow-s {
      left: calc(60% + 50px);
    }
  }

  .tipsy-arrow-s {
    border-top-color: $color-C1;
    left: 46%;
  }
}

.w-customAlert.click-thru {
  width: 500px !important;
}

.w-customAlert.concept {
  .head {
    margin-left: 0;
  }
}

.w-StrategyGrid {
  height: 370px;

  .clear-filter {
    margin-right: 0;
  }
  .item {
    cursor: default;
    padding: 10px 0 10px 20px;

    .column {
      padding-right: 5px;
    }
  }
  .w-head {
    display: block;

    .column {
      padding-right: 5px;
    }
  }
  .strategy-list-body {
    .content-holder {
      height: 257px;
      overflow: hidden;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .campaign-name {
    width: 25%;
  }
  .campaign-id {
    width: 15%;
  }
  .strategy-name {
    width: 25%;

    .column {
      width: auto;
    }

    .truncate-ellipsis {
      max-width: 90%;
    }
  }
  .strategy-id {
    width: 15%;
  }

  .link-icon-hold {
    float: left;
    width: 13px;
    height: 13px;
    padding: 0;
    display: none;

    a {
      color: #666;
      line-height: 7px;
      margin: 0 0 0 6px;
    }
  }
}

.w-CreativeBin {
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 372px;
  display: none;

  .clear-filter {
    margin-left: -40px !important;
  }
  .w-head {
    display: block;
  }
  .browse {
    //@extend .last;
    width: 74%;
    height: inherit;
    .item {
      padding: 0;
      position: relative;
    }
    .item-row {
      padding: 8px 0;
    }
    .clear {
      clear: both !important;
    }
    .creative-list-grid {
      .content-holder {
        height: 257px;
        overflow: hidden;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    .column {
      word-break: break-all;
      padding-right: 20px;
    }
    .headers {
      background-color: #e0e0e0;
      padding-left: 0;
      padding-right: 0;
    }
    .header {
      padding: 0 20px 0 0;
    }
    .column-search {
      float: left;
    }
    .column-head {
      float: right;

      .head-select {
        width: 100px;
        margin-top: 8px;
      }
    }
    .side-label {
      font-size: 13px;
      font-weight: normal;
      text-align: right;
      width: auto;
      padding: 8px 6px 0 16px;
      margin-top: 8px;
    }
    .side-label-first {
      padding: 8px 6px 0 0;
    }
    .header.creative-name {
      width: 28%;
      padding-left: 30px;
      padding-right: 0;
      .text {
        display: table-cell;
      }
    }
    .creative-name {
      width: 28%;
      padding-left: 9px;
      .text {
        display: table-cell;
      }
    }
    .id {
      width: 8%;
    }
    .status {
      width: 5%;
    }
    .externalID {
      width: 14%;

      // CSS Truncate
      .truncate {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .details {
      width: 14%;
    }
    .type {
      width: 6%;
    }
    .secure {
      width: 6%;

      .secure-warning {
        word-break: keep-all;
      }
    }
    .verified-secure {
      color: #9ecb5c;
    }
    .verified-nonsecure {
      color: #fed01a;
    }
    .verified-tip {
      word-break: normal;
    }
    .drag {
      padding-left: 10px;
      padding-right: 10px;
      width: 14px;
    }
    .include {
      @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
      @include transparent(30);
      display: block;
      position: inline;
      margin-right: 8px;
      margin-bottom: auto;

      &:hover {
        @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
        @include transparent(100);
      }

      &.active {
        @include sprite-icon($left: 10, $down: 1, $width: 15px, $height: 15px);
        @include transparent(100);
      }
    }
    .exclude {
      @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
      @include transparent(30);
      display: block;
      position: inline;
      margin-right: 5px;
      margin-bottom: auto;

      &:hover {
        @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
        @include transparent(100);
      }

      &.active {
        @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
        @include transparent(100);
      }
    }
  }

  .bins-column {
    @include box-fill($left: auto !important);
    width: 24%;
    right: 27px;
    margin-top: 20px;
    height: inherit;

    .concept_icon {
      border: 1px solid $color-A5;
      border-radius: 2px;
      background-color: $color-A6;
      text-align: center;
      float: left;
      color: $color-A1;
      width: 11px;
      padding-top: 2px;
      padding-bottom: 2px;
      font: 9px/7px $custom-font-regular;
      letter-spacing: 1px;
    }

    .creative-list-name {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .w-body {
      top: 0 !important;
      margin-right: 5px;
    }

    .w-Bins .bin {
      border-left: 1px solid #e0e0e0;
      height: 90%;
      border-bottom: 1px solid #e0e0e0;

      .text {
        padding-left: 28px;
        padding-bottom: 5px;
        padding-top: 4px;
        cursor: default;
      }

      .icon {
        float: left;
        margin: 5px 6px 0 8px;
      }

      .remove-icon {
        top: 12px;
      }

      .title {
        margin-left: 0;
      }
    }

    .include {
      @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
      @include transparent(30);

      &:hover {
        @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
        @include transparent(100);
      }

      &.active {
        @include sprite-icon($left: 10, $down: 1, $width: 15px, $height: 15px);
        @include transparent(100);
      }
    }

    .exclude {
      @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
      @include transparent(30);

      &:hover {
        @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
        @include transparent(100);
      }

      &.active {
        @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
        @include transparent(100);
      }
    }
  }
}

@import 'tracking-list';
@import 'creatives-create-edit-3pas-single';
