.overlay-view {
	background: $color-D12;
	display: block;
	width: auto;
	float: none;

	.tab-level-1 {
		@include box-fill();

		>.tabs-content {
			@include box-fill($top: 40px);
			background-color: $color-A12;
		}
	}

	.tab-level-2 {
		>.ui-tabs-nav {
			padding-top: 7px;
		}

		>.tabs-content {
			top: 37px !important;
			bottom: 50px;
			position: relative;
			background-color: $color-A12;
		}
	}
}