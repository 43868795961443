@import "mixins";

loginButton {
	@extend .btn-primary;
	height: 46px;
	width: 46px;
	position: relative;
}

.w-Auth {

	.w-login-c,
	.w-reset-c {
		height: 116px;
		position: relative;
	}

	.email-address-input,
	.password-input,
	.password-reset {
		float: left;
	}

	.actions {
		float: right;
	}

	.action {
		position: relative;
		float: right;
	}

	.w-foot {
		position: relative;
	}

	.warning-message {
		padding-bottom: 10px !important;

		a:link, a:visited {
			color: $color-D8;
			decoration: none;
		}
	}

	.warning-message .icon {
		@include sprite-icon($left: 8, $down: 4, $width: 27px, $height: 25px);
		float: left;
		margin: 5px 12px 0 0;
	}

	.warning-message .msg {
		float: left;
		font: normal 11.4px/14px $unified-font-regular;
		color: $color-D8;
		width: 330px;
	}

	.version-information {
		margin-top: 100px;
		font: normal 12px/13px $unified-font-regular;
	}

	.w-forgot-password-c,
	.w-email-sent-c,
	.w-success-c {
		@extend .box-fix;
		position: relative;
		width: auto;
		display: none;

		.email-address-input {
			width: 85.5%;
		}
	}

	.form-input {
		margin: 0 16px 10px;

		.text,
		.password {
			@include inputShadow();
			font-size: 16px;
			height: 24px;
			padding: 10px 15px;
			margin: 0 -16px;
			border: 1px solid $color-A4;
			width: 100%;
			color: $color-A3;
		}
	}

	.w-head h3 {
		color: $color-A1;
		font-size: 18px;
	}

	.login {
		position: relative;
	}

	.action {
		@extend loginButton;
		span {
			background: none;
			height: 25px;
			left: 12px;
			position: absolute;
			top: 10px;
			width: 25px;
		}
	}

	.action-span {
		@include sprite-icon($left: 8, $down: 5, $width: 27px, $height: 25px);
		position: absolute;
		left: 9px;
	}

	.login-action,
	.reset-action {
		span {
			@extend .action-span;
			top: 10px;
		}
	}

	.email-action {
		span {
			@extend .action-span;
		}
	}

	.cancel {
		margin-top: 32px;
		margin-left: 10px;
	}

	.forgot-password,
	.cancel,
	.return-to-login {
		clear: both;
		float: left;
		color: $color-D2;
		font-size: 14px;
		margin-bottom: 20px;

		&:hover {
			color: $color-A12;
		}
	}

	.remember-me-txt {
		color: $color-D2;
	}

	.forgot-password {
		clear: both;
		float: right;
		color: $color-D2;
		font-size: 14px;
		margin: 1px 0 32px;

		&:hover {
			color: $color-A12;
		}
	}

	.forgot-password-header,
	.reset-header,
	.success-header {
		font-family: $unified-font-bold;
		font-weight: normal;
		color: $color-A12;
		font-size: 18px;
		padding-bottom: 8px;
		margin-top: 8px;
	}

	.forgot-password-description,
	.success-description {
		color: $color-A12;
		font-size: 12px;
		font-weight: normal;
		padding-bottom: 12px;
		line-height: 16px;
	}

	.mm-social-description {
		color: $color-A12;
		font-size: 14px;
		font-weight: normal;
		padding-bottom: 12px;
		line-height: 16px;
	}
}

.w-LoginDialog {
	@extend .dialog-panel;
	@include border_radius($radius: 3px);

	background: $color-D3;
	width: 380px !important;
	position: absolute !important;
	padding: 0;
	border: 3px solid $color-A2;

	.w-head {
		margin-bottom: 35px;

		.session-msg,
		.login-msg {
			color: $color-A7;
			text-align: center;
		}

		.login-msg {
			margin-top: 5px;
		}
	}

	.w-foot {
		display: none;
	}

	.ui-widget-content {
		padding: 0;
	}

	.ui-widget-header {
		display: none;
	}

	.w-Login {
		padding: .6em 1.3em 1.3em 1.3em;
	}

	.login-content {
		padding: 10px 0;
	}

	.password-input {
		width: 85%;
	}

	.w-errorBox {
		width: auto;
	}
}

.w-AuthInline {
	z-index: 1;

	.w-head {
		width: 365px;
		height: 85px;
	}

	.password-input {
		width: 85.5%;
	}

	.error-list {
		width: auto;
		height: auto;
		line-height: 16px;
	}
}

.w-Logout {
	margin: 0 auto;
	padding-top: 25px;
	text-align: center;

	h3 {
		font-size: 18px;
		font-weight: normal;
	}

	h5 {
		font-size: 12px;
		font-weight: normal;
	}
}

.w-adBlockDetected {

	.content-top {
		padding: 15px;
		font-family: $unified-font-regular;
	}

	strand-header {
		padding-bottom: 20px;
		
		.bab-warning-icon {
			color: $color-A23;
		}
	}
}


