@import 'mixins';

.w-CampaignDuplicate {
  @extend .dialog-panel;

  .campaignDuplicateModal {
    z-index: 1000 !important;
  }

  .form-body {
    padding: 0;

    .error-holder {
      padding-top: 0;
      width: 400px;
      .error-box {
        display: none;
      }
    }

    > div {
      margin: 0;
      padding: 19px 48px 19px 32px;
    }

    strand-header {
      margin-bottom: 10px;
    }
  }

  .form-stack:last-child {
    margin-bottom: 0;
  }

  .modal-content {
    overflow-y: scroll;
    max-height: calc(90vh - 60px);
    min-width: 1000px;
  }

  .w-head {
    margin-top: 25px;

    .basic-info {
      margin-left: 32px;
    }

    .info {
      padding-bottom: 3px;
    }
  }

  .duplicating {
    width: 100%;
  }

  .campaign-settings-container {
    background-color: $color-A10;
    border-color: $color-A7;
    border-style: solid;
    border-width: 1px 0;
  }

  #advertiser-loading-el {
    top: -8px;
    left: 50px;
  }

  .w-PixelBundlesFilter {
    .select {
      width: 210px;
    }
  }

  .form-stack {
    .column {
      width: 210px;
    }

    .column-C {
      padding-right: 20px;
    }

    .start-end-dates {
      width: 675px;
      margin-top: -11px;
    }

    .duplicate-campaign-chk-header {
      font-family: $custom-font-semi-bold;
    }

    .duplicate-campaign-brain-chk {
      .tab-info {
        margin-right: 51px;
        margin-top: 2px;
      }
    }

    .campaign-brain {
      margin-top: 22px;
    }

    .strategy-search-input {
      width: 325px;
    }

    .show-selected {
      margin-top: 5px;
    }

    .dropdown-holder,
    .select {
      width: 180px;
    }
  }

  .campaigns-select-label {
    display: inline-block;
    margin-right: 10px;

    strand-header {
      margin-bottom: 0;
    }
  }

  dd {
    &.campaigns-select {
      display: inline-block;
      float: none;
      width: 300px;
      vertical-align: middle;
    }
  }

  .strategy-search-input {
    float: left;
    padding-right: 30px;
  }

  .duplicate-strategies-grid {
    max-height: 200px;
  }

  .w-DataGrid {
    height: 160px;
    .checkbox-input-field {
      width: 24px;
    }

    .current-strategy-name {
      width: 350px;
    }

    .id {
      width: 121px;
      padding-left: 4px;
      top: 6px;
    }

    .no-of-copies {
      width: 119px;
    }

    .header.checkbox,
    .header.current-strategy-name,
    .header.id {
      top: 1px;
    }

    .item {
      padding: 5px 20px;

      .checkbox-input-field {
        padding-top: 5px;
      }

      .current-strategy-name {
        padding-top: 7px;
      }
    }

    .errored-strategy-name {
      width: 300px;
    }
    .errored-reason {
      width: 350px;
    }
  }

  .campaignDuplicateSavingModal {
    .saving-modal {
      padding: 25px 0px 75px 25px;

      .header-holder:first-child {
        padding-right: 7px;
      }

      .spinner-holder {
        margin-top: 10px;
        margin-left: -6px;
      }

      .label {
        font-family: $custom-font-regular;
        font-size: 11px;
      }
    }
  }

  .campaignDuplicateErrorModal {
    .saving-error-modal {
      padding: 25px;
    }

    .header-holder {
      padding-bottom: 10px;

      #headerIcon {
        color: #c32921;
        padding-right: 5px;
        vertical-align: text-bottom;
      }
    }

    .error-message-holder {
      padding: 10px 0 25px 25px;
    }
  }
}

#date-picker-duplicatecampaign {
  @extend .datePickerCreator;
}
