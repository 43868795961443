.w-FilterList {
	@include rounded-box($padding: 0, $background: $color-A12, $border-radius: 4px, $border-rule:1px, $border-color:$color-A7);

	.search {
		padding: 0 6px;
		border-bottom: 1px solid $color-A7;
	}

	.txt {
		margin: 3px 0;
		padding: 3px 22px 4px 3px;
		width: 246px !important;
		height: 16px;
		border: none;
		font: normal 11px/14px $unified-font-regular;
		letter-spacing: 0.4px;
		background: $color-A12 url('../images/chosen-sprite.png') no-repeat 100% -22px;

		&:focus {
			outline: none;
		}
	}

	.list {
		position: relative;
		overflow: hidden;
		max-height: 210px;
		margin-top: 4px;
		padding: 6px 0 10px;

		input[type="checkbox"] + span {
			font: normal 11px/14px $unified-font-regular;
			letter-spacing: 0.4px;
			width: 270px;
			padding: 6px 10px 4px;
		}
	}

	.fl-item {
		cursor: pointer;

		&.selected {
			background-color: $color-D14;
		}

		&:hover {
			background-color: $color-E8;
		}

		&.disabled {
	  		@include transparent();
		}
	}

	.all, .all-search-results {
		input[type="checkbox"] + span {
			font: normal 11px/14px $unified-font-bold;
		}
	}

	.icon-spinner .spinner {
		left: 11px !important;
		top: 7px !important;
	}

	.loading-msg {
		margin-left: 23px;
		color: $color-A3;
	}

	.empty-msg {
		margin-left: 5px;
		color: $color-A3;
	}

	.no-result {
		padding: 5px 10px;
		background-color: $color-A8;
		font: normal 12px/14px $custom-font-semi-bold;
	}

	&.disabled {
		@include transparent();
	}

	.form-footer,
	.form-footer-style {
		@include rounded-box($padding: 0, $background: $color-D12, $border-radius: 0 0 4px 4px, $border-rule: 0, $border-color: $color-A8);
		width: 291px;
		margin-left: -10px;
		padding: 10px 9px 10px 0;
		background-color: $color-D12;
		border-top: 1px solid $color-A8;

		.actions {
			margin-right: 2px !important;
		}

		.ui-cancel {
			margin-right: 0;
		}

		.ui-submit {
			&.disabled:hover {
				@include _linear-gradient($color-D8, $color-D9);
			}

			&.disabled:active {
				box-shadow: none;
				behavior: none !important;
			}
		}
	}
}