.onboarding-head {
  strand-header {
    display: inline-block !important;
    .strand-header {
      margin-left: 5px;
    }
  }
}

.onboarding-create {
  background: $color-A12;

  .disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  #onboarding-form {
    max-width: 1080px;
    min-height: calc(100vh - 160px);

    .form-stack {
      padding: 22px 32px 0;
      margin: 1em;

      .header-small {
        padding: 5px 0 !important;
      }

      .disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
      }

      #otherImplementation,
      #otherTagManagement,
      #tagManagementGroup {
        display: none;
      }

      #selected-advertiser,
      #editImplementation,
      #selected-name,
      #group-id {
        font: normal 13px/14px $unified-font-regular;
        color: $color-A3;
      }

      .attributes-headers dd {
        &:not(:last-child) {
          margin-right: 20px !important;
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    #sectionTwo, #sectionThree {
      .edit-headers {
        .col-1-4 {
          margin-right: 0 !important;
          width: 231px !important;
        }
        #pixelId {
          text-align: center;
          width: 70px !important;
        }
      }
    }

    .data-pixels-section {
      .data-pixels-repeater-row {
        font-size: 13px;
      }

      .pricing-input {
        padding-right: 15px;
      }
    }

    .events-attributes-section {
      display: none;

      .col-4-4 {
        margin: 3em 0 0;
      }

      [id$='Error'] {
        margin-top: 3px;
      }

      strand-repeater {
        .remove-row {
          padding: 5px;
          position: absolute;
        }
        .add-row {
          position: absolute;
          bottom: -22px;
          right: -62px;
          width: 200px;
        }
        .clearRow {
          margin-left: -3px !important;
          margin-right: -17px !important;
          color: #999;
          cursor: pointer;
        }
      }
      .add-attr-row {
        float: right;
        label {
          margin: 1em 0 0;
        }
      }

      #deliveryFormat {
        display: block;
      }

      .bottom-section {
        margin-bottom: 100px;
        margin-top: 13px;
      }
    }
    /* Four Column Grid (ie: 1/4, 2/4, 3/4, 4/4)
     dolson - Much clearer than Column-A/B/C thats happening elsewhere
     Could easily change these to be fluid width */
    .col-1-4,
    .col-2-4,
    .col-3-4,
    .col-4-4 {
      @extend .column;
    }

    .col-1-4 {
      @include width-colx($x: 3);
      padding: 1em 0 0;
    }

    .col-2-4 {
      @include width-colx($x: 6);
      padding: 1em 0 0;
    }

    .col-3-4 {
      @include width-colx($x: 9);
      padding: 1em 0 0;
    }

    .col-4-4 {
      @include width-colx($x: 12);
    }

    .error-message {
      left: 0 !important;
      padding-right: 6px;
      padding-left: 4px;
    }
  }

  #onboarding-create-footer {
    #save-button {
      width: 53px;
    }
  }

  #view-onboarding-modal {
    strand-header {
      margin: 20px 0;
      text-transform: capitalize;
    }

    .content-top {
      padding: 15px;
    }

    #onboarding-modal-text {
      overflow: scroll;
      max-height: 65vh;
      padding: 0 1em;

      pre {
        font-family: $custom-mono-font-regular !important;
        font-size: 80%;
      }
    }
  }
}
