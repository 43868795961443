.w-ChartToggle {
	position: relative;
	float: left;
	width: 100%;

	.toggle-divider {
		position: absolute;
		width: 100%;
		height: 0;
		border-bottom: 1px solid $color-A7;
		background-color: $color-A12;

		&.campaign-chart {
			border-left: 1px solid $color-A7;
			border-right: 1px solid $color-A7;
			height: 4px;
		}
	}

	.toggle-btn {
		@include box-fill($top:0, $right:0, $bottom:auto, $left:0);
		@include button($bg-color: $color-A12,
		$color-top: $color-A12,
		$color-bottom: $color-A12,
		$border-color: $color-A7,
		$shadow-color: $color-C4,
		$color: $color-A3);
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-left: auto;
		margin-right: auto;
		width: 89px;
		padding: 2px !important;
		background-color: $color-A12;
	}

	.campaign-chart .toggle-btn {
		top: 4px;
	}

	.arrow {
		position: absolute;
		top: 6px;
		left: 8px;

		&.hide {
			@include sprite-icon($left: 9, $down: 8, $width: 7px, $height: 9px);
		}
		&.show {
			@include sprite-icon($left: 10, $down: 8, $width: 7px, $height: 9px);
			margin-top: 1px;
		}
	}

	.toggle-label {
		margin-left: 18px;
		letter-spacing: 1px;
		font-size: 11px;
		text-transform: capitalize;
		color: $color-A3;
		font-family: $unified-font-regular;

		&.hide {
			margin-left: 20px;
		}
	}
}