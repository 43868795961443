@import "mixins";

.w-Targeting {
	.w-SiteList {
		height: 100%;
		background-color: $color-A12;

		.eligible {
			width: 100%;
			padding-left: 0;

			label {
				float: right;
				display: block;
				margin-right: 5px;
				margin-top: 12px;

				input[type="checkbox"] + span {
					font: normal 11px/12px $custom-font-regular;
					color: $color-A2;
				}
			}
		}


		.headers {
			padding-left: 0;

			.name {
				padding-left: 13px;

				.header-c {
					padding-left: 26px;
				}
			}
		}

		.content {
			bottom: 47px;

			.item {
				padding: 0;

				.item-row {
					@extend .box-fix;
					width: 100%;
					padding: 8px 0;

					.name {
						padding-left: 12px;

						.item-c {
							float: left;
						}

						.icon {
							margin-top: 2px;
						}

						.exclude {
							margin-left: 0px;
						}

						.text {
							margin-left: 12px;
							color: $color-A3;
							line-height: 18px;
							width: 80%;
						}
					}

					.assignedToCampaign {
						padding-top: 2px;

						.icon {
							@include sprite-icon($left: 6, $down: 10, $width: 15px, $height: 15px);
							@include transparent(30);

							&.active {
								@include transparent(100);
							}
						}
					}
				}
			}
		}


		.w-foot {
			background-color: $color-A12;
		}

		.name {
			width: 29%;
		}

		.assignedToCampaign {
			width: 3%;
			text-align: right;
		}

		.type {
			width: 9%;
			text-align: right;
		}

		.domains,
		.apps {
			text-align: right;
		}

		.domains {
			width: 13%;
		}

		.apps {
			width: 11%;
		}

		.lastUpdated {
			width: 16%;
			text-align: right;
			word-wrap: break-word;
		}

		.siteId {
			width: 6%;
			text-align: right;
		}

	  .download {
			width: 4%;
			text-align: right;

			.icon {
				@include sprite-icon($left: 11, $down: 2, $width: 15px, $height: 15px);
				@include transparent(30);
				float: right;

				&:hover {
					@include transparent(100);
				}
			}
		}


	}

	.w-SiteListTargeting {
		.bin {
			height: 100%;
		}
	}
    .w-StrategyTargeting-tabset .w-SiteListTargeting .content{
      bottom: 47px !important;
    }
	.w-Bins {
		.segment {
			.site-list-title {
				margin-left: 3px;
			}
		}

		.site-list-name {
			width: 88%;
		}

		.icon {
			float: left;
			margin: 2px 6px 0 8px;
		}

		.assignedToCampaign {
			padding-top: 2px;
			.icon {
				@include sprite-icon($left: 6, $down: 10, $width: 15px, $height: 15px);
				@include transparent(30);

				&.active {
					@include transparent(100);
				}
			}
		}

		.additional-sites .remove-icon {
			display: none;
		}
	}

	.list-footer {
	  min-width: 780px;
      bottom: 0px !important;

        .actions {
          float: right;
        }

        .exclusivenessOptionBlock {
            padding-left:10px;
            float:left;

			.exclusivenessFeatureLabel{
			  	font-size:13px !important;
                vertical-align: top;
                line-height: 25px;
			}

		  	.exclusivenessCheckBoxLabel{
				font-size:10px !important;
			}

            .selectHolder {
                width:250px;
                display: inline-block !important;
            }

            .select {
              width:250px;
            }

            .checkBoxHolder {
			  display: inline-block;
              padding-left:5px;
              vertical-align: top;
              line-height: 25px;
            }
        }
 	}
}

.w-AdditionalSitesDialog {
	@extend .dialog-panel-form;

	.w-head {
		margin: 0 0 36px;
	}

	h1 {
		font-size: 22px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0;
	}

	.form-list {
		dt {
			float: left;
			padding: 5px 5px 0 0;
		}

		.type-select {
			float: left;
		}
	}

	.type-select {
		width: 100px;

		.chzn-container {
			width: 100px;
		}

		.chzn-drop {
			width: 100px !important;
		}
	}
}
