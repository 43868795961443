.deal-analytics-container {
  display: flex;
  flex-direction: column;

  .choose-action {
    text-align: left;
    z-index: 3;
    margin-right: 5px;

    .option-disabled {
      color: $color-A5;
    }
  }

  .deal-analytics-grid-container {
    position: absolute !important;
    top: 74px;
    right: 0;
    bottom: 0;
    left: 0;

    .deal-analytics-grid {
      height: 100%;
      min-width: 1200px;
      width: 100%;

      .disable-text-transform {
        #label {
          text-transform: none;
        }
      }

      .deal-item-status-false,
      .deal-item-status-0 {
        color: $color-A5;
      }

      .deal-item-assignment-true {
        color: $color-B1;
      }
    }
  }

  #dialogInnerModal {
    .content-top {
      margin-bottom: 0;
    }

    #dialogMessage {
      padding: 10px 0 0;
      max-height: calc(100vh - 200px);
      overflow-y: scroll;

      .loadContainer {
        display: inline-block;
        margin: 0 0 40px;
      }

      .campaign {
        font-weight: bold;
      }

      strand-action {
        margin-left: 3px;
      }

      ul {
        padding-left: 10px;

        .strategy {
          padding-bottom: 6px;
        }
      }
    }
  }
}
