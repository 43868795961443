.w-CampaignEditMargin {
	@include calc("height", "100% - 60px");
	padding: 23px 0 0;

	.symbol {
		margin-right: 2px;
	}

	.header-title .title {
		font-size: 20px;
	  	padding-top: 7px;
	}
	.spend-to-pace {
		width: 125px;
	}

	.info-column {
		white-space: nowrap;
		margin-left: 16px !important;
	}

	.campaign-name {
		padding-top: 5px;
		font-size: 14px;
	}

	.goal,
	.actual {
		width: 120px;
		padding: 0;
		margin-left: 32px;

		.value {
			width: 100%;
			float: none !important;
		}
	}

    .basic-info {
	  .info {
		margin-top: -5px;
	  }
	}

	.w-body {
		@include box-fill();
		top: 85px !important;
		background: $color-A12;
		border-top: 1px solid $color-A7;
	}

	.margin-management-info {
		margin: 17px 0 0 17px;
	}

	.margin-c {
		@include box-fill($top: 50px, $left: 17px, $right: 17px);
	}
}