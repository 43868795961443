.w-Unauthorized {
  margin: 100px auto;
  text-align: center;
  color: $color-A3;

  .title {
    font-family: $custom-font-semi-bold;
    font-size: 27px;
  }

  .description {
    font-size: 16px;
    line-height: 20px;
    margin: 10px auto 20px;
  }

  .return-to-home {
    margin: 10px auto;

    .action {
      @extend .btn-primary;
      width: 180px;
    }
  }
}