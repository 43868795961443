
.creative-concept-grid-item {
  .edit-icon {
    color: #525252;
  }

  .creative-concept-non-secure-icon {
    color: #FED01A;
  }

  .creative-concept-secure-icon {
    color: #9ECB5C;
  }

  .creative-concept-delete-icon {
    color: #666666;
  }
}

.creative-concept-deactivation-notice-modal-icon {
   color: #FFCC33;
}

