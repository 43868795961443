@import 'adServer';

.w-CampaignStrategyCreateEdit {
  .icon-header {
    float: left;
    margin-left: 1px;
    margin-top: 5px;
  }

  .status,
  .managed-by {
    .form-radio {
      width: 39%;
    }
  }

  .dba {
    .dba-line-spacer {
      margin: 8px 0 5px;
    }
  }
  .column-A-status {
    width: 258px;
    margin-right: 24px;
    float: left;
  }

  .column-B-ProgG {
    .tab-info {
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .advertiser,
  .static-value {
    label {
      cursor: text;
      position: relative;
      top: 6px;
    }
  }

  .budget {
    .form-input {
      @include input-with-symbol($width: 84%, $symbol-align: left);

      .symbol {
        width: auto;
        margin-right: 5px;

        &.disabled {
          @include transparent($percent: 50);
        }
      }

      .input {
        width: auto;

        input {
          width: 80%;
        }
      }

      .pct {
        width: auto;
        margin-left: 5px;
      }
    }
  }

  .budget-set-currency {
    .form-input {
      @include input-with-symbol($width: 82%, $symbol-align: left, $symbol-width: 30px);
      width: 48%;
      margin-right: 6px;

      .symbol {
        text-align: right;

        &.hidden {
          visibility: hidden;
        }
      }

      .input {
        margin-left: 5px;
        float: left;
        width: 90px;
      }

      .chzn-results {
        max-height: 220px;
      }
    }
  }

  .goal,
  .actual {
    .aov {
      float: left;
      width: auto;
      padding-top: 7px;
      margin-left: -15px;

      &.disabled {
        @include transparent($percent: 50);
      }
    }

    .goal-type {
      min-height: 27px;
      width: 107px;

      label {
        cursor: text;
        position: relative;
        top: 6px;
      }
    }

    &.goal-row {
      .goal-type {
        width: 165px;
      }

      .spend-bid-price,
      .goal-value {
        width: auto;
      }
    }

    .spend-bid-price,
    .goal-value {
      @include input-with-symbol($width: 50%, $symbol-align: left, $symbol-width: 30px);

      .symbol {
        text-align: right;

        &.disabled {
          @include transparent($percent: 50);
        }
      }

      .input {
        width: 60%;
        display: inline;
        margin-left: 4px;
        margin-right: 0;
        float: left;
      }

      padding-left: 40px;
    }
  }

  .start-end-dates {
    float: left;

    .date-display {
      float: left;
      width: auto;
      font-size: 14px;
      border-bottom: 1px solid transparent;

      &:hover {
        @extend .isEditable;
      }
    }
  }

  .column-A {
    @include width-colx($x: 3);
    float: left;
  }

  .column-B {
    .max-bid-wm {
      dt {
        span {
          margin-bottom: 10px;
          float: right;
        }
      }

      .tab-info {
        margin-top: 1px;
        margin-left: 10px;
      }

      .input {
        width: 85px;
        float: left;
        display: inline;
        margin-left: 3px !important;
      }

      dd {
        width: 100%;
      }
    }
  }

  .column-B,
  .column-C {
    @include width-colx($x: 4, $g: 20px);
  }

  .column-C {
    @extend .last;
  }

  .column-D {
    width: 100%;
    float: left;
  }

  .currency-budget-col {
    width: 368px;
    float: left;
  }

  .frequency,
  .budget-pacing {
    dt {
      width: 750px;
    }

    .frequency-opts {
      padding-top: 5px;
      width: 213px;
    }

    .frequency-type {
      width: 82px;
      margin-right: 15px;
    }

    .frequency-amount,
    .impression-amount {
      width: 117px;
      padding-left: 5px;

      input {
        width: 51%;
      }

      label {
        width: 32px;

        &.disabled {
          @include transparent($percent: 50);
        }
      }
    }

    .frequency-interval {
      padding-left: 5px;
      width: 82px;
    }

    .no-limit-warning {
      display: none;
      width: 740px;
      position: absolute;
      left: 310px;
      top: 22px;

      &.strategy-details {
        top: -2px;
      }
    }

    .no-campaign-cap-warning {
      margin-top: 8px;
    }
  }

  .column-B {
    .spend-cap {
      margin-top: 30px;
      margin-left: -250px;
    }
  }

  .spend-cap,
  .monetary-cap,
  .impression-cap {
    .spend-type {
      width: 103px;
    }

    .spend-manual {
      margin-top: -4px;
    }

    .text {
      width: 70px;
    }

    .spend-cap-header {
      font-family: $custom-font-semi-bold;
    }

    #pacingTooltip {
      color: #c3c3c3;
    }

    #pacingTooltip:hover {
      color: #666666;
    }

    .spend-label {
      border: 0;
      box-shadow: none;
      font-size: 13px;
      padding-left: 0;
      cursor: default;

      &.disabled {
        @include transparent($percent: 50);
      }
    }

    .tab-info {
      margin-right: 201px;
      margin-top: 2px;
    }

    .symbol {
      text-align: center;
      width: 30px;
      display: inline;

      &.disabled {
        @include transparent($percent: 50);
      }
    }

    .spend-cap-wrap {
      padding-top: 4px;
    }
  }

  .frequency-label {
    font-family: $custom-font-semi-bold;
  }

  .w-Targeting {
    margin: 0 !important;
    width: 100%;
    border-radius: 0 !important;

    .list-body {
      @include box-fill($top: 45px);
    }

    .w-DataGrid {
      .content {
        @include box-fill($top: 26px, $bottom: 25px !important);
        height: auto !important;
      }
    }

    .tabs-content {
      .w-panel-body {
        @include box-fill();
        min-height: 330px;

        .column-A {
          position: relative;
        }
      }
    }
  }

  .w-SiteListTargeting {
    .column-A {
      @extend .last;
      width: 67%;
      height: 100%;

      .list-c {
        @include box-fill($top: 9px, $right: 17px, $left: 17px);
      }
    }

    .bins-column {
      @include box-fill($left: auto !important);
      width: 33%;
    }
  }

  .w-PixelBundlesList .close {
    display: none;
  }

  .pc-window-minutes,
  .pv-window-minutes {
    label {
      margin-left: 4px;

      span .strong {
        font-family: $custom-font-semi-bold;
        font-size: 14px;
        color: $color-A2;
      }
    }
  }

  #postClickWindowIcon,
  #postViewWindowIcon {
    color: #d1d1d1;
  }

  #postClickWindowIcon:hover,
  #postViewWindowIcon:hover {
    color: #676767;
  }

  .include {
    @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);

    &:hover {
      @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 10, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }
  }

  .exclude {
    @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);
    margin-left: 7px;

    &:hover {
      @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }
  }

  .form-body {
    padding: 22px 32px 50px 48px;
    background-color: $color-A12;
  }
}

.w-CampaignCreateEdit {
  .create-edit-head {
    .display-info {
      .campaign {
        .label {
          margin-top: 18px;
        }
      }
    }
  }

  .campaignName {
    float: left;
    width: calc(100% - 20px);
  }

  .strategy-actions,
  .strategy-actions-disabled {
    float: right;
    margin: 6px 22px;
  }

  .strategy-actions-disabled {
    visibility: hidden;
  }

  .settings {
    @include linear-gradient($from: $color-A12, $to: $color-A8);
    @include border-radius($radius: 3px);

    float: right;
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding: 5px;
    border: 1px solid $color-A6;
    margin-top: 1px;
  }

  .settings-icon {
    @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
  }

  .settings-open {
    display: block;
  }

  .w-box.check-radio-forms {
    padding-bottom: 30px;
    background-color: $color-A12;
  }

  .campaign-general-form {
    .w-body {
      background: #ffffff;
    }
  }

  .same-device-feature {
    .header {
      font-family: PNLB;
    }

    .same-device-label {
      height: 20px;

      #dbaWikiLinkIcon,
      #sameDeviceWikiLinkIcon {
        color: #cacaca;
      }

      #dbaWikiLinkIcon:hover,
      #sameDeviceWikiLinkIcon:hover {
        color: #676767;
      }
    }

    .same-device-line-spacer {
      margin: 0 0 5px;

      .restrict-same-device-targeting {
        margin-top: 5px;
      }

      .same-device-radio-group {
        float: left;
      }

      .same-device-custom-dropdown-container {
        width: 110px;
        float: left;
        padding-left: 15px;
      }

      .error {
        border-radius: 5px;
      }
    }
  }

  .pg-inline-box {
    margin-bottom: 20px;
    margin-top: 23px;
  }

  .pc-window-minutes,
  .pv-window-minutes {
    width: 50%;

    label {
      margin-left: 4px;

      span {
        &.header {
          font-family: $custom-font-semi-bold;
          font-size: 14px;
          color: $color-A3;
        }
      }
    }

    .form-input {
      width: 27%;
      margin-left: 3px;
    }

    .form-select {
      margin-left: 12px;
      width: 68px;
    }
  }

  .add-strategy {
    position: absolute;
    margin-right: 20px;
    top: 64px;
    right: 0;

    .add-button-c {
      margin-right: 40px;
    }

    .add-action,
    .add-action-disabled {
      @extend .btn-add-primary;
    }

    .add-action-disabled {
      @include transparent($percent: 50);
    }
  }

  .w-AdvertiserFilter .form-list {
    margin-left: 0;

    .select {
      width: 206px;
    }
  }

  .margin-settings {
    .margin {
      float: left;
      width: 55%;

      .form-input {
        @include input-with-symbol($width: 35%, $symbol-align: right);
      }
    }

    .form-input {
      float: left;
      width: 32%;
    }
  }

  .budget-flight-margin .margin-settings {
    .form-input {
      width: 75px;
    }
  }

  .agency-fee {
    .form-input {
      @include input-with-symbol($width: 28%, $symbol-align: right);
    }
  }

  .cpm-fees {
    .form-input {
      @include input-with-symbol($width: 64%, $symbol-align: left);
    }
  }

  .io-name {
    width: 68%;
  }

  .io-reference {
    width: 61%;
  }

  .campaign-name {
    width: 90%;
  }

  .ad-server {
    width: 230px;
  }

  .settings-pane-title {
    margin-bottom: 16px;

    .pane-label {
      @extend labelForDynamicContent;
    }
  }

  .attribution {
    display: none;

    .w-PixelBundlesList {
      padding-bottom: 0 !important;

      .list-body,
      .content {
        position: relative !important;
        top: 0;
      }

      .w-head {
        padding-left: 0;
      }

      .eligible {
        padding: 10px 0;
      }

      .search {
        float: left;
      }

      .name .text {
        margin-left: 0;
      }

      .w-body {
        padding: 0;

        &.content {
          max-height: 210px;
          overflow: hidden;
        }

        .content-holder {
          max-height: 210px;
        }
      }

      .close {
        display: block;
      }
    }

    .pixel-select-box {
      min-height: 31px;
    }

    .w-box.w-PixelBundlesList {
      background-color: $color-A9;
    }
  }

  .roi-section {
    .form-list {
      .header {
        float: left;
        padding-right: 5px;
        font-family: $custom-font-semi-bold;
      }

      .header + span {
        font-family: $custom-font-italic;
      }

      .roi-section-label {
        height: 20px;
      }

      .roi-section-line-spacer {
        margin: 0 0 5px;

        .radio {
          margin-top: 5px;
        }
      }

      /* strand-tooltip inside of a strand-radio CSS fixes */
      .info-tip {
        pointer-events: all;
      }

      .info-tip-label {
        color: $color-A12;
      }

      .tooltip-holder {
        float: right;
        margin-right: -50px;
        margin-top: 1px;

        .t1-tooltip {
          margin-top: 0;
        }
      }

      .roi-activate + span {
        padding-right: 20px;
      }
    }
  }

  .pv-window-minutes {
    margin-left: -27px;
  }

  .post-view-attr {
    margin-top: 15px;
    width: 100%;

    .form-input {
      @include input-with-symbol($width: 37%, $symbol-align: right);
    }
  }

  .column-A,
  .column-B {
    @include width-colx($x: 4);
  }

  .column-C {
    @include width-colx($x: 4.5);
  }

  .column-E {
    @include width-colx($x: 3.25);
    float: left;

    .pc-pv-too-high {
      margin-left: -30px;
      margin-top: -30px;
    }
  }

  .column-F {
    width: 95px;
    float: left;

    .label-text {
      padding: 7px 0 6px;
    }
  }

  .column-F-B {
    float: left;
    margin-right: 20px;

    .label-text {
      padding: 7px 0;
    }

    .input-note {
      padding: 2px 0 0;
    }

    .monetary-pacing {
      .monetary-opts {
        padding-top: 5px;
      }
    }

    .impression-pacing {
      .impression-opts {
        padding-top: 11px;
      }
    }
  }

  .column-G {
    float: left;
    width: 495px;
    margin-right: 22px;
  }

  .column-H {
    @include width-colx($x: 4);
    float: left;
  }

  .column-A-custom {
    margin-right: 74px;
  }

  .column-A-daily {
    margin-right: 74px;
  }

  .merit-pixels-list {
    background-color: $color-A9;
    border-top: 1px solid $color-A7;
    display: none;
    margin-left: -48px;
    margin-right: -13px;
    padding: 0 13px 10px 48px;

    &.extra-padding {
      padding-top: 50px;
    }

    .w-box.w-PixelBundlesList {
      background-color: $color-A9;
    }
  }

  .advanced-settings-toggle {
    margin-right: -8px;
    margin-top: 2px;
    cursor: pointer;

    .toggle-button {
      background-color: $color-A7;
      padding: 5px;
      border-radius: 5px;
    }
  }

  .additional-info {
    max-width: 105%;
  }

  .attribution,
  .optional-settings {
    .form-stack {
      margin-bottom: 0;
      padding: 15px 13px 16px 48px;
    }
  }

  .chzn-done {
    display: none !important;
  }

  .w-vendorsList .title {
    width: 200px;
  }

  .w-vendorsList,
  .optional-settings {
    margin-left: -48px;
    margin-right: -20px;
  }

  .optional-settings {
    .form-stack {
      margin-bottom: -12px;
    }
  }

  .w-SiteListTargeting .view-loader {
    height: 100%;
  }

  .info-box {
    display: none;
    margin-bottom: 15px;

    &.show-on-load {
      display: block;
    }
  }

  .goaltype-info-box-holder {
    .info-box > .icon {
      margin-right: 0;
    }

    .message {
      p {
        line-height: 15px;
        margin-bottom: 0;
      }

      p:not(:first-child) {
        margin-top: 7px;
      }
    }
  }

  .minimize-ad {
    padding-bottom: 10px;

    .tab-info {
      margin-top: 1px;
      margin-left: 10px;
      float: left;
    }

    .minimize-checkbox {
      float: left;
    }

    .minimize-add-header {
      font-family: $custom-font-semi-bold;
      float: left;
    }

    .advertiser-ad-collision {
      float: left;
      font-family: $custom-font-italic;
      font-size: 13px;
      color: $color-A3;
      margin-left: 10px;
    }
  }

  .minimizeAdCollision-info-box-holder {
    margin-top: -20px;
  }

  .post-bid-measurement {
    .column-Post-Bid-A {
      float: left;
      margin-right: 20px;
      width: 110px;
    }

    .column-Post-Bid-B,
    .column-Post-Bid-C,
    .column-Post-Bid-D,
    .column-Aud-Verif-A,
    .column-Aud-Verif-B,
    .ndar dd {
      float: left;
      margin-right: 20px;
      margin-top: 5px;
      width: 240px;

      &.gender-select {
        width: 85px;

        .nielsen-gender-checkbox {
          padding: 5px 0;

          & + .nielsen-gender-checkbox {
            margin-top: 10px;
          }
        }
      }

      .nielsen-age + .nielsen-age {
        margin-top: 10px;
      }
    }

    .column-Aud-Verif-B {
      width: 700px;

      strand-pulldown-button {
        width: 100%;

        &.nielsen-age strand-scroll-panel {
          height: 200px;
        }

        .selected-label {
          padding-top: 8px;
        }
      }

      .strand-pulldown-button .selected-label {
        font-weight: normal;
      }

      .all-checkbox label {
        font-weight: bold;
      }
    }

    .audience-verification-checkbox {
      top: 10px;
    }

    .nielsen-input strand-input {
      width: 100%;
    }

    .ndar-inline-message {
      margin-top: 10px;
      width: 100%;
    }

    .column-Post-Bid-C .text {
      cursor: default;
    }

    .vendor-measurement-price {
      width: 100%;
      float: left;
      margin-top: 20px;
    }

    .post-bid-label {
      font: 14px $custom-font-semi-bold;
      color: $color-A3;
    }

    .select-vendor-inline-message,
    .viewability-inline-error-message {
      margin-top: 10px;
    }

    .post-bid-viewability-checkbox {
      top: 10px;
    }

    .do-not-show-viewability-modal {
      .upper-content {
        padding: 20px;
      }

      .viewability-modal-header {
        padding-bottom: 10px;
      }
    }
  }

  .beta {
    padding: 0 2px 0 5px;
  }

  label input[type='checkbox']:disabled + span {
    opacity: 0.5;

    &:before {
      opacity: 1;
    }
  }
}

.bid-min-devices-op {
  margin-top: 10px;
}

.bid-cross-strategy {
  color: #323232;
  font: bold 13px/15px 'PNR';
}
.bid-info-tip {
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}

.w-CampaignDetails {
  [dir='LTR'],
  .symbol {
    font-size: 100%;
    margin-right: 0;
  }

  .column-supply-type {
    width: 280px;
  }

  .w-dropdown-section {
    margin-top: -15px;
    margin-bottom: 20px;
  }

  .border-bottom {
    border-bottom: 1px solid $color-A6;
    width: 100%;
  }

  .cross-device-inline-message,
  .dba-inline-message {
    margin-top: 10px;
  }

  .select-vendor-inline-message-info,
  .cross-device-inline-message,
  .dba-inline-message {
    display: block;
  }
}

#date-picker-createcampaign {
  @extend .datePickerCreator;
}

@import 'campaign-strategy-list';
@import 'vendor-list';
