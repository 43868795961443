$column-merge: 50px;
$column-width: 50px;

.w-OrgContract {
  @include box-fill($top: 2px);
  padding: 25px;
  position: relative;
  background-color: $color-A12;

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .contracts-head {
    .basic-info {
      max-width: 500px;
      float: left;
      margin-left: 26px;
      margin-top: 10px;
      width: 45%;

      .title {
        font: 14px/21px $unified-font-bold;
        color: #383838;
        height: 21px;
        float: left;
      }
    }

    strand-dropdown {
      width: 225px;
      margin-left: 10px;
      margin-right: 30px;
    }
  }

  hr {
    border-bottom: 1px solid $color-A5;
    padding-top: 20px;
  }

  .no-contracts {
    text-align: center;
    font: normal 11px/12px $unified-font-regular;
    color: $color-A4;
    padding-top: 50px;
  }
}

.w-OrgContractCreateEdit {
  display: inline-block;
  padding: 10px 0 100px;
  background: none;
  width: 100%;
  height: 100%;

  .w-body {
    background: $color-A12;
    border-top: 1px solid $color-A7;
    overflow: scroll;
  }

  .contract-group {
    .form-stack {
      margin-bottom: 0 !important;
    }

    .form-list {
      dt {
        font: normal 11px/12px $unified-font-regular;
        color: $color-A4;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
    }

    .group-title {
      width: 100% !important;
      font: 13px/20px $unified-font-bold;
      color: $color-A2;
      height: 21px;
      padding: 10px 0;

      .tab-info {
        margin-top: 4px !important;
      }
    }

    .column-A,
    .column-B {
      @include width-colx($x: 5.7);
      float: left;
    }

    .column-A {
      margin-right: 56px;
    }

    .inner-col-A,
    .inner-col-B {
      @include width-colx($x: 2.8);
      float: left;
      margin-right: 0;
    }

    .inner-col-A {
      width: 223px !important;
      text-align: right;
      padding-top: 8px;

      &.tall-label {
        padding-top: 2px;

        .label-text {
          display: inline-block;
          padding-top: 0;
          span {
            display: block;
          }
        }

        .tab-info {
          margin-top: 5px !important;
        }
      }

      &.no-info {
        width: 196px !important;
      }

      &.no-info-additional {
        padding-right: 25px;
        width: 198px !important;
      }
    }

    .inner-col-B {
      padding: 7px 0 0 5px;
      width: 270px !important;

      .platform-monthly-min,
      .spend-cap {
        width: 85% !important;
        padding-left: 4px;
      }

      .form-input {
        width: 80px;
        display: inline-block;
      }

      .optional-fees-ddl {
        width: 145px;
        display: inline-block;
      }

      .align-fix {
        padding-left: 12px;
      }
    }

    .notes {
      position: absolute;
      margin: 3px 0 0 5px;

      font: normal 12px/14px $custom-font-italic;
      color: $color-A5;

      .tab-info {
        margin-top: 0 !important;
      }
    }

    .is-edit {
      padding-top: 0 !important;
      margin-bottom: 5px;
    }

    .currencyDisplay {
      &.hidden {
        display: none;
      }
    }

    .date-picker-calendar {
      width: 418px;
      height: 264px;
    }

    .date-display {
      border-bottom: 1px solid transparent;
      padding-top: 7px;

      &:hover {
        @extend .isEditable;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .pg-platform-access-fee-group {
      .inner-col-A {
        padding-top: 3px;
        .pg-text {
          padding-right: 26px;
        }
      }
    }
  }

  .pct-ddl {
    width: 85px;
  }
}

.btn-primary,
.btn-secondary {
  &.disabled {
    @include transparent($percent: 50);
    cursor: default;
    pointer-events: none;
  }
}

.tooltip-contracts {
  @extend .w-FormError;

  .tipsy-inner {
    color: $color-A12;
    background-color: $color-A0;
    word-wrap: break-word;
    white-space: normal;
    max-width: 550px;
  }

  .tipsy-arrow-s {
    border-top-color: $color-A0;
  }

  .tipsy-arrow-w {
    border-right-color: $color-A0;
  }
}
