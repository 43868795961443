.w-ReadonlyCreatives {

	.heading-icon {
		@include sprite-icon($left: 5, $down: 4, $width: 18px, $height: 18px);
	}

	.section-head  {
        font-family: $custom-font-regular !important;
		font-weight: 700;
	}

	.column-header{
		margin-bottom: 5px;

		.t1-tooltip {
			position: absolute;
			float: right;
			display: inline-block;
			margin: -2px 0 0 4px;
		}
	}

	.col1 {
		width: 32%;
	}
	.col2, .col3, .col4, .col5, .col6, .col7, .col8{
		width: 7%;
	}

	.icon,
	.creative-name {
		position: relative;
		float: left;
		height:18px;
	}

	.creative-name {
		width: 88%;
	}

	.creative-name-child {
		position: relative;
		float: left;
		height: 18px;
		margin-left: 15px;
		width: 88%;
		// CSS Truncate
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.icon-arrow {
		@include sprite-icon($left: 5, $down: 9, $width: 14px, $height: 11px);
		cursor: hand;
		cursor: pointer;
	}

    .icon-arrow-expanded {
        @include sprite-icon($left: 1, $down: 0, $width: 14px, $height: 11px);
        margin-top: 3px;
    }

    .icon-arrow-hide {
        visibility: hidden;
    }

	.adx-error
	{
		@include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
	}


	.adx-warning
	{
		@include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
	}

	.adx-error,
	.adx-warning
	{
		float: right;
	}

	.inactive-warning {
		float: right;
		right: 4px;
	}

	.icon-spinner {
		position: absolute;
		top: 6px;
		left: 6px;
	}

	#inactiveConceptIconStrategies {
    opacity: 0.5;
    color: #666666
  }

  #inactiveConceptIconStrategies:hover {
    color: #000000;
  }

  .non-secure-warning {
  	color: #9ecb5c;
  }

  .nonSecureWarningIconTooltipBox {
  	text-align: left;
  }

  #inactiveConceptIcon {
		color: #FED01A;
  }
}