.w-ContextualTargeting {

    .browse-list {
        .drag {
            padding-top: 8px !important;
        }
    }

    .list-body {
        transition: top .2s;
        bottom: 1px !important;

        &.info-message-on {
            top: 105px;
        }
    }

    .info-message-on {

        .browse-list {
            bottom: 17px !important;
        }
    }

    .acxiom-info {
        margin: 8px 0 18px 0;
        width: 99%;
        display: none;

        .message {
            width: 90%;
        }

        .icon-close {
            margin-top: -21px;
        }
    }

    .view-loader {
        position: absolute;
        left: 44%;
        top: 50%;
    }

    .entities {
        width: 190px;
    }

    .chzn-drop {
        max-height: 305px;
        overflow-y: auto;
    }

    .browser-c, .search-c {
        height: 100%;
        max-height: 362px;
        background-color: $color-A12;

        .w-DataGrid {
            .content-advanced-settings-open {
                bottom: 115px;
            }

            .content-advanced-settings-closed {
                bottom: 69px;
            }
        }

        .item-wrapper {
            position: relative;
        }

        .headers {
            padding-left: 0;

            .segment {
                width: 75%;
                padding-left: 12px;
            }
        }

        .group-name {
            margin: 20px 0 5px 65px;
            font-family: $custom-font-bold;
            color: $color-D10;
            font-size: 12px;
            letter-spacing: .3px;
        }

        .item {
            padding: 0;

            &:hover {
                background-color: transparent;
            }

            .arrow {
                margin: 4px 0 0 0;
                position: relative;

                .icon-arrow {
                    @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);
                    margin-left: 4px;
                }

                &.loading {
                    .icon-arrow {
                        visibility: hidden;
                    }

                    .icon-spinner {
                        top: 15px;
                        left: 1px;
                    }
                }

                &.expanded {
                    .icon-arrow {
                        @include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
                        margin: 2px 0 0 2px;
                        padding-right: 2px;
                    }
                }
            }

            .item-row {
                float: left;
                width: 100%;
                padding: 8px 0;
                border-bottom: 0px solid $color-A7;

                &:hover {
                    background-color: $color-E8;
                }

                &:selected {
                    background-color: $color-D7;
                }
            }

            .drag {
                padding-top: 12px;
            }

            .segment {
                padding-left: 12px;

                .item-c {
                    float: left;
                }

                .icon {
                    margin-top: 2px;

                    &.arrow {
                        margin-top: 4px;
                    }
                }

                .text {
                    margin-left: 12px;
                    color: $color-A3;
                    line-height: 18px;
                    width: 90%;

                    &.browse {
                        margin-left: 1px;
                        width: 88%;
                    }

                }
            }

            .size, .cpm {
                margin-top: 2px;

                .item-c {
                    font-family: $custom-font-regular;
                }
            }
        }

        .browse-list {
            .item {
                .item-row {
                    padding: 7px 0;
                }
            }
        }

        .segment {
            width: 75%;
        }

        .size {
            width: 6%;
            text-align: right;
        }

        .cpm {
            width: 9%;
            text-align: right;
        }

        .w-foot {
            @include box-fill($top: auto);

            .browse-path {
                background: $color-A7;
                padding: 7px 15px 6px 13px;
                font-family: $custom-font-regular;
                font-size: 12px;
                color: $color-A3;
                line-height: 13px;
            }

            .page-controls {
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }

    .grid-holder {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }

    .list-c {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

    .tabWarning {
      height: 100px;
      position: absolute;
      top:12px;
      bottom: 12px;
      left: 12px;
      right: 12px;
      padding: 12px 12px 22px 12px;
      background: $color-E7;
      border: 1px solid rgba(226, 123, 8, 0.86);
      border-radius: 3px;
      z-index: 2;

      .icon {
        @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 22px);
        margin-right: 8px;
        float:left;
      }

      .mainText {
        color: #111111;
        font: 18px/22px $custom-font-semi-bold;
      }

      .infoText {
        color: $color-A3;
        font: 11px/14px $custom-font-semi-bold;
        padding: 8px 0px 10px 36px;
      }

      .hideIcon {
        @include sprite-icon($left: 7, $down: 0, $width: 18px, $height: 18px);
        float: right;
        right: 10px;
        cursor: pointer;
      }

      .retired-segments-holder {
        @include bg-color-rgba();
        overflow: auto;
        padding-left: 15px;
        margin-left: 30px;
        margin-right: 11px;
        font: 11px/14px $custom-font-regular;
        height: 42px;
      }
    }

    .w-foot-c {
            background: $color-A12;
        }

        .w-foot {
            @include box-fill($top: auto);
            z-index: 1;

            .disabled {
                @include transparent();
                background: $color-A8;
                z-index: 99;
                position: absolute;
                width: 52%;
                height: 30px;
                top: -5px;
            }

            .open {
                background-color: transparent;
            }

            .info-icon {
                width: 13px;
                margin: 0 0 0 10px;
            }

            .browse-path {
                background: $color-A7;
                padding: 7px 15px 6px 13px;
                font-family: $custom-font-regular;
                font-size: 12px;
                color: $color-A3;
                line-height: 13px;
                margin-bottom: 5px;
            }

        }
}

.safety-settings-tooltip {

    dl {
        display: block;
        width: 234px;
        padding: 10px;
        font: 12px/14px "PNR";
        white-space: normal;
        word-wrap: break-word
    }

    dt {
        display: block;
        text-transform: none;
        color: $color-A6;
        margin-right: 2px;
    }

    dd {
        font-family: $custom-font-bold;
        font-size: 12px;
    }
}

.w-Contextual-Bins {
    .data-row {
        .item-row {
            padding: 3px 0 2px;
        }

        .includeExcludeOp {
            top: 0px;
        }

        .segment {
            .text {
                span {
                    background-color: transparent;
                }
            }
        }

        .warning-holder {
            float: left;
            margin-top: 3px;
            padding-left: 3px;

        }

        .warning-icon {
            @include sprite-icon($left: 4, $down: 10, $width: 15px, $height: 15px);
        }
    }

    .icon-spinner{
        left: 140px !important;
    }

    .headers{
        padding-right:9px !important;

        .segment{
            width: 68.1%;
        }
    }

    .segment {
        width: 67%;
    }

    .size {
        width: 11%;
        text-align: right;
    }

    .cpm {
        width: 15%;
        text-align: right;
        margin-left: 40px;

        .editable {
            &:hover {
                @extend .isEditable;
            }
        }
    }

    .bins-foot {
        .segment {
            width: 69%;
        }

        .cpm {
            white-space: nowrap;
        }
    }
}

.w-InlineEditPopoverContainer {
    min-width: 230px;
    width: auto;
}
.w-ContextualSegmentPriceEdit {
    padding: 0 !important;

    .form-stack {
        margin-bottom: 15px;
    }

    .form-body {
        padding: 15px 40px 1px 20px;

        .form-input {
            position: absolute;
            right: -11px;
            width: 50px;

            .symbol {
                position: absolute;
                right: 55px;
                top: 6px;
                width: 30px;
                text-align: right;
            }
        }
    }

    .form-radio {
        width: 100% !important;
    }

    .info-icon {
        position: absolute !important;
        margin: 0 0 0 4px !important;
        top: 5px;
        right: -22px;
    }

    .form-footer {
        background-color: $color-A9;
        padding: 10px 0 10px 0px;

        .messages {
            padding-left: 10px;
        }

        .error-message {
            width: 100%;
        }

        .action {
            margin-right: 0;
        }
    }
}

.contextualSegmentEdit-tipsy {
    width: 200px;

    .tipsy-inner {
        white-space: normal;
        word-wrap: break-word;
        padding: 10px;
    }
}