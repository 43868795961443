@import "palette";
@import "mixins";

.w-ErrorMessage {
	@extend .dialog-panel;
	width: 450px !important;
	height: 336px;

	.ui-dialog-titlebar {
		display: none;
	}

	&.ui-dialog {
		.ui-dialog-content {
			padding: 0;
		}
	}
  		
	.w-body {
		.panel {
			.errorContent {
				font-family: $custom-font-regular;
				
				.icon {
					@include sprite-icon($left: 8, $down: 10, $width: 28px, $height: 25px);
					margin: -1px 1px 0 -1px;
					float: left;
				}
				
				.code {
					float: left;
					margin: 2px 0 10px 6px;
					font-family: $custom-font-semi-bold;
					font-size: 22px;
				}
				
				.description {
					clear: left;
					margin-bottom: 30px;
					font-size: 14px;
					line-height: 17px;
				}
				
				.instruction {
					clear: left;
					font-size: 13px;
				}
				
				.detail {
					width: 418x;
					margin-top: 8px;
					
					.textarea {
						border: 1px solid $color-A6;
						font-size: 10px;
						width: 98%;
  					}
				}
			}
		}
	}
}
