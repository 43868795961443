.w-Health {
	@extend .dialog-panel;
	position: relative;
	font-family: $custom-font-regular;

	.w-head {
		color: $color-A1;
		margin-left: 30px;

		.icon {
			@include sprite-icon($left: 10, $down: 2, $width: 26px, $height: 26px);
			float: left;
			position: absolute;
			margin: 3px 3px 0 -30px;
		}

		.title {
			font-family: $custom-font-semi-bold;
			font-size: 22px;
			text-transform: capitalize;
			letter-spacing: normal;
		}

		.name {
			font-family: $custom-font-semi-bold;
			font-size: 14px;
			letter-spacing: normal;
			margin: 6px 0 8px;
		}
	}

	.w-body {
		.checklist {
			@extend .box-fix;
			clear: none;
			float: left;
			margin-top: 10px;
			width: 40%;

			&:first-child {
				width: 60%;
			}
		}

		.check-item {
			box-sizing: border-box;
			margin: 20px 0 6px;
			min-height: 36px;
			padding: 0 4px 0 40px;
			position: relative;

			&:first-child {
				margin-top: 0;
			}

			&:before {
				content: '';
				height: 26px;
				left: 0;
				position: absolute;
				top: 1px;
				width: 28px;
			}

			&.pass:before {
				@include sprite-at-position($left: 8, $down: 1);
				top: 0;
			}

			&.warning:before, &.unknown:before {
				@include sprite-at-position($left: 8, $down: 4);
			}

			&.fail:before {
				@include sprite-at-position($left: 8, $down: 10);
			}

			&.nodata:before {
				@include sprite-at-position($left: 8, $down: 11);
			}

			&.checking:before {
				background: url('../images/transparent-loader.gif') center center no-repeat;
			}
		}

		.test {
			font-size: 16px;
			line-height: 25px;
		}

		.notes {
			color: $color-A4;
			font-size: 11px;
			font-style: italic;
			line-height: 1.4em;

			&.fail {
				color: $color-C1;
			}
			.download-icon {
				color: $color-A4;
			}
		}
	}
}
