.w-trackingList {

	.title {
		font: 14px $custom-font-semi-bold;
		color: $color-A3;
		width: 300px;
	}

	.form-list {
		width: 100%;
	}

	.tab-info {
		margin-top: 4px;
	}

	.tracking-event {
		margin-top: 5px;
		padding: 5px 0;
		position: relative;
		border-bottom: 1px solid $color-A6;
	}

	.url {
		padding-right: 10px;
		width: 620px;
	}

	.event-name {
		padding-right: 10px;
		width: 200px;
	}

	// .vendor {
	// 	padding: 0 10px;
	// 	width: 420px;

	// 	.chzn-drop {
	// 		z-index: 2;
	// 	}
	// }

	// .cost {
	// 	width: 115px;

	// 	.input,
	// 	.symbol {
	// 		display: inline-block;
	// 	}

	// 	input {
	// 		margin-right: 5px;
	// 		width: 50px;
	// 	}

	// 	input:disabled {
	// 		@include transparent($percent: 50);
	// 	}
	// }

	.add-tracking-event {
		span {
			color: $color-D1;
			font-size: 11px;
			line-height: 25px;
			margin-left: 15px;
			vertical-align: bottom;
			cursor: pointer;
		}
	}

	.remove {
		@include sprite-icon($left: 7, $down: 0, $width: 16px, $height: 16px);
		position: absolute;
		right: 0;
		top: 16px;
		cursor: pointer;
	}

}
