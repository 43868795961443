.w-AgencyFilter {
  @extend .select-filter;
  .chzn-container-single .chzn-single {
    @include border-radius($radius: 3px 0 0 3px);
  }

  .chzn-container-active .chzn-single-with-drop {
    @include _border-radius($radius: 3px 0px 0px 0px);
  }
}
