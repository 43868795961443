.w-ReadonlyCustomBrainSelections {
  .section-heading {
    .pixel-user-icon {
      padding-right: 6px;
      padding-bottom: 8px;
    }
  }

  .entity-content {
    padding-bottom: 20px;
  }

  .table-body {
    padding: 6px 0 10px 0;
  }

  .col1 {
    width: 30%;
  }
  .col2 {
    width: 10%;
  }
  .col3 {
    width: 10%;
  }
  .col4 {
    width: 10%;
  }
  .col5 {
    width: 10%;
  }

  .col1.audience {
    width: 66.5%;
  }
}
