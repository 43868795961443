.ribbon-strip {
	position: relative;
	padding: .2em;
	zoom: 1;
	margin: 0;
	padding: 0;

	.ribbon-nav {
		@include linear-gradient($from: $color-A12, $to: $color-D12);
		margin: 0;
		padding: 0 0 0 16px;
		margin: 0 0 -1px;
		border-bottom: 1px solid $color-A7;
		border-radius: 0;
		height: 33px;
		cursor: pointer;

		.command-unit {
			border: none;
			@include linear-gradient($from: $color-A12, $to: $color-D12);
			list-style: none;
			float: left;
			position: relative;
			top: -1px;
			margin: 0 0 1px 0;
			border-top: solid 1px $color-A7;
			border-bottom: 0;
			padding: 0;
			white-space: nowrap;
			height: 33px;

			&:before, &:last-child:after {
				@include linear-gradient($from: $color-A12, $to: $color-A7);
				position: relative;
				float: left;
				width: 1px;
				height: 33px;
				top: 1px;
				content: "";
			}

			&:last-child:after {
				position: absolute;
				right: 0;
			}

			&.unit-selected {
				background: $color-A12;
				padding-top: 0;
				height: 36px;
			}

			&>div {
				margin-top: 5px;
			}
		}
	}
}

.w-SelectForRibbonStrip {
	position: relative;
	float: left;
	z-index: 2;
	color: $color-A1;
	width: 99%;
	height: 33px;
	font: normal 12px/14px $unified-font-regular;
	text-transform: capitalize;

	.head-label {
		cursor: pointer;
		margin-right: 1px;
		padding-left: 0;
		line-height: 25px;

		.current-option {
			@include calc("width", "100% - 35px");
			float: left;
			padding-left: 10px;
			text-align: left;

			.item-label {
				text-align: left;
				font-family: $unified-font-bold;
			}

			.item-trailing-icon {
				display: inline-block;
				position: absolute;
				top: 6px;
				width: 22px;
				height: 13px;

				&.to-hide {
					display: none;
				}

				.icon {
					margin-left: 7px;
					height: 12px;
				}
			}
		}

		.current-option--stacked {
			.item-label {
				position: absolute;
				font-size: 10px;
				line-height: 1;
			}

			.item-text {
				margin-top: 6px;
				display: inline-block;
			}

			.item-trailing-icon {
				top: 12px;
			}

			+ b {
				margin-top: 16px;
			}
		}

		b {
			@include sprite-icon($left: 1, $down: 7, $width: 10px, $height: 10px);
			margin: 10px 7px 0 5px;
			float: right;
		}

		&.hide-arrow {
			b {
				display: none;
			}
		}

		.hide-arrow .current-option {
			width: 98%;
		}
	}

	.w-head.white-bg {
		background-color: $color-A12;
	}

	.item-text {
		text-align: left;
		letter-spacing: .5px;
	}

	.item-list {
		@include rounded-box($padding: 0, $background: $color-A12, $border-radius: 0 0 4px 4px, $border-rule: 1px, $border-color: $color-A8);
		border-left: 1px solid $color-A8;
		padding: 5px 0;
		border-top: 0;
		border-top-right-radius: 0;
		left: -1px;
		width: 100.4%;

		.item {
			position: relative;
			padding: 8px 0 8px 10px;
			cursor: pointer;
			text-align: left;

			&:hover {
				color: white;
				background-color: $color-D15;
			}

			&.disabled {
				@include transparent($percent: 70);
				cursor: default;
				background-color: $color-A12;
			}

			&.selected {
				font-family: $unified-font-bold;
				background-color: $color-D7;
				color: $color-D11;

				&:hover {
					color: white;
					background-color: $color-D15;
				}
			}
		}
	}

	&.disabled {
		@include transparent(60);
	}
}