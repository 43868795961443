.w-StrategyDetails {
  [dir='LTR'],
  .symbol {
    font-size: 100%;
    margin-right: 0;
  }

  .suppressed {
    display: none;
  }

  .column-C {
    @include width-colx($x: 2.25);
  }

  .symbol {
    width: auto !important;
    text-align: center;

    &.hidden {
      display: none;
    }
  }

  .w-body {
    background-color: $color-A12;
    .form-list {
      .bid-device-inline-error-message {
        display: none;
      }
    }
  }

  .pg-status-message {
    display: none;

    &.isInactive {
      display: inline-block;
    }
  }

  .strategy-name {
    width: 336px;
  }

  .channel {
    .media-type {
      display: inline;
      padding-right: 20px;

      .display-icon {
        @include sprite-icon($left: 9, $down: 9, $width: 17px, $height: 17px);
        margin-top: -6px;
      }

      .video-icon {
        @include sprite-icon($left: 9, $down: 10, $width: 16px, $height: 10px);
        margin-top: -4px;
      }
    }

    .media-run-type {
      display: inline;
    }

    .form-checkbox {
      padding-right: 9px;
    }

    .disabled {
      @include transparent(60);
    }
  }

  .strategy-type {
    width: 207px;
  }

  .supply-type {
    width: 100%;
  }

  .column-supply-type {
    width: 280px;

    &.is-strategy {
      width: 380px;
    }

    .supply-type {
      .supply-type-non-tag {
        cursor: text;
      }

      .supply-type-dropdown strand-list-item[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .form-radio {
      width: auto;
      margin-right: 33px;
    }

    .facebook-radio-btn {
      &.disabled {
        @include transparent($percent: 50);
      }
    }
  }

  .strategy-description {
    .textarea {
      font-size: 13px;
      padding: 4px;
      width: 570px;
      height: 50px;
    }
  }

  .start-end-dates {
    .form-checkbox {
      margin-left: 10px;

      span {
        font-size: 12px;
      }

      input.disabled {
        @include transparent(90);
      }
    }
  }

  .status,
  .optimize {
    .form-radio {
      width: 32%;
    }
  }

  .goal-type {
    label {
      position: relative;
      top: 6px;
      cursor: text;
    }
  }

  .form-select.bid-for {
    width: auto;
  }

  .for-label {
    padding-left: 9px;
    padding-right: 9px;
  }

  .goal {
    width: 530px;

    .spend-bid-price,
    .goal-value {
      width: auto;

      .input {
        width: auto;

        input {
          width: 120px;
        }
      }
    }

    &.goal-row {
      clear: both;
      margin-top: 23px;
    }

    .for-label {
      position: relative;
      top: 5px;
    }

    .pct {
      padding-left: 4px;
    }

    .roi-label {
      width: auto;
      margin-left: 5px;
      line-height: 25px;
      padding-left: 5px;

      &.disabled {
        @include transparent($percent: 50);
      }

      .roi-for {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .goal-value-header {
      height: 14px;

      .type-value-txt {
        float: left;
      }

      .roi-info-txt {
        font-family: $custom-font-italic;
        font-size: 12px;
        float: left;

        .symbol {
          font-style: italic;
          margin-right: 3px;
        }
      }

      .campaign-goal-value-txt {
        font-family: $custom-font-semi-bold-italic;
        font-size: 12px;
        color: $color-A3;
        text-transform: capitalize;
        float: left;
      }

      .roi-header-icon {
        @include sprite-icon($left: 6, $down: 10, $width: 10px, $height: 12px);
        float: left;
        margin-top: 2px;
        margin-left: 5px;
      }
    }

    .roi-type {
      width: 75px;
      padding-left: 6px;
    }
  }

  .goal-roi {
    width: 100%;
  }

  .max-bid,
  .min-bid {
    margin-top: 23px;

    .form-list {
      .optional {
        font-style: italic;
        font-weight: normal;
      }
      .symbol {
        display: inline;
      }

      .input {
        display: inline;
        margin-left: 3px;

        .currency {
          width: 120px;
        }
      }
      .bid-price {
        width: auto;
      }
    }
  }

  .budget {
    .input {
      width: 156px;
      float: left;
      margin-left: 5px !important;
    }
  }

  .pacing-frequency {
    .column-A {
      .frequency-type {
        width: 62px;
      }

      .frequency-amount {
        @include input-with-symbol($width: 84%, $symbol-align: left);
        padding-left: 5px;
        width: auto;

        .input {
          float: left;
          margin: 0 0 0 3px;
          width: 90px;
          display: inline;

          input {
            display: inline;
            width: 50%;
          }
        }

        label {
          left: 2px;
          width: auto;
        }
      }

      .frequency-interval {
        width: 66px;
        padding-left: 0;
      }
    }

    .column-A-custom {
      width: 100%;

      .label-text {
        width: 65px !important;
        padding: 7px 8px 6px 0;
      }

      .frequency-type,
      .monetary-type,
      .impression-type {
        margin-right: 8px;
        width: 82px;
      }

      .frequency-amount,
      .monetary-amount,
      .impression-amount {
        @include input-with-symbol($width: 84%, $symbol-align: left);
        padding-left: 5px;
        width: auto;

        .input {
          float: left;
          margin: 0 0 0 3px;
          width: 130px;
          display: inline;

          input {
            display: inline;
            width: 65%;
          }
        }

        label {
          left: 2px;
          width: auto;
        }
      }

      .budget-pacing {
        .pacing-opts {
          padding-bottom: 15px;
        }
        .target-allocation {
          .label-text {
            width: 41px !important;
            &.label-campaign {
              width: 86px !important;
            }
          }
          .pacing-goal {
            display: inline-block;
            width: 87px;
            margin-right: 8px;

            .text {
              float: left;
              width: 77px;
            }
          }
          .pacing-type {
            width: 102px;
            margin-right: 8px;
          }
        }
      }

      .frequency-interval,
      .monetary-interval,
      .impression-interval {
        width: 82px;
        padding-left: 0;
      }

      .impression-type {
        width: 82px;
      }
    }

    .column-B {
      @include width-colx($x: 6.5);

      .frequency-cap {
        .no-campaign-cap-warning {
          display: none;
        }

        dt {
          height: 15px;

          .cap-info {
            font-family: $custom-font-italic;
            font-size: 12px;
            color: $color-A3;
            margin-left: 6px;
            display: inline;
            clear: none;
            .cap-details {
              font-family: $custom-font-semi-bold-italic;
              text-transform: capitalize;
              margin-right: 5px;
            }
            &.new-freq-control {
              margin-right: 35px;
            }
          }

          .frequency-cap-icon {
            @include sprite-icon($left: 6, $down: 10, $width: 10px, $height: 10px);
            display: inline;
            position: absolute;
            margin-top: 3px;
            margin-left: -5px;
            padding: 0 6px;
            top: 0;
          }
        }

        .strategy-frequency {
          @include width-colx($x: 4);

          .frequency-type {
            width: 77px;
          }
        }

        .frequency-header {
          float: left;
        }

        .tab-info {
          float: left;
          margin-top: 1px;
          margin-left: 10px;
        }

        .campaign-frequency-info {
          float: right;
          position: absolute;
          top: 0;
          display: inline;
          &.has-cap {
            right: 20px;
          }
        }
      }

      .impression-cap {
        width: 180px;

        .form-input {
          width: 35%;
        }

        .form-info {
          width: 30%;
          margin-left: 18px;
          line-height: 22px;
        }

        .form-checkbox span {
          font-family: $custom-font-bold;
        }

        &.has-campaign-frequency {
          margin-left: 15px;
        }

        .tab-info {
          margin-right: 43px;
          margin-top: 2px;
        }
      }
    }
  }

  .bid-aggressiveness-text {
    width: 20px;
    height: 16px;
    float: right;
    margin-right: -16px;
    margin-top: -18px;
  }

  .advanced-settings-toggle {
    position: absolute;
    right: 13px;
    margin-top: -14px;
    margin-right: 3px !important;
    padding-top: 0;
  }

  .additional-info .goal-value {
    width: 111px !important;
  }

  .advanced-settings-c {
    .form-stack {
      margin-bottom: 10px;
      padding: 20px 13px 20px 48px;
    }

    .form-input {
      @include input-with-symbol($width: 52%, $symbol-align: left);
    }

    .bid-for {
      width: 44%;
      line-height: 25px;
      margin-left: 5px;
    }

    .bid-price,
    .goal-value {
      .input {
        width: 85px;
        float: left;
        display: inline;
        margin-left: 3px;
      }
    }
  }

  .viewability-inline-error-message {
    margin-bottom: 10px;
  }
}

#date-picker-createstrategy {
  @extend .datePickerCreator;
}

.cluetip-datepicker.datepicker-width-auto {
  width: auto !important;
}
