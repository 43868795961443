.w-CampaignStrategyInfoColumn {

	.value {
		font: normal 9px $custom-mono-font-regular !important;
	}

	.goal, .actual {
		padding-left: 45px;

		.goal-type {
			padding-left: 5px;
			width: auto;

			&:empty {
				padding: 0;
			}
		}

		.goal-value-info {
			float: right;

			.value {
				float: left;
			}

			&.campaign {
				float: none;
			}
		}
	}

	.symbol {
		margin-right: 2px;
	}

	.ajax-loader {
		@include transparent(55);
		.ajaxSpinner {
			margin-left: 48%;
			margin-top: 3px;
		}
	}

	// .target-data-loader-video {
	// 	position: absolute;
	// 	top: 50px;
	// 	width: 100px;
	// 	height: 22px;
	// 	left: 105px;

	// 	.ajaxSpinner {
	// 		margin-left: 0;
	// 	}
	// }

	// .target-data-loader-no-video {
	// 	position: absolute;
	// 	top: 50px;
	// 	width: 100px;
	// 	height: 22px;
	// 	left: 85px;
		
	// 	.ajaxSpinner {
	// 		margin-left: 0;
	// 	}
	// }

	.summary-badges-holder {
		width: 380px; 
		height: 40px; 
		position: absolute; 
		right: 6px;
		z-index: 100;
	}
}