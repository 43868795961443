/***
* Info Boxes
***/
.model-list-wrapper, .lookalikes-model-create {

  .lookalikes-message {
      padding-top: 6px;
      padding-left: 2.5%;
      width: 95%;
  }

  .lookalikes-message-title {
      font-weight: bold;
  }
  .header-normal {
    color: $color-A4;
  }
  .model-filters {
      margin-top: 0 !important;
      position: relative;

      .entities #entities_dropdown_chzn {
          width: 160px;
      }

      .entities .chzn-single, .entities li {
          font-family: AR !important;
          font-size: 13px !important;
          font-weight: normal !important;
      }

      .w-Filters {
          margin: 0 !important;
      }

      .w-Filters .auto-complete {
          width: auto;
      }

      .body {
          float: left;

          .entities {
              width: auto;
          }
      }

      .clear-filter {
          @include sprite-icon($left: 4, $down: 5, $width: 15px, $height: 15px);

          cursor: pointer;
          display: none;
          margin-left: -20px;
          position: absolute;
          z-index: 4;
      }
  }

	.report-info {
	    position: absolute;
	    top: -107px;
	    right: 0;
	    float: right;
	    margin: 1em 50px 0 0;
	    line-height: normal;
	    strand-header { left: 10px; }
		strand-icon {
    		color: #438dca;
    		margin-right: 7px;
    	}
	    .text {

	        font-family: Arimo;
	        font-size: 11px;
	    }
	}
	.report-info-wrapper {
	    border: 1px solid #B0E8F9;
	    padding: 7px;
	    border-radius: 5px;
	    background-color: #e2f7fd;
	    margin-bottom: 15px;
	    display: flex;
	}
}

/***
* Create Edit
***/
.lookalikes-model-create {
	background: #ffffff;
	.report-info {
		top: -76px;
	}
	#lookalike-model-form {
		height: calc(100vh - 229px);
		max-width: 1080px;
		min-height: 753px;
		padding: 0 0 1em;
		.form-stack {
			padding: 22px 32px 0;
			margin: 0;

			&.name {
				padding: 10px 32px !important;
			}
			.header-small {
				padding: 5px 0 !important;
			}
			strand-header {
				display: block !important;
			}
      #regions {
        width: 250px;
      }
      #prospectCheck, #remarketCheck {
        margin: 5px;
      }
			.disabled {
		    	opacity: .5 !important;
    			pointer-events: none !important;
			}
			#user-number-display {
				display: none;
				font-style: italic;
				font-size: 90%;
				margin-top: 35px;
			}
			#tacCheck {
				display: none;
			}
		}
		/* Four Column Grid (ie: 1/4, 2/4, 3/4, 4/4)
	   dolson - Much clearer than Column-A/B/C thats happening elsewhere
	   Could easily change these to be fluid width */
		.col-1-4,
		.col-2-4,
		.col-3-4,
		.col-4-4 {
			@extend .column;
		}
		.col-1-4 {
			@include width-colx($x: 3);
			padding: .5em 0 1.5em;
		}
		.col-2-4 {
			@include width-colx($x: 4);
			padding: .5em 0 1.5em;
		}
		.col-3-4 {
			@include width-colx($x: 9);
		}
		.col-4-4 {
			@include width-colx($x: 12);
		}
    #sectionOne {
      .col-1-4, .col-2-4 {
        padding-top: 0;
      }
    }
	}
	#model-create-footer {
	    #save-model-button {
	        width: 53px;
	    }
	}

  .multiselectSelect {
  	#target {
  		strand-spinner {
  		    padding: 0 5px 0 13px;
  		    position: absolute;
  		    margin-top: -9px;
  		    top: 50%;
  		    right: 0;
  		    display: block;
  		}
  	}
  	label {
  		font-weight: normal !important;
  	}
  	select {
  		outline: none !important;
  		border-width: 0;
		  option {
		    font-family: Arimo,sans-serif;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    font-smoothing: antialiased;
		    font-weight: 400;
		    font-size: 13px !important;
		    line-height: 15px !important;
		    padding: 6px 10px;
		    color: #333;
		    cursor: pointer;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    &:checked {
		    	background: #d5edf9 !important;
    			color: #2E6C9E !important;
		    }
		    &:hover {
	    	  background: #2f93c8;
				  color: #FFF;
		    }
        &:disabled {
          opacity: 0.5;
          cursor: initial;
          &:hover {
            color: #333;
            background: none;
          }
        }
      }
    }
  }
}
