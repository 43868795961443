.w-StrategyListItem {

	&.strategy-item {
		.accordion-open {
			@include box-shadow($swidth: -5px, $scolor: $color-A6, $horizontal: 0, $vertical: 10px, $blur: 8px);
			background: $color-A12;
		}

		&.strategy-badge-panel-open .accordion-open {
			z-index: 0 !important;
		}
	}

	.edit-icon-tooltip {
		position: absolute;
	    right: 0;
	    top: 6px;
	    cursor: pointer;
	}

	// TODO: safe to remove?
	// .strategy-edit-icon {
	// 	@include sprite-icon($left: 9, $down: 1, $width: 19px, $height: 16px);
	// }

	.upstreamCapIcon {
		color: #9A9A9A;
	}

	.upstreamCapIcon:hover {
		color: #333;
	}

	.campaignCapIconActive {
		color: #383838;
	}

	.campaignCapIconInactive {
		color: #9C9C9C;
	}

	.campaignCapIconActive:hover,
	.campaignCapIconInactive:hover {
		color: #333;
	}

	.currency {
		font-size: 11px;
	}

	.conversions,
	.clicks,
	.impressions,
	.ctr,
	.ctc,
	.vcr,
	.viewability {
		.amount {
			font-size: 11px;
		}
	}

	.editable-c .amount {
		font-size: 11px;
	}

	.budget,
	.remaining {
		.info-text {
			display: none;
		}
	}

	.editable-content {
		line-height: 16px;
		min-height: 16px;
	}

	.strategy-drawer {
		.drawer {
			background: $color-A8;
			border-bottom: 1px solid $color-A7;
		}

		.ajax-loader {
			@include transparent(55);
			.ajaxSpinner {
				margin-left: 48%;
				margin-top: 3px;
			}
		}

		// TODO: safe to remove?
		// .target-data-loader-video {
		// 	position: absolute;
		// 	top: 50px;
		// 	width: 100px;
		// 	height: 22px;
		// 	left: 105px;

		// 	.ajaxSpinner {
		// 		margin-left: 0;
		// 	}
		// }

		// .target-data-loader-no-video {
		// 	position: absolute;
		// 	top: 50px;
		// 	width: 100px;
		// 	height: 22px;
		// 	left: 85px;

		// 	.ajaxSpinner {
		// 		margin-left: 0;
		// 	}
		// }
	}

	.strategy-edit-holder {
		position: absolute;
		right: 38px;
		top: 3px;
	}

	.actions .inactive {
		float: none !important;
	}

	.actions {
		top: 5px;
		right: 11px !important;
	}

	.section {
		cursor: pointer;
	}

	.name {
		.name-part,
		.type-part,
		.id-part {
			float: left;
			display: inline-block;
		}
		.name-part {
			@include calc("width", "98% - 93px");
			padding: 3px 0 0 10px;
			margin-top: -1px;
			margin-right: 3px;
		}

		.type-part {
			width: 26px;
			margin-top: -1px;

			.editable-c {
				width: 250px;

				.editable-content {
					line-height: 25px;
				}
			}

		}

		.id-part {
			width: 50px;
			text-align: right;
			line-height: 24px;
		}

		.strategy-item-icon {
			margin-right:5px;
		}

		.sub-title {
			line-height: 20px;
			display: block;
			float: left;
			font-size: 12px;
			word-break: normal;
			min-height: 18px;
			&.word-break {
				word-break: break-all;
			}
		}

		.type {
			line-height: 10px;
			width: 120px;
		}

		.drawer-expander {
			height: 10px;
			width: 10px;
			position: absolute;
			left: -8px;
			top: 6px;
			display: inline;

			&.icon-arrow {
				@include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);
				margin-left: 4px;
				margin-top: 1px;
			}

			&.expanded {
				&.icon-arrow {
					@include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
					margin: 4px 0 0 1px;
					padding-right: 2px;
				}
			}
		}

		.editable-content {
			min-height: 22px;
		}
	}

	.spendMode,
	.impressionMode {
		.spend-to-pace,
		.performance {
			.separator {
				width: 9px;
				text-align: center;
				line-height: 21px;
			}
		}

		.spend-to-pace {
			line-height: 21px;
			text-align: right;
			white-space: nowrap;

			.editable-c,
			.separator {
				display: inline-block;
				white-space: nowrap;
			}

			.editable-c {
				margin-top: -2px;

				.w-InlineEditForm {
					width: 80px;

					.text {
						width: 94%;
					}
				}
			}

			.min-bid {
				margin-left: 16px;
			}

			.cpm-bid-price .w-InlineEditForm .form-list:after,
			.min-bid .w-InlineEditForm .form-list:after,
			.max-bid .w-InlineEditForm .form-list:after {
				@include box-shadow($swidth: -5px, $scolor: $color-A6, $horizontal: 0, $vertical: 10px, $blur: 8px);

				background: $color-A12;
			    border: 1px solid $color-A6;
			    display: inline;
			    margin-left: -1px;
			    padding: 11px 8px;
			    position: absolute;
			    top: 0px;
			    visibility: visible;
			}

			.cpm-bid-price .w-InlineEditForm .form-list:after {
				content: 'CPM BID PRICE';
			}

			.min-bid .w-InlineEditForm .form-list:after {
				content: 'MIN BID';
			}

			.max-bid .w-InlineEditForm .form-list:after {
				content: 'MAX BID';
			}
		}

		.performance {
			white-space: nowrap;

			.editable-wrap-goal-value,
			.actual,
			.goal-type,
			.goal-type-2,
			.separator {
				float: left;
				display: inline;
				clear: none;
				margin-left: 0;
				margin-right: 0;
				padding-left: 0;
				padding-right: 0;
			}

			/* note: 85 used below is sum of [39: goal-type width + margin-right], [9: separator] and [36: goal-type-2] and [1: safty padding] */
			.editable-wrap-goal-value {
				//@include calc("width", "(100% - 85px) * 0.60");
				width: calc((100% - 85px) * 0.70);
			}

			.goal-type {
				width: 38px;
				text-align: right;
				margin-right: 1px;

				.data {
					width: 94%;
				}
			}

			.actual {
				//@include calc("width", "(100% - 85px) * 0.40");
				width: calc((100% - 85px) * 0.25);
			}

			.goal-type-2 {
				width: auto;
				text-transform: uppercase;

				.data {
					width: 99%;
				}
			}

			.symbol {
				line-height: 15px;
				top: -1px;
			}

			.section-c {
				margin-top: -3px;
			}
		}

		.spend-YDY .symbol {
			line-height: 15px;
			top: 3px;
		}
	}

	.spendMode{

		.pacing-label {
			width: 15.8%;
		}

		.ydy-label {
			width: 10.1%;
		}

		.bid-price-label {
			width: 10%;
		}

		.strategy-alloc-performance {
	    width: 18.2%;
		}

		.alloc-days-remaining {
	    margin-left: 22px !important;
		}

		.alloc-dates {
			margin-left: -6px !important;
		}
	}

	.impressionMode{

		.imp-pacing-label {
			width: 18%;
		}

		.imp-ydy-label {
			width: 7%;
		}

		.imp-bid-price-label {
			width: 9.5%;
		}

		.strategy-alloc-performance { 
      width: 17.8%; 
    }
	}

	.date-range {
		height: 22px;
		line-height: 22px;
		.days-remaining {
			margin-top: -7px;
			margin-left: 30px;
			background: $color-A12;
			text-align: center;
		}

		.section-c {
			height: 24px;
			float: left;
			width: 90px;

			.dates .data {
				font-size: 10px;
			}

			.dates {
				margin-left: 2px;
			}

			.start-date,
			.end-date {
				padding: 0;
				height: 22px;
				line-height: 22px;
			}
		}

		&.onhover-noedit {
			.dates {
				display: block;
				margin-top: -3px;
			}
		}
	}

	.list-item {
		@extend .box-fix;
		position: relative;
	}

	.item {
		@extend .box-fix;
		font-size: 10px;
		line-height: 27px;
		color: $color-A13;
		padding: 6px 1px 3px;
		position: relative;
		border-bottom: 1px solid $color-A7;
		min-height: 25px;

		.chart-icon {
			margin-top: 1px;
		}

		.section-c {
			padding-top: 3px;

			&.not-editable-c {
				padding-top: 0;
			}
		}

		&:hover {
			background: $color-D7;
		}

		.disabled {
			background: $color-A10;
			font-style: italic;
		}

		.budget {
			.editable-content {
				right: 0;
			}
		}

		.frequency-cap-info-upstream-icon {
			color: #9A9A9A;
		}

		.frequency-cap-info-upstream-icon:hover {
			color: #333;
		}

		.frequency-cap-info-campaigncap-icon-active {
			color: #383838;
		}

		.frequency-cap-info-campaigncap-icon-inactive {
			color: #9C9C9C;
		}

		.frequency-cap-info-campaigncap-icon-active:hover,
		.frequency-cap-info-campaigncap-icon-inactive:hover {
			color: #333;
		}

		.frequency-cap-icon-hide,
		.frequency-no-cap,
		.impression-no-cap,
		.frequency-cap-tip-content {
			display: none;
		}

		.remaining {
			.impression-no-cap {
				display: none;
			}

			.editable-c {
				.editable-content {
					right: 0;
				}

				.input {
					width: 35%;
				}
			}

			.impression-cap-text {
				.editable-content {
					right: 0;
				}

				.input {
					width: 35%;
				}

				.w-GroupInlineEditForm input {
					width: 90%;
				}
			}
		}

		.budget,
		.remaining {
			.frequency-cap-text {
				.chzn-drop,
				.chzn-results {
					min-width: 110px !important;
				}
			}
			.impression-cap-text {
				.chzn-drop,
				.chzn-results {
					text-align: left;
					min-width: 70px !important;
				}
			}
			.editable-c {
				width: 275px;

				.editable-content {
					white-space: nowrap;
					position: absolute;
				}

				.w-GroupInlineEditForm {
					top: -7px;
					dd.info {
						height: 24px;
						line-height: 24px;
					}
					.form-list {
						height: 25px;
						.input {
							height: 24px;
							line-height: 24px;
						}
						dd.select {
							.chzn-results {
								top: -4px;
								background: #ffffff;
							}
						}
					}
				}
			}
		}

		.remaining {
			.editable-c {
				.w-GroupInlineEditForm {
					.form-list {
						dd.select {
							.chzn-drop {
								min-width: 98px !important;
							}
						}
					}
				}
			}
		}

		.frequency-type,
		.pacing-type,
		.impression-pacing-type {
			text-transform: uppercase;
		}

		.allocation-pacing-interval {
			display: none;
		}
	}
}

.read-only-view .w-StrategyListItem {
	.type-part {
		margin-top: 3px;
	}

	.item {
		.remaining .section-c,
		.budget .section-c {
			line-height: 10px;
			text-align: right;
		}
	}
}

.w-PacingASAPAlert {
	@extend .dialog-panel;
	width: 410px !important;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;

	.ui-dialog-title {
		font-size: 24px;
		font-weight: normal;
		line-height: 23px;
		color: $color-A1;
	}

	.confirm-message {
		margin-top: 10px;
	}

	.ui-dialog-content {
		padding: 0.5em 0.2em;
	}

	b {
		@include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 28px);
		float: left;
		margin-right: 10px;
		margin-top: -3px;
	}

	.confirm {
		@extend .btn-primary;
		float: right;
	}

	.cancel {
		@extend .ui-cancel;
		margin-top: 6px;
		padding-right: 0;
	}
}

.performanceMode1,
.performanceMode7,
.performanceMode14,
.performanceMode30,
.performanceModeFTD,
.performanceModeCTD {
	.w-StrategyListItem .item {
		.budget,
		.remaining {
			display: none;
		}
	}

	.performanceMode {
		.section {
			margin-top: -2px;
		}
	}
}

.w-StrategyFrequencyCap {
	text-transform: capitalize;
	font-weight: normal;

	.tipsy-inner {
		padding: 5px;
	}

	.tipsy-arrow {
		margin-left: 0;
		left: 43%;
	}
	.tipsy-bold {
		font-weight: bold;
		color: #FFFFFF;
	}
	.tipsy-normal {
		font-weight: normal;
	}
}

.strategy-item {
	.strategy-edit-holder,
	.strategy-actions-holder {
		display: none;

		&.hover  {
			display: block;
		}
	}

	&:hover,
	&.hover {
		.strategy-edit-holder,
		.strategy-actions-holder {
			display: block;

			&.suppress-hover  {
				display: none;
			}
		}
	}
}

.disabled-strategy-items{
	pointer-events: none;
	color: #9a9a9a !important;
}

.strategy-edit strand-icon {
	color: #676767;
}

.deactivate-strategy-modal {
	.strand-modal {
		p {
			font-size: 12px;
			line-height: 18px;
			margin: 0;
		}
		strand-header {
			margin-bottom: 20px;
		}
		.content-top {
			padding: 15px;
		}
	}

}
