.w-SegmentReporting-tabset .tab-item {
    height: 100%;
}

.segments-reports {
    height: 100%;

    .unsaved-modal {
        .modal-content { padding: 30px; }
        .modal-header { margin-bottom: 30px; }
        .warning-icon { 
            margin-right: 10px; 
            color: $color-F4;
        }
        .modal-copy {
            font: normal 14px/14px $unified-font-regular;

            p:first-of-type {
                line-height: 16px;
                margin-bottom: 20px;
                word-break: break-all;
            }
        }
    }

    .w-segments-data-export {
        top: 25px;
        right: 0;
        bottom: 60px;
        left: 0;
        position: absolute;
        padding-top: 25px;
    }

    .manage {
        padding-top: 25px;
        position: absolute;
        width: 1145px;

        .btn-add-report {
            float: right;
            width: 110px;
        }
    }

    .create-edit {
        height: 100%;

        ._wrapper {
            height: 100%;
        }
    }

    .create-edit-wrapper {
        height: 100%;
        padding-left: 25px;
    }

    .segment-export-wrapper {
        width: 100%;
        height: 40px;

        #segment-export {
            float: right;
            margin-right: 50px;
        }
    }

    .data-export-grid {
        float:left;
        width: 100%;
        height: calc(100% - 135px);
    }

    .data-export-info-marker {
        display: inline-block;
        margin: 13px 0 0 70px;
        position: absolute;

        .arrow-left {
            border-bottom: 8px solid transparent;
            border-right: 8px solid $color-D10;
            border-top: 8px solid transparent;
            float: left;
            height: 0;
            margin-top: 7px;
            width: 0;
        }

        .marker-label {
            background-color: $color-D10;
            color: $color-A12;
            float: left;
            font-family: $unified-font-regular;
            font-size: 13px;
            padding: 9px;
        }
    }

    .clear-left {
        clear: left;
        height: 0;
    }
    
    .faded {
        opacity: 0.5;
    }

    .report-type {
        margin-left: 0px;
        float: left;
        position: relative;
        width: 200px;
        height: 60px;
        .report-type-title { margin-bottom: 5px; }
    }

    .date-field {
        float: left;
        margin-left: 40px;
        position: relative;
        width: 175px;
        .date-field-header { margin-bottom: 5px; }
    }

    .email {
        margin-left: 0px;
        float: left;
        position: relative;
        width: 300px;
        height: 60px;

        .report-recipients-title { margin-bottom: 5px; }
    }

    .report-info {
        float: right;
        position: relative;
        width: 350px;
        margin-right: 50px;
        line-height: normal;

        strand-header { left: 10px; }

        .text {
            font-family: Arimo;
            font-size: 12px;
        }
    }

    .report-info-wrapper {
        border: 1px solid #B0E8F9;
        padding: 15px;
        border-radius: 5px;
        background-color: #e2f7fd;
        margin-bottom: 15px;

        #info-icon {
            color: $color-A20;
        }
    }

    .report-info-header-wrapper {
        position: relative;
        height: 25px;
    }

    .select-campaigns {
        width: 300px;
    }

    .select-campaigns > ._wrapper {
        float: left;
        clear: left;
        position: relative;
        width: 100%;
        height: 60px;
        margin-top: 4px;

        strand-header { margin-bottom: 5px; }
    }
    .select-campaigns {
        ._wrapper {
            #campaigns {
                opacity: 1 !important;
            }
        }
    }

    .select-segments-wrapper {
        float: left;
        width: 100%;

        .entities {
            float: left;
            display: inline;
            width: 100%;
            height: 23px;

            .chzn-container {
                position: relative;
            }

            .chzn-single {
                height: 23px;
                margin: 0px;
                width: auto;
            }

            .chzn-single span {
                line-height: 23px;
                font-family: $unified-font-regular;;
                font-size: 13px;
                font-weight: normal;
                padding: 0px;
            }

            .chzn-drop {
                width: 100% !important;
                margin-left: 0px;
            }
        }
        .entities-results-wrapper {
            float: left;
            display: inline;
            width: 69%;
            height: 23px;
        }

        #entities-results {
            border-radius: 0 3px 3px 0;
            behavior: url("css/css-pie/PIE.htc");
            -pie-watch-ancestors: 1;
            position: relative;
            background: #fff url('../images/chosen-sprite.png') no-repeat 100% -22px;
            padding-left: 5px;
            padding-right: 20px;
            border: 1px solid #c3c3c3;
            font-size: 12px;
            height: 21px;
            width: 210px;
        }
    }

    .segments-panel {
        height: 100%;

        .w-CampaignStrategyCreateEdit {
            height: 100%;
        }

        .w-Targeting, .w-PixelTargeting {
            height: 100%;

            .w-foot {
                display: none;
            }
        }

        .w-panel-body {
            position: relative;
            height: 100%;
            width: auto;
        }

        .w-DataGrid .content {
            padding-left: 0;

            .item .item-row {
                padding: 10px 0;
            }
        }

        .column-A {
            position: absolute;
            @include box-fill();
            width: 67%;
            height: 100%;
            overflow: hidden;
        }

        .segment-select {
            color: $color-A5;
            &.selected {
                color: $color-B1;
            }
        }

        .segment-select:not(.selected):hover {
            color: $color-A1;
        }

        .list-c {
            position: absolute !important;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
        }

        .grid-holder {
            height: 100%;
        }

        .list-body {
            top: 0;
        }

        .bins-column {
            position: absolute;
            @include box-fill($left: auto);
            width: 33%;

            .include-bin {
                border-top: 1px solid #e0e0e0;
            }
        }

        .a, .b {
            width: 4%;
            text-align: center;
        }

        .segment-name {
            width: 40%;
            .text {
                overflow: hidden;
                margin-right: 10px;
                text-overflow: ellipsis;
            }
        }

        .advertiser {
            width: 30%;
            .text {
                overflow: hidden;
                margin-right: 10px;
                text-overflow: ellipsis;
            }
        }

        .segment-id {
            width: 10%;
        }

        .edit-segment {
            width: 5%;
        }

        .w-Bins .title {
            margin-left: 0;
        }

        .w-Bins .label {
            margin-left: 4px;
        }

        .include {
            margin-left: 10px;
        }

        #strandGrid {
            min-height: 0px;
        }

    }

    .email-btn {
        min-width: 139px;
    }


    strand-icon#selected {
        color: #86c633;
        &:hover {
            color: #86c633;
        }
    }
    strand-icon#deselected {
        color: #C3C3C3;
        &:hover {
            color: #2c333a;
        }
    }
}

.report-image {
    background: url(../images/overlap_example.png) no-repeat top center;
    width: 100%;
    height: 1000px;
    background-size: contain;
}