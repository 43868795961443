.w-CampaignListItem {
  // strand-icon styling for the campaigns grid
  .campaign-edit {
    // This is FIXED in https://github.com/MediaMath/compass/pull/1856
    // can be removed once this PR is merged
    .icon-edit {
      background: none;
    }
    color: $color-A1;
  }

  [dir='LTR'],
  .symbol {
    font-family: Arial;
  }

  .view-loader-strategy-list {
    .loader {
      @include transparent(60);
    }
  }

  .strategy-list {
    margin: 0 4px;
  }

  .strategyGoal .strategy {
    display: block;
  }

  .campaignGoal .campaign {
    display: block;
  }

  .toggle {
    display: none;
  }
  position: relative;

  @include rounded-shadow-box($shadow-color: $color-A6, $shadow-border: 1px, $blur: 2px);
  @include _box-shadow($shadow: 0 3px 3px -4px $color-A4);
  border-top: none;
  padding: 0;
  margin: 0 0 2px;

  &:hover {
    padding: 0;
  }

  .strategy-list-open {
    border-style: solid;
    border-color: $color-A4;
    border-width: 0 1px 1px;
    padding: 0;
  }

  .campaign-headers {
    @include border-radius(5px);
    border-top: 1px Solid $color-A6;
    margin: 0;
    padding: 8px 0;

    &:hover {
      background: $color-D7;
    }

    .advertiser {
      font: 11px/21px $unified-font-bold;
      text-align: left;
      margin-left: 10px;
      color: $color-D1;
      display: inline-block;
    }

    .sub-title {
      font: 13px/18px $unified-font-bold;
      color: $color-A1;
      cursor: pointer;
      word-break: break-all;
      height: 18px;
    }
  }

  .favorite-icon {
    @include sprite-icon($left: 0, $down: 4, $width: 28px, $height: 28px);
    @include border-radius(4px 0 0);
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .favorite-enabled {
    @include sprite-at-position($left: 1, $down: 4);
  }

  .check-icon {
    padding: 0px 3px 0 11px;
    margin-top: 22px;
  }
  .campaign-item-icon {
    margin-right: 3px !important;
  }

  .onhover {
    .dates {
      display: block;
    }
    .start-date,
    .end-date {
      border-bottom: 1px dotted $color-A1;
    }
  }

  .strategy-headers {
    border-top: 1px solid $color-A6;
    text-transform: uppercase;
    text-align: right;
    padding: 0;
    line-height: 2.25em;
    height: 27px;
  }

  .strategy-list-headers {
    background: $color-A9;
    border-top: 1px Solid $color-A6;
    border-bottom: 1px solid $color-A6;
  }

  .search-box {
    position: relative;
    padding: 0;
    width: auto;

    .text {
      @include border-radius(0);
      background: $color-A12;
      font-size: 11px;
      padding: 1px;
      border: 2px inset;
    }
  }

  .strategies-search-box {
    position: relative;
    z-index: 2;
    display: none;
  }

  .smart-scroll-bottom .strategies-search-box {
    display: none !important;
  }

  .strategies {
    @extend .box-fix;
    position: relative;

    .loading-indicator {
      @include calc('left', '50% - 50px');
      @include transparent(80);
      position: absolute;
      top: -40px;
    }
  }

  .checkbox-input .checkbox {
    z-index: 1;
  }

  .headers {
    .budget-meter {
      margin-top: -2px;

      .remaining-amount {
        margin-top: 6px;
      }
    }
  }

  .performance {
    .editable-content {
      .amount {
        min-height: 12px;
      }
    }
  }
}

.deactivate-campaign-modal {
  .strand-modal {
    p {
      font-size: 12px;
      line-height: 18px;
      margin: 0;
    }
    strand-header {
      margin-bottom: 20px;
    }
    .content-top {
      padding: 15px;
    }
  }
}

.activate-campaign-modal {
  .saving-error-modal {
    padding: 25px;
  }

  .header-holder {
    padding-bottom: 10px;

    #headerIcon {
      color: $color-F7;
      padding-right: 5px;
      vertical-align: text-bottom;
    }
  }

  .error-message-holder {
    padding: 10px 0 25px 25px;
  }
}
