.w-StrategyDrawer {
	@extend .box-fix;
	@include _border-radius(0 0 4px 4px);
	padding: 4px 0 0 0;
	margin: 0 0 0 -2px;
	height: 45px;

	.t1-tooltipremaining-amount {
		font-size: 11px;
	}

	.name {
		.header,
		.section-c {
			margin-right: 9px;
			padding-right: 0;
		}

		.editable-c {
			width: 220px;

			.editable-content {
				text-align: right;

				.info-text {
					display: none;
				}

				.impressioncap-type {
					visibility: hidden;
				}

				.impressioncap-editable {
					float: right;
					text-align: right;
				}

				.impressioncap-editable:hover {
					border-bottom: 1px dotted $color-A1 !important;
				}
			}

			.group-edit-content:hover {
				border-bottom: 1px solid transparent !important;
			}

			.w-GroupInlineEditForm {
				top: -7px;
				.form-list {
					height: 25px;
					.input {
						height: 24px;
						line-height: 24px;
						width: 35%;
						.text {
							height: 21px;
							line-height: 21px;
							width: 90%;
						}
					}
					.input-c {
						height: 22px;
						line-height: 22px;
					}
					dd.select {
						width: 85px;
						height: 25px;
					}
					.chzn-results {
						top: -4px;
						background: white;
					}
				}
				.info-c {
					width: 50px;
					white-space: nowrap;
					line-height: 22px;
					height: 22px;
				}
			}
		}
	}

	.section-c {
		float: right;
		padding-right: 10px;
		display: block;
		cursor: pointer;
		clear: both;
		font: 10px/27px $unified-font-regular;
		color: $color-A13;
		line-height: 20px;
	}

	.budget {
		.symbol {
			line-height: 10px;
		}

		.editable-c {
			width: 120px;

			.editable-content {
				text-align: right;
			}
		}
	}

	.header {
		float: right;
		color: $color-A3;
		text-transform: uppercase;
		line-height: 2.25em;
	}

	.budget-meter .remaining-amount {
		color: $color-A13;
		font: 11px/27px $unified-font-regular;
		padding-left: 0;
	}

	.remaining {
		.section-c {
			margin-top: -4px;
		}
	}

	.spend-YDY,
	.spend-to-pace {
		.symbol,
		.currency {
			line-height: 20px !important;
		}

		.section-c {
			margin-top: -2px;
		}
	}

	.spend-YDY {
		.editable-c {
			width: 120px;
			.editable-content {
				.bid_agreesiveness_ediable:hover {
					border-bottom: 1px dotted $color-A1 !important;
				}
			}
			.group-edit-content:hover {
				border-bottom: 1px solid transparent !important;
			}
			.w-GroupInlineEditForm {
				top: -7px;
				right: -17px;
				.form-list {
					height: 25px;
					dd.input {
						line-height: 25px;
						width: 47px;
						height: 20px;
						.input-c {
							margin-top: 1px;
							height: 24px;
						}
					}
					dd.info {
						width: 40px;
						white-space: nowrap;
						line-height: 22px !important;
						height: 22px !important;
						margin-top: 2px;

						&:last-child {
							width: 15px;
							.info-c {
								color: $color-A13 !important;
								margin-left: -3px;
								height: 23px;
								border-left: 0;
								padding-left: 0;
								text-align: left;
							}
						}
					}
				}
			}
		}
	}

	.section {
		line-height: 24px;
		padding-bottom: 10px;
		text-transform: none;
		position: relative;
		float: left;
	}

	.section-c.not-editable-c {
		margin-top: -2px;
	}
}