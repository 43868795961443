.w-budgetFlights,
.w-vendorsList,
.w-itemList {
  padding: 0 13px 15px 48px;
  background-color: $color-A12;

  .title {
    font: 14px $custom-font-semi-bold;
    color: $color-A3;
    width: 160px;
  }

  .tab-info {
    margin-top: 2px;
  }

  .headers {
    margin-top: 20px;
    font: 14px $custom-font-semi-bold;
    color: $color-A3;

    .cost {
      padding-left: 32px;
    }
  }

  .budget-flight-row,
  .agreement,
  .list-item-row {
    margin-top: 10px;
    padding: 5px 0;
    position: relative;
    border-bottom: 1px solid $color-A6;
  }

  .source {
    padding-right: 10px;
    width: 105px;
  }

  .vendor {
    padding: 0 10px;
    width: 420px;

    .chzn-drop {
      z-index: 2;
    }
  }

  .col-amount,
  .cost,
  .name {
    width: 135px;

    .input,
    .cpm,
    .symbol {
      display: inline-block;
    }

    .symbol {
      width: 30px;
      text-align: right;
    }

    input {
      margin-right: 5px;
      width: 50px;
    }

    input:disabled {
      @include transparent($percent: 50);
    }
  }

  .col-add-cmd,
  .add-vendor,
  .add-item {
    span {
      color: $color-D1;
      font-size: 11px;
      line-height: 25px;
      margin-left: 15px;
      vertical-align: bottom;
      cursor: pointer;
    }
  }

  .remove {
    @include sprite-icon($left: 7, $down: 0, $width: 16px, $height: 16px);
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;

    &.hidden {
      visibility: hidden;
    }
  }
}

.w-budgetFlights {
  padding: 0;
  margin-bottom: 25px;
  font-size: 13px;

  .budget-flight-row {
    &.current-flight {
      font: 14px $custom-font-semi-bold;
    }

    &.single-row {
      border-bottom: 1px solid transparent;
    }
  }

  .special-message {
    .col-chart-icon,
    .col-delete-cmd,
    .col-amount {
      visibility: hidden;
    }
  }

  .current-label {
    font: 12px $custom-font-italic;
  }

  .title {
    width: 131px;

    .tab-info {
      margin-right: 19px;
    }
  }

  .col-dates {
    width: 360px;
    line-height: 24px;
    cursor: pointer;

    &.budget-ended {
      @include transparent(60);
    }
  }

  .col-amount,
  .col-imp-amount {
    width: 140px;

    &.budget-ended {
      @include transparent(60);
    }

    .symbol {
      display: inline-block;
      white-space: nowrap;
    }

    input {
      width: 90px;
      text-align: right;
    }

    .read-only {
      line-height: 24px;
      text-align: right;
      width: 95px;
    }
  }

  .col-imp-amount {
    .input {
      display: inline-block;
    }
    .symbol {
      margin-left: 20px;
    }
  }

  .w-dropdown-section,
  .headers {
    .col-amount {
      margin-left: 20px;
    }
    .col-imp-amount {
      margin-left: 0;
    }
    .label-note {
      font-family: $custom-font-italic;
    }
    .col-cpe-header {
      width: 145px;
    }
    .col-io-header {
      width: 66px;
    }
    .col-campaignid-header {
      width: 100px;
    }
    .col-chart-icon-header {
      width: 17px;
    }
  }

  .col-cpe {
    width: 145px;
    &.budget-ended {
      @include transparent(60);
    }
  }

  .col-io {
    width: 66px;
  }

  .col-campaignid {
    width: 70px;
  }

  .col-io.budget-ended {
    @include transparent(60);
  }

  .col-chart-icon {
    width: 40px;

    span {
      @include sprite-icon($left: 10, $down: 11, $width: 18px, $height: 18px);
      @include transparent($percent: 60);
      display: inline-block;
      float: right;
      margin-top: 7px;

      &:hover {
        @include transparent($percent: 99);
      }
    }

    .tooltip-tabs {
      width: 120px;
    }
  }

  .col-add-cmd {
    font-family: $custom-font-regular;

    span {
      margin-left: 15px;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .col-delete-cmd {
    width: 20px;
    float: right;
  }

  .remove {
    top: 10px;
  }

  .w-body {
    margin-bottom: 10px;
  }

  .w-dropdown-section {
    .col-currency {
      line-height: 24px;
      width: 210px;
      margin-left: 30px;
    }

    .chzn-results {
      max-height: 175px;
    }
  }

  .showCurrentFlights {
    .form-checkbox {
      line-height: 18px;
      margin-left: 5px;
    }
  }
}

#date-picker-budgetflights {
  width: 418px;
}

.budgetflight-chart-icon-tooltip {
  width: 166px;
}
