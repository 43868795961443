.w-DigitalTargeting {

	.w-Filters .chzn-container {
		width: 135px;
	}

	.w-DataGrid {
		.arrow {
			&.loading {
				.icon-spinner {
					top: 15px;
					left: 7px;
				}
			}
		}

		.icon {
			float: left;
		}

		.browse-list .item {
			border-bottom: 1px solid $color-A11;
		}

		.children {
			$width: 30px;
			$offset: 25px;

			.no-children {
				padding-left: $offset;
			}

			.children {
				padding-left: $width;
				margin-left: -$width;

				.item-row {
					padding-left: $width;
				}

				.no-children {
					padding-left: $width + $offset;
				}

				.children {
					padding-left: 2 * $width;
					margin-left: -(2 * $width);

					.item-row {
						padding-left: 2 * $width;
					}

					.no-children {
						padding-left: (2 * $width) + $offset;
					}
				}

				.detail .icons {
					margin-left: 10px;
				}
			}

			.item {
				padding-left: $width + $offset;
			}

			.detail .icons, .detail .type {
				margin-left: 40px;
			}
		}

		.search .item {
			padding-left: 60px;
		}
	}

	.w-Digital-Bins {
		.group-name {
			border-bottom: 1px solid $color-A7;
		}
	}

	.hidden {
		display: none;
	}

	.disabled {
		@include transparent();

		.select-action {
			cursor: text !important;
		}
	}
}
