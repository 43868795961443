div.tagsinput { 
	border:1px solid #CCC;
	background: rgba(255, 255, 255, 1.0); 
	padding:1px 10px; 
	width:300px;
	font-size: 13px;
	position: relative;
	display: inline-block;
	zoom: 1;
	*display: inline;
	font-weight: normal;
	overflow-y: auto;
	border-radius: 4px;
	box-shadow: inset 1px 1px 3px rgba(0,0,0,.125);
	span.tag { 
		border: 1px solid #ccc; 
		-moz-border-radius:2px; 
		-webkit-border-radius:2px; 
		display: block; 
		float: left; 
		padding: 5px; 
		text-decoration:none; 
		background: rgba(229, 229, 229, 1.0); 
		color: rgba(51, 51, 51, 1.0); 
		margin-right: 5px; 
		font-family: helvetica;  
		font-size:13px;
		a { 
			font-weight: bold; 
			color: #555; 
			text-decoration:none; 
			font-size: 11px;  
		} 

	}
	input { 
		width:80px; 
		margin:0px; 
		font-family: helvetica; 
		font-size: 13px; 
		border:1px solid transparent; 
		padding:4px 5px 4px 5px;
		background: transparent; 
		color: #000; 
		outline:0px;  
		margin-right:5px;
		margin-bottom: -2px;
	}
	div { 
		display:block; 
		float: left; 
	} 
}
.tags_clear { clear: both; width: 100%; height: 0px; }
.not_valid {background: #FBD8DB !important; color: #90111A !important;}
.validating {background: rgba(229, 229, 229, 0.5) !important; color: rgba(51, 51, 51, 0.5)!important;}