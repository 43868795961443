.w-ReadonlySupply {

	.heading-icon {
		@include sprite-icon($left: 4, $down: 4, $width: 22px, $height: 18px);
  }

  .supply-open, .supply-pmpe, .supply-pmpd {
    .data-row {
			padding: 0 0 16px;
    }
  }

	.supply-pmpe {
		.col1 {
			width: 58%;
		}
		.col2 {
			width: 8%;
			word-wrap: break-word;
		}
		.col3 {
			width: 6.5%;
		}
		.col4 {
			width: 6.5%;
		}
		.col5 {
			width: 6.5%;
		}
	}

	.supply-pmpd {
		.col1 {
			width: 58%;
		}
		.col2 {
			width: 17%;
		}
		.col3 {
			width: 15%;
		}
	}

	.supply-open {
        .section-head:first-child {
            padding-top: 0;
        }
  }
}