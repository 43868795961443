#bulkeditReviewFooter {
	position: absolute;
    bottom: 0px;
}

.bulkedit-review-container {
	.w-panel-body-strategies-errors,
	.w-panel-body-review {
	  @include box-fill($top: 50px, $bottom: 50px);
	}

	.w-panel-body-strategies-errors {
		top: 110px !important;
	}

	.bulk-changes-grid-error {
		height: 65% !important;
	}

	.strategies-link {
		color: $color-D4;
		cursor: pointer;
	}

	.be-review-actions {
		top: 60px;
	}

	.strategies-header {
		padding: 75px 20px 25px 25px;
	  
	    #strategies-header-warning-icon {
		  color: #fed01a;
		}
	}

	.actions-header {
		padding: 25px;
	}

	.w-ReadonlyStrategies,
	.w-ReadonlyReviewActions {
		position: relative;
    	height: 250px;
    	width: 100%;
	}

	.loader-holder {
		top: 80%;
	    left: 44%;
	    position: absolute;
	}

	.be-review-strategies {

		.header {
			cursor: default;
		}

		.header.strategies-error-type {
			width: 150px;

			.info-tooltip {
				position: absolute;
    			left: 50px;
    			top: 5px;

			  #readOnlyHeaderIcon {
				color: #666;
			  }

			  #readOnlyHeaderIcon:hover {
				color: #000;
			  }
			}
		}

		.header.strategies-name {
			width: 150px;
		}

		.header.strategies-id {
			width: 150px;
		}

		.header.strategies-status {
			width: 600px;
		}

		.w-DataGrid {
			padding: 0px 20px 0px 20px;
			bottom: 60px;
			min-height: 250px;

			.column.strategies-error-type {
				width: 150px;
			}

			.column.strategies-name {
				width: 150px;
			}

			.column.strategies-id {
				width: 150px;
			}

			.column.strategies-status {
				width: 600px;
			}

			.item {
				cursor: default;
			}
		}
	}

	.be-review-actions {
		.header {
			cursor: default;
		}

		.header.actions-type {
			width: 150px;
		}

		.header.actions-name {
			width: 450px;
		}

		.header.actions-assignment {
			width: 200px;
		}

		.header.actions-action {
			width: 100px;
		}

		.w-DataGrid {
			padding: 0px 20px 0px 20px;
			bottom: 60px;
			min-height: 340px;

			.column.actions-type {
				width: 150px;
			}

			.column.actions-name {
				width: 450px;
			}

			.column.actions-assignment {
				width: 200px;
				text-transform: capitalize;
			}

			.column.actions-bulk-action {
				width: 100px;
				text-transform: capitalize;
			}

			.item {
				cursor: default;
			}
		}
	}
}