.w-LocationTargeting {
  .header-warning {
    width: 100%;
    padding: 8px;
    background: $color-E7;
    border-left: 1px solid $color-A7;
    border-bottom: 1px solid $color-A7;

    .icon {
      background: url(../images/mm_sprite.png) -224px -112px no-repeat;
      display: block;
      height: 22px;
      width: 28px;
      float: left;
    }

    .text {
      color: $color-A3;
      font: 11px/14px $custom-font-semi-bold;
      padding-top: 5px;
      margin-left: 35px;
    }
  }

  .save-defaultlocation-info {
    margin: 0;
    width: 99%;
    display: none;
    padding: 7px 7px 4px 7px;

    .message {
      width: 96%;
    }

    .icon-close {
      position: absolute;
      right: 2px;
      top: 9px;
    }
  }

  .list-body {
    &.info-message-on {
      top: 104px;
    }
  }

  .w-Filters .chzn-container {
    width: 150px;
  }

  .filter-strip {
    .save-default-group {
      margin-top: 5px;
      width: 124px;
      float: right;

      .save-location-default {
        width: 86px;
        height: 16px;
        padding: 2px 5px 5px 5px;
        text-align: center;
      }

      .info {
        float: right;
        width: 15px;
        height: 16px;
        margin: 5px 0 0 0;
      }
    }
  }

  .browser-c {
    .disabled {
      @include transparent(45);
    }

    .w-DataGrid .content {
      bottom: 61px;
    }

    .item-wrapper {
      position: relative;
    }

    .empty-spacer {
      width: 25px;
    }

    .type {
      width: 48%;
      &.wrap {
        white-space: nowrap;
      }
    }

    .name {
      width: calc(48% - 33px);
      &.wrap {
        white-space: nowrap;
      }
    }

    .mapping-status {
      width: 13%;
    }

    .date-modified {
      width: 18%;

      .item-c {
        padding-left: 7px;
      }
    }

    .tooltip {
      color: #666666;
      text-transform: none;
    }

    .hyperlocal-info {
      color: #666666;
    }

    .cpm {
      width: 10%;

      .item-c {
        padding-left: 15px;
      }
    }

    .children {
      .children {
        position: relative;
        padding-left: 30px;
        margin-left: -30px;

        .detail .icons {
          margin-left: 10px;
        }

        .item-row {
          padding-left: 30px;
        }

        .item {
          padding-left: 45px;
        }

        .children {
          .item {
            padding-left: 65px;
          }
        }
      }

      .item {
        padding-left: 55px;
      }

      .detail .icons,
      .detail .type {
        margin-left: 40px;
      }

      .item-wrapper {
        position: static;
      }
    }

    .w-foot {
      @include box-fill($top: auto, $right: -18px, $bottom: 36px);

      .browse-path {
        bottom: 0;
        background: $color-A7;
        padding: 7px 15px 6px 13px;
        font-family: $custom-font-regular;
        font-size: 12px;
        color: $color-A3;
        line-height: 13px;
      }
    }

    .search {
      .item-row {
        margin-left: 50px;
      }

      .item-row.hyperlocal {
        margin-left: 35px;
      }

      .arrow {
        &:not(.hyperlocal) {
          display: none !important;
        }
      }

      .w-foot {
        visibility: hidden;
      }
    }
  }

  .browser-c,
  .postal-codes {
    .icons {
      position: absolute;
      width: 40px;
      margin-left: 10px;
      left: 0;
      z-index: 1;
    }

    .icon {
      float: left;
      .icon-spinner {
        margin-top: -10px;
        margin-left: 5px;
      }
      .show {
        opacity: 1;
      }
      .hide {
        opacity: 0;
      }
    }
  }

  .postal-codes {
    @include box-fill($top: auto);

    background-color: $color-D12;
    height: 26px;
    padding: 0 18px 10px 20px;
    margin-left: -20px;
    margin-right: -14px;

    .icons {
      float: left;
      width: 90px;
      padding-top: 10px;
      margin-left: 30px;

      span {
        margin-left: 5px;
        font: 12px/12px $custom-font-regular;
        vertical-align: top;
      }
    }

    .file-uploader-c {
      margin-left: 100px;
    }

    .uploadSpinner {
      position: absolute;
      right: 40px;
      top: 14px;
      width: 100px;
      height: 20px;
    }
  }

  .w-Locations-Bins {
    .group-name {
      border-bottom: 1px solid $color-A7;
    }

    .segment {
      width: 98% !important;
    }

    .cpm {
      display: inline;
      float: right;
      padding-right: 28px;
    }

    .download-label {
      display: inline !important;
      float: left !important;
      width: 80% !important;
    }

    .download {
      @include sprite-icon($left: 11, $down: 2, $width: 11px, $height: 16px);
      @include transparent($percent: 30);
      float: right;
      margin: 2px 23px 0 0;
    }

    .bin-default {
      font-family: $unified-font-bold-italic;
      font-size: 10px;
    }
  }

  .hidden {
    display: none;
  }

  .disabled {
    @include transparent();

    .select-action {
      cursor: text !important;
    }
  }

  #errorModal {
    .w-box {
      padding: 20px 15px 0;

      .w-head {
        padding-bottom: 15px;
      }
    }
  }
}
