
.apps-edit-tabs {
  .w-box.appTeamSection, .appAccessSection {
    width: 100%;
    background: $color-D12;
    border-right: 1px solid $color-A7;

    #usersGrid{
      height: 500px;
      strand-grid-item ._mm_column.strand-grid-item {
        font-size: 11px;
      }
    }
  }
  .apps-access-tabs {
    .tabs-content {
      background-color: #f2f4f8 !important;
      overflow-y: scroll;
      .appAccessSection {
        .form-body {
          padding: 0 !important;
        }
      }
    }
    .search-holder {
      background-color: $color-A12;
      padding-left: 10px;
    }
  }
  .form-body {
    padding: 0;
  }
   .editAccessForm {
    padding-top: -15px;
   }
   #accessUsersGrid {
    height: 532px;
    padding-top: 12px;
   }
  .tab-3-level-1-item {
    height: 100%;
  }
  .team-container {
    width: 100%;
    .form-body {
      padding: 0;
    }
    .no-results-found  {
      display: none;
    }
  }
  div[field="action"] {
    strand-icon {
      color: $color-A5;
    }
     strand-icon.selected {
      color: $color-B1;
     }
    :hover {
      color: $color-A0;
    } 
  }

  .bin-holder, .access-bin-holder {
    background: $color-D12;
    width: 32.9%;

    .bin-header {
      color: $color-A0;
      border-bottom: 1px solid $color-A7;
      background: $color-A12;
      font-size: 11px;
      text-transform: uppercase;
      padding: 10px 0 9px;
      strand-icon {
        margin-right: 10px;
        color: $color-B1;
      }
    }
    .dev-team-container {
      height: 509px;
      overflow-y: scroll;
      .dev-team {
        background: $color-A12;
        border-bottom: 1px solid $color-A7;
        font-size: 11px;
        padding: 5px 10px;
        border-left: none;
        strand-icon {
          margin-top: 2px;
          color: $color-A5;
        }
        :hover {
          cursor: pointer;
        }
      }
    }
  }
  .access-bin-holder {
    float: left;
    overflow-y: scroll;
  }
  .edit-team-container {
    width: 66%;
    padding-left: 11px;
    height: 587px;
    margin-bottom: 60px;
    .grid-holder {
      height: 500px;
    }
  }
  .edit-team-container, .edit-access-container  {
    background: $color-A12;
    border-right: 1px solid $color-A7;
    border-bottom: 1px solid $color-A7;
  }
  .edit-access-container  {
    padding-left: 10px;
    width: 66%;
    .grid-holder {
      height: 544px;
      #load-more-users {
        margin-top: 10px;
        position: absolute;
        left: 46%;
      }
    }
  }
  .search-team-users {
    width: 212px;
    margin:15px 0 10px;
  }
}

.w-AppsTestCreateEdit {
  .w-body {
    position: absolute;
    top: 116px !important;
    left: 0;
    width: 100%;
    height: auto !important;
    min-height: 600px !important;
  }

  .column-A {
    width: 470px !important;
    float: left;
  }

  .column-A-alt {
    width: 309px !important;
    margin-top: 15px;
    float: left;   
  }

  .column-B {
    float: left;
    margin-left: 57px;
  }

  .previewContainer {
    position:absolute;
    width: 287px !important;

    .preview-title {
      font-family: $unified-font-bold;
      font-size: 13px;
      margin-left: 86px;
      margin-bottom: 16px;
      color: $color-A3;
    }
  }

  .form-body {
    padding: 15px 0 0 44px;
    background-color: $color-A12;
  }

  .form-stack {
    margin-bottom: 30px !important;
    margin-top: 5px;
  }
    
  .icon{
    margin-left: 5px;
    color: $color-A4;
  }
}

.w-AppVersions {
  position: relative;
  
  .column-A {
    width: 581px !important;
  }

  .w-body {
    background-color: $color-A12;
  }

  .form-body {
    position: absolute;
    width: 605px;
    top: 0;
    left: 0;
  }

  .release-title-counter {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    margin-top: 6px;
  }

  .versionHistory{
    position: absolute;
    top: 0;
    left: 605px;
    padding: 37px 32px 50px 48px;

    .heading {
      font-family: $unified-font-bold;
      font-weight: 100;
      font-size: 14px;
      color: $color-A4;
      margin-bottom: 10px;
    }

    .history-container {
      p {
        font-size: 13px;
      }

      .history-item.disable {
        cursor: default;
      }

      .history-item {
        cursor: pointer;
        margin-bottom: 8px;

        p {
          display: inline-block;
          font-family: $unified-font-bold;
          font-weight: 100;
          font-size: 13px;
          color: $color-A4;         
        }

        p.disable {
          cursor: default;
        }

        .arrow-down {
          transform: rotate(90deg);
        }

        .arrow-spacer {
          display: inline-block;
          width: 10px;
        }

        .arrow-icon {
          color: $color-A4;
        }
      }

      .history-main {
        font-family: $unified-font-regular;
        font-weight: 100;
        font-size: 13px;
        color: $color-A4;
        margin: 6px 0 0 22px;
        overflow: hidden;

        pre {
          font-family: $unified-font-regular;
          font-weight: 100;
          font-size: 13px;
          color: $color-A4;
          word-wrap: break-word;
          white-space: pre-wrap;
        }

        ul {
          list-style-type: disc;
          margin-left: 15px;
        }

        li {
          margin-bottom: 2px;
          list-style-type: disc;
        }
      }
    }
  }

  .app-url,
  .release-title-input {
    width: 447px;
  }

  .release-notes {
    .textarea {
      font-size: 13px;
      height: 80px;
      padding: 4px;
      width: 447px;      
    }

    .field-note {
      font-size: 11px;
      font-family: $unified-font-italic;
    }
  }

  .upload-icon .half {
    float: left;
    width: 50%;
  }

  .upload-icon .fourty {
    float: left;
    width: 40%;
  }

  .upload-icon {
    .info-review {
      margin-top: 8px;
      display: none;
    }

    .fourty-four {
      width: 44%;

      .pending-review {
        margin-top: 8px;

        .cancel-pending {
          color: $color-A20;
          cursor: pointer;
        }
      }

      .pending-hidden {
        display: none;
      }
    }
  }

  .upload-icon .less-half {
    width: 46%;
  }

  .app-status {
    min-height: 27px;
    width: 107px !important;

    label {
      cursor: text;
      position: relative;
    }
  }

  .app-availability {
    min-height: 27px;
    width: 107px !important;    
  }

  .app-description {
    .icon {
      margin-top: 2px;
    }

    .radio {
      margin: 0;
    }

    strand-icon {
      margin-right: 44px;
    }

    span {
      color: #7dbef1;
      margin-left: 6px;
    }
  }
}

.w-AppDetails {
  .space-right {
    margin-right: 25px;
  }

  .external-icon {
    color: $color-A4;
    margin-left: 6px;
  }

  .external-tooltip {
    font-weight: normal;
  }

  .launch-externally {
    display: inline-block;
  }

  .launch-externally.hidden {
    display: none;
  }

  .column-B.terms-upload-container {
    width: 458px;
  }

  strand-modal.billing-changes {
    min-width: 800px;

    p.main-text {
      font-family: $unified-font-regular;
      font-smoothing: antialiased;
      color: #333;
      font-size: 13px;
      line-height: 15px;
      font-weight: 400;
      display: inline-block;
    }

    p.spacer {
      clear: both;
      position: relative;
      top: 1px;
      display: inline;
    }

    p.header-text {
      font-family: $unified-font-bold;
      font-size: 16px;
      margin-bottom:20px;      
    }
   
   .column-a {
      width: 100% !important;
      padding: 30px;
      box-sizing: border-box;
    }
  }

  #billing-cpm {
    margin-left: 20px;
    margin-top: -1px;
    font-family: AB !important;

    .hidden {
      display: none;
    }

    strand-input {
      width: 80px;
    }
  }

  #billing-radios {
    margin-top: 5px;

    label {
      font-family: AB !important;
      color: $color-A3;
    }
  }

  .billing-arrow {
    margin-top: -2px;
  }

  .billing-header {
    font-family: AB;
    font-size: 13px;
    cursor: pointer;
    color: $color-A3;
  }

  .billing-section {
    display: none;
    margin-left: 13px;
    color: $color-A3;
  }

  .billing-spacer {
    margin-top: 25px;
  }

  .billing-seperator {
    width: 96%;
  }

  .arrow-down {
    transform: rotate(90deg);
  }

  .terms-upload-container {
    margin-top: 16px;
  }

  .additional-terms .hidden {
    display: none;
  }

  strand-modal.learn-more-modal-edit {
    width: 10px !important;
  }

  margin-bottom: 50px;

  .app-desc-text {
    word-wrap: break-word;
  }

  .learn-more-tool-tip-content {
    display: none;
  }

  .uploaded-terms {
    margin-top: 5px;

    span {
      color: $color-A20;
      cursor: pointer;
    }

    strand-icon {
      margin-left: 8px;
      cursor: pointer;
    }

    .hidden {
      display: none;
    }
  }

  .file-uploader {
    width: 100%;
   
    .file-selector {
      padding-top: 0 !important;
      width: 457px !important;
    }

    .file-name {
      width: 542px !important;
      max-width: 100% !important;
      padding-right: 10px !important;
      
      .text-input {
        font-style: italic;
        opacity: 0.75 !important;
        max-width: 100% !important;
      }
    }

    .file-input {
      display: none;
    }

    .file-upload-btn {
      width: 47px;
    }
  }

  .w-body {
    background-color: $color-A12;
  }

  #app-name-body {
    margin-bottom: 8px !important;

    .app-name-container {
      margin-top: 20px;
    }
  }

  .app-name,
  .url,
  .release-title-input {
    width: 447px;
  }

  .support-email {
    width: 276px;
  }

  .learn-more-image {
    width: 457px;
  }

  .app-description {
    .textarea {
      font-size: 13px;
      height: 30px;
      padding: 4px;
      width: 447px;
    }
  }

  .learn-more-description {
    .textarea {
      font-size: 13px;
      height: 140px;
      padding: 4px;
      width: 447px;
    }
  }

  #column-B-form-input {
    width: 100%;
  }

  #required-notification .container {
    width: 836px;
  }

  #billing-notification {
    display: block;
    margin-top: 15px;

    .container {
      width: 823px;
    }
  }
}

.newAppForm {
  font-family: $unified-font-regular;

  .form-list dt {
    font-family: $unified-font-bold;
    font-size: 13px;
  }

  .form-input .text,
  .form-list dd {
    font-family: $unified-font-regular;
  }

  .field-note {
    font-size: 11px;
    font-family: $unified-font-italic;
  }

  .space-right {
    margin-right: 25px;
  }

  .external-icon {
    color: $color-A4;
    margin-left: 6px;
  }

  .external-tooltip {
    font-weight: normal;
  }

  .launch-externally {
    display: none;
  }
}

.w-AppsCreateEdit.w-CreateEditForm {
  padding-top: 0px !important;
  font-family: $unified-font-regular;


  .w-body.review-rejected {
    top: 110px !important;
  }

  .w-body {
    top: 64px !important;
  }

  .form-list dt {
    font-family: $unified-font-bold;
    font-size: 13px
  }

  .form-input .text,
  .form-input .textarea,
  .form-list dd {
    font-family: $unified-font-regular !important;
  }
}

.w-AppsCreateEdit {
  background-color: $color-D12;

  .padIcon{
    margin-left: 5px;
    color: $color-A4;
  }

  .description-counter,
  .app-name-counter {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
  }

  .description-counter {
    margin-top: 13px;
  }

  .app-name-counter {
    position: absolute;
    top: 32px;
    width: 50px;
    right: -48px;
  }

  dl.app-name {
    position: relative;
  }

  dl.app-description {
    width: 515px;
  }

  .w-head {
    height: 72px;
    padding: 25px 0 15px;

    h1 {
      @extend .apps-header;
      font-family: $unified-font-bold;
    }

    .review-rejected {
      margin: 10px 0 0 26px;
      width: 90%;

      .str-icn-warning {
        line-height: 20px !important;
      }
    }
  }

  .ribbon-holder {
    margin: 0;
  }

  .command-ribbon {
    background-color: $color-A12;
    border-top: 1px solid $color-A7;
    display: block;
    height: 39px;
    margin-top: -1;
    width: 100%;
  }

  .ribbon-strip {
    margin: 0;
    padding: 0;
    position: relative;
    zoom: 1;

    .ribbon-nav {
      background: linear-gradient($color-A12, $color-D12);
      border-bottom: 1px solid $color-A7;
      border-radius: 0;
      cursor: default;
      height: 48px;
      margin: 0;
      padding: 0 0 0 16px;
      position: relative;

      .search-filter {
        float: right;
        font-style: normal;
        margin-right: 10px;
        margin-top: 13px;
        width: 300;
      }

      > li:first-child {
        font: 12px/14px $unified-font-bold;
        margin-right: 10px;
      } 

      > li:last-child {
        float: right;
        margin-top: 0;
      }

      > li {
        float: left;
        margin-right: 20px; 
        margin-top: 17px;

        span {
          color: $color-A1;
          font: 12px/14px $unified-font-regular;
          text-transform: capitalize;
        }
      }

      > li.sort-by {
        margin-left: 8px;
        margin-top: 19px;
      }

      > li.sort-by span {
        font: 12px/14px $unified-font-bold;
      }     

      > li.sort-by span:hover {
        cursor: default;
        text-decoration: none;
      } 

      > li span:hover {
        cursor: pointer;
        text-decoration: underline;
      } 

      li.selected span {
        text-decoration: underline;
      } 
    } 
  }

  .basic-info {
    float: left;
    margin-left: 26px;
    width: 45%;
  }

  strand-modal.learn-more-modal,
  strand-modal.learn-more-modal-edit {
    min-width: 800px;

    .column-b {
      float: left;
      margin-right: 36px;
      margin-top: 24px;
      width: 304px;
    }

    h3 {
      letter-spacing: -1px;
      line-height: 23px;
      padding-bottom: 3px;
    }

    p {
      line-height: 16px;
    }

    hr {
      margin-left: -1px;
    }

    .buttons {
      background-color: $color-D12;
      font-size: 14px;
      height: 55px;
      margin-top: -2px;
      width: 100%;
    
      .buttons-c {
        float: right;
        margin-right: 24px;
        margin-top: 15px;
        padding: 5px;
       
        .launch {
          @extend .btn-primary;
          float: left;
          height: 12px;
          margin-top: -5px;
          width: 76px;
            
          div {
            margin-top: -2px;
          }
        }
        
        .close {
          color: $color-D1;
          float: left;
          font-family: $unified-font-regular;
          margin-right: 12px;
          text-decoration: underline;
          a:hover {
            cursor: pointer;
          }
        }
      }
    }

    .content-top {
      .modal-header {
        border-bottom: 1px solid $color-A5;
        height: 68px;
      }
    
      .app-icon {
        float: left;
        height: 90px !important;
        margin-left: 32px;
        margin-top: 20px;
        width: 88px;
      }

      strand-header.title {
        margin-left: 14px;
        margin-top: 25px;
      }

      .kb {
        color: $color-D1;
        float: right;
        font-family: $unified-font-regular;
        font-size: 12px;
        margin-right: 35px;
        margin-top: 29px;
      }
    }

    .columns {
      clear: both;
      height: 77%;
      margin-left: 32px;
      overflow: auto;
    }
    
    .column-a {
      float: left;
      margin-top: 24px;
      width: 385px;

      ul {
        margin-top: 4px;
        padding-left: 16px;
        width: 85%;
      }

      li {
        font-family: $unified-font-regular;
        font-size: 12px;
        line-height: 16px;
        list-style-type: disc !important;
        margin-bottom: 3px;
      }

      h3 {
        color: $color-A2;
        font-family: $unified-font-bold;
        font-size: 14px;
        line-height: 1.2;
        margin-top: 5px;
      }

      p {
        color: $color-A2;
        font-family: $unified-font-regular;
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 2px;
      }
    }
  }
  
  .header {
    color: $color-A2;
    font-family: $unified-font-bold;
    font-size: 14px;
  }

  .favorites-icon.selected {
    @include sprite-icon($left: 1, $down: 4, $width: 28px, $height: 28px);
  }

  .favorites-icon.not-selected {
    @include sprite-icon($left: 0, $down: 4, $width: 28px, $height: 28px);
  }

  .single-favorite {
    .favorites-icon {
      margin-left: -8px;
      margin-top: -9px;
    }
  }
   
  .all-favorites {
    margin-top: 10px;

    .favorites-icon {
      float: left;
      margin-top: 16px;
    }
    
    span {
      color: $color-A4;
      float: right;
      font-family: $unified-font-regular;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      margin-right: -20px;
      margin-top: 18px;
    }
  }

  .no-results {
    margin: 0 auto 64px;
    padding-left: 12px;
    padding-top: 40px;
    width: 500px;
    word-break: break-word;

    .warning-msg {
      font-family: $unified-font-regular;
      font-weight: 400;
      line-height: 20px;
      margin-left: 42px;
      margin-top: 0;
    }
   
    .warning-icon {
      @include sprite-icon($left: 2, $down: 10, $width: 56px, $height: 50px);
      float: left !important;
      margin-right: 10px !important;
    }

    .msg-header {
      color: $color-A4;
      font-family: $unified-font-bold;
      font-size: 15px;
    }

    .msg-subheader {
      color: $color-A5;
      font-size: 13px;  
      margin-top: 11px;
    }

    .warning-msg .strand-warning-icon {
      float: left !important;
      margin-right: 10px !important;
      top: 1px;
      color: $color-E2;
    }   
  }

  .spinner-container {
    @extend .apps-spinner-container;
    height: auto;
  }

  .w-Filters input {
    float: right;
    font-style: normal;
    width: 253px;
  }

  hr {
    background-color: $color-A5;
    color: $color-A5;
    height: 1px;
  }

  .selection-item {
    color: $color-D11;
    font-family: $unified-font-regular;
    font-size: 12px;
    line-height: 24px;
    padding-left: 6px;
  }

  .selection-item.selected {
    color: $color-A1;
    background-color: $color-D13;
  }

  .selection-item:hover {
    background-color: $color-D15;
    color: $color-A12;
    cursor: pointer;
    font-family: $unified-font-bold;
  }

  .app-column-a {
    float: left;
    width: 184px;
  }

  .app-column-b-c {
    background-color: $color-A12;
    padding-left: 8px;
  }

  .app-column-b {
    margin-right: 4px;
    margin-top: 22px;

    li:hover {
      cursor: pointer;
    }

    #sortable li:hover {
      cursor: grab;

      .app-title {
        cursor: pointer;
      }
      
      .learn-more-icon {
        color: $color-A4;
        cursor: pointer;
      }
    }

    #sortable  li:active {
      cursor: move;
    }

    #sortable li.ui-sortable-helper {
      cursor: move;
    }

    .last-used-message {
      margin: 0 auto;
      width: 270px;
    }

    .apps-list {
      overflow: auto;
      padding-top: 26px;
    }

    .extra-apps {
      margin-top: 15px;
    }

    .recently-used-title {
      font-family: $unified-font-bold;
      font-size: 15px;
      margin: -17px 0 20px 14px;
    }
  }

  .app-item {
    @include rounded-shadow-box($shadow-border: 1px, $border-radius: 0px, $border-color: #cccccc, $shadow-color: $color-A5);
    border: 1px;
    float: right;
    height: 261px;
    overflow: hidden;
    padding: 12px;
    width: 176px;

    .learn-launch {
      float: right;
      margin-right: -11px;
      margin-top: -18px;
      width: 30px;
    }

    .app-icon {
      height: 175px !important;
      margin: 0 0 12px;
      width: 175px;
    }

    .info {
      float: left;
      width: 100%;

      h2 {
        font-family: $unified-font-bold;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 11px;
        overflow: hidden;
        padding: 0 0 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-size: 11px;
        line-height: 14px;
      }

      a {
        color: $color-A20;
        display: inline-block;
        float: right;
        text-decoration: underline;
      }
    }

    .launch {
      padding: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .launch-icon {
      @include sprite-icon($left: 10, $down: 11, $width: 4px, $height: 4px);
      height: 4px;
      margin-left: -18px;
    }

    .learn-more-icon {
      bottom: 10px;
      color: $color-A20;
      display: inline-block;
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      right: 10px;
      text-decoration: underline;
      cursor: pointer; 
    }
  
    .learn-more {
      float: right;
      margin-top: -13px;
      padding-left: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .invisible {
      opacity: .5 !important;
    }
  }
}