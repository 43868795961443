/*
  The creatives tab inherits from these stylesheets:
  >  creative-list
  >  creative-create-edit
*/

.w-CreativeGrid {
  .w-Filters {
    .entities {
      height: 26px;
      bottom: 3px;
      .chzn-single {
        height: 26px;
      }
    }
    .text {
      height: 24px;
      bottom: 3px;
    }
    .status-select {
      width: 92px;
      bottom: 3px;
    }
    .type-select {  
      width: 125px;
      bottom: 3px;
    }
    .componentBased-new {
      label {
        cursor: text;
        position: relative;
        top: 1px;
      }
    }
  }
  .button.strand-button {
    padding: 14px 10px;
  }
  .ssl-warning {
    display: none;
  }
}

.w-CreativesComponentBase {
  .list-body {
    overflow: visible;
  }
  .w-strand-grid {
    height: 100%;
    .feedback, .fb_status {
      font-size: 9px;
    }

    strand-popover[_placement="n"]:after {
      border-top: 10px solid $color-A24 !important;
    }

    .net-status-hold {
      text-align: center;
    }

    .net-status-approved {
        color: $color-A21;
    }

    .net-status-pending {
        color: $color-E6;
    }

    .net-status-pending48 {
        color: $color-E6;
        margin-left: -5px;
    }

    .net-status-rejected {
        color: $color-A22;
    }

    .net-status-popover {
      font-size: 11px;
      color: $color-A2;
      left: 0;
      top: 0;

      dd {
          padding-bottom: 3px;
      }

      .net-pop-title {
          font-family: $unified-font-bold;
          padding-bottom: 9px;
      }

      .columnA {
          float: left;
          width: 120px;
          margin-right: 15px;
          margin-bottom: 10px;
      }

      .columnB {
          float: left;
          width: 126px;
          margin-bottom: 10px;
      }

      .net-status-icon {
          display: inline-block;
          padding-right: 5px;
          margin-top: -4px;
      }

      .status-text {
          display: inline-block;
      }

      .status-text::first-letter {
          text-transform: uppercase;
      }
    }
  }

  #grabber.strand-grid-column {
    z-index: 0;
  }
}

// create/edit
.w-ComponentBasedAdd  {
  .w-head .ssl-warning {
    width: 600px !important;
    display: block;
  }

  .w-body {
    top: 90px !important;
  }

  .component-template-download {
    margin-top: 22px;
  }

  .upload-assets {
    border: 0;
    margin-bottom: 0;
    
    .form-stack {
      padding-bottom: 6px;
    }
    .form-list {
      width: 450px;
      float: left;
      margin-right: 15px;

      &.component-create-edit-bulk-upload {
        width: auto;
        margin-top: 27px;
      }

      .component-bulk-upload-info-icon {
        color: #666666;
      }

      .file-name {
        width: 325px;
        padding-right: 10px;
      }
    }
  }

  .table-review {
    margin-bottom: 40px;

    span.title {
      font-family: 'PNLB';
      font-size: 15px;
      font-weight: bold;
    }

    .table {
      margin-top: 10px;
    }

    .create-status {
      display: flex;
      
      .create-status-progress {
        margin-top: 3px;
        padding-right: 2px;
      }
    }
  }

  .create-status-icon {
    padding-bottom: 1px; // firefox fix
    padding-right: 5px;
  }

  .component-bulk-error-msg {
    color: #ff0000;
  }
  
  .tpas {
    .optional {
      margin-left: 20px;
    }
  }

  .media-upload {
    .file-selector {
      padding-top: 0;
    }
  }

  .loader-holder {
    @include transparent($percent: 90);
    @include calc(top, '50% - 12px');
    @include calc(left, '50% - 80px');
    position: absolute;
  }

  .view-tag-list {
    dt {
      margin-bottom: 12px;

      label {
        color: $color-A3;
      }
    }

    .form-input {
      margin-bottom: 10px;
      
      strand-input {
        @include width-colx($x: 6);
      }
    }

    hr {
      padding-top: 0;
    }

    .remove {
      cursor: pointer;
      float: right;
      opacity: .5;
    }

    .add {
      color: $color-D1;
      cursor: pointer;
      font-size: 11px;
      line-height: 25px;
      vertical-align: bottom;
    }
  }
}

// must be outside w-CreativesGrid because JQuery.tipsy adds the tooltip HTML to body
.component-icon-error-tip {
  letter-spacing: .3px;
  margin: -9px 0 0 -5px;

  .tipsy-inner { padding: 6px; }
}

.create-edit-warning-1 {
  .component-bulk-media-upload-warning {
    left: 465px;
  }
  .component-bulk-upload-warning {
    padding-top: 0px;
  }
}

.create-edit-warning-2 {
  float: left;

  .component-bulk-media-upload-warning {
    left: 154px;
  }
}

.component-bulk-upload-warning {
  .info-box {
    &.error {
      width: 100%;
    }
  }
}

.component-bulk-uploadable-review, .component-bulk-not-uploadable-review {
  .component-bulk-success-icon {
    color: #9FCB66;
  }

  .component-bulk-submit-failure-icon {
    color: #EC4F53;
  }

  .component-bulk-not-uploadable-icon {
    color: #ED4E54;
  }

  .component-bulk-pending-icon {
    color: #989898;
  }
}

.exit-component-bulk-modal {
    
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  strand-header {
    margin-bottom: 14px;

    .warning-icon {
      color: #FED01A;
    }

    span {
      font-size: 18px;
    }
  }

  .content-top {
    padding: 20px;
  }

  strand-icon {
    margin-right: 20px;
  }

  .hideButton {
    display: none;
  }

  #modalCheckbox {
    left: 15px;
    position: absolute;
    top: 20px;
  }

        
}
