.w-ReadonlyPixel {

	.heading-icon{
		@include sprite-icon($left: 6, $down: 12, $width: 22px, $height: 18px);
	}

	.col1{
		width: 58%;
	}
	.col2{
		width: 10%;
	}
	.col3{
		width: 12%;
	}
}