.w-BulkEditDialog {
  @extend .dialog-panel-form;

  width: 850px !important;
  overflow: visible !important;

  .review,
  .assignment {
    display: none;
  }

  .assignment .form-stack {
    position: relative;
    margin-top: 0;
    margin: 0 -30px -30px;
    height: 420px;
  }

  .column-A {
    width: 40%;
  }

  .column-B {
    float: right;
    position: relative;
    width: 60%;
  }

  .info-wrapper{
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;

    span {
      text-align: right;
      padding: 6px 0 2px 0;
      font: 11px $custom-font-semi-bold;
      color: $color-A1;
    }
  }

  .title {
    font: 20px $custom-font-semi-bold;
    color: $color-A3;
    margin-top: 5px;

    .icon {
      float: left;
      margin: 4px 7px 0 0;
      @include sprite-icon($left: 5, $down: 4, $width: 16px, $height: 16px);
    }
  }

  .related-info {
    font: 11px $custom-font-semi-bold;
    color: $color-D4;
    text-transform: uppercase;
  }

  .form-body, 
  .info-box,
  .form-stack {
    margin-top: 15px;
  }

  .form-body {
    // max-height: 420px;
    // overflow-y: auto;
  }

  .checkbox-input {
    float: left;
    margin-right: 10px
  }

  dt {
    span {
      font-family: "PNR";
      font-weight: normal;
    }
  }

  .check-radio-forms label input[type="checkbox"] + span::before {
    content: "";
    @include sprite-at-position($left: 2, $down: 8);
    height: 16px;
    width: 16px;
    padding: 0;
    position: relative;
    margin: -3px 5px 0 0;
    border: none;
  }

  .check-radio-forms label input[type="checkbox"]:checked + span::before {
    background-position: -196px -224px;
    &:active{
      background-position: -168px -224px;
    }
  }

  .form-item {
    border-top: 1px solid $color-A8;
    padding: 10px;

    >dt {
      color: $color-A3;
      margin-bottom: 0;

      .security-tool-tip {
        color: #CACACA;
      }

      .security-tool-tip:hover {
        color: #676767;
      }
    }

    dd {
      font-size: 14px;
      font-weight: normal;
      color: $color-A3;
    }
  }

  .form-drawer {
    display: none;
    margin: 10px 0 0 26px;

    .form-type-drop {
      width: 100px;
    }

    .mraid-type,
    .mraid-expand {
      display: inline-block;
    }
  }

  .tpas_ad_tag {
    width: 320px;
  }

  .form-radio {
    display: inline-block;
    margin-right: 26px;
  }

  .form-input {
    position: static;
  }

  .concept-contain {
    min-height: 30px;

    .w-AddConcept {
      z-index: 9999;
    }
  }

  .concept-popup,
  .concept-select {
    width: 300px;
  }

  #mraidIcon {
    color: #CACACA;
    bottom: 2px;
    &:hover {
      color: #676767;
    }
  }

  .chzn-container {
    width:100%;

    .chzn-drop { width:100% !important; }
  }

  .text {
    position: static;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
  }

  .form-footer {
    overflow: hidden;

    .save-action,
    .saveAndContinue-action {
      span {
        visibility: hidden;
      }
    }

    .back {
      float: left;
      .icon {
        // Removing icon until correct one is added
        // @include sprite-at-position($left: 5, $down: 0);
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 14px;
        margin-right: 3px;
      }
    }
  }

  .entityType {
    text-transform: capitalize;
  }

  .w-DataGrid {
    .content-holder {
      /* Enable Scrollbars */
      max-height: 122px;
    }

    .headers {
      padding: 7px 20px;
    }

    .name,
    .field {
      width: 48%;
      margin-right: 2%;
      word-wrap: break-word;
    }

    .id,
    .value {
      width: 50%;
      word-wrap: break-word;
    }
  }

  .w-boxList {
    .popup-container {
      z-index: 9999;
    }
  }

  .w-FilterList {
    .form-footer-style {
      .ui-cancel {
        margin-right: 15px;
      }
    }
  }

  .date-picker {
    .remove-schedule {
      float: right;
      margin-bottom: 0;
    }

    .start-end-dates {
      width: 60%;
    }

    .schedule .title {
      font-family: $custom-font-semi-bold;
      font-weight: 100;
      font-size: 14px;
      color: $color-A3;
    }
  }

  .date-picker-calendar {
    width: 418px;
    height: 264px;
  }

  .date-display {
    float: left;
    border-bottom: 1px solid transparent;

    &:hover {
      @extend .isEditable;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .entity-tip {
    @extend .isEditable;
  }
}

.tooltip-bulkedit {
  @extend .tooltip-tabs;
  width: auto;
  max-width: 500px;
  white-space: nowrap;
  
  .truncate {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
