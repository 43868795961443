.w-BreadCrumb {
	padding-left: 15px;
	padding-top: 10px;
	min-width: 1240px;
	font-smoothing: antialiased;
	letter-spacing: 0.3px;

	span, a {
		font: 11px/12px $unified-font-regular;
		color: $color-D9;
		text-decoration: none;
		cursor: default;
	}

	a, .arrow {
		float: left;
		vertical-align: top;
	}

	.arrow {
		margin: 0 5px;
	}

	.trunc-crumb {
		float: left;
		max-width: 25%;
		color: $color-D10;
	}

	.second-level {
		max-width: 50%;
	}

	.active{
		text-decoration: underline;
		&:hover {
			color: $color-D7;
			cursor: pointer;
		}
	}

	.info-c {
		float: right;
		margin-right: 20px;
		margin-top: -4px;

		span {
			vertical-align: none;
		}
	}

	.display-info {
		float: right;

		.label {
			font: 9px/9px $unified-font-regular;
			padding-left: 20px;
			text-transform: uppercase;
		}

		.value {
			font: 9px/9px $unified-font-bold;
			padding-left: 5px;
		}
	}
}