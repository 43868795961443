.unsaved-segment-changes-dialog {
  box-shadow: 0 0 10px $color-A0;
  padding: 0;

  .unsaved-changes {
    padding: 0;

    .unsaved-changes-head {
      background-color: $color-A12;
      padding: 30px 30px 0 40px;

      strand-icon {
        margin-right: 15px;
        color: #fdd019;
      }
    }

    .unsaved-changes-body {
      background-color: $color-A12;
      padding: 0 30px 20px 40px;

      .unsaved-changes-body-text {
        padding-top: 30px;
      }
    }
  }
}

.save-as-modal {
  .body {
    padding: 25px 25px 5px;

    .form {
      padding-top: 20px;

      .item {
        margin-bottom: 20px;
      }

      #save-as-name {
        left: 75px;
      }

      #save-as-merit {
        left: 40px;
      }
    }
  }

  .footer {
    #save-as-spinner {
      margin: auto;
    }
  }
}

.refresh-overlay {
  position: absolute;
  right: 8px;
  top: 19px;
  display: none;
  cursor: pointer;
}

.refresh-overlay-with-tooltip {
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;
  cursor: pointer;
}

.segment-save-dialog {
  background-color: $color-A12;
  border-radius: 4px !important;

  span {
    font-size: 1.2em;
    padding-right: 5px;
  }
}

._segment-wrapper {
  height: 100%;
  position: relative;

  .w-box {
    padding: 0 !important;
    height: 200%;
    position: relative;

    .w-head {
      padding: 20px;

      strand-header {
        max-width: 500px;
        display: inline;
        width: 45%;
      }

      #status-msg {
        display: inline;
        max-width: 500px;
        margin-right: 26px;
        padding-right: 5px;
        text-align: right;
      }
    }

    .w-body {
      position: relative;
      height: 300%;

      .segment-message {
        padding: 20px;
        display: none;
      }
      font-weight: bold;

      // START advertiser dropdown
      .advertisers-wrapper {
        float: left;
      }

      .selected-advertiser {
        font: normal 13px $unified-font-regular;
        height: 7px;
        top: 7px;
        position: relative;
      }

      .segment-name-wrapper {
        float: left;
        margin-right: 275px;
        width: 40%;
        max-width: 550px;
      }

      #advertisers {
        width: 250px;
        opacity: 1 !important;
      }

      #advertisers strand-input {
        border-bottom: 3px solid $color-A5;
      }

      .form-stack {
        margin-bottom: 23px;
      }

      dd {
        font: normal 13px/14px $unified-font-regular;
        color: $color-A3;
        margin-bottom: 0;
        float: left;
        display: inline;
        zoom: 1;
        position: relative;
        width: 100%;
      }

      dt {
        font-family: 100 14px $unified-font-bold;
        color: $color-A3;
        margin-bottom: 10px;
      }

      // END

      #advertiser-select-bar {
        border-top: 1px solid $color-A6;
        border-bottom: 1px solid $color-A6;
        background-color: $color-A12;
      }

      #advertiser-button-container {
        margin-top: 25px;
        margin-left: 20px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;

        .segment-name-label,
        .advertisers-label,
        .merit-event-label {
          font-family: $unified-font-regular;
          font-size: 13px;
          margin-bottom: 10px;
          width: 250px;
        }

        .segment-name-label {
          margin-left: 20px;
        }
      }

      #advertiser-dropdown {
        display: inline;
      }

      #segment-name {
        display: inline;
        padding-left: 20px;
      }

      #segment-creation-container {
        position: relative;
        background-color: $color-D12;
        height: inherit;
        &:after {
          clear: both;
          content: "";
          display: block;
        }
      }

      #segment-creation-workspace {
        position: relative;
        float: left;
        height: 300%;
        padding-bottom: 20px;

        > div {
          &:after {
            clear: both;
            content: "";
            display: block;
          }
        }
        &.adaptive {
          border-right: 1px solid $color-A6;
          width: calc(100% - 201px);
        }
      }

      #segment-creation-right-panel {
        float: left;
        position: relative;
        width: 12.25em;
      }

      #segment-creation-items {
        margin-left: 15px;
        margin-top: 33px;
        margin-bottom: 40%;
      }

      #paren-tools-container {
        .boxtitle {
          padding-bottom: 15px;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;

          .paren-tool {
            cursor: default !important;
          }
        }
      }

      #advanced-tools-button-container {
        position: absolute;
        bottom: 20px;
        width: 100%;
      }

      #generate-forecast-button {
        display: block;
        margin: 10px auto;
        width: 150px;
        text-align: center;
      }

      #generate-profile-button {
        display: block;
        margin: 10px auto;
        width: 150px;
        text-align: center;
      }

      p {
        margin: 7px;
        margin-left: 20px;
        padding-top: 5px;
        color: #c0c0c0;
        font-family: $unified-font-regular;
        font-size: 12px;
        font-weight: normal;
        clear: both;
      }

      ul {
        list-style-type: none;
        float: none;
      }

      .segment-element {
        min-width: 60px;
        font-size: 1em;
        border: 1px solid $color-A19;
        margin-left: 5px;
        margin-bottom: 7px;
        color: $color-A0;
        vertical-align: middle;
        min-height: 70px;
        padding-right: 10px;
        padding-left: 15px;
        padding-bottom: 0;
        border-radius: 5px;
        position: relative;
      }

      .right-paren,
      .left-paren {
        cursor: move;
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
        .element-control {
          padding-right: 10px;
          strand-icon {
            color: #c8c8c8;
          }
          strand-icon:hover {
            color: #333333;
          }
        }
      }

      h2 {
        font-size: 23px;
        font-family: $unified-font-regular;
        font-weight: bold;
        color: #191919;
        line-height: 1px;
        padding-bottom: 20px;
        white-space: nowrap;
      }

      h3 {
        font-size: 11px;
        font-family: $unified-font-regular;
        font-weight: bold;
        color: #2e6c93;
        text-transform: uppercase;
      }
      small {
        font-size: 1px;
      }

      .segment-operator {
        cursor: pointer;
        padding-left: 15px;
        padding-right: 0;
        min-width: 72px;

        &.disabled {
          cursor: default;
        }

        &.standard {
          pointer-events: none;
          .operator-text {
            left: 14px;
            bottom: 6px;
          }
        }
      }

      .segment-operand {
        cursor: move;
        .element-control {
          strand-icon {
            color: #c8c8c8;
            .icon-edit {
              background: none;
              width: 10px;
              height: 10px;
            }
          }
          strand-icon:hover {
            color: #333333;
          }
        }
      }

      .paren-tool {
        height: 80px;
        max-width: 57px;
        font-size: 1.4em;
        border: 1px solid $color-A6;
        color: $color-A0;
        padding: 6.3px 6.3px 5.4px 5.4px;
        cursor: move;
      }

      #paren-left-tool {
        margin-right: 15px;
      }

      .paren-tool span {
        position: relative;
        letter-spacing: 5px;
        line-height: 80px;
        margin-left: 15px;
        margin-right: 15px;
      }

      .add-behavior-text {
        top: 15px;
        font-family: $custom-font-regular;
        line-height: 18px;
      }

      #left-paren-placeholder {
        position: absolute;
        width: 60px;
        box-shadow: 5px 5px 5px #888888;
        background-color: $color-A12;
        display: none;
      }

      #right-paren-placeholder {
        position: absolute;
        width: 60px;
        box-shadow: 5px 5px 5px #888888;
        background-color: $color-A12;
        display: none;
      }

      .element-control {
        color: #c8c8c8;
        float: right;
        display: inline-block;
        font-size: 0.6em;
        line-height: 10px;
        padding: 10px 0 0 35px;
        letter-spacing: 3px;
        font-weight: normal;
      }

      .operator-text {
        position: relative;
        text-transform: uppercase;
        font-family: $unified-font-italic;
        font-size: 16px;
        line-height: 18px;
        display: block;
        float: left;
        margin-top: 33px;
      }

      #refresh-button {
        cursor: pointer;
        padding-top: 5px;

        &.disabled {
          opacity: 0.8;
          cursor: default;
        }
      }

      .segment-operator-dropdown {
        position: absolute;
        top: 67px;
        z-index: 500;
        border: 1px solid $color-A6;
        border-radius: 3px;
        min-width: 79px;
        margin-left: -15px;
        ul {
          width: 100%;
          li {
            font-family: $unified-font-italic;
            font-size: 16px;
            text-transform: uppercase;
            padding-left: 10px;
            line-height: 18px;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
          .operator-hover {
            background-color: #ddf1fa;
            color: #014171;
          }
        }
      }

      .segment-element-text {
        padding-right: 50px;
        clear: both;
        h2 {
          font-size: 15px;
          line-height: 17px;
          padding-bottom: 0;
        }
        h3 {
          font-size: 9px;
          line-height: 10px;
        }
      }

      .right-paren .segment-element-text {
        position: relative;
        top: 6px;
        left: 20px;
      }

      .left-paren .segment-element-text {
        position: relative;
        top: 6px;
        left: 20px;
      }

      #right-paren-placeholder .segment-element-text {
        position: relative;
        top: 25px;
        left: 20px;
      }

      #left-paren-placeholder .segment-element-text {
        position: relative;
        top: 25px;
        left: 20px;
      }

      #add-behavior-button {
        border: 4px dotted $color-A6;
        border-radius: 5px;
        min-width: 130px;
        color: rgb(0, 56, 113);
        font-size: 1em;
        text-align: center;
        line-height: 64px;
        height: 62px;
        min-height: 62px;
        background-color: #f2f4f8;
        cursor: pointer;
        padding: 0 25px;
      }

      li.segment-placeholder {
        border: 5px dotted $color-D8;
        color: rgb(0, 56, 113);
        font-size: 1em;
        text-align: center;
        line-height: 72px;
        height: 72px;
        background-color: #f2f4f8 !important;
        padding: 0;
      }

      #add-behavior-button.disabled {
        cursor: default;
        color: rgba(0, 56, 113, 0.5);
        border: 5px dotted rgba(211, 211, 211, 0.5);
      }

      .placeholder {
        border: 4px dashed #434d56;
        background-color: #656668;
        height: 70px;
        margin: 7px;
        width: 20px;
      }

      .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $color-A0;
        position: relative;
        float: left;
        display: block;
        margin-top: 40px;
        margin-left: 20px;
        &.switch {
          border-bottom: 5px solid $color-A0;
          border-top: 0;
        }
      }

      .boxtitle {
        display: inline-block;
        padding-left: 25px;
        padding-top: 25px;
        font-family: $unified-font-regular;
        font-size: 13px;
        color: #191919;
      }
      .close-control,
      .edit-control {
        cursor: pointer;
      }

      #segment-size-bar {
        background-color: $color-A12;
        border-top: 1px solid $color-A6;
        border-bottom: 1px solid $color-A6;
        margin-bottom: 60px;
        min-height: 80px;

        &:after {
          clear: both;
          content: "";
          display: block;
        }
      }

      #segment-size-container {
        border-left: 1px solid $color-A6;
        float: right;
        font-size: 2em;
        height: 104px;
        margin: -25px 0;
        width: 200px;
        text-align: center;
        min-height: 40px;
        min-width: 200px;

        #segment-size-inner-container {
          margin-right: 20px;
          display: inline-block;
          height: 100%;
          padding: 15px;
          position: relative;

          .size-title {
            font-family: $unified-font-regular;
            font-size: 13px;
            text-align: left;
          }

          .segment-size {
            text-align: left;
            display: block;
            font-family: $unified-font-regular;
            font-weight: 10;
            color: #191919;
          }

          .progress-bar {
            top: 5px;
            width: 106px;
          }

          #sizing-success {
            color: #86c633;
          }

          #sizing-warning > #sizing-warning-icon {
            color: #eac616;
          }

          #sizing-error > #sizing-error-icon {
            color: #e10707;
          }
        }
      }
      #segment-expression-drawer {
        bottom: 59px;
        z-index: 500;
      }

      #segment-expression-grid {
        strand-grid-item:hover > div > #columnContainer > ._mm_column > div > span > div > strand-icon {
          display: inline-block !important;
        }
        .filterAttribute {
          text-transform: capitalize;
          span {
            text-transform: lowercase;
          }
        }
        strand-icon[id^="edit-icon"] {
          cursor: pointer;
          color: $color-A5;
          &:hover {
            color: $color-A1;
          }
        }
      }

      #segment-expression-container {
        float: left;
        padding: 20px;
        min-height: 40px;
        .expression-title {
          font: 13px/21px $unified-font-regular;
          color: rgb(0, 60, 113);
        }

        #expression-text {
          font-family: $unified-font-regular;
          font-size: 15px;
          color: #191919;
        }
        span {
          display: block;
        }
      }

      #button-bar {
        height: 70px;
        background-color: $color-D12;
      }

      #button-container {
        float: right;
        padding: 3px;
        padding-top: 10px;
      }

      #segment-creation-workspace .boxtitle {
        width: 750px;
      }
      .sortable {
        float: left;
      }

      .sortable li {
        float: left;
        background-color: $color-A12;
      }

      #advanced-tools-items .sortable li {
        float: none;
      }

      #advanced-tools-items {
        margin-left: 25px;
      }

      #create-operator {
        display: none;
      }

      #newbehavior {
        display: none;
      }

      #segment-creation-right-panel .close-control {
        display: none;
      }

      .segment-element.shadowed {
        background-color: $color-A6;
      }

      #save-segment-button {
        width: 53px;
      }

      #save-and-add-segment-button {
        width: 148px;
      }
    }
  }
}
