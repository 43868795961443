.w-CampaignMarginBrowse {

	div {
		box-sizing: border-box;
	}

	.w-head,
	.w-foot-c {
		padding-right: 20px;

		.info-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: 0;
		}


	}

	.w-head {
		.margin-pct {
			padding-right: 34px;

			.info-icon {
				right: 9px;
			}
		}

		.total-ad-cost,
		.total-spend,
		.ecpm {
			padding-right: 18px;
		}

	}
	.item {
		padding: 0 20px 0 20px;
	}

	.item-row {
		padding: 4px 0;
		line-height: 24px;
		vertical-align: middle;
	}

	.header-label {
		display: block;
		text-align: right;
	}

	.arrow {
		margin-top: 8px;
	}

	.number {
		text-align: right;
	}

	.form-input {
		width: 70px;

		.text {
			box-sizing: border-box;
			height: 24px;
			text-align: right;
		}
	}

	.precent {
		font-size: 12px !important;
	}

	.form-list {
		.form-label {
			margin-left: 1px;
			line-height: 24px;
			font-size: 10px;
		}

		.form-input .text {
			font-size: 12px;
		}
	}


	.date {
		width: 38%;

		.header-label {
			text-align: left;
		}
	}

	.strategy-name {
		@include calc(width, "38% - 50px");

		margin-left: 50px;
	}

	.margin-pct {
		width: 17%;

		.form-list {
			float: right;
		}
	}

	.total-ad-cost {
		width: 13%;
	}

	.total-spend {
		width: 10%;
	}

  	.ecpm {
		width: 8%;

		.header-label {
		  text-transform: none;
		}
	  }

	.goal-performance {
		width: 14%;

		.goal-performance {
			width: 75%;
		}

		.goal-type {
			width: 25%;
			text-align: left;
			text-transform: uppercase;
		}

		&.item-c {
			box-sizing: border-box;
		}
	}

	.content {
		top: 27px !important;;

		.margin-pct {
			margin-top: -5px;
			padding-top: 5px;

			&:after {
				@include linear-gradient($color-A14, $color-A15);
				@include rotate($deg: 270deg);

				visibility: visible;
				height: 6px;
				width: 34px;
				color: transparent;
				position: absolute;
				top: 15px;
				right: -30%;
			}
		}
	}

	.w-foot {
		@include box-fill($top: auto, $bottom: 14px);

		.margin-pct {
			text-align: right;
			padding-right: 13px;
		}

		.foot-info {
			font-size: 12px;
		}
	}

	.w-body {
		height: auto;
		bottom: 35px;
	}
}