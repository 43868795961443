.w-bulkEditCreatives {
  .w-panel-body {
    top: 0px;
    bottom: -20px;
  }

  .w-DataGrid {
    .content-holder {
      height: 100%;
    }

    .header.name {
      width: 28%;
      padding-left: 0px;
    }

    #nonSecureWarningIcon {
      color: #FED01A;
    }

    #verifiedSecureWarningIcon {
      color: #9ecb5c;
    }
  }

  .bins-column {
    bottom: -52px;

    .drop-area {
      bottom: 46px;
    }

    .bins-foot {
      bottom: 42px;
      border-top: 1px solid #e0e0e0;
    }

    .edit-options {
      height: 30px;
      background: $color-A7;
    }

    .header-text {
      position: absolute;
      top: 10px;
    }

    .info-tooltip {
      margin-left: 113px;
      margin-top: 4px;

      #addRemoveOverwriteInfoIcon {
        opacity: 0.5;
        color: #666666
      }

      #addRemoveOverwriteInfoIcon:hover {
        color: #000000;
      }
    }

    .bulk-edit-options {
      position: absolute;
      top: 10px;
      left: 138px;
    }

  }
}