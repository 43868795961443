.deal-group-edit {
  position: relative;
  background: $color-A12;
  min-height: calc(100vh - 142px);
  margin-bottom: 60px;

  .deal-group-edit-header {
    padding: 20px 0 30px 35px;
    background: $color-A16;
    font-size: 15px;
    font-family: $unified-font-bold;
    font-weight: 700;
    line-height: 20px;
  }

  .deal-group-edit-form {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 35px;

    .deal-group-edit-loader {
      position: absolute;
      top: 30%;
      left: 45%;
      z-index: 1;
    }

    .deal-group-row {
      margin-bottom: 35px;
    }

    .deal-group-label {
      margin-bottom: 15px;
    }

    .deal-group-name {
      display: flex;
      flex-direction: column;
    }

    .deal-group-name-input {
      width: 280px;
    }

    .select-group-owner-type {
      width: 165px;
    }

    .select-group-owner {
      width: 290px;
    }

    .deal-group-row.select-group-owner-row {
      margin-bottom: 0;
    }

    .deals-grid-container {
      display: flex;
      width: 100%;

      /*
      need to do this otherwise width defaults to as wide as it needs to be to fit search row and grid/bin
      gets compressed...any other way to get wrapper to be same width as parent?
      */
      ._wrapper {
        width: 100%;
      }

      .deals-search-tree {
        width: 100%;
      }

      .deal-group-deal-search-select {
        width: 165px;
      }

      .deals-search-tree-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
        width: calc(100% - 312px);
        height: 32px;

        .deal-tree-search-input {
          margin-right: auto;
        }

        .deal-group-search-tree-input {
          width: 210px;
          margin-right: 20px;
        }

        .search-warning-deals {
          margin-right: 10px;
        }

        .deal-status-toggle-group {
          justify-content: flex-end;

          .deal-status-toggle-label {
            margin-right: 10px;
          }

          .deal-status-toggle-dropdown {
            width: 105px;
          }
        }
      }

      .deals-tree-row {
        width: 100%;
        display: flex;
        height: 365px;

        .selected-items-container {
          width: 300px;
          border: 1px solid $color-A9;

          .label {
            margin-left: 8px;
          }

          creatives-bin-list-item {
            label {
              white-space: normal;
            }
          }
        }

        .tree-container {
          width: calc(100% - 312px);
          margin-right: 10px;

          .deals-grid {
            height: 365px;
            margin-left: -35px;

            .wrapping-field {
              white-space: normal;
            }

            strand-grid-item strand-checkbox[checked] {
              color: $color-B1;
            }

            .deal-item-status-false {
              color: $color-A5;
            }
          }
        }

      }
    }
  }

  .deal-groups-footer-container {
    position: absolute;
    left: 0;
    bottom: -60px;
    width: 100%;
    
    .save-action label {
      visibility: hidden;
    }
  }
}