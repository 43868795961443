@use 'sass:math';
@import 'grid';
@import 'palette';

@function _grid_column_width($x: 1, $y: 0, $media_column: $column, $media_gutter: $gutter) {
  @return $x * $media_column + $media_gutter * $x - $media_gutter + $y;
}

@function _grid_column_height($x: 1, $y: 0) {
  @return $x * $column + $gutter * $x - $gutter + $y;
}

@mixin unit-width($x: 1) {
  width: $x * $unit;
}

@mixin column-height($x: 1, $y: 0) {
  height: _grid_column_height($x, $y);
}

@mixin column-width($x: 1, $y: 0) {
  width: _grid_column_width($x, $y);
}

@mixin page-inner-width {
  @include column-width($column_max);
}

@mixin page-full-width {
  @include column-width($column_max, $page-margin-left + $page-margin-right);
}

@mixin width-colx($x: 1, $g: $gutter) {
  @include column-width($x);
  margin-right: $g;
}

@mixin transparent($percent: 50) {
  opacity: math.div($percent, 100);
}

@mixin _border-radius($radius: 0) {
  border-radius: $radius;
}

@mixin _linear-gradient($from: $color-A0, $to: $color-A12) {
  background: linear-gradient($from, $to); //future CSS3 browsers
}

@mixin _box-shadow($shadow: 0) {
  box-shadow: $shadow;
}

@mixin _box-shadow-updated($swidth: 4px, $scolor: $color-A4, $horizontal: 0, $vertical: 0, $blur: 0) {
  box-shadow: $horizontal $vertical $blur $swidth $scolor;
  position: relative;
}

@mixin border-radius($radius: 0) {
  @include _border-radius($radius);
  position: relative;
}

@mixin linear-gradient($from: $color-A0, $to: $color-A12) {
  @include _linear-gradient($from, $to);
  position: relative;
}

@mixin rounded-box(
  $padding: 2px,
  $background: $color-A12,
  $border-radius: $unit,
  $border-rule: 1px,
  $border-color: $color-A6
) {
  @include _border-radius($border-radius);
  background: $background;
  border: $border-rule solid $border-color;
  left: 0;
  padding: $padding;
  position: relative;
  top: 0;
  zoom: 1;
}

@mixin box-shadow($swidth: 4px, $scolor: $color-A4, $horizontal: 0, $vertical: 0, $blur: 0) {
  @include _box-shadow-updated($swidth, $scolor, $horizontal, $vertical, $blur);
}

@mixin webcomponent-box-shadow() {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.18);
}

@mixin rounded-shadow-box(
  $padding: 2px,
  $background: $color-A12,
  $border-radius: $unit,
  $border-color: $color-A6,
  $shadow-color: $color-A12,
  $shadow-border: 4px,
  $horizontal: 0,
  $vertical: 0,
  $blur: 0
) {
  @include _border-radius($border-radius);
  @include _box-shadow-updated(
    $swidth: $shadow-border,
    $scolor: $shadow-color,
    $horizontal: $horizontal,
    $vertical: $vertical,
    $blur: $blur
  );
  background: $background;
  border: 1px solid $border-color;
  left: 0;
  padding: $padding;
  top: 0;
  zoom: 1;
}

@mixin inner-shadow-box(
  $horizontal: 0,
  $vertical: 0,
  $blur-radius: 2px,
  $sradius: 0,
  $scolor: $color-A1,
  $background: none
) {
  background: $background;
  box-shadow: inset $horizontal $vertical $blur-radius $sradius $scolor;
  position: relative;
}

@mixin sprite-at-position($left: 1, $down: 1) {
  background: url('../images/mm_sprite.png') $left * -($unit * 7) $down * -($unit * 7) no-repeat;
}

@mixin sprite-icon($left: 1, $down: 1, $width: $unit, $height: $unit) {
  @include sprite-at-position($left: $left, $down: $down);
  display: block;
  height: $height;
  width: $width;
}

// Requires Sass 3.1+
//  Usage: Gradient position and shape-size are required. Color stops are optional.
//  @include radial-gradient(50% 50%, circle farthest-corner, #1e5799, #efefef);
//  @include radial-gradient(50% 50%, circle farthest-corner, #eee 10%, #1e5799 30%, #efefef);
@mixin radial-gradient(
  $pos: 50% 50%,
  $shape-size: circle farthest-corner,
  $color-from: $color-A4,
  $color-to: $color-A0,
  $color-bg: $color-A4,
  $swidth: 100%
) {
  background: $color-to;
  background-image: #{'-*-radial-gradient('}$pos#{','} $shape-size#{','} $color-from#{','}$color-to#{','} $color-bg $swidth#{')'};
}

@mixin button(
  $bg-color: $color-D9,
  $color-top: $color-D8,
  $color-bottom: $color-D9,
  $border-color: $color-D10,
  $shadow-color: $color-A6,
  $color: $color-D11,
  $width: auto
) {
  @include _linear-gradient($color-top, $color-bottom);
  @include _border-radius($radius: 3px);

  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;
  cursor: pointer;
  font-family: $unified-font-bold;
  font-size: 13px;
  line-height: 19px;
  margin: 0;
  outline: none;
  padding: 5px 15px;
  text-transform: capitalize;
  width: $width;
}

@mixin inputShadow($shadow-from: $color-A5, $shadow-to: $color-A7) {
  background-image: linear-gradient(to top, $shadow-to 85%, $shadow-from 99%);
}

@mixin input-with-symbol($width: 50%, $symbol-align: left, $symbol-width: 11px, $symbol-padding-top: 6px) {
  float: left;
  width: $width;

  .symbol {
    float: $symbol-align;
    padding-top: $symbol-padding-top;
    width: $symbol-width;
  }

  .input {
    @if $symbol-align == left {
      margin-left: $symbol-width;
      margin-right: 13px;
    } @else {
      margin-right: $symbol-width + 13px;
    }

    input {
      display: block;
      width: 100%;
    }
  }
}

@mixin rotate($deg: 90deg) {
  transform: rotate($deg);
}

@mixin search-icon() {
  background: $color-A12 url('../images/chosen-sprite.png') no-repeat 100% -22px;
  padding-left: 5px;
  padding-right: 20px;
}

@mixin box-fill($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute !important;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmValueChange {
  from {
    background-color: $color-E8;
  }
  to {
    background-color: $color-A12;
  }
}

@mixin animate($animationType: animationType, $duration: 2000ms, $easing: ease-out, $direction: normal) {
  animation: $animationType $duration $easing $direction;
}

@mixin transition($property: property, $duration: 400ms, $easing: ease, $delay: 0s) {
  transition: $property $duration $easing $delay;
}

@mixin flipHorizontally() {
  transform: scaleX(-1);
  filter: FlipH;
}

@mixin flipVertically() {
  transform: scaleY(-1);
  filter: FlipV;
}

@mixin calc($property, $expression) {
  #{$property}: calc(#{$expression});
}

@mixin calc-important($property, $expression) {
  #{$property}: calc(#{$expression}) !important;
}

@mixin bg-color-rgba($r: 255, $g: 255, $b: 255, $a: 0.5) {
  background-color: rgba($r, $g, $b, $a);
}
