.w-OrganizationSelect {
	.w-body {
		padding: 14px 0 0;
		opacity: 1.0;

		.organization-bar {
			@include border-radius(0);
			background: none !important;
			color: $color-A4;
			font-size: 17px;
			padding: 4px 12px;
			float: left;
			position: relative;
			cursor: pointer;

			.org-name {
				float: left;
			}

			.arrow-icon {
				@include sprite-icon($left: 0, $down: 7, $width: 19px, $height: 19px);
				float: left;
				margin: 6px 22px;
			}

			.arrow-up-icon {
				@include sprite-icon($left: 0, $down: 8, $width: 19px, $height: 19px);
			}
		}

		.panel {
			position: absolute;
			width: 262px;
			margin-top: 30px;
			margin-left: 2px;

			.auto-complete {
				position: relative;
				width: 260px;
				height: 33px;
				background: #FFF;
				border: 1px solid $color-A4;

				input {
					@include border-radius(2px);
					@include inner-shadow-box($horizontal: 1px, $vertical: 1px, $blur-radius: 2px, $sradius: 0, $scolor: $color-A8, $background: $color-A12);
					@include search-icon();
					width: 226px;
					height: 22px;
					margin: 4px;
					border: 1px solid $color-A5;
					font-family: sans-serif;
					font-size: 13px;
				}
			}
		}
	}

	.w-body.disabled {
		opacity: 0.5;

		.organization-bar {
			cursor: default;
		}
	}
}

.tooltip-orgs {
	@extend .w-FormError;

	.tipsy-inner {
		color: $color-A12;
		background-color: $color-A0;
		word-wrap: break-word;
		white-space: normal;
		max-width: 550px;
	}

	.tipsy-arrow-s {
		border-top-color: $color-A0;
	}

	.tipsy-arrow-w {
		border-right-color: $color-A0;
	}
}

@import "contracts";