@import "mixins";

/*    Default Layout: 992px. 
    Gutters: 24px.
    Outer margins: 48px.
    Leftover space for scrollbars @1024px: 32px.
-------------------------------------------------------------------------------
cols    1     2      3      4      5      6      7      8      9      10
px      68    160    252    344    436    528    620    712    804    896    */
html {
  overflow-y: auto;
}

body {
	padding: 0;
	-webkit-text-size-adjust: 100%; /* Stops Mobile Safari from auto-adjusting font-sizes */
	color: $color-A2;
	.page-head-c,
	.page-body,
	.col-full {
		min-width: 1260px;
	}
}

.col-full {
  width: 100%;
}


/*    Tablet Layout: 768px.
    Gutters: 24px.
    Outer margins: 28px.
    Inherits styles from: Default Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */
@media only screen and (min-width: 1600px) {
  
  body {
    .page-head-c,
    .page-body,
    .col-full,
	.top-nav {
      width: 1600px;
    }
  }
}


@media only screen and (max-width: 1180px) {
  
  body {
    .page-head-c,
    .page-body,
    .col-full,
	.top-nav {
      width: 1180px;
    }
  }
}


/*    Mobile Layout: 320px.
    Gutters: 24px.
    Outer margins: 34px.
    Inherits styles from: Default Layout.
---------------------------------------------
cols    1     2      3
px      68    160    252    

@media only screen and (max-width: 767px) {
  
  body {
    @include column-width(4);
    padding: 0 0 8px;
  }
  
}

*/

/*    Wide Mobile Layout: 480px.
    Gutters: 24px.
    Outer margins: 22px.
    Inherits styles from: Default Layout, Mobile Layout.
------------------------------------------------------------
cols    1     2      3      4      5
px      68    160    252    344    436    

@media only screen and (min-width: 480px) and (max-width: 767px) {
  
  body {
    @include column-width(6);
    padding: 0 0 8px;
  }
  
}
*/

body {
  line-height: 1;
  font-family: $custom-font-regular;
  height: 100%;
}

.wrapword {
	word-wrap: break-word;
}
  
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; 
}

.clearfix {
  display: inline-block; 
}

html[xmlns] .clearfix {
  display: block; 
}

* html .clearfix {
  height: 1%; 
}

.clearfix-block:after {
  content: " ";
  display: block;
  clear: both;
}

.center {
  text-align: center; 
}

.constrain-x {
  overflow-x: auto;
  overflow-y: hidden; 
}

.constrain-y {
  overflow-y: auto;
  overflow-x: hidden; 
}

.box-fix {
  display: block;
  clear: both;
  zoom: 1;
  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; 
  } 
}

.align-lt {
  float: left !important; 
}

.align-rt {
  float: right !important; 
}

.text-lt {
  text-align: left; 
}

.text-rt {
  text-align: right;

  .button { float: right; }
}

.inline {
  display: inline; 
}

.last {
  margin-right: 0 !important; 
}

ul.horiz li, ol.horiz li, dl.horiz dt, dl.horiz dd {
  display: inline; 
}

ul.horiz-float li, dl.horiz-float dt, dl.horiz-float dd {
  float: left; 
}

.pinned {
  position:fixed !important;
  left: auto !important;
  margin: 0 auto !important;
  z-index:1;
}

.beta {
  color: #2e6c9e;// $color-D2 in new color palette
  font: normal 9px $unified-font-bold;
  padding-right: 5px;
}

[unresolved] {
  display: none;
}
