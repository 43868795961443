.w-SelectFilterList {
	.w-head {
		position: relative;
	}

	.w-body {
		display: none;
	}

	.select-group {
		display: none;
	}
}