.w-boxList {
  dt {
    margin-bottom: 12px;

    .vendor-list {
      font: normal 12px/14px $custom-font-semi-bold;
      color: $color-A3;
    }
  }

  .top {
    margin-top: 5px;
  }

  .vendor-list {
    cursor: default;
  }

  .item {
    @include linear-gradient($from: $color-A12, $to: $color-A8);
    @include border-radius($radius: 3px);
    @extend small;
    padding: 0 4px;
    margin-right: 10px;
    border: 1px solid $color-A5;
    font-size: 11px;

    width: auto;

    &:last {
      margin-right: 0px;
    }

    div {
      float: left;
    }
  }

  .auto-item {
    font-size: 11px;
  }

  .remove {
    @include sprite-icon($left: 5, $down: 10, $width: 8px, $height: 8px);
    cursor: pointer;
    margin: 6px 0 0 4px;
  }

  .add {
    color: $color-D1;
    font-size: 11px;
    line-height: 25px;
    vertical-align: bottom;
    cursor: pointer;
  }

  .popup-container {
    margin-bottom: 50px;
    position: absolute;
    z-index: 1;
  }

  .popup {
    top: -120px;
    left: 5px;
  }

  .w-FilterList {
    width: 180px;
    padding: 4px 4px 0;
    background-color: $color-A12;

    .search {
      width: 170px;
      padding-left: 4px;
    }

    .txt {
      width: 150px !important;
    }

    .list {
      height: 172px;

      input[type="checkbox"] + span {
        width: 150px;
        padding-left: 6px;
      }
    }
  }

  .form-footer,
  .form-footer-style {
    margin-left: -4px;
    width: 179px;
  }
}
