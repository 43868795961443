.change-log-dialog {
  width: 100%;
  padding: 0;

  .column-A {
    width: 60%;
  }

  .column-B {
    float: right;
    position: relative;
    width: 40%;
  }

  .info-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    text-transform: uppercase;

    span {
      text-align: right;
      font-size: 10px;
      padding: 6px 0 2px;
      display: block;

      strong {
        font-family: $custom-font-bold;
      }
    }
  }

  .advertiser-name,
  .campaign-name {
    max-width: 48%;
    display: inline-block;
  }

  .title {
    font: 22px $custom-font-bold;
    color: $color-A1;
    margin-top: 5px;
  }

  .entity-name {
    font: 14px $custom-font-bold;
    font-weight: bold;
    color: $color-A1;
    margin-top: 5px;
  }

  .related-info {
    font: 11px $custom-font-bold;
    color: $color-D4;
  }
}

.w-EntityChangeLog {
  @extend .dialog-panel;

  & > .w-body {
    height: 370px;
    margin-top: 15px;
  }

  & > .w-foot {
    margin-top: 0;
  }

  .w-DataGrid {
    margin-top: 20px;
  }

  .header {
    &.old-value {
      margin-left: -10px;
    }

    &.new-value {
      margin-left: 30px;
    }
  }

  .content {
    height: 300px;
    overflow: hidden;
  }

  .item {
    height: 28px;
  }

  .name {
    display: block;
    font: 14px $custom-font-regular;
    text-transform: capitalize;
  }

  .date-time,
  .change-by {
    font: 10px $custom-font-regular;
    margin-top: 2px;
    color: $color-D4;
  }

  .date-time {
    float: left;
  }

  .change-by {
    margin-left: 2px;
    text-transform: uppercase;
  }

  .change-by-c {
    word-wrap: break-word;
  }

  .field-name {
    width: 38%;
  }

  .spacer {
    width: 6%;
  }

  .new-value,
  .old-value {
    width: 28%;
    height: 100%;
  }

  .ajax-loader {
    @include calc(top, '50% + 24px');
    @include calc(left, '50% - 50px');
    width: auto;
    height: auto;

    &.change-logs-infinite {
      left: auto;
      top: -60px;
      width: 164px;
    }
  }

  .watermark {
    float: left;
    padding-top: 8px;
    color: $color-A4;
    font: 12px $custom-font-regular;
  }
}
