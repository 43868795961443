@import "mixins";

.auto-complete-results {
  position: absolute;
  top: 20px;
  left: 0;
  width: 99%;
  font-size: 12px;
  font-weight: normal;
  color: $color-A4;

  .ui-autocomplete {
    @include border-radius($radius: 0 0 3px 3px);
    float: left;
    text-align: left;
    left: 0 !important;
    width: 99.95% !important;
    max-height: 500px;
    background: $color-A12;
    border: 1px solid $color-A5;
    overflow-y: auto;

    .ui-autocomplete-category {
      font-size: 11px;
      line-height: 13px;
      padding: 5px 6px;
      vertical-align: center;
    }

		.ui-autocomplete-summary {
			padding: 5px 6px;
		}

   .ui-menu-item {
      background: $color-A12;
      font-size: 13px;
      color: $color-A1;

      a {
        text-decoration:none;
        display:block;
        padding: 5px 6px;
        line-height: 15px;
        vertical-align: center;
        zoom:1;
        cursor: pointer;
        word-wrap: break-word;
      }

      strong {
        color: $color-E1;
      }

      .ui-state-hover,
      :hover,
      :focus {
        color: $color-E1;
        background-color: $color-A9;
      }
    }
  }
}

.ui-combobox-label{
    display: block;
    float: left;
    margin-right: 10px;
}

.ui-combobox-button {
    @include sprite-icon($left: 2, $down: 0, $width: 28px, $height: 28px);
    float: right;
    margin-top: 5px;

		&:hover{
			cursor: pointer;
		}
}

.auto-complete {
  font-family: $custom-font-regular;
  input {
    font-family: $custom-font-regular;
  }
	.ui-autocomplete-loading {
		@extend search-loader;
	}
}