@import "mixins";

.w-BulkeditStrategies {

  .w-panel-body-strategies {
    @include box-fill($top: 60px, $bottom: 50px);
  }

  .w-DataGrid {
    @include box-fill();

    .header {
      float: left;
      cursor: pointer;
    }

    .header.name {
      width: 29%;
    }

    .header.id {
      .header-c {
        padding-left: 6px;
      }
    }

    .icon-placement {
      float: left;
    }

    .name {
      width: 30%;
      padding-left: 10px;
    }

    .id {
      width: 15%;
    }

    .type {
      width: 15%;
    }

    .status {
      width: 20%;
    }

    .content-holder {
      height: 90%;
    }
  }

  .searchBoxHolder {
    padding-left: 25px;
    padding-top: 10px;

    strand-checkbox {
      padding-left: 15px;
    }
  }

  .searchTxt {
    font-style: italic;
    font-size: 12px;
    opacity: 0.5;
    padding-left: 33px;
    padding-top: 3px;
  }

  #bulkeditStrategiesFooter {
    position: absolute;
    bottom: 0px;
  }
}