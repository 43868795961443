.summary-badges-section {
  .warning-holder {
    position: relative;
    right: 1px;
    float: left;
  }

  .warning-icon {
    @include sprite-icon($left: 4, $down: 10, $width: 15px, $height: 15px);
  }
}
