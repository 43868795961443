.w-Bins {
  @extend data-item;
  @include box-fill();

  .hidden {
    display: none;
  }

  .bin {
    border-left: 1px solid $color-A7;
    height: 50%;

    &.included {
      border-bottom: 1px solid $color-A7;
    }

    .hovered {
      border: 8px solid $color-D8;
    }
  }

  .w-body {
    height: 100%;
    position: relative;
    margin: 0;
  }

  .headers {
    position: relative;
    background-color: $color-A12;
    padding: 6px 0 5px 6px;
    border-bottom: 1px solid $color-A7;
    height: 21px;
    -moz-padding-end: 14px; //aligning headers for big moz scroll
  }

  .header {
    color: $color-A3;
    font: 10px/14px $custom-font-semi-bold;
    text-transform: uppercase;
    top: 3px;

    .header-c {
      padding-top: 1px;

      .count {
        font-family: 12px/12px $custom-font-bold;
        text-transform: none;
      }
      .label {
        font: 10px/10px $custom-font-regular;
      }
      .icon-spinner {
        position: absolute;
        top: 7px;
        left: 89px;
      }
    }
  }

  .text {
    padding-left: 6px;
  }

  .segment,
  .name {
    padding-right: 1px;
    width: 64%;

    .include-bin-icon {
      @include sprite-icon($left: 10, $down: 1, $width: 14px, $height: 14px);
      float: left;
    }

    .exclude-bin-icon {
      @include sprite-icon($left: 11, $down: 1, $width: 14px, $height: 14px);
      float: left;
    }

    .title {
      margin-left: 18px;
    }
  }

  .include-exclude-op {
    @include rounded-shadow-box(
      $padding: 1px,
      $background: $color-A10,
      $border-radius: 0 0 4px 4px,
      $border-color: $color-A5,
      $shadow-color: $color-A5,
      $shadow-border: 0,
      $horizontal: 0,
      $vertical: 0,
      $blur: 3px
    );
    background-color: $color-A10;
    position: absolute;
    top: 32px;
    right: 15px;
    left: auto;
    margin: 0;
    padding: 0 9px 1px 0;
    width: 89px;
    height: 20px;
    border-top: none;
    text-transform: none;
    z-index: 1;

    label input[type='radio'] + span {
      font: 12px/13px $custom-font-semi-bold;
    }

    .form-list .form-radio {
      padding: 3px 0 0 7px;
      width: auto;
      float: left;
    }
  }

  .drag-selected .item-row {
    background-color: $color-E7;
  }

  .content-container {
    position: relative;
    margin-top: -27px;
    height: 100%;
  }

  .bin-content {
    position: absolute;
    top: 27px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-D12;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .content-with-footer .bin-content {
    bottom: 28px;
  }

  .warning {
    width: 100%;
    position: absolute;
    padding: 8px;
    background: $color-E7;
    border-bottom: 1px solid $color-A7;
    z-index: 5;
    top: -6px;

    .icon {
      @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 22px);
      float: left;
    }

    .text {
      color: $color-A3;
      font: 11px/14px $custom-font-semi-bold;
      padding-top: 5px;
      margin-left: 35px;
    }
  }

  .drop-area {
    @include rounded-box($padding: 0, $background: $color-D12, $border-radius: 15px);
    border: 4px dashed $color-A7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 28px;
  }

  .drop-area-c {
    position: absolute;
    margin-top: -50px;
    top: 50%;
    width: 100%;

    .drop-icon {
      @include sprite-icon($left: 9, $down: 4, $width: 46px, $height: 66px);
      margin: 5px auto 8px;
    }

    .drop-text {
      font-family: $custom-font-semi-bold;
      text-transform: capitalize;
      font-size: 16px;
      color: $color-A5;
      text-align: center;
      text-shadow: 2px 2px 2px $color-A12;
    }
  }

  .pixel_bin_count {
    font: 10px/10px $custom-font-semi-bold;
  }

  .data-row {
    font: 12px/14px $custom-font-regular;

    .group-name {
      position: relative;
      padding: 5px 5px 5px 7px;
      letter-spacing: 0.3px;

      .group-count {
        position: absolute;
        top: 6px;
        right: 9px;
        color: $color-A3;
        font: 10px/14px $custom-font-regular;
        text-transform: uppercase;

        .hidden {
          display: none;
        }
      }

      .operators {
        padding-left: 14px;
      }

      .additional-header {
        float: right;
        padding-right: 25px;
      }
    }

    .geofence-cpm {
      display: inline;
      float: right;
      padding-right: 28px;
    }

    .item {
      padding: 0;
      cursor: pointer;
      background-color: $color-A12;
      position: relative;
    }

    .item-row {
      float: left;
      width: 100%;
      padding: 2px 0;
      border-bottom: 1px solid $color-A7;

      &:hover {
        background-color: $color-E8;
      }

      .text {
        margin-left: 0;
        color: $color-A3;
        line-height: 18px;
        width: 98%;
        overflow-wrap: break-word;
        @extend .grab_cursor;
      }

      .remove-icon {
        @include sprite-icon($left: 5, $down: 10, $width: 10px, $height: 10px);
        @include transparent(50);
        position: absolute;
        top: 8px;
        right: 6px;

        &:hover {
          @include transparent(100);
        }
      }
    }
  }

  .bins-foot {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &.w-Audience-Bins {
    .group-name {
      clear: both;
      padding: 0;
      .single-name {
        padding: 5px 6px 5px 7px;
      }
      .and-separator {
        background: $color-A7;
        color: $color-A3;
        height: 18px;
        text-align: center;
        padding-top: 5px;
        font-weight: normal;
        font-size: 14px;
        font-family: $custom-font-regular;
      }
      .group-coll {
        padding: 5px 6px 5px 7px;
        .group-c {
          float: left;
          padding-right: 25px;
        }
        .group-incl-excl {
          float: left;
          position: relative;
          top: -4px;
          width: 100px;
        }
        .add-group {
          display: inline-block;
          position: relative;
          left: 110px;
          font-family: $custom-font-regular;
          cursor: pointer;
        }

        .remove-icon {
          @include sprite-icon($left: 5, $down: 10, $width: 10px, $height: 10px);
          display: block;
          height: 10px;
          width: 10px;
          opacity: 0.5;
          cursor: pointer;
        }
        .close {
          margin-top: 5px;
          float: right;
        }
      }
    }
  }

  &.w-Contextual-Bins .include-exclude-op {
    top: 0 !important;
  }
}
