@import 'chart-toggle';

.w-CampaignControls {
  font-family: $unified-font-regular;
  height: 26px;
  margin-top: 8px;

  .add-campaign {
    margin-left: 16px;
    margin-right: -1px;
    margin-top: 3px;

    .add-action {
      @extend .btn-add-primary;
      padding-top: 3px;
      padding-bottom: 1px;

      b {
        margin-top: 3px !important;
      }
    }
  }

  .view-actions {
    float: right;
    margin-bottom: 8px;

    .view-actions-c {
      @extend .box-fix;
      padding: 0;

      .action {
        float: left;
        cursor: pointer;
      }
    }
  }

  .refresh {
    @include sprite-icon($left: 3, $down: 5, $width: 18px, $height: 20px);
    margin-top: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  .chart-controls {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .chart-controls-c {
    @extend .box-fix;
    border-radius: 3px;
    border: 1px solid $color-A4;

    .selected {
      @include linear-gradient($from: $color-A4, $to: $color-A6);
    }

    li {
      @include border-radius($radius: 2px);
    }

    .action {
      padding: 3px;
    }
  }

  .view-all {
    @include sprite-icon($left: 6, $down: 10, $width: 14px, $height: 14px);
    position: absolute;
    top: 7px;
    left: 9px;
  }

  .view-favorite {
    @include sprite-icon($left: 6, $down: 11, $width: 14px, $height: 14px);
  }

  .view-chart {
    @include sprite-icon($left: 8, $down: 9, $width: 20px, $height: 20px);
    float: left;
  }

  .action-name {
    color: $color-A3;
    margin-left: 30px;
    font-family: $custom-font-semi-bold;
    padding-top: 5px;
    font-size: 12px;
    width: 65px;
    text-align: center;
  }
}
