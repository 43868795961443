.w-CreativeGrid {

	.pull-left {
		float: left;
	}

	.pull-right {
		float: right;
	}

	//CRW-2554 - this whole block of styling can be removed once the 'new' ribbon is no longer appropriate
	.ui-tab {
		&#creatives-tab-label {
			min-width: 88px;
			&.ui-state-default {
				&:before {
					@include sprite-icon($left: 9, $down: 12, $width: 30px, $height: 35px);
					@include transparent(50);
					content: "";
					pointer-events: none;
					position: absolute;
					left: 58px;
					bottom: 0;
				}
			}
			&.ui-state-active {
				&:before {
					@include sprite-icon($left: 7, $down: 12, $width: 30px, $height: 35px);
					@include transparent(100);
					content: "";
					pointer-events: none;
					position: absolute;
					left: 58px;
					bottom: 4px;
				}
			}
		}
	}

	.manage {
		float: right;
		margin: 0 10px 0 0;
		padding: 5px 8px;
		z-index: 20;
		position: relative;
		top: 24px;
		font-family: $custom-font-regular;
		text-decoration: none;
	}

	.select-large {
		.chzn-container-single {
			@include border-radius($radius: 3px);
			border: 1px solid $color-A5;

			.chzn-single {
				width: 130px !important;
				height: 28px !important;
					line-height: 30px !important;

				b {
					margin: 12px 0 auto;
				}
			}
		}
	}

	.list-body {
		top: 62px !important;
		overflow: hidden;
	}

	.conceptModalP {
		font-size: 14px;
		line-height: 20px;
		margin: 0;
	}

	.conceptModalHeader {
		margin-bottom: 15px;
	}

	.conceptModalTop {
		padding: 30px;
	}

	.list-filters {
		padding: 10px 20px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;

		.status {

			line-height: 23px;

			.chzn-container-single {
				width: 90px;
			}

			.chzn-single {
				@include border-radius($radius: 3px);
				border: 1px solid $color-A5;
			}

			.chzn-single-with-drop {
				@include border-radius($radius: 3px);
				border: 1px solid $color-A5;
				z-index: 3;
			}
		}

		.form-list {
			margin-right: 10px;
		}

		.center-fix {
			margin-top: 4px;
		}

		&.disabled {
			@include transparent();
		}

		.advLoader {
			margin-top: 3px;
		}
	}

	.btn-add-primary {
		float: right;
	}

	.video {
		.w-DataGrid {

			.conversion {
				height: 20px;
			}

			.conversion-progress {
				width: 90%;
			}

			.upload-bar {
				width: 62px;
				.bar {
					width: 100%;
				}
			}

			.headers {
				padding-right: 20px;
				height: 28px;

				.info-icon {
					display: inline-block;
					top: initial;
					right: initial;
					bottom: initial;
					margin: 0 0 0 4px;
				}
			}

			.edit,
			.edit-placeholder {
				width: 2%;
				visibility: hidden;

			}

			.edit-icon {
				color: #333333;
			}


			.item-wrapper {
				 &:hover, &.hover {
					.edit {
						visibility: visible;
					}
				}
			}

			.content {
				top: 42px !important;
			}

			.container {
				display: block;
				height: 100%;
			}


			.column {
				.settings-icon {
					@include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
				}
			}

			.loader-overrides {
				@include transparent($percent: 90);
				@include calc(top, "50% - 12px");
				@include calc(left, "50% - 80px");
				width: 160px;
				position: absolute;
			}

			//GRID COLUMN DEFINITIONS

			.default {
				width: 115px;
				padding: 0 0 !important;
				margin: 0 15px 0 0 !important;
				word-break: break-all;
				display: block;
				div {
					padding: 0;
				}
			}

			.toggle-arrow, .toggle-arrow .header-c {
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			.toggle-arrow {
				padding-right: 15px;
			}

			.toggle-arrow {
				width: 5px;
				top: 3px;
				float: left;
			}

			.checkbox-input {
				width: 15px;
				padding-right: 15px;
			}

			.dummycheckbox {
				@include sprite-at-position($left: 2, $down: 8);
				height: 15px;
				width: 15px;
				padding: 0;
				position: relative;
				opacity: 0.5;
				cursor: default;
			}

			.name {
				.editable-content {
					width: 100%;
				}

				.container {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					padding: 0 !important;
					margin: 0 15px 0 0 !important;
				}
			}

			.id {
				width: 4%;
			}

			.approval {
				width: 5%;
			}

			.advertiser {
				width: 6%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				padding: 0 0 !important;
				margin: 0 15px 0 0 !important;
			}

			.concept {
				width: 5%;
				.editable-content {
					width: 100%;
				}
				.container {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					padding: 0 !important;
					margin: 0 15px 0 0 !important;
				}
			}

			.third-pas-id {
				width: 6%;
			}

			.duration {
				width: 5%;
			}

			.conversion {
				width: 67px;

				.thumbnail {
					width: 42px;
					border: 1px solid  $color-A0;
					overflow: hidden;
					margin: 0 11px;
					img {

						width: 42px;
					}
				}

				.transcode-error {
					.icon {
						margin-left: 24px;
						@include sprite-icon($left: 7, $down: 10, $width: 18px, $height: 15px);
					}
				}
			}

			.adddate {
				width: 5%;
			}

			.companion {
				width: 6%;
			}

			.secure {
				width: 5%;
			}

			.startdate {
				width: 6%;

				.on-hover {
					max-width: 85%;
				}
			}

			.enddate {
				width: 5%;
			}

			.on-hover {
				border-bottom: 1px solid transparent;
				display: block;

				&:hover {
					border-bottom: 1px dotted $color-A1;
				}
			}

			.config {
				width: 1%;
				background: none;
				border: 0;
			}

			.w-foot {
				position: absolute;
				background-color: $color-A12;
			}

			.net-status-hold {
				text-align: center;
			}

			.net-status-icon {
				display: none;
				margin-top: -4px;
			}

			.net-status-approved {
				color: $color-A21;
			}

			.net-status-pending {
				color: $color-E6;
			}

			.net-status-pending48 {
				color: $color-E6;
				margin-left: -5px;
			}

			.net-status-rejected {
				color: $color-A22;
			}

			.net-status-popover {
				dd {
					padding-bottom: 3px;
				}

				.net-pop-title {
                    font-family: $unified-font-bold;
                    padding-bottom: 9px;
                }

				.columnA {
					float: left;
					width: 120px;
					margin-right: 15px;
					margin-bottom: 10px;
				}

				.columnB {
					float: left;
					width: 126px;
					margin-bottom: 10px;
				}

				.net-status-icon {
					display: inline-block;
					padding-right: 5px;
					margin-top: -4px;
				}

				.status-text {
					display: inline-block;
				}

				.status-text::first-letter {
					text-transform: uppercase;
				}
			}

			.item {
				strand-popover[_placement="n"]:after {
					border-top: 10px solid $color-A24 !important;
				}

				.adx-error {
					position: absolute;
					@include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
				}


				.adx-warning {
					position: absolute;
					@include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
				}

				.adx-error,
				.adx-warning {
					top: 0;
					right: 2px;
				}

				.icon-arrow {
					@include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);

					&.expanded {
						@include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
						margin-top: 2px;
					}
				}
			}

			.children {
				color: $color-A3;
				background: $color-A10;
				display: none;
				// initially hide children

				.column {
					padding-right: 20px;
				}

				.item {
					background: $color-A10;
					border-bottom: 1px solid $color-A7;
				}

				dl {
					word-wrap: break-word;
				}

				dt {
					color: $color-A4;
					font-family: PNLB;
					font-size: 10px;
					text-transform: uppercase;
				}

				.tab-info {
					margin: 0 0 0 4px;
					position: absolute;
					display: inline-block;
				}

				.w-InlineEditForm {
					top: 2px;
				}

				.indent {
					margin-left: 51px;
				}

				.landing-page, .click-page {
					word-break: break-all;
					width: 17%;
				}

				.auto-detected-vendors {
					width: 12%;

					dd {
						width: 120px;
					}
				}

				.manual-vendors {
					width: 12%;

					dd {
						width: 120px;
					}
				}

				.classification-block {
					padding-top: 4px;
					width: 25%;
					min-width: 180px;

					dl {
						background: #f9f9f9;
					}

					dt {
						color: initial;
						background: #eaeaea;
						padding: 3px 6px;
					}

					dd {
						font-family: PNLB;
						padding: 3px 6px;
					}

					.catParent {
						font-family: $custom-font-regular;
					}
				}

				.vast {
					width: 8%;
				}

				.vpaid {
					width: 8%;
				}

				.skip-offset {
					width: 8%;
				}

				.html-code {
					width: 91%;

					dd {
						pre {
							white-space: pre-wrap;
						}
					}
				}

				.unsecure-urls {
					width: 35%;

					dd {
						word-wrap: break-word;
					}

					.info-tip {
						color: #c3c3c3;
					}

					.info-tip:hover {
						color: #666666;
					}

					label {
						text-transform: none;
					}
				}

				.classifications-hold {
					width: 55%;

					dd {
						word-wrap: break-word;
					}

					#appliedLoader {
						display: none;
					}
				}

				.secure-list-head {
					margin-top: 5px;
					font-family: PNLB;
				}

				li.secure-list {
					padding: 2px 0;
					list-style: initial;
					list-style-type: disc;
					list-style-position: outside;
					margin-left: 15px;
				}

				.beta {
					padding: 0 5px;
				}
			}
		}
	}

	.concept {

		.w-DataGrid {

			.creative-list-grid {
				.ps-container .ps-scrollbar-y {
					@include transparent($percent: 40);
				}

				.ps-container:hover .ps-scrollbar-y {
					@include transparent($percent: 65);
				}

				.ps-container .ps-scrollbar-y.in-scrolling {
					@include transparent($percent: 90);
				}

				.w-body {
					@include box-fill($top: 27px);
					overflow: hidden;
					height: auto;
				}
			}

			.loader-overrides {
				@include transparent($percent: 90);
				@include calc(top, "50% + 150px");
				@include calc(left, "50% - 80px");
				width: 160px;
				position: absolute;
			}

			.column {
				.settings-icon {
					@include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
				}
				word-break: break-all;
				padding-right: 15px;
			}

			.headers {
				color: #525252;
				padding-right: 20px;
			}


			.edit {
				width: 2%;
				visibility: hidden;
				padding-right: 0;
			}

			.item-wrapper {
				 &:hover, &.hover {
					.edit {
						visibility: visible;
					}
				}
			}

			.content {
				top: 40px !important;
				position: absolute !important;
				height: auto !important;
			}

			.toggle-arrow, .toggle-arrow .header-c {
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			.checkbox-input, .toggle-arrow {
				padding-right: 15px;
			}

			.toggle-arrow {
				width: 5px;
				top: 3px;
				float: left;
			}

			// CONCEPT GRID COLUMN DEFINITIONS

			.default {
				width: 120px;
				padding: 0 0 !important;
				margin: 0 18px 0 0 !important;
				word-break: break-all;

				div {
					padding: 0;
				}
			}

			.checkbox-input {
				width: 14px;
			}

			.name {
				width: 21%;
			}

			.hasErr {
				width: 150px;
			}

			.id {
				width: 6%;
			}

			.status {
				width: 6%;
			}

			.advertiser {
				width: 12%;

				.container {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100%;
				}
			}

			.activecount {
				text-align: right;
				width: 6%;
				padding-right: 2px;
			}

			.strategy {
				width: 21%;
				display: block;
			}

			.modified {
				width: 5%;
			}

			.config {
				width: 15px;
				background: none;
				border: 0;
			}

			.settings-icon {
				padding-right: 2px;
			}

			.item {

				.icon-arrow {
					@include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);

					&.expanded {
						@include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
						margin-top: 2px;
					}
				}

				.icon-spinner {
					margin-top: -1px;
					margin-left: -3px;
					width: 12px;
					height: 12px;
				}

				.sub-spinner {
					position: relative;
					float: left;
					top: 5px;
					left: -13px;
					width: 0;
					height: 12px;
					z-index: 100;
				}

				.adx-error {
					position: absolute;
					@include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
				}


				.adx-warning {
					position: absolute;
					@include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
				}

				.adx-error,
				.adx-warning {
					top: 0;
					right: 2px;
				}

				.hidden {
					display: none;
				}
			}

			.creatives-grid-window {
				background: $color-A10;
				padding-right: 12px;
			}

			.w-foot {
				padding-top: 2px;

				.list-actions {
					display: block;
					margin: 0 auto;
					width: 10%;
				}

				.more-creatives {
					@extend .btn-primary;
					float: left;
				}
			}

			.sub-foot {
				padding-top: 2px;
				padding-bottom: 2px;
				border-bottom: 1px solid $color-A7;

				.list-actions {
					display: block;
					margin: 0 auto;
					width: 10%;
				}
				.more-creatives {
					@extend .btn-primary;
					float: left;
				}
				.clear {
					clear: both;
					height: 1px;
				}
			}

			.child-head {
				background-color: #e0e0e0;
				padding-right: 30px;
			}

			.creatives-grid {
				.headers {
					background-color: #e0e0e0;
					padding-right: 1px;
				}
				.creative-header {
					color: #525252;
					font-family: PNLB;
					font-size: 10px;
					text-transform: uppercase;
				}
				.checkbox-input {
					width: 15px;
					padding-right: 15px;
				}
				.name {
					width: 11%;
				}
				.id {
					width: 11%;
				}
				.status {
					width: 11%;
				}
				.externalID {
					text-align: right;
					width: 11%;
					padding-right: 20px;
				}
				.details {
					width: 13%;
					.transcode-error {
						.icon {
							@include sprite-icon($left: 7, $down: 10, $width: 18px, $height: 15px);
							width: 18px;
							height: 15px;
						}
					}
				}
				.type {
					width: 11%;
				}
				.secure {
					width: 11%;

					.secure-warning {
						word-break: keep-all;
					}
				}
				.non-secure-warning {
					&:hover {
						cursor: default;
					}
				}
			}
		}

		.children {
			color: $color-A3;
			display: none; // initially hide children
			cursor: default;



			.checkbox {
				cursor: pointer;
			}

			.item {
				background: $color-A10;
				border-bottom: 1px solid $color-A7;
				cursor: default;
			}

			dt {
				color: $color-A4;
				font-family: PNLB;
				font-size: 10px;
				text-transform: uppercase;
			}

			.w-InlineEditForm {
				top: 2px;
			}

			.indent {
				margin-left: 51px;
			}
		}

		.w-CreativeList {
			height: 100%;
		}
	}
}

.w-FlagCreativeViolationDialog {
	@extend .dialog-panel;
	width: 400px !important;
	padding: 15px 15px 0 !important;
	margin-top: -100px !important;

	.ui-dialog-titlebar {
		display: none;
	}

	.ui-dialog-content {
		padding: 0 0.2em;
	}

	.message {
		margin-left: 0;

		.head {
			font-family: $custom-font-semi-bold;
			line-height: 24px;
			color: $color-A1;
			padding-bottom: 5px;
		}

		.message-c {
			font-size: 13px;
			font-weight: normal;
			line-height: 18px;
			margin-bottom: 15px;
		}

		.form-list dd {
			margin-bottom: 15px;

			.w-FilterList {
				.search {
					padding: 0;
					border-bottom: none;

					.txt {
						width: 389px !important;
						margin: -1px -1px;
						padding: 1px;
						background: none;
						height: auto;
						border: 1px solid $color-A7;
						border-top-left-radius: 4px;
						border-top-right-radius: 4px;
					}
				}

				.list {
					height: 100px;
					margin-top: 0;
					padding-top: 1px;
				}
			}
		}
	}

	.save {
		@extend .ui-submit;
		float: right;
		margin-right: 17px;

		&.disabled {
			@include transparent();
			cursor: default !important;
			pointer-events: none;
		}
	}

	.cancel {
			@extend .ui-cancel;
			margin-top: 6px;
			padding-right: 0;
	}
}
