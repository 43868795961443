.w-VideoTargeting {

	.w-Filters .chzn-container {
		width: 135px;
	}

	.w-DataGrid {
		.type {
			width: 65%;
			margin-left: 10px;

			.type{
			margin-left: 20px;
			}
		}

		.video, .audio {
			width: 15%;
			text-align: right;
			.icon {
				float: right; 
				.icon-circle {
					border-radius: 5px;
					background: $color-A3;
					height: 8px;
					width: 8px;
					margin-right: 18px;
					margin-top: 5px;
				}
			}
		}

		.icons {
			position: absolute;
			width: 40px;
			margin-left: 20px;
			left: 0;
			z-index: 1;
		}

		.icon {
			float: left;
		}

		.browse-list .item {
			border-bottom: 1px solid $color-A11;
		}

		.children .item,
		.search .item {
			padding-left: 60px;

			.arrow .icon-arrow{
			margin-left:0px;
			}
		}

		.children .children .item-row {
			margin-left:20px;
		}
	}

	.hidden {
		display: none;
	}

	.disabled {
		@include transparent();

		.select-action {
			cursor: text !important;
		}
	}
}