/*
    The creatives tab inherits from these stylesheets:
    >  creative-list
    >  creative-create-edit
*/

.w-CreativeGrid {

    .search {
        .w-Filters {
            margin: 0;

            .chzn-container-single {
                width: 140px;
            }

            .text {
                border-radius: 0 3px 3px 0;
            }

            .chzn-results {
                font-family: 'PNR'; /* ProximaNovaRegular */

                // truncate the dropdown list and make it scrollable
                max-height: 200px;
                overflow-y: auto;
            }

            .clear-filter {
                margin-left: -27px;
            }

            &.disabled {
                pointer-events: none;
            }
        }
    }

    .display {
        .w-DataGrid {

            .loader-overrides {
                @include transparent($percent: 90);
                @include calc(top, "50% - 12px");
                @include calc(left, "50% - 80px");
                width: 160px;
                position: absolute;

                &.creative-infinite {
                    bottom: 0;
                    top: auto;
                }
            }

            .w-head {
                height: 15px;
                padding-right: 20px;

                .checkbox {
                    top: -1px;
                }
            }

            .headers {
                .info-icon {
                    // Fix for info tooltip
                    margin-left: 4px;
                    display: inline-block;
                    top: initial;
                    right: initial;
                    bottom: initial;
                }
            }
            
            .edit {
                visibility: hidden;
            }

            .item-wrapper {
                 &:hover, &.hover {
                    .edit {
                        visibility: visible;
                    }
                }
            }
            
            .column {
                padding-right: 1%;
            }

            .edit-icon {
                color: #323232;
            }

            .icon-group {
                margin-top: 3px;
                .view {
                    margin: 3px 0 0;
                    @include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
                    @include transparent($percent: 30);
                    display: inline-block;
                    vertical-align: middle;
                }

                .alert {
                    margin: 3px 0 0 1px;
                    @include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
                    @include transparent($percent: 30);
                    display: inline-block;
                    vertical-align: middle;
                }
                cursor: pointer;
            }

            .icon-group:hover .view {
                @include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
                @include transparent($percent: 100);
                display: inline-block;
                vertical-align: middle;
            }
            .icon-group:hover .alert {
                @include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
                @include transparent($percent: 100);
                display: inline-block;
                vertical-align: middle;
            }

            .toggle-arrow, .toggle-arrow .header-c {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .checkbox-input, .toggle-arrow {
                padding-right: 15px;
            }

            .toggle-arrow {
                width: 5px;
                top: 3px;
            }
            .name {
                width: 15%;
            }
            .id {
                width: 4%;
            }
            .approval {
                width: 5%;
                
                strand-popover[_placement="n"]:after {
                    border-top: 10px solid $color-A24 !important;
                }
            }
            .advertiser {
                width: 6%;
            }
            .concept {
                width: 9%;
            }
            .third-pas-id {
                width: 8%;
            }
            .file-type {
                width: 6%;
            }
            .dimensions,
            .last-modified,
            .start-date {
                width: 6%;

                .on-hover {
                    max-width: 70%;
                }
            }
            .end-date,
            .secure {
                width: 5%;

                .on-hover {
                    max-width: 80%;
                }
            }
            .on-hover {
                border-bottom: 1px solid transparent;
                display: block;

                &:hover {
                    border-bottom: 1px dotted $color-A1;
                }
            }
            .config {
                width: 14px;
            }
            .config-disabled {
                width: 14px;
            }

            .checkbox-input,
            .toggle-arrow,
            .name,
            .id,
            .advertiser,
            .concept,
            .third-pas-id,
            .file-type,
            .dimensions,
            .secure,
            .last-modified,
            .start-date,
            .end-date,
            .landing-page,
            .ad-server-name,
            .ad-format,
            .auto-detected-vendors,
            .manual-vendors,
            .audio,
            .expansion-trigger,
            .expansion-direction,
            .html-code,
            .click-thru-url,
            .main-file,
            .backup-file,
            .divider {
                float: left;
                word-wrap: break-word;
            }

            .config,
            .config-disabled {
                position: absolute;
            }

            .display_nonsecure {
                color: #FED01A;
            }

            .display_secure {
                color: #9ecb5c;
            }

            .net-status-hold {
                text-align: center;
            }

            .net-status-icon {
                display: none;
                margin-top: -4px;
            }

            .net-status-approved {
                color: $color-A21;
            }

            .net-status-pending {
                color: $color-E6;
            }

            .net-status-pending48 {
                color: $color-E6;
                margin-left: -5px;
            }

            .net-status-rejected {
                color: $color-A22;
            }

            .net-status-popover {
                dd {
                    padding-bottom: 3px;
                }

                .net-pop-title {
                    font-family: $unified-font-bold;
                    padding-bottom: 9px;
                }

                .columnA {
                    float: left;
                    width: 120px;
                    margin-right: 15px;
                    margin-bottom: 10px;
                }

                .columnB {
                    float: left;
                    width: 126px;
                    margin-bottom: 10px;
                }

                .net-status-icon {
                    display: inline-block;
                    padding-right: 5px;
                    margin-top: -4px;
                }

                .status-text {
                    display: inline-block;
                }

                .status-text::first-letter {
                    text-transform: uppercase;
                }
            }

            .item {

                .icon-arrow {
                    @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);

                    &.expanded {
                        @include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
                        margin-top: 2px;
                    }
                }

                .settings-icon-disabled {
                    @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
                    opacity: 0.3; 
                }

                .pencil-icon-placeholder {
                    width: 14px;
                    display: block;
                }

                .dummycheckbox {
                    @include sprite-at-position($left: 2, $down: 8);
                    height: 16px;
                    width: 16px;
                    padding: 0;
                    position: relative;
                    opacity: 0.5;
                    cursor: default;
                }

                .adx-error,
                .adx-error-list {
                    @include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
                }


                .adx-warning,
                .adx-warning-list {
                    @include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
                }

                .adx-error,
                .adx-warning,
                .secure-warning {
                    float: right;
                }

                .adx-error-list,
                .adx-warning-list {
                    margin-left: 123px;
                    margin-top: -15px;
                    z-index: 10;
                }

                .secure-warning,
                .secure-warning-list {
                    @include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
                }

                .ksize-warning {
                    color: #f85252;
                }

                .hidden {
                    display: none;
                }
            }

            .landing-page {
                .tab-info {
                    margin: 0 0 0 4px;
                    position: absolute;
                    display: inline-block;
                }
            }

            .third-pas {
                .landing-page, .expansion-trigger {
                    width: 20%;
                }
                .ad-server-name {
                    width: 14%;
                }
                .ad-format {
                    width: 10%;
                }
                .mraid {
                    width: 4.2%;
                    .info-icon {
                        margin: 0 0 0 4px;
                        position: absolute;
                        display: inline-block;
                    } 
                }
                .auto-detected-vendors {
                    width: 15%;

                    dd {
                        width: 120px;
                    }
                }
                .manual-vendors {
                    width: 16%;

                    dd {
                        width: 120px;
                    }
                }
                .html-code {
                    width: 35%;
                    margin-right: 10px;

                    dd {
                        pre {
                            white-space: pre-wrap;
                        }
                    }
                }

                .classifications-hold {
                    width: 55%;

                    dd {
                        word-wrap: break-word;
                    }

                    #appliedLoader {
                        display: none;
                        margin-bottom: 2px;
                    }
                }

                .classification-block {
                    padding-top: 4px;
                    width: 25%;
                    min-width: 180px;

                    dl {
                        background: #f9f9f9;
                    }

                    dt {
                        color: initial;
                        background: #eaeaea;
                        padding: 3px 6px;
                    }

                    dd {
                        font-family: PNLB;
                        padding: 3px 6px;
                    }

                    .catParent {
                        font-family: $custom-font-regular;
                    }
                }

                .non-secure-call {
                    width: 91%;
                    dd {
                        div {
                            height: auto;
                            overflow: auto;
                        }
                        li {
                            list-style-type: disc;
                            list-style-position: inside;
                        }
                    }
                    .vendor-name {
                        font-family: $unified-font-bold;
                    }
                }
            }

            .t1as {
                .click-thru-url {
                    width: 20%;
                }
                .landing-page {
                    width: 14%;
                }
                .ad-server-name {
                    width: 10%;
                }
                .ad-format {
                    width: 7%;
                }
                .auto-detected-vendors {
                    width: 16%;
                }
                .manual-vendors {
                    width: 13%;
                }
                .main-file,
                .backup-file,
                .hybrid-file {
                    width: 30%;
                    .icon {
                        @include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 16px);
                        @include transparent($percent: 30);
                        display: inline-block;
                        position: absolute;
                        margin: 1px 0 0 4px;

                        &:hover {
                            @include transparent(100);
                        }
                    }

                    .html-backup-fileicon {
                        color: #c3c3c3;

                        &:hover {
                            color: #666666;
                        }
                    }

                    .icon-group {
                        margin-top: 3px;
                        .view {
                            margin: 3px 0 0;
                            @include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
                            @include transparent($percent: 30);
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .alert {
                            margin: 3px 0 0 1px;
                            @include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
                            @include transparent($percent: 30);
                            display: inline-block;
                            vertical-align: middle;
                        }
                        cursor: pointer;
                    }

                    .icon-group:hover .view {
                        @include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
                        @include transparent($percent: 100);
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .icon-group:hover .alert {
                        @include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
                        @include transparent($percent: 100);
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .tag-code-hold {
                    margin-right: 10px;
                    width: 30%;
                }

                .third-party-tags,
                .html-code {
                    width: 100%;
                    margin-right: 10px;
                    dd {
                        pre {
                            white-space: pre-wrap;
                        }
                    }
                }

                .classifications-hold {
                    width: 55%;

                    dd {
                        word-wrap: break-word;
                    }

                    #appliedLoader {
                        display: none;
                        margin-bottom: 2px;
                    }
                }

                .classification-block {
                    padding-top: 4px;
                    width: 25%;
                    min-width: 180px;

                    dl {
                        background: #f9f9f9;
                    }

                    dt {
                        color: initial;
                        background: #eaeaea;
                        padding: 3px 6px;
                    }

                    dd {
                        font-family: PNLB;
                        padding: 3px 6px;
                    }

                    .catParent {
                        font-family: $custom-font-regular;
                    }
                }

                .auto-detected-vendors,
                .manual-vendors {
                    dd {
                        width: 120px;
                    }
                }
            }
            .info-tip {
                opacity: 0.3;
                color: #525252;
                &:hover {
                    opacity: 1;
                }
            }
        }

        .children {
            color: $color-A3;
            display: none;
            // initially hide children

            .item {
                background: $color-A10;
                border-bottom: 1px solid $color-A7;
            }

            dt {
                color: $color-A4;
                font-family: PNLB;
                font-size: 10px;
                text-transform: uppercase;

                .beta {
                    padding-left: 5px;
                    
                }

                label {
                        text-transform: none;
                }
            }

            .w-InlineEditForm {
                top: 2px;
            }

            .indent {
                margin-left: 51px;
            }
        }

        // CSS Truncate
        .advertiser, .editable-content dd {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .editable-view .editable, .editable-view .group-edit-content {
        float: left;
        max-width: 100%;
    }

    .name {
        .video {
            max-width: 85% !important;
        }
    }

    .w-InlineEditForm {
        position: absolute;
        top: -4px;

        textarea {
            font-family: monospace;
            padding: 0;
            margin: 0;
            border: 0;
            outline: none;
            resize: vertical;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .action {
            float: left;
        }

        .w-body {
            position: static;
            height: auto;
        }

        .form-list .select .chzn-container .chzn-results {
            max-height: 260px;
            overflow-y: scroll;
        }

        .w-foot {
            top: initial;

            .edit-actions {
                margin-top: 0;
            }
        }
    }

    .name .w-InlineEditForm {
        width: 250px;
    }

    .click-thru-url .w-InlineEditForm,
    .landing-page .w-InlineEditForm {
        width: 300px;
    }

    .third-party-tags .w-InlineEditForm {
        width: 100%;
        height: 55px;

        dd.input {
            width: 100%;
            height: auto;
            overflow: hidden;
        }

        textarea {
            min-height: 40px;
        }
    }

    .secure .w-InlineEditForm {
        width: 70px;
    }

    .file-type .w-InlineEditForm {
        width: 90px;
    }

    .has-sound .w-InlineEditForm {
        width: 70px;
    }

    .ad-server-name .w-InlineEditForm {
        width: 120px;
    }

    .expansion-trigger .w-InlineEditForm {
        width: 160px;
    }

    .third-pas-id .w-InlineEditForm {
        width: 250px;
    }

    .ad-format .w-InlineEditForm {
        width: 100px;
    }

    .concept .w-InlineEditForm {
        width: 200px;

        .chzn-container-active {
            .chzn-drop {
                top: 20px !important;
            }
        }
    }

    .html-code .w-InlineEditForm {
        width: 100%;

        dd.input {
            width: 100%;
            height: auto;
            overflow: hidden;
        }

        textarea {
            min-height: 130px;
        }
    }

    .dimensions .w-InlineEditForm {
        width: 100px;
    }

    .inline-edit-loader {
        z-index: 4;
    }

    .hide-row {
        display: none !important;
        // initially hide
    }

    .third-party-tags {
        .editable {
            // show only 3 lines for html blocks
            max-height: 43px;
        }
    }

    .html-code {
        .editable {
            // show only 10 lines for html blocks
            max-height: 140px;
        }
    }

    dl.html-code,
    dl.third-party-tags,
    dl.tag {
        // Idk why, but without this, the width becomes 18611px
        width: 100% !important;

        dd {
            padding: 5px 0 1px;
            overflow: hidden;
            // cut off extra lines, prevent ugliness
        }
    }
}

.w-DisplaySearchTooltip {
    .tipsy-inner {
        color: $color-A12;
        padding: 8px;
    }

    .tipsy-arrow-s {
        left: 46%;
    }

    .tipsy-arrow-w {
        left: 2%;
        top: 43%;
        border-width: 8px;
    }

    margin-top: -5px;
}