@import "mixins";

.w-PixelSegment {
  .w-body {
    .column-A {
      width: 67% !important;
      padding-left: 17px;
      height: 100%;
      overflow: hidden;

      ._wrapper {
        height: 100%;

        .list-c {
          height: 100%;
        }
      }
    }

    .acxiom-info {
      margin: 8px 0 0px 0;
      width: 97%;
      display: none;

      .message {
        width: 90%;
      }

      .icon-close {
        margin-top: -21px;
      }
    }
  }

  .browser-c, .search-c {
    height: 100%;
    background-color: $color-A12;

    .w-DataGrid {
      .content {
        bottom: 102px;
        top:36px;
      }

      .loader-overrides {
        position: absolute;
        bottom: 0;
        width: 135px;
        left: 400px;
      }
    }

    .item-wrapper {
      position: relative;
    }

    .headers {
      padding-left: 0;

      .segment {
        width: 75%;
        padding-left: 12px;
      }
    }

    .group-name {
      margin: 20px 0 5px 65px;
      font-family: $custom-font-bold;
      color: $color-D10;
      font-size: 12px;
      letter-spacing: .3px;
    }

    .item.audience {
      .text {
        white-space: normal;
      }
    }

    .item {
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      .arrow {
        margin: 4px 0 0 0;
        position: relative;

        .icon-arrow {
          @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);
          margin-left: 4px;
        }

        &.loading {
          .icon-arrow {
            visibility: hidden;
          }

          .icon-spinner {
            top: 15px;
            left: 1px;
          }
        }

        &.expanded {
          .icon-arrow {
            @include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
            margin: 2px 0 0 2px;
            padding-right: 2px;
          }
        }
      }

      .item-row {
        float: left;
        width: 100%;
        padding: 8px 0;
        white-space: nowrap;
        border-bottom: 1px solid $color-A7;

        &:hover {
          background-color: $color-E8;
        }

        &:selected {
          background-color: $color-D7;
        }
      }

      .drag {
        padding-top: 12px;
      }

      .segment {
        padding-left: 12px;
        width: 78%;

        .item-c {
          float: left;
        }

        .icon {
          margin-top: 2px;

          &.arrow {
            margin-top: 4px;
          }
        }

        .include {
          &.partial {
            @include sprite-icon($left: 10, $down: 7, $width: 15px, $height: 15px);
            @include transparent(100);

            &:active {
              @include transparent(30);
            }
          }

          &.parentSelected {
            @include sprite-icon($left: 9, $down: 7, $width: 15px, $height: 15px);
            @include transparent(100);

            &:active {
              @include transparent(30);
            }
          }
        }

        .text {
          margin-left: 12px;
          color: $color-A3;
          line-height: 18px;
          width: 90%;

          &.browse {
            margin-left: 1px;
            width: 88%;
          }

        }
      }

      .size, .cpm {
        margin-top: 2px;

        .item-c {
          font-family: $custom-font-regular;
        }
      }
    }

    .browse-list {
      .item {
        .item-row {
          padding: 4px 0;
        }
      }
    }

    .size {
      width: 6%;
      text-align: right;
    }

    .cpm {
      width: 9%;
      text-align: right;
    }

    .w-foot {
      @include box-fill($top: auto);

      position: fixed !important;
      bottom: 51px;
      margin-left: 129px;
      margin-right: 28.7%;

      .browse-path {
        background: $color-A7;
        padding: 7px 15px 6px 13px;
        font-family: $custom-font-regular;
        font-size: 12px;
        color: $color-A3;
        line-height: 13px;
      }

      .page-controls {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .w-PixelBundlesList {
    .pixel-grid {
      margin-top: 5px;
      bottom: 30px;
      height: 100%;
    }

    .filters-box {
      .chzn-container .chzn-results {
        max-height: 277px;
      }
    }

    .w-DataGrid {
      margin-top: 10px;

      .headers {
        .size {
          padding-left: 50%;
        }
      }
    }
  }

  .w-Pixel-Bins {
    width: 30%;
    left: auto;

    .include-bin {
      height: 100%;
    }

    .headers {
      .segment {
        width: auto;

        .icon-spinner {
          left: auto;
          right: -5px;
        }
      }
    }
  }
}

.w-PixelTargetingFilters {

  .header-lb {
    font-size: 13px;
    padding-bottom: 4px;
  }
  .w-body {
    padding-top: 2px;

    .primaryFilter {
      width: 150px;

      .chzn-container {
        width:150px;
      }
    }

    .secondaryFilter {
      .entities {
        width: 150px;
      }
    }
  }
}



