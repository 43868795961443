.w-NumberEdit {
	float: right;

	.text {
		text-align: right;
	}

}

.w-InlineEditError,
.w-InlineEditWarning {
	z-index: 5;

	.tipsy-inner {
		color: $color-A12;
		background-color: $color-C1;
		padding: 8px;
	}

	.tipsy-arrow-s {
		border-top-color: $color-C1;
		left: 46%;
	}
}

.w-InlineEditWarning {
	.tipsy-inner {
		background-color: $color-E2;
	}

	.tipsy-arrow-s {
		border-top-color: $color-E2;
	}
}

.adx-tipsy {
	width: 320px;

	.tipsy-inner {
		white-space: normal;
		word-wrap: break-word;
	}
}

.adx-error-tipsy {
	@extend .w-InlineEditError;
	@extend .adx-tipsy;
}

.adx-warning-tipsy {
	@extend .w-InlineEditWarning;
	@extend .adx-tipsy;
}

.w-InlineEditForm,
.w-GroupInlineEditForm {
	z-index: 3;

	.form-list dd {
		font-weight: normal;
		background: $color-A12;
	}

	.action {
		background: $color-A1;
		cursor: pointer;

		.save,
		.cancel {
			height: 10px;
			margin: 5px 2px 3px 7px;
			width: 13px;
		}

		.save {
			@include sprite-at-position($left: 4, $down: 6);
			&:hover,
			&:focus {
				@include sprite-at-position($left: 4, $down: 7);
			}
		}

		.cancel {
			@include sprite-at-position($left: 5, $down: 6);
			margin-right: 2px;

			&:hover,
			&:focus {
				@include sprite-at-position($left: 5, $down: 7);
			}
		}

		&:hover {
			.save {
				@include sprite-at-position($left: 4, $down: 7);
			}

			.cancel {
				@include sprite-at-position($left: 5, $down: 7);
			}
		}
	}

	.hidden {
		display: none !important;
	}

	.chzn-container {
		width: 99.95% !important;
		display: block;
	}

	.chzn-container-single .chzn-single {
		background-color: transparent;
		border: none;
		border-radius: 0;
		background-image: none;
		box-shadow: none;
	}

	.chzn-container-active .chzn-single-with-drop {
		background-color: transparent;
		background-image: none;
		border: none;
		border-radius: 0;
	}

	.chzn-container-active {
		.chzn-single span{
			color: $color-E1;
		}

		.chzn-drop {
			margin: 7px 0 0 -9px;
		}

		.chzn-results li {
			padding-left: 15px;
		}
	}

	.last .chzn-container-single .chzn-drop {
		margin-left: -6px;

		.chzn-results li {
			padding-left: 13px;
		}
	}

	.select {
		height: 23px;
	}

	.error {
		border: 1px solid $color-C2 !important;
	}
}

.w-InlineEditForm {
	position: relative;
	width: 90%;
	top: -2px;

	dd {
		border: 1px solid $color-A6;
	}

	.input,
	.select {
		width: 100%;
	}

	.chzn-container-active .chzn-drop {
		margin: 2px 0px 0px -1px;
	}

	.form-input {
		width: 100%;
		border: none;
		height: 20px;
		font-size: 15px;
	}

	.text {
		width: 98%;
	}

	.w-foot {
		position: absolute;
		top: 27px;
		right: -2px;

		.edit-actions {
			@include _border-radius($radius: 0px 0px 8px 8px);
			position: relative;
			float: right;
			min-width: 46px;
			margin-top: -9px;
			background: $color-A2;
		}

		.save-action {
			@include _border-radius($radius: 0px 0px 0px 8px);

			border-right: 1px solid $color-A0;
		}

		.cancel-action {
			@include _border-radius($radius: 0px 0px 8px 0px);

			margin-left: 1px;
		}
	}

	.w-body {
		position: absolute;
		top: 0px;
		width: 100%;

		.edit-actions {
			@include _border-radius($radius: 0px 0px 8px 8px);
			position: relative;
			top: 25px;
			right: -2px;
			position: absolute;
			min-width: 46px;
			background: $color-A2;
		}

		.save-action {
			@include _border-radius($radius: 0px 0px 0px 8px);

			border-right: 1px solid $color-A0;
		}

		.cancel-action {
			@include _border-radius($radius: 0px 0px 8px 0px);

			margin-left: 1px;
		}
	}

	.small-body,
	.small-head {
		width: 35%;
	}

	.small-head {
		position: relative;
	}

	.select {
		background: $color-A12;
	}

}

.w-GroupInlineEditForm {
	position: relative;
	width: 100%;
	top: -4px;

	.w-body {
		position: absolute;
	}

	.form-list {
		padding: 1px;
		border: 1px solid $color-A6;
		background: $color-A12;
	}

	dd {
		float: left;
		margin: 0 !important;
		padding: 0 !important;
		border: none !important;
	}

	.input {
		width: 20%;
	}

	.info {
		width: 10%;
		text-transform: none;
	}

	.select-c,
	.input-c,
	.info-c {
		border-left: 1px solid $color-A6;
		margin: 2px;
		padding-left: 5px;
		height: 26px;
		white-space: nowrap;
	}

	.info-c {
		text-align: center;
		color: $color-A5;
		font-size: 10px;
		padding-left: 2px;
	}

	select,
	input {
		border: none;
		display: inline;
	}

	.select-input {
		width: 100%;
	}

	input {
		width: 75%;
	}

	.first {
		border-left: medium none;
	}

	.edit-actions {
		@include _border-radius($radius: 0px 0px 8px 8px);
		position: relative;
		min-width: 46px;
		position: absolute;
		right: 0;
		background: $color-A2;
	}

	.save-action {
		@include _border-radius($radius: 0px 0px 0px 8px);

		border-right: 1px solid $color-A0;
	}

	.cancel-action {
		@include _border-radius($radius: 0px 0px 0px 8px);

		margin-left: 1px;
	}
}
