@import "mixins";

.w-PixelTargetingFilters {
	@extend .w-Filters;
	float: left;

	.actions {
		margin-top: 0;
		float: right;
		position: absolute;
		right: -5px;
		top: 0;
	}

	.w-body {

		margin-top: 0 !important;

		.primaryFilter {
			float: left;

			.chzn-single {
				@include border-radius($radius: 3px);
				border: 1px solid $color-A5;
				margin: -1px -1px 0;
				height: 23px;
				line-height: 23px;
			}

			.chzn-container {
				width: 104px;
			}
		}

		.secondaryFilter {
			float: left;
			margin-left: 5px;

			.entities {
				float: left;
				width: 130px;

				input {
					padding-top: 2px;
				}
			}

			.term {
				float: left;
				width: 290px;

				.text {
					padding-right: 0;
					width: 100%;
				}
			}
		}
	}
}

.w-Targeting {
	.w-PixelBundlesList {
		height: 100%;
		background-color: $color-A12;


		.eligible {
			padding-left: 0;

			label {
				position: absolute;
				right: 0;
				top: 7px;
			}
		}

		.w-DataGrid {
			margin-top: 0;

			.column {
				padding-right: 20px;
			}

			.headers {
				padding-left: 0;

				.name {
					padding-left: 12px;
					padding-right: 0;

					.header-c {
						margin-left: 12px;
					}
				}
				.loads {
					text-align: right;
				}
				.uniques {
					text-align: right;
				}
			}

			.content {
				.item {
					padding: 0;

					.item-row {
						float: left;
						width: 100%;
						padding: 8px 0;
						white-space: nowrap;

						.name {
							padding-left: 12px;
							padding-right: 0;
							overflow-wrap: break-word;

							.item-c {
								float: left;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.icons {
								width: 50px;
							}

							.icon {
								margin-top: 2px;
							}

							.include {
								@include sprite-icon($left: 10, $down: 0, $width: 14px, $height: 14px);
								@include transparent(30);

								&:hover {
									@include sprite-icon($left: 10, $down: 0, $width: 14px, $height: 14px);
									@include transparent(100);
								}

								&.active {
									@include sprite-icon($left: 10, $down: 1, $width: 14px, $height: 14px);
									@include transparent(100);
								}
								margin-left: 12px;
							}

							.exclude {
								@include sprite-icon($left: 11, $down: 0, $width: 14px, $height: 14px);
								@include transparent(30);
								margin-left: 7px;

								&:hover {
									@include sprite-icon($left: 11, $down: 0, $width: 14px, $height: 14px);
									@include transparent(100);
								}

								&.active {
									@include sprite-icon($left: 11, $down: 1, $width: 14px, $height: 14px);
									@include transparent(100);
								}
							}

							.text {
								margin-left: 55px;
								color: $color-A3;
								line-height: 18px;
								white-space: normal;
							}
						}
					}
				}
			}
		}

		.w-foot {
			background-color: $color-A12;
		}

		.name {
			width: 33%;
		}

		.segment .name {
			width: 70%;
		}

		.loads {
			width: 9%;
			min-width: 72px;
			text-align: right;
		}

		.uniques {
			width: 12%;
			min-width: 72px;
			text-align: right;
		}

		.referrer {
			width: 18%;
			padding-left: 10px;
			text-align: left;
			word-wrap: break-word;

			.item-c {
				float: left;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}

		.id {
			width: 12%;
		}

	}

	.pixel_type_icon {
		border: 1px solid $color-A5;
		border-radius: 2px;
		background-color: $color-A6;
		text-align: center;
		float: left;
		color: $color-A1;
		width: 11px;
		padding-top: 2px;
		padding-bottom: 2px;
		font: 9px/7px $custom-font-regular;
		letter-spacing: 1px;
	}
}

.w-Pixel-Bins {
	.headers {
		.segment {
			width: auto;

			.icon-spinner {
				left: auto;
				right: -5px;
			}
		}
	}
}

.w-PixelTargeting .w-Bins .name {
	overflow-wrap: break-word;
	width: 90%;
}