.w-StrategiesPanel {
	@include box-fill();
	background: $color-A12;

	.w-CampaignListItem {
		border: none;
	}

	.w-StrategyList {
		background: $color-A12;
		padding-top: 20px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.active-only label {
		line-height: 17px;
	}

	.w-filters {
		padding: 20px 10px 0 10px;
	}

	.sort {
		float: left;
		width: 350px;

		.chzn-drop {
			width: 140px !important;
		}
	}

	.chart-icon {
		display: none;
	}

	.loader-overrides {
		width: 160px;
		position: absolute;
		@include transparent($percent: 90);
		@include calc(top, "50% - 12px");
		@include calc(left, "50% - 80px");
	}

	.list-size-controls {
		margin: 5px 0;

		.control-select-title {
			margin-right: 5px;
		}

		.control-select-c {
			width: 62px;
		}

		.title {
			text-transform: capitalize;
			font-size: 12px;
			letter-spacing: normal;
		}
	}
}



