@import "mixins";
@import "filters";
#highlighted-term { background: $color-E8; }

.segments-list-wrapper {
    .w-box {
        padding: 16px 0 0 !important;
    }

    .w-box .w-head {
        padding-bottom: 5px !important;
    }

    .title {
        max-width: 500px;
        margin: 0 100px 0 26px;

        font: 14px/21px "AB";
        color: $color-A2;

        height: 33px;
    }

    .overlap-report-notification {
        z-index: 1;
        position: absolute;
        top: -40px;
        right: 8px;
    }

    .segment-message-container {
        .segment-message {
            &.org {
                width: 83%;
                bottom: 10px;
            }
        }
    }

    .segment-type-select {
      width: 160px;
      padding-right: 10px;
    }

    .form-list {
      margin-top: 2px;
    }

    .clear-filter {
      margin-top: 2px;
    }

    .segment-message-title {
        font-weight: bold;
    }

    .w-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: visible;
        background: rgb(255, 255, 255);
    }

    .campaign-filters-wrapper {
        padding: 20px 20px 20px 26px;
        height: 25px;
        font: 14px/21px "AB";
        color: $color-A2;

        .segment-controls {
                font-family: "AR";
//                    height: 26px;
//                    margin-top: 8px;

        .view-actions {
            float: right;
            margin-bottom: 8px;
            margin-right: 10px;
            padding: 0;

            .refresh-timestamp {
                float: left;
                margin-top: 12px;
                margin-right: 12px;
                color: $color-A3;
                font-size: 11px;
            }

            .column {
                float: left;
                display: inline;
                zoom: 1;
                position: relative;
            }

            #data-setup {
                margin: 3px 10px 0px 5px;
            }

            #segment-export {
                margin: 3px 20px 0px 0px;
            }

            .add-segment {
                margin: 3px 20px 0px 0px;
            }
        }

    }
        .campaign-filters {
            margin-top: 0px !important;

            .entities #entities_dropdown_chzn {
                width: 120px;
            }

            .entities .chzn-single, .entities li {
                font-family: AR !important;
                font-size: 13px !important;;
                font-weight: normal !important;;
            }

            .w-Filters {
                margin: 0px !important;
            }

            .w-Filters .auto-complete {
                width: auto;
            }

            .body {
                float: left;

                .entities {
                    width: auto;
                }
            }

            .clear-filter {
                @include sprite-icon($left: 4, $down: 5, $width: 15px, $height: 15px);

                cursor: pointer;
                display: none;
                margin-left: -20px;
                position: absolute;
                z-index: 4;
            }
        }

        .auto-complete-results {
            margin-left: -2px;

            .ui-menu-item {
                .ui-state-hover,
                :hover,
                :focus {
                    color: white;
                    background-color: $color-D15;
                    border-radius: 0;
                }
            }
        }
        .chzn-drop {
            width:160px !important; ;
        }

        .chzn-container .chzn-results {
            .highlighted,
            :hover {
                color: white !important;
                background-color: $color-D15 !important;
            }

            .result-selected {
                font-family: $custom-font-semi-bold;
                background-color: $color-D7;
                color: $color-D11;

                &:hover {
                    color: white;
                    background-color: $color-D15;
                }
            }
        }

    }

    .segment-list-wrapper {
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 12px;
    }

    .title {
        float: left;
        padding-top: 10px;
    }

    .segment-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        font-size: 12px;
        background-color: white;

        .w-DataGrid {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            #list-of-segments {
                overflow: hidden;
                height: 90%;

                .ajax-loader {
                    margin-top: 1em;
                    opacity: .9;
                    top: calc(50% - 12px);
                }
            }
            .segment-name {
                width: 250px !important;
            }

            .id {
                width: 120px !important;
            }

            .creator {
                width: 150px !important;
            }

            .date-modified {
                width: 150px !important;
            }

            .segment-size {
                width: 120px !important;
            }

            strand-grid-item > div > #columnContainer > div > div > span > div > a > strand-icon {
                width: 120px !important;
            }

            strand-grid-item:hover > div > #columnContainer > div > div > span > div > a > strand-icon {
                display: inline-block !important;
            }

            strand-icon[id^="edit-icon"] {
                width: 120px !important;
                cursor: pointer;
                color: $color-A5;
                &:hover {
                    color: $color-A1;
                }
            }

            strand-icon[id^="view-icon"] {
                width: 120px !important;
                cursor: pointer;
                color: $color-A5;
                &:hover {
                    color: $color-A1;
                }
            }

            .settings {
                width: 120px !important;
            }

            .default {
                padding: 0 0 !important;
                margin: 0 20px 0 0 !important;
                word-break: break-all;
            }

            .segments-lock-icon {
                height: 18px;
            }

            .w-DataGrid-head {
                height: 13px;
                padding: 8px 20px;

                .default {
                    width: 140px;
                    height: 20px;
                }
            }

            .w-DataGrid-body .content-holder {
                overflow: visible !important;

                .no-data, .empty-advertiser, .error-loading-advertiser {
                    .item {
                        height: 14px;
                        border-bottom: 1px solid #e7e7e7;
                        padding: 6px 0 7px 18px;
                        cursor: default;
                        &:hover {
                            background-color: $color-A12;
                        }

                        .blank {
                            padding: 7px 0;
                        }

                        .msg {
                            margin-left: -15px;
                            text-align: center;
                            color: $color-A4;
                            font-size: 13px;
                            -webkit-font-smoothing: antialiased;
                        }
                    }
                }

                .layout-loading {
                    position: absolute;
                    top: -4px;
                    width: 100%;
                    z-index: 9;
                    text-align: center;
                    font-size: 14px;
                    color: $color-A4;
                    padding: 10px 0;

                    .loading {
                        border-radius: 15px;
                        behavior: url('http://localhost/compass/css/css-pie/PIE.htc');
                        -pie-watch-ancestors: 1;
                        position: relative;
                        opacity: 0.5;
                        background-color: $color-A3;
                        color: white;
                        padding: 4px;
                        margin: 0 auto;
                        width: 20%;
                        font-size: 11px;
                        display: block;
                        text-align: center;
                    }
                }

                .list-of-segments .item-wrapper .item .item-row {
                    line-height: 29px;
                }

                .default {
                    width: 120px;
                }

                .existing_rows {
                    .item:hover {
                        background-color: rgba(0, 0, 0, 0) !important;
                    }

                    .item {
                        cursor: default;
                    }
                }

            }
            //
            .w-foot {
                bottom: 55px;
                left: 2px;
                position: absolute;
                margin: 0;
                background-color: white;
            }
        }

    }

    .w-foot {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 5px;
        width: 100%;
        height: 7%;
        margin: 5px 15px 0px 15px;

        .page-count {
            color: $color-A4;
            font-size: 12px;
            font-style: italic;
            float: left;
            padding-left: 10px;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .list-actions {
            display: block;
            margin: 0 auto 0;
            width: 10%;
        }

        .list-size {
            float: right;
            margin-right: 2px;

            .label {
                margin: 6px 10px 0 0;
                font-size: 12px;
                font-style: italic;
                color: $color-A4;
                display: inline-block;
                float: left;
            }

            .list-size-list {
                width: 60px;
            }
        }
    }

    #duplicate-modal {

        font-family: 'AR';

        .duplicate-segment-body {

            margin: 15px 20px;

            .duplicate-name-label {
                display: block;
                margin-top: 7px;
                margin-bottom: 7px;
                font-size: 11px;
            }

            .source-info {
                color: $color-A4;

                .source-title {
                    font-size: 11px;
                    font-style: italic;
                    margin-top: 25px;
                    margin-bottom: 8px;
                }

                span {
                    font-size: 11px;
                }

            }

            .advertiser-name {
                color: $color-D4;
                font-size: 11px;
                text-transform: uppercase;
                margin-bottom: 7px;
            }
        }


        #duplicate-segment-save {
            width: 81px;
        }
    }
}
