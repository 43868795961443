@import "mixins";

$custom-font-regular: "PNR";
$custom-font-italic: "PNRI";
$custom-font-semi-bold: "PNLB";
$custom-font-semi-bold-italic: "PNLBI";
$custom-font-bold: "PNB";
$custom-font-bold-italic: "PNBI";

$custom-mono-font-regular: "IMR";
$custom-mono-font-bold: "IMB";

$custom-font-regular-svg: "PNRS";
$custom-font-bold-svg: "PNBS";

$unified-font-regular: "AR";
$unified-font-bold: "AB";
$unified-font-bold-italic: "ABI";
$unified-font-italic: "AI";

$unified-font-regular-svg: "ARS";
$unified-font-bold-svg: "ABS";

$font-regular: "Verdana";

.gigantic {
	font-size: 110px;
	line-height: 120px;
	letter-spacing: -2px;
}

.huge, h1 {
	font-size: 68px;
	line-height: 72px;
	letter-spacing: -1px;
}

.large, h2 {
	font-size: 42px;
	line-height: 48px;
}

.bigger, h3 {
	font-size: 26px;
	line-height: 36px;
}

.big, h4 {
	font-size: 22px;
	line-height: 30px;
}

.small, small {
	font-size: 13px;
	line-height: 18px;
}