.w-CampaignListItem,
.w-StrategyListItem {
  .currency {
    white-space: nowrap;
  }

  .spendMode .actual .alert-item {
    color: $color-C2;
  }

  .impressionMode .actual .alert-item {
    color: $color-C2;
  }

  .allocation-spend-budget-remaining {
    width: 14.8%;

    .header-c {
      width: 100%;

      .spend-budget-details {
        width: 75%;

        .spend-budget-col-title {
          width: 35%;

          .title {
            padding-left: 18px;
          }

          .remaining-amount-title {
            padding-top: 4px;
            padding-left: 0;
          }
        }

        .spend-budget-col-values {
          width: 47%;

          .w-InlineEditForm {
            top: -53px;
            left: 87px;

            .w-body {
              width: 125%;
            }

            .w-foot {
              right: -16px;
            }
          }

          .data {
            float: right;
            position: relative;
            margin-top: -47px;
            margin-right: -106px;
          }

          .budget-meter {
            margin-top: -23px;
            margin-right: -106px;

            .line,
            .bar {
              height: 20px;

              .colored-bar {
                height: 20px;

                .highlight,
                .solid {
                  height: 9px;
                }
              }
            }

            .arrow {
              top: 19px;
            }

            .remaining-amount {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  .allocation-imp-budget-remaining {
    width: 17%;

    .header-c {
      width: 100%;

      .imp-budget-details {
        width: 88%;

        .imp-budget-col-title {
          width: 53%;

          .remaining-amount-title {
            padding-top: 4px;
            padding-left: 0;
          }
        }
        .imp-budget-col-values {
          width: 51%;

          .w-InlineEditForm {
            top: -53px;
            left: 118px;

            .w-body {
              width: 84%;
            }

            .w-foot {
              right: 10px;
            }
          }
          .data {
            float: right;
            position: relative;
            margin-top: -47px;
            margin-right: -106px;
          }
          .budget-meter {
            margin-top: -23px;
            margin-right: -106px;

            .line,
            .bar {
              height: 20px;

              .colored-bar {
                height: 20px;

                .highlight,
                .solid {
                  height: 9px;
                }
              }
            }

            .arrow {
              top: 19px;
            }

            .remaining-amount {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  .total-alloc-pacing {
    margin-left: 12px;
    width: 6.5%;
  }

  .spend-YDY-alloc-pacing {
    width: 9.9%;
  }

  .imp-YDY-alloc-pacing {
    width: 7%;
  }

  .alloc-spend-to-pace {
    width: 9.9% !important;
  }

  .alloc-imp-pace {
    width: 9% !important;
  }

  .spend-YDY {
    width: 6.9%;
  }

  .spend-to-pace {
    width: 12.9%;
  }

  .spend-to-date {
    width: 9.9%;
  }

  .spend-to-date.vcr-viewability {
    width: 7.9%;
  }

  .budget {
    width: 6.5%;
  }

  .remaining,
  .clicks {
    width: 10.3%;
  }

  .clicks.vcr-viewability {
    width: 6.9%;
  }

  .spent,
  .goal-value,
  .goal-actual {
    width: 9.9%;
  }

  .impressions {
    width: 8.5%;
  }

  .conversions {
    width: 7.9%;
  }

  .impressions.vcr-viewability {
    width: 8%;
  }

  .conversions.vcr-viewability {
    width: 6.9%;
  }

  .vcr,
  .ctr,
  .ctc,
  .ecpa,
  .ecpc,
  .ecpm {
    width: 5.5%;
  }

  .viewability {
    width: 6.9%;
  }

  .name {
    width: 24.8%;
    font-weight: normal;
    text-align: left;
    padding-right: 28px;

    .header-c {
      padding: 0 10px;
      border-right: 0;
    }

    .editable-content {
      overflow: hidden;
    }

    .sub-title {
      border-bottom: 1px solid transparent;
      font-size: 14px;
      float: left;
    }
  }

  .alloc-campaign-name,
  .alloc-strategy-name {
    width: 20.8% !important;
  }

  .section-c {
    .goal-details-c {
      top: -2px;
    }

    .goal-type-c {
      top: -2px;
    }
  }

  .alloc-performance {
    width: 17.8% !important;

    .titles,
    .values {
      width: 46% !important;
    }
  }

  .alloc-imp-performace {
    width: 16.8% !important;

    .titles,
    .values {
      width: 46% !important;
    }
  }

  .performance {
    width: 18.8%;

    .title {
      padding-right: 4px;
    }

    .w-InlineEditForm {
      width: 90%;
    }

    .header-c,
    .section-c {
      width: 100%;
    }

    .goal-details-c {
      width: 75%;

      .w-InlineEditForm {
        top: -6px;
      }
    }

    .goal-type {
      text-transform: uppercase;
      font-size: 11px;
      color: $color-A0;
      margin-left: 9px;
    }

    .goal-type-c {
      width: 25%;

      .goal-type,
      .strategy-type {
        padding-bottom: 4px;
        font-size: 10px;
        margin-top: 5px;
        margin-left: 15px;
        float: left;
        text-transform: uppercase;
        text-align: left;
      }

      .alloc-goal-type {
        margin-left: 30px;
      }

      .strategy-type {
        margin-top: 9px;
      }

      .w-InlineEditForm {
        .goal-type-dropdown-body {
          width: 70px;
          left: 7px;
          top: -5px;
        }

        .select {
          height: 20px;
        }

        .chzn-container {
          font-size: 10px;
          .chzn-single {
            height: 20px;
            line-height: 20px;
          }

          b {
            margin-top: 8px;
          }

          li {
            padding-left: 5px;
          }
        }

        .chzn-drop {
          margin-left: -1px;
          margin-top: 2px;
          top: 19px;
          width: 100% !important;
        }
      }
    }

    .titles {
      width: 47%;
    }

    .values {
      width: 52%;

      .editable-c,
      .actual {
        height: 21px;
      }
    }

    dl {
      @extend .box-fix;
      display: block;
    }

    .data {
      font-size: 12px;
      color: $color-A1;
      line-height: 12px;
      margin: 5px 0 0 5px;
    }

    .amount {
      float: right;
    }
  }

  .date-range {
    width: 8.7%;

    .header-c {
      float: left;
      margin-left: 12px;
      cursor: text;
    }
    .start,
    .end {
      .title {
        width: 36px;
        text-align: right;
        margin-right: 5px;
      }
      .data {
        line-height: 0.3em;
        padding: 9px 0 5px;
      }
    }

    dl {
      @extend .box-fix;
      display: block;

      dd,
      dt {
        float: left;
      }
    }

    .section-c {
      border-right: 0;
      margin-left: 24px;
    }

    .days-remaining {
      font-family: $unified-font-bold;
      border: 1px solid $color-A4;
      border-top-width: 5px;
      line-height: 23px;
      width: 24px;
      float: left;
    }

    .dates {
      float: left;
      margin: -4px 0 -8px 6px;
      display: none;

      .data {
        font-size: 11px;
        line-height: 8px;
        padding: 5px 0;
      }
    }
  }

  .strategies {
    .chart-icon {
      margin-right: -16px;
    }
  }

  .w-StrategyDrawer {
    .remaining .header {
      text-align: right;
      min-width: 70px;
    }
    .spend-to-pace .header {
      text-align: right;
      min-width: 94px;
    }

    .summary-badges {
      cursor: default;
      width: 33%;
    }
  }
}

/* A container to hold everything at compass level */
.sb-container {
  position: absolute;
  top: -5px;
  margin-left: 5%;
  float: right;
}

/* Component content styles -  */
campaigns-strategy-badges[unresolved] {
  display: none;
}

/* light dom items styling */
.sb-panel {
  width: 348px;
  height: auto;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
}

.sb-header {
  box-sizing: border-box;
  width: 100%;
  display: block;
  position: relative;
  padding: 10px;
}

.sb-header span,
.sb-section-header span,
.sb-section-content-item span {
  font-family: 'Arimo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  word-wrap: break-word;
}

.sb-header,
.sb-section-header {
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 3px;
}

.sb-section-content-item strand-icon {
  vertical-align: inherit;
  margin-right: 5px;
  position: absolute;
}

.sb-scroll-area {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px 10px;
  overflow: hidden;
  overflow-y: auto;
  height: auto;
  max-height: 250px;
}

.sb-section {
  padding-bottom: 5px;
  margin-right: 12px;
}

.sb-section:last-of-type {
  padding-bottom: 0;
}

.sb-section-header span {
  font-size: 11px;
  line-height: 17px;
  color: #fff;
}

.includeOP {
  float: right;
}

.sb-section-content-item {
  padding: 2px 0;

  strand-icon[type='include-outline'] {
    color: #17c13c !important;
    float: left;
  }

  strand-icon[type='exclude'] {
    color: #fc647b !important;
    float: left;
  }
}

.sb-icon-include {
  color: #17c13c;
}

.sb-icon-exclude {
  color: #fc647b;
}

.boldTextSummaryBadge {
  font-size: 11px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
}

.sb-section-content-item-text {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  color: #fff;
}

.sb-section-content-wrapper {
  display: block;
  padding-left: 17px;
}

/* TEMP use compass clearfix here - as this styling will live in compass */
.clearfix:after {
  content: ' ';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
