@import "mixins";

.onboarding-wrapper {
  .onboarding-head {
    padding: 27px;

    strand-header {
      vertical-align: initial;

      .strand-header {
        display: inline;
        margin-left: 1em;
      }
    }
  }

  .add-data, .bulk-actions-selector {
    z-index: 2;
  }

  .bulk-action.disabled {
    pointer-events: none;
    color: $color-A18;
  }

  #emailPixelModal {
    .header-medium.strand-header {
      padding-left: 20px;
      padding-top: 20px;
    }

    .header-small.strand-header {
      padding-left: 20px;
      padding-top: 10px;
    }

    .textarea {
      padding-left: 20px;
      padding-bottom: 20px;
    }

  }

  #exportPixelLoad {
    .header-medium.strand-header {
      padding-left: 30px;
      padding-top: 30px;
    }

    .header-small.strand-header {
      padding-left: 30px;
      padding-top: 20px;
    }

    #days {
      .header-small.strand-header {
        padding-left: 0;
      }
    }

    strand-icon {
      padding-left: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    strand-inline-box {
      padding-left: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    #export-window {
      width: 40px;
    }
    
    strand-input {
      padding-left: 5px;
      padding-top: 20px;
    }

  }

  #addPixelsToContainerModal {

    .header-medium.strand-header {
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 10px;
    }

    strand-loader {
        padding-left: 30px;
    }
     
    .addPixel-container {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 20px;
      padding-bottom: 30px;
      strand-input {
        width: 400px;
      }
    }

    strand-repeater {
      strand-repeater-row {
        width: 95%;
      }
      .remove-row {
        padding: 5px;
        position: absolute;
      }
      .add-row {
        position: absolute;
        bottom: -22px;
        right: -29px;
        width: 63px;
      }
      .clearRow {
        position: absolute;
        right: -25px;        
        color: #999;
        cursor: pointer;
      }
    }    
  }

  #editDataAttributeMapping {
    .attributes-list {
      padding: 24px 1em 1em;
      width: 700px;
      max-height: 80vh;
      overflow: auto;
      strand-icon {
        padding: 0 !important;
      }
      #caratIcon {
        padding: 0 10px 0 13px !important;
      }
      dd {
        width: 100%;
      }
      dt {
        font-family: $custom-font-semi-bold;
        font-weight: 100;
        font-size: 14px;
        color: $color-A3;
        margin-bottom: 10px;

        .kb-link {
            text-decoration: none;

            .info-tip {
                color: #525252;
            }
        }
      }

      strand-inline-box {
        padding-left: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .textarea {
        padding-left: 5px;
        padding-top: 20px;
      }

      .standard-attributes-container {
          width: 700px;
          height: 30px;
          margin-bottom: 10px;
          .standard-attribute-tooltip-holder {
              width: 4%;
              height: 30px;
              line-height: 30px;
          }

          .standard-attribute-tooltip {
              color: #9a9a9a;
          }

          .tooltip-label-holder label {cursor: default}

          .tooltip-label-holder a {cursor: pointer;}

          .standard-attributes-label {
              float: left;
              width: 16%;
              padding-right: 20px;
              margin: 0;
              font-family: PNR;
              font-size: 13px;
              font-weight: normal;
              line-height: 30px;
          }

          .standard-attributes-select {
              float: left;
              width: 80%;
              padding: 0;
              margin: 0;
          }

          .standard-attributes {
              width: 100%;
          }

          .chzn-container {
              .chzn-drop {
                  z-index: 1010;
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  border: 1px solid #ccc;
                  border-top: 0;
                  background: $color-A12;
                  box-shadow: 0 0 12px 0 rgba(0,0,0,.18);
                  border-radius: 0 0 4px 4px;
                  width: 100%;
              }


              .chzn-results {
                  max-height: 200px;
                  li {
                      -webkit-touch-callout: none;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                      font-smoothing: antialiased;
                      font-weight: 400;

                      &.highlighted {
                          color: $color-A12;
                          background-color: $color-D15 !important;
                      }
                  }
              }

              &.chzn-container-multi {

                  .chzn-choices {
                      -webkit-box-sizing: border-box;
                      -moz-box-sizing: border-box;
                      box-sizing: border-box;
                      border: 1px solid #CCC;
                      border-radius: 4px;
                      background-color: $color-A12;
                      box-shadow: inset 1px 1px 3px rgba(0,0,0,.125);
                      color: $color-A1;
                      font-family: Arimo,sans-serif;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                      font-smoothing: antialiased;
                      font-weight: 400;
                      font-size: 13px !important;
                      line-height: 15px !important;
                      overflow: hidden;
                      outline: 0;
                      cursor: text;
                      width: 100%;
                      padding-left: 5px;

                      li {
                          float: left;

                          &.search-field {
                              margin: 0;
                              padding: 0;
                              white-space: nowrap;

                              input[type="text"] {
                                  margin: 1px 0;
                                  padding: 5px;
                                  height: 15px;
                                  outline: 0;
                                  border: 0 !important;
                                  background: transparent !important;
                                  box-shadow: none;
                                  color: $color-A4;
                                  font-size: 100%;
                                  line-height: normal;
                                  border-radius: 0;
                                  width: 100%;
                                  font-family: $unified-font-regular;
                                  font-smoothing: antialiased;
                                  font-style: italic;
                                  font-weight: 400;
                              }

                              .default {
                                  color: #999;
                              }
                          }

                          &.search-choice {
                              position: relative;
                              margin-right: 5px;
                              padding: 5px 20px 5px 5px;
                              margin: 1px 1px 1px 5px;
                              height: 15px;
                              border-radius: 3px;
                              color: $color-A1;
                              background: $color-A19;
                              line-height: 15px;
                              cursor: default;

                              .search-choice-close {
                                  position: absolute;
                                  top: 7px;
                                  right: 3px;
                                  display: block;
                                  width: 12px;
                                  height: 12px;
                                  background: url('../images/chosen-sprite.png') -48px 0 no-repeat;
                                  font-size: 1px;
                              }
                          }

                          &.search-choice-focus {
                              background: $color-A6;

                              .search-choice-close {
                                  background-position: -48px -11px;
                              }
                          }
                      }
                  }

                  .chzn-results {
                      margin: 0;
                      padding: 0;
                  }

                  .chzn-drop {
                      font-family: Arimo,sans-serif;

                      .result-selected {
                          display: list-item;
                          cursor: default;
                          color: $color-A5;
                      }
                  }
              }

            &.chzn-container-active {
              .chzn-choices {
                outline: 0;
                box-shadow: 0 0 6px rgba(171,210,246,.83);
                border: 1px solid rgb(171,210,246);

                li.search-field input[type="text"] {
                    color: $color-A1 !important;
                }
              }
            }
          }
      }
      .attributes-headers {
          clear: both;
          margin-top: 1em;
      }
      .attributes-title {
        margin-bottom: 15px;
      }

      .data-key {
        padding-right: 9px;
      }

      .name {
        width: 403px;
      }

      .data-type {
        text-align: left;
      }

      .delete-loader {
        display: none;
        position: fixed;
        z-index: 1000;
        left: 50%;
        top: 50%;
      }

      .attribute {
        font: $unified-font-regular;
        font-size: 13px;
        font-weight: normal;
        display: inline-block;
        height: 29px;
        position: relative;
        left: 2px;
        -webkit-font-smoothing: antialiased;

        &.name {
          width: 400px;
        }

        &.key {
          width: 61px;
        }

        &.read-only {
          margin-right: 8px;
        }
      }
    }
    .attributes-warning {
      #viewport {
        padding: 1em;
      }
    }
  }

    .w-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: visible;
        background: #fff;
    }

    .pixel-status-inactive {
        color: $color-A18;

        .onboard-settings {
            color: $color-A2;
        }
    }

    .onboarding-message {
        display: none;
        width: 830px;
        margin: auto;
        padding: 20px;
    }

    .onboarding-actions-grid {
        height: 100%;
    }

    .actions-bar {
        min-height: 30px;
        padding: 20px 20px 0px 20px;
    }

    .pixel-type-container {
        float: left;
        width: 185px;
    }

    .search-container {
        float: left;
        margin-left: 13px;
    }

    .search-types {
        width: 150px;
    }

    .search-term {
        width: 300px;
    }

    .status-container {
        float: left;
        margin-left: 10px;
        width: 100px;
    }

    .bulk-actions-container {
        float: right;
        margin-right: 10px;
        width: 150px;
    }

    .add-pixel-container {
        float: right;
        width: 163px;
    }

    .grid-container {
        background: $color-A12;
        height: 100%;
    }

    .pixels-list {
        width: 100%;
        height: 100%;
        display: inline-block;
        padding-top: 20px;
    }

}