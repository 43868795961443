.duplicate-background {
	@include  radial-gradient($pos: 50% 50%,
		$shape-size: circle farthest-corner,
		$color-from: $color-A2,
		$color-to: $color-A0,
		$color-bg: $color-A2,
		$swidth: 193%);

	@include transparent(60);
	background-color: $color-A1;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.w-StrategyDuplicate {
	@extend .dialog-panel;
	@include calc-important("left", "50% - 415px");
	@include calc-important("top", "50% - 237px");

	bottom: 0;
	font-family: $custom-font-regular;
	height: 475px;
	left: 0;
	position: absolute !important;
	right: 0;
	width: 830px;
	z-index: 2;

	.w-head {
		.basic-info {
			margin-left: 0;
			width: 100%;
		}

		.title {
			font-family: $custom-font-semi-bold;
			font-size: 22px;
			letter-spacing: normal;
			padding-top: 5px;
			text-transform: capitalize;
		}
	}

	.form-body { padding: 22px 0 0; }
	.form-footer { margin-top: 15px; }
}

.w-StrategySettings {
	.w-body {
		.search-loader {
			left: 320px;
			position: absolute;
			top: 240px;
			width: 170px;
		}
	}

	.form-checkbox { width: 100%; }
	.name-c { width: 500px; }
	.info-marker { right: 125px; }

	.form-list {
		dt { margin-bottom: 2px; }

		.info {
			color: $color-A4;
			float: left;
			font-family: $custom-font-italic;
			font-size: 10px;
			padding-left: 19px;
			padding-top: 3px;
		}

		.strategy-duplicate-settings {
			dd {
				margin-bottom: 10px;
				float: none;
				width: 33%;
			}
			
			&.with-video {
				margin-top: 15px;
				dd {
					width: 25%;
				}
			}
		}
	}
	
	.form-input { padding-top: 8px; }
	.strategy-name { width: 470px; }
	.date-display { padding-top: 8px; }
	.strategy-duplicate-settings {
		padding-top: 8px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.disabled {
		 @include transparent();
	}
}

.duplicationDialog { float: left; }

#date-picker-duplicatestrategy {
	 @extend .datePickerCreator;
}
