.w-StrategyCreateEdit {
  margin: 0;

  .creatives-box {
    bottom: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  .strategy-targeting-loader {
    left: 45%;
    position: absolute;
    top: 45%;
  }
  
  .media-col {
    width: 260px;
  }
  
  .w-Targeting,
  .w-Supply,
  .w-Creatives {
    background-color: $color-A12;
    border-radius: 0 !important;
    margin: 1px 0 0 !important;
    width: 100%;

    .ui-tabs-nav { background-color: $color-A12; }
    .ui-tabs-panel { @extend .box-fix; }

    .creatives-pg-notificaton {
      width: 65%;
      margin-left: 14px;
      margin-top: 16px;
    }

    .column-A {
      @extend .last;
      height: 100%;
      overflow: hidden;
      position: absolute;
      width: 67%;

      .list-c { @include box-fill($top: 9px, $right: 17px, $left: 17px); }

      .list-footer {
        @include box-fill($top: auto, $bottom: 1px);
        @extend .box-fix;
        background-color: $color-D12;
        border-top: 1px solid $color-A7;
        padding: 10px 0;

        .actions { margin-right: 10px; }

        .btn-additional-sites {
          @extend .btn-secondary;
          padding: 2px !important;
          width: 135px;

          span {
            font-size: 12px;

            &.count { font-family: $custom-font-semi-bold; }
          }
        }
      }
    }

    .bins-column {
      @include box-fill($left: auto !important);
      width: 33%;
    }

    .w-PixelBundlesList .close { display: none; }

    span.strong {
      font-family: $custom-font-semi-bold;
      color: $color-A2;
    }

    .list-container { @extend .box-fix; }
  }

  .w-Creatives .column-A { background-color: $color-A12; }

  .w-Supply .tab-info {
    @include sprite-icon($left: 10, $down: 3, $width: 13px, $height: 13px);
    cursor: pointer;
  }

  .w-Supply {
    .supply-save-overlay {
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 100;
    }
  }

  .include {
    @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);

    &:hover {
      @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 10, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.partial {
      @include sprite-icon($left: 10, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active { @include transparent(30); }
    }

    &.parentSelected {
      @include sprite-icon($left: 9, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active { @include transparent(30); }
    }
  }

  .exclude {
    @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);
    margin-left: 7px;

    &:hover {
      @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.partial {
      @include sprite-icon($left: 11, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active {
        @include transparent(30);
      }
    }
    &.parentSelected {
      @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
      @include transparent(50);

      &:active { @include transparent(25); }
    }
  }

  .searchbox-holder {
    display: inline;
    float: left;
  }

  .searchbox-action-holder {
    display: inline;
    float: right;
    padding-right: 4px;
    padding-top: 5px;
  }

  .item-options {
    border-bottom: 1px solid $color-A8;
    padding-left: 4px;
    padding-top: 3px;

    label input[type="radio"] + span { font: 12px/13px $custom-font-regular; }

    .form-list {
      float: left;

      .form-radio {
        float: left;
        padding: 3px 0 0 7px;
        width: auto;
      }
    }

    .actions {
      float: right;
      /*			padding-top: 5px;
			padding-right: 4px;*/
			color: $color-A3;

			.label {
        float: left;
        font: 12px/13px $custom-font-semi-bold;
        margin-right: 5px;
        text-transform: none;
			}

			.select-action {
        color: $color-D5;
        cursor: pointer;
        float: left;
        font: 12px/13px $custom-font-regular;
        padding: 0 6px;
        text-decoration: underline;
			}

			.divider {
        color: $color-A4;
        float: left;
        font: 15px/15px $custom-font-regular;
			}
		}
	}

	.strategy-actions,
	.strategy-actions-disabled {
		float: right;
		margin: 0 4px;
	}

	.strategy-actions-disabled { visibility: hidden; }

	.settings {
		@include linear-gradient($from: $color-A12, $to: $color-A8);
		@include border-radius($radius: 3px);
    border: 1px solid $color-A6;
    cursor: pointer;
    float: right;
    height: 15px;
    padding: 5px;
    width: 15px;
	}

	.settings-icon { @include sprite-icon($left: 4, $down: 9, $width: 15px, $height: 14px); }
	.settings-open { display: block; }

  .info-box {
    display: none;
    margin-bottom: 15px;

    &.show-on-load { display: block; }
  }

  .info-box-holder {
    .info-box > .icon { margin-right: 0; }

    .message {
      p {
        line-height: 15px;
        margin-bottom: 0;
      }

      p:not(:first-child) { margin-top: 7px; }
    }
  }

  .bins-foot {
    .headers { border-top: 1px solid $color-A7; }

    dd {
      font: 12px/13px $custom-font-regular;
      text-align: right;
      text-transform: none;
      white-space: nowrap;
    }

    .icon-spinner-footer {
      position: absolute;
      right: 36px;
      top: 12px;
    }
  }

  .display-info {
    .entity-name { margin-top: 18px; }

    .goal-value-info {
      float: none;

      .amount { min-width: 70px; }
    }

    .goal-type { min-height: 17px; }
    .amount { padding-bottom: 7px; }
    .goal { padding-left: 30px; }
    .actual { padding-left: 15px; }
    .value { font: normal 9px $custom-mono-font-regular !important; }
    .strategy { float: right; }
    .campaign { margin-left: 35px; }
  }
}

@import "strategy-details";
@import "strategy-duplicate";
@import "strategy-targeting-audience";
@import "strategy-targeting-pixel";
@import "strategy-targeting-sitelist";
@import "strategy-targeting-contextual";
@import "strategy-targeting-digital";
@import "strategy-targeting-video";
@import "strategy-targeting-location";
@import "strategy-supply-open";
@import "strategy-supply-video";
@import "strategy-supply-pmpe";
@import "strategy-supply-pmpd";
@import "strategy-targeting-day-part";
@import "strategy-creatives";
@import "pmpe-modal";
@import "pixelSegment";
@import "strategy-inline-forecaster";