.w-CreateEditForm {
  padding: 16px 0 0;

  [dir='LTR'],
  .symbol {
    font-family: Arial;
    margin-right: 3px;
    text-transform: none;
  }

  :-moz-placeholder {
    color: $color-A4;
    font-style: normal;
  }

  ::-webkit-input-placeholder {
    color: $color-A4;
    font-style: normal;
  }

  .w-head {
    padding-right: 0;
  }

  .create-edit-head {
    padding-bottom: 7px;
  }

  .form-input .text {
    //dolson: TODO - remove this, unnecessary with box-sizing fix
    font-size: 13px;
    height: 15px;
  }

  .form-radio {
    padding-top: 5px;
  }

  .label-text {
    padding-top: 6px;
    padding-left: 4px;
    width: auto !important;
  }

  .inactive-input {
    background: $color-A7;
  }
  .active-inactive {
    float: right;
    .entity-status {
      margin-top: -1px;
    }
  }

  .basic-info {
    float: left;
    margin-left: 26px;
    width: 60%;

    .label {
      margin-bottom: -5px;
      font: 11px/21px $unified-font-bold;
      color: $color-D4;

      a {
        color: $color-D4;
      }
    }

    .label-flex {
      display: flex;
      white-space: nowrap;
    }

    .title {
      font: 14px/21px $unified-font-bold;
      color: $color-A2;
      height: 21px;
    }
  }

  .display-info {
    margin-left: 60%;

    .display-info-c {
      float: right;
      margin-top: 1px;
      margin-right: 20px;
      color: $color-A3;
      font-size: 10px;

      .value {
        font: 10px/10px $custom-font-bold;
      }

      .budget,
      .spend-to-pace {
        margin-left: 32px;
      }

      .goal-actual {
        margin-left: 24px;

        .actual {
          clear: left;
        }

        .value {
          position: relative;
          float: left;
          margin-left: 2px;
        }

        .goal-type {
          position: relative;
          float: left;
          padding-left: 5px;
        }
      }

      .start-end {
        width: 120px;
        margin-left: 16px;
      }

      .amount-date-label {
        position: relative;
        text-align: right;
        width: 46px;
        padding-bottom: 8px;
      }

      .active-inactive {
        width: 54px;

        .info-stack {
          float: right;
        }
      }
    }
  }

  .info-column {
    text-align: right;

    .label {
      padding-bottom: 6px;
    }

    .warning-holder {
      position: absolute;
      right: -6px;
      top: -1px;
    }

    .warning-icon {
      @include sprite-icon($left: 4, $down: 10, $width: 15px, $height: 15px);
    }
  }

  .no-permission {
    text-align: center;
    margin-top: 80px;

    .error-state {
      @include sprite-icon($left: 8, $down: 10, $width: 28px, $height: 26px);
      margin: 0 auto;
    }

    div {
      margin-top: 5px;
    }

    a {
      color: $color-D4;
    }

    .return-to-monitor {
      @extend .btn-primary;

      width: 200px;
      margin-top: 10px;
    }
  }

  .info-stack {
    padding-bottom: 8px;

    .value {
      text-align: left;
      margin-left: 40px;
    }
  }

  .entity-status {
    margin-top: 8px;

    .label {
      font-size: 11px;
      font-family: $custom-font-bold;
      color: $color-A3;
      text-transform: capitalize;
    }

    .status {
      display: none;
    }

    .label-inactive {
      display: none;
    }

    .label-active {
      display: none;
      margin-left: 5px;
    }
  }
  .active {
    .status {
      float: left;
    }

    .label-inactive {
      display: none;
    }

    .label-active {
      display: block;
    }
  }

  .inactive {
    .status {
      float: left;
    }

    .label-active {
      display: none;
    }

    .label-inactive {
      display: block;
    }
  }

  //Need to evaluate if we want to remove this or leave it as is
  .form-list {
    dd {
      @extend .column;
    }
  }

  .form-stack {
    @extend .box-fix;
    margin-bottom: 23px;
  }

  .form-stack-short {
    @extend .box-fix;
    margin-bottom: 12px;
  }

  //Need to evaluate if we want to remove this or leave it as is
  //These may be ideal for design sync-up
  .column-A,
  .column-C {
    @extend .column;
    @include width-colx($x: 3);
  }

  .column-B {
    @extend .column;
    @include width-colx($x: 4);
  }

  .column-narrow {
    @extend .column;
    @include width-colx($x: 1);
  }

  .column-AA {
    @extend .column;
    @include width-colx($x: 6);
  }

  .column-AB {
    @extend .column;
    @include width-colx($x: 7);
  }

  .column-BB {
    @extend .column;
    @include width-colx($x: 8);
  }

  .column-full {
    @extend .column;
    @include width-colx($x: 8.85);
  }

  .label,
  .info-label {
    text-transform: uppercase;
  }

  .amount-date-label {
    text-transform: uppercase;
    text-align: right;
    float: left;
    width: 37px;
    padding-right: 4px;
  }

  .create_edit_tabs {
    margin: 20px 0;
  }

  .form-list,
  .form-stack {
    .kb-link {
      text-decoration: none;
    }

    dt {
      font-family: $custom-font-semi-bold;
      font-weight: 100;
      font-size: 14px;
      color: $color-A3;
      margin-bottom: 10px;

      .kb-link {
        text-decoration: none;

        .info-tip {
          color: #525252;
        }
      }
    }

    dd {
      font-size: 13px;
      font-weight: normal;
      color: $color-A3;
      margin-bottom: 0;
    }

    .form-radio {
      width: 50%;
      white-space: nowrap;
    }
  }

  .campaign-general-form,
  .w-StrategyDetails {
    dd {
      width: 100%;
    }
  }

  .header-action {
    position: absolute;
    top: 64px;
    right: 16px;
  }

  .form-footer,
  .form-footer-style {
    .actions {
      float: right;
      margin-right: 20px;
    }

    .stage-btn-primary,
    .execute-btn-primary,
    .save-btn-primary {
      @extend .btn-primary;
      margin: 0;
    }

    .stage-btn-secondary,
    .save-btn-secondary {
      @extend .btn-secondary;
      margin: 0;
    }

    .action {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }

    .stage-action,
    .save-action,
    .execute-action,
    .saveAndContinue-action,
    .stageAndContinue-action,
    .stageAndReview-action,
    .saveAndClose-action,
    .saveAndNew-action {
      span {
        visibility: hidden;
      }
    }

    .stage-disabled,
    .save-disabled,
    .execute-disabled,
    .saveAndContinue-disabled,
    .stageAndContinue-disabled,
    .stageAndReview-disabled,
    .saveAndClose-disabled,
    .saveAndNew-disabled {
      @include transparent(50);
    }

    .cancel {
      @extend .ui-cancel;
      margin-right: 0;
    }

    .cancelNewT1 {
      background: transparent;
      border: 0 none;
      color: $color-D6;
      cursor: pointer;
      float: right;
      font-family: $unified-font-regular;
      font-size: 13px;
      margin: 9px 0 0 0;
      text-decoration: underline !important;
      text-transform: capitalize;
    }
  }

  input {
    background-color: $color-A12;

    .inactive {
      background-color: $color-A7;
    }
  }

  .optional {
    font-family: $custom-font-regular;
    font-size: 13px;
    font-style: italic;
    color: $color-A3;
  }

  .video-optional {
    margin-left: 19px;
  }

  .cap-info {
    font-family: $custom-font-italic;
    font-size: 12px;
    color: $color-A3;
    margin-left: 5px;
    margin-right: 5px;
    display: inline;
    clear: none;
    .cap-details {
      font-family: $custom-font-semi-bold-italic;
      text-transform: capitalize;
    }
  }

  labelForDynamicContent {
    text-transform: uppercase;
    font-family: $custom-font-semi-bold;
    font-weight: 100;
    font-size: 15px;
    color: $color-A3;
  }

  .advanced-settings {
    @include _border-radius($radius: 4px);

    font-family: $custom-font-semi-bold;
    color: $color-A3;
    float: right;
    background-color: transparent;
    margin-right: 6px;
    text-align: center;
    font-size: 12px;
    width: 144px;
    cursor: pointer;

    .toggle-button {
      padding: 5px;
      float: right;
      margin-right: -5px;
      a {
        text-decoration: none;
        padding-right: 5px;
      }
    }

    a {
      text-decoration: none;
    }

    .open {
      @include _border-radius($radius: 4px);
      background-color: $color-A7;
      padding: 6px 12px;

      .state-close {
        display: none;
      }

      .state-open {
        display: inline;
        margin-right: 5px;
      }
    }

    .close {
      .state-close {
        display: inline;
        margin-right: 5px;
      }

      .state-open {
        display: none;
      }
    }
  }

  .advanced-settings-outer {
    font-family: $custom-font-semi-bold;
    color: $color-A3;
    float: right;
    background-color: transparent;
    text-align: center;
    font-size: 12px;
    width: 144px;
    margin-top: 2px;
    margin-right: -10px;
    cursor: pointer;

    .toggle-button {
      padding: 5px;
      a {
        text-decoration: none;
        padding-right: 5px;
      }
    }
  }

  .error-message,
  .warning-message,
  .unsaved-message,
  .success-message {
    div {
      margin-left: 28px;
      padding: 6px 0 0 8px;
      font-size: 12px;
    }

    float: left;
    margin-top: 5px;
    width: 44%;
  }

  .error-message {
    b {
      @include sprite-icon($left: 8, $down: 10, $width: 28px, $height: 28px);
      float: left;
    }
  }

  .warning-message,
  .unsaved-message {
    b {
      @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 28px);
      float: left;
    }
  }

  .success-message {
    b {
      @include sprite-icon($left: 8, $down: 1, $width: 28px, $height: 28px);
      float: left;
    }
  }

  .progress-message {
    float: left;

    .progress-holder {
      padding-top: 10px;
      float: left;
    }

    b {
      float: left;
    }

    .message {
      margin-left: 28px;
      padding: 6px 0 0 8px;
      font-size: 12px;
      float: left;
    }
  }

  .reach-settings {
    box-shadow: inset 0 2px 2px $color-A8, inset 0 -2px 2px $color-A8;
    background: $color-A10;
    margin: -8px -33px 23px -48px;
    height: 50px;
    font-size: 13px;

    .form-input {
      padding: 17px 0 0 46px;
    }

    span {
      display: inline-block;
      float: left;
      padding-right: 4px;
    }

    .input {
      margin-top: -5px;
    }

    .min-bid {
      width: auto;
    }

    .max-bid .text,
    .min-bid .text {
      width: 90px;
    }

    .bid-aggressiveness .text {
      width: 35px;
    }
  }

  .additional-info {
    box-shadow: inset 0 2px 2px $color-A8, inset 0 -2px 2px $color-A8;
    background: $color-A10;
    margin-left: -48px;
    margin-right: -32px;
  }

  .advanced-section {
    margin: -30px -32px 20px -48px;
    max-width: 105%;
    box-shadow: inset 0 2px 2px $color-A8, inset 0 -2px 2px $color-A8;
    background: $color-A10;
    padding: 20px 0 10px;
    .advanced-section-container {
      margin-left: 48px;
      margin-right: 32px;

      .tpa-feature {
        .header {
          font-family: PNLB;
        }
        .tpa-label {
          height: 20px;
        }
        .tpa-line-spacer {
          margin: 0 0 5px;

          .TPAS-enabled-chk {
            margin-top: 5px;
          }

          .tpa-radio-group {
            float: left;
          }

          .tpas-custom-dropdown-container {
            width: 110px;
            float: left;
            padding-left: 15px;
          }

          .error {
            border-radius: 5px;
          }
        }

        #connectedIdIcon {
          color: #cacaca;
        }

        #connectedIdIcon:hover {
          color: #676767;
        }
      }

      .stl-feature {
        .header {
          font-family: PNLB;
        }
        .stl-line-spacer {
          margin: 0 0 5px;

          .error {
            border-radius: 5px;
          }
          .stl-dropdown {
            width: 200px;
          }
          .stl-message {
            margin: 8px 0 0 5px;
          }
        }
        .stl-link {
          color: #7380a9;
        }
        .stl-info {
          margin-top: 10px;
        }
        .stl-info2 {
          margin-top: 10px;
        }
        .stl-info-hide {
          display: none;
        }
        .stl-info-hide2 {
          display: none;
        }
        #suspiciousTrafficFilterIcon {
          color: #cacaca;
        }
        #suspiciousTrafficFilterIcon:hover {
          color: #676767;
        }
      }

      .repeat-merit-events {
        margin-top: 15px;
        width: 700px;

        .merit {
          width: 270px;
        }

        .minutes {
          width: 90px;
        }

        .one-merit-event-per {
          width: 50px;
          margin-left: 10px;
          margin-right: 3px;
        }
      }

      .optional-settings {
        .form-stack {
          margin-bottom: 0;
          padding: 15px 13px 16px 48px;
        }
      }
    }
    .attribution-hide {
      display: none;
    }

    .info-tip {
      opacity: 0.3;
      color: #525252;
      margin-left: 5px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .chzn-container {
    width: 100%;
  }

  .chzn-drop {
    width: 100% !important;
  }

  .w-Filters {
    padding: 0 0 30px;
  }

  .search-box {
    padding-top: 8px;
  }
}

.w-TargetValuesWipeAlert,
.w-FormUnloadAlert,
.w-WarningAlert,
.w-customAlert {
  @extend .dialog-panel;
  width: 400px !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  .icon {
    float: left;
  }

  .message {
    margin-left: 0;
  }

  .head {
    font-family: $custom-font-semi-bold;
    font-size: 22px;
    line-height: 24px;
    color: $color-A1;
    margin-left: 36px;
  }

  .message-c {
    margin-top: 23px;
  }

  .ui-dialog-titlebar {
    display: none;
  }

  .ui-dialog-content {
    padding: 0;
  }

  b {
    @include sprite-icon($left: 8, $down: 4, $width: 30px, $height: 25px);
    margin: 0;
  }

  .icon.error-icon {
    b {
      @include sprite-icon($left: 8, $down: 10, $width: 28px, $height: 28px);
    }
  }

  .save,
  .yes,
  .save-action {
    @extend .ui-submit;
    float: right;
  }

  .save-action .ui-button-text {
    visibility: hidden;
  }

  .donot-save,
  .no {
    @extend .btn-secondary;
    float: right;
    margin-right: 15px;
  }

  .cancel {
    @extend .ui-cancel;
    margin-top: 6px;
    padding-right: 0;
  }

  .question {
    margin-top: 20px;
  }
}

.assign-creative-modal {
  width: 70% !important;
  min-width: 1080px;
  padding-right: 0;
  margin-right: -20px;

  .unload-alert {
    height: 390px;
  }

  .view-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -63px;
    z-index: 2000;
  }

  .body-content {
    .head {
      font-size: 20px;
      margin-left: 0;
      margin-bottom: 3px;
      color: #333333;
    }
    .sub-head {
      font-weight: bold;
      font-size: 15px;
      margin-left: 0;
      padding-bottom: 13px;
      margin-bottom: 12px;
      border-bottom: 1px solid $color-A6;
      color: #333333;
    }
    .related-info {
      font: 11px 'PNB';
      color: #2e6c9e;
    }
    .w-CreativeBin {
      .browse {
        width: 75% !important;
        .w-DataGrid {
          margin-top: 12px !important;
        }
      }
      .bins-column {
        width: 24%;
        margin-top: 0;
        right: 0;
      }
    }
    .advertiser-name {
      display: inline-block;
    }
    .agency-name {
      display: inline-block;
    }
  }
}

.datePickerCreator {
  width: 418px;
}
