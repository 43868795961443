.w-BatchSupply {
  .column-A {

    .list-c {
      top: 20px !important;

      .icon {
        padding-right: 12px;
      }

      .column.name {
        width: 85%;
      }
    }

  }

  .bins-column {
    .include-bin {
      height: 100%;
    }
  }
}