.w-ReadonlySitelists {

	.heading-icon{
		@include sprite-icon($left: 3, $down: 4, $width: 18px, $height: 18px);
	}

	.col1{
		width: 45%;
	}
	.col2{
		width: 4%;
	}
	.col3{
		width: 9%;
	}
	.col4{
		width: 9%;
	}
	.col5{
		width: 9%;
	}
	.col6{
		width: 10%;
	}
	.col7{
		width: 6%;
	}
	.col345{
		width: 22.5%;
	}

	.table-body{
		padding: 6px 0 10px 0;
	}
}