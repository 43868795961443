.w-Chart {
  background-color: $color-A12;
  position: relative;

  .no-data {
    left: 0;
    position: absolute;
    right: 0;
  }

  .w-head {
    border: 0;
    left: 0;
    margin: 0;
    padding: 16px 0 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .selected-date {
      float: left;
      padding-top: 1px !important;
    }

    .date-picker-holder {
      float: left;
    }

    .dates {
      border: 1px solid $color-A12;
      color: $color-A3;
      font: 11px/13px $unified-font-bold;
      margin-top: -1px;
      text-align: right;
    }
  }

  .chart-series {
    .label {
      font-size: 10px !important;
    }
  }

  .chart-title-box {
    font-smoothing: antialiased;

    .chart-title {
      color: $color-A2;
      font-family: $unified-font-bold;
      font-size: 16px;
      letter-spacing: -0.1px;
      margin-top: -2px;
    }

    .chart-subtitle {
      color: $color-A2;
      font-family: $unified-font-regular;
      font-size: 11px;
      letter-spacing: 0.2px;
      margin-top: 7px;
    }
  }

  .metric-controls {
    float: right;
    font-smoothing: antialiased;
    position: absolute;
    right: 0;
    top: 27px;

    .metric-label {
      color: $color-A1;
      float: left;
      font-family: $unified-font-bold;
      font-size: 11px;
      line-height: 15px;
      margin-right: 11px;
    }

    .metric-select-c {
      float: left;
      margin-top: -8px;
    }

    .versus {
      float: left;
      font-family: $unified-font-regular;
      font-size: 11px;
      line-height: 15px;
    }
  }

  .w-chart-box {
    float: left;
    height: 220px;
    margin: 75px 0 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    .w-body {
      border-top: 0;
      border-width: 0;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .w-chart-panel {
    overflow: hidden;
    width: 100%;

    .ajax-loader {
      top: 108px;
      width: 140px;
    }
  }

  .w-chart-body {
    font-smoothing: antialiased;
    height: 220px;
    margin: 0;
    padding: 0;
    position: relative;

    text {
      direction: ltr;
      unicode-bidi: bidi-override;
    }

    .label {
      fill: $color-A2;
      font-family: $unified-font-regular-svg, $unified-font-regular;
      font-size: 9px;
      font-smoothing: antialiased;
      text-align: right;
      text-transform: none;
    }

    .left-axis-label,
    .right-axis-label,
    .x-axis-label {
      color: $color-A1;
      font-family: $unified-font-bold-svg, $unified-font-bold;
      font-size: 11px;
    }

    .x-axis-label {
      bottom: 36px;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;

      .x-axis-sublabel {
        font-family: $unified-font-regular-svg, $unified-font-regular;
        padding-left: 4px;
      }
    }

    .right-axis-label {
      @include rotate(+90deg);
      right: -108px;
    }

    .left-axis-label {
      @include rotate(-90deg);
      left: -112px;
    }

    .left-axis-label,
    .right-axis-label {
      position: absolute;
      text-align: center;
      top: 35%;
      width: 300px;
    }

    .x-axis-group-label {
      font-family: $unified-font-bold-svg, $unified-font-bold;
    }

    .change-log-markers .marker {
      @include transparent(25);
      cursor: pointer;

      .icon {
        @include sprite-icon($left: 0, $down: 13, $width: 15px, $height: 15px);
        float: left;
        margin-top: -1px;

        &.multi-marker {
          @include sprite-icon($left: 1, $down: 13, $width: 15px, $height: 15px);
          margin-left: 1px;
        }
      }

      .number {
        float: left;
        font: normal 12px/12px $unified-font-bold;
      }

      &:hover {
        @include transparent(50);
      }
      &.selected {
        @include transparent(100);
      }
    }
  }

  .w-chart-placeholder {
    height: 295px;
  }

  .chart-header {
    margin: 27px 23px 0 22px;

    .metric-icon {
      @include sprite-icon($left: 11, $down: 5, $width: 14px, $height: 12px);
      float: left;
      margin: 8px -34px 0 0;
    }

    .metric-icon2 {
      @include sprite-icon($left: 11, $down: 10, $width: 16px, $height: 11px);
      float: left;
      margin-top: 2px;
    }

    .chzn-single,
    .chzn-single-with-drop {
      @include box-shadow($swidth: 0, $scolor: $color-A12, $horizontal: 0, $vertical: 0, $blur: 0);
      background: none;
      border: 0;
      padding-left: 11px;

      span {
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 24px;
        margin-right: 18px;
      }

      div {
        top: 1px;
      }
    }

    .chzn-drop {
      @include border-radius($radius: 3px);
      border: 1px solid $color-A6;
      font: normal 11px/13px $unified-font-regular;
      position: absolute;
      right: 6px !important;
      top: 30px !important;
      width: 160px !important;
      z-index: 2 !important;

      .chzn-results {
        border: none !important;
        max-height: 208px;
        overflow-y: auto;
      }

      li {
        letter-spacing: 0.2px;
        line-height: 13px;
      }
    }

    .chzn-search {
      background-color: $color-A12;
      border-bottom: 1px solid $color-A6;
      padding: 2px 6px 2px 4px;

      input {
        background: $color-A12 url('../images/chosen-sprite.png') no-repeat 100% -22px;
        border: none !important;
        font: normal 11px/13px $unified-font-regular;
        padding-left: 2px;
        padding-right: 20px;
        width: 132px !important;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .bar {
      background-color: $color-A6;
      float: left;
      height: 20px;
      margin-top: -1px;
      width: 1px;
    }

    .no-results {
      color: $color-A4;
      font-size: 12px;
    }
  }

  .chart {
    position: relative;
    z-index: 1;
  }

  path.line {
    fill: none;
    stroke: $color-B1;
    stroke-width: 1px;
  }

  .end-axis {
    stroke: $color-A8;
  }

  circle.line {
    fill: $color-A12;
    stroke: $color-B1;
    stroke-width: 2px;
  }

  path.area {
    @include transparent(40);
    fill: none;
  }

  .axis {
    shape-rendering: crispEdges;

    line {
      stroke: $color-A8;
    }

    .minor {
      stroke: $color-A7;
      stroke-dasharray: 3, 3;
    }

    text {
      font: 11px $unified-font-regular;
    }
  }

  .x.axis {
    text {
      display: none;
    }
  }

  .performance {
    .ybar.axis {
      fill: none;

      text {
        fill: $color-F2;
      }
    }

    .ybar2.axis {
      fill: none;

      text {
        fill: $color-F1;
      }
    }

    .benchmark-tag-left {
      background-color: $color-F2;
    }
    .benchmark-tag-right {
      background-color: $color-F1;
    }
    .benchmark-ptr-left {
      border-left: 4px solid $color-F2;
    }
    .benchmark-ptr-right {
      border-right: 4px solid $color-F1;
    }
  }

  .by_hour {
    .ybar.axis {
      fill: none;

      text {
        fill: $color-F3;
      }
    }

    .ybar2.axis {
      fill: none;

      text {
        fill: $color-F12;
      }
    }

    .benchmark-tag-left {
      background-color: $color-F3;
    }
    .benchmark-tag-right {
      background-color: $color-F12;
    }
    .benchmark-ptr-left {
      border-left: 4px solid $color-F3;
    }
    .benchmark-ptr-right {
      border-right: 4px solid $color-F12;
    }
  }

  .y-label {
    font: 12px $unified-font-regular;
  }
  .label {
    font: 12px $unified-font-regular;
  }

  .line,
  .x.axis {
    .minor {
      stroke-opacity: 0.5;
    }
  }

  .x.axis path {
    display: none;
  }

  .y.axis line,
  .y1.axis line,
  .y.axis path {
    fill: none;
  }

  .chart-msg-panel {
    color: $color-A4;
    font-size: 14px;
    font: 11px $unified-font-regular;
    left: 0;
    position: absolute;
    text-align: center;
    top: 38%;
    width: 100%;

    .progress-bar {
      @include transparent(40);
      font: 11px/12px $unified-font-regular;
      height: 4px;
      margin: 0 auto;
      overflow: visible;
      text-align: right;
      width: 30%;

      .value {
        background-color: $color-A2;
        height: 3px;
        padding: 0;

        span {
          color: $color-A2;
          position: relative;
          top: -20px;
        }
      }
    }
  }

  .chzn-container {
    float: left;
    width: auto !important;

    .chzn-single {
      margin-right: -2px;
      margin-top: 3px;
      padding: 0 3px 0 8px;
    }

    .chzn-single-with-drop {
      @include border-radius($radius: $unit $unit 0 0);
    }

    .chzn-results {
      background: $color-A12;
      border: 1px solid $color-A6;
    }
  }

  .chzn-drop {
    background: transparent;
    border: 0;
  }

  .chart-type-label {
    float: left;
    line-height: 36px;
    margin-right: 8px;
    margin-top: -2px;
    text-transform: uppercase;
  }

  .right-axis-label,
  .left-axis-label {
    font-size: 10px;
    position: absolute;
    text-align: center;
    top: 35%;
    width: 300px;
  }

  .right-axis-label {
    @include rotate(+90deg);
    right: -120px;
  }

  .left-axis-label {
    @include rotate(-90deg);
    left: -120px;
  }

  .chzn-single {
    float: left;
    font-weight: normal;
  }

  .campaign-stats {
    height: 35px;
    margin: 18px 0 0 50px;

    dl {
      float: left;
      width: 17%;
    }

    dt {
      color: $color-A4;
      font-size: 9px;
      text-transform: uppercase;
    }

    dd {
      font: 17px $unified-font-regular;
      font-weight: normal;
      margin-top: 5px;
    }

    .time-period dd {
      font: 13px $unified-font-regular;
      font-weight: normal;
    }

    .ecpa,
    .ecpc,
    .ecpm {
      width: 10%;

      dt {
        text-transform: none;
      }
    }
  }

  .goal-benchmark-line {
    stroke: $color-A4 !important;
  }

  .benchmark-tag-c {
    color: $color-A12;
    cursor: pointer;
    display: inline-block;
    font: 10px/15px $unified-font-bold;
    letter-spacing: -0.2px;
    z-index: 1;
  }

  .benchmark-tag-left,
  .benchmark-tag-right {
    display: inline-block;
    height: 16px;
    overflow: hidden;
    padding: 1px 8px;
  }

  .benchmark-ptr-left,
  .benchmark-ptr-right {
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    height: 0;
    margin-top: 5px;
    width: 0;
  }

  .watermark {
    .benchmark-tag-left {
      background-color: $color-F14;
    }
    .benchmark-tag-right {
      background-color: $color-F7;
    }
    .benchmark-ptr-left {
      border-left: 4px solid $color-F14;
    }
    .benchmark-ptr-right {
      border-right: 4px solid $color-F7;
    }
  }

  .benchmark-tag-left,
  .benchmark-ptr-left {
    float: left;
  }

  .benchmark-tag-right,
  .benchmark-ptr-right {
    float: right;
  }

  .benchmark-ptr-left.dim {
    border-left-color: $color-A5;
  }
  .benchmark-ptr-right.dim {
    border-right-color: $color-A5;
  }

  .benchmark-tag-left,
  .benchmark-tag-right {
    &.dim {
      background-color: $color-A5;
    }
  }

  .goal-benchmark-label {
    .title {
      color: $color-A4;
      float: right;
    }
  }

  .loader-holder {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }

    .loader {
      left: calc(50% - 110px);
      position: absolute;
      top: calc(50% - 14px); // height of component is 28px
      width: 220px; // width of component
    }
  }
}

.no-data-chart {
  position: relative;
  text-align: center;

  .grid {
    border: 1px solid $color-A8;
    height: 200px;
    left: 66px;
    position: absolute;
    right: 66px;
  }

  .vertical {
    @include box-fill();

    .cell {
      float: left;
      height: 100%;
      width: 10%;

      &:nth-child(odd) {
        border-right: 1px dashed $color-A8;
      }
      &:nth-child(even) {
        border-right: 1px solid $color-A8;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  .horizontal {
    @include box-fill();

    .cell {
      border-bottom: 1px solid $color-A8;
      height: 33%;
      width: 100%;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .bars {
    display: inline-block;
    left: 4px;
    position: relative;
    top: 45px;
  }

  .bar {
    background-color: $color-A5;
    border: 2px solid $color-A12;
    float: left;
    height: 90px;
    position: relative;
    width: 32px;
  }

  .bar0 {
    height: 37px;
    top: 53px;
  }

  .bar2 {
    height: 59px;
    top: 31px;
  }

  .msg-c {
    color: $color-A4;
    font: normal 13px/15px $unified-font-regular;
    font-smoothing: antialiased;
    margin-top: 54px;
    padding-left: 8px;
    position: relative;
  }

  .msg {
    background-color: $color-A12;
    display: inline;
  }
}

//Tooltip
.chart-tooltip {
  padding: 5px 6px;
  text-align: left;
  font-family: $unified-font-regular;
  font-smoothing: antialiased;

  dl {
    padding: 2px;

    &.info-c {
      padding-bottom: 7px;
    }

    &.value-c {
      padding-bottom: 3px;
    }
  }

  dt {
    text-transform: none;
    color: $color-A12;
    margin-right: 2px;

    .info {
      font-family: $unified-font-bold;
    }

    .label {
      font-family: $unified-font-regular;
      text-stroke: 0.1px;
      text-stroke-color: $color-A12;
    }
  }

  dd,
  dt {
    display: inline;
  }

  dd {
    font-family: $unified-font-bold;
    font-size: 11px;
    text-transform: capitalize;
  }

  .dot,
  .dot2 {
    float: left;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
    margin: 2px 4px 0 0;
  }

  &.performance {
    .dot {
      background-color: $color-F2;
    }
    .dot2 {
      background-color: $color-F1;
    }
  }

  &.by_hour {
    .dot {
      background-color: $color-F3;
    }
    .dot2 {
      background-color: $color-F12;
    }
  }

  &.watermark {
    .dot {
      background-color: $color-F14;
    }
    .dot2 {
      background-color: $color-F7;
    }
  }
}
