.add-behavior-dialog {
	padding: 0;
	overflow: visible;

	.ui-dialog-content {
		padding: 0;
	}

	.w-AddBehavior {

		.w-box {
			width: 1100px;
			background-color: white;
			border-radius: 4px;

			.w-head {
				padding: 30px;
			}

			.w-body {
				padding: 0px 30px 30px 30px;

				.search-container {
					padding-bottom: 30px;

					&:after {
						clear: both;
						content: "";
						display: block;
					}

					strand-input {
						float: left;
						width: 400px;
					}

					strand-button {
						float: left;
						margin-left: 10px;
					}
				}

				.tree-header {
					background-color: #e0e0e0;
					padding-left: 43px;
					font: 11px/30px "AB";
					color: rgb(0, 60, 113);
				}

				.tree-container {
					height: 300px;
					overflow: auto;

					table {
						width: 100%;

						tbody {

							tr {
								border-bottom: 1px solid lightgray;

								&:hover {

									background-color: #d6eefa;
								}

								td {
									padding: 10px 0px 10px 10px;

									&.checkbox {
										width: 35px;
									}

									.empty-row {
										line-height: 29px;
										margin-left: 58px;
										vertical-align: -1px;
										font-style: italic;
									}

									.cancel-label {
										line-height: 29px;
										font: 13px/29px "Arimo";
										margin-right: 10px;
										vertical-align: -1px;
									}

									.loading-row-spinner {
										display: none;
										&.loading {
											display: inline-block;
											margin-right: 7px;
										}
									}

									.name {
										font: 13px/16px "Arimo";

										&:hover {
											cursor: pointer;
										}
									}

									.open-close {
										width: 10px;
										height: 10px;
										margin-right: 15px;
										display: inline-block;

										&:hover {
											cursor: pointer;
										}

										&.loading {
											display: none;
										}

										.triangle {
											width: 0;
											height: 0;

											&.triangle-down {
												border-left: 5px solid transparent;
												border-right: 5px solid transparent;
												border-top: 5px solid black;
												margin-top: 3px;
											}

											&.triangle-right {
												border-top: 5px solid transparent;
												border-bottom: 5px solid transparent;
												border-left: 5px solid black;
												margin-left: 3px;
											}
										}
									}
								}

								&.filtered-out {
									display: none;
								}

								&.not-showing {
									display: none;
								}

							}
						}
					}
				}
			}
		}
	}
}
