.w-StrategyList {
	background: $color-A12;

	.name {
		margin-left: 31px;
		text-align: left;
	}

	.section .data {
		text-align: right;
	}

	.read-only-view {
		.read-only-icon {
			right: 0;
			top: 9px;
		}
	}

	.w-foot {
		margin-top: 5px;
	}

	.strategy-item {
		margin: 0;
		padding: 0;
		border: none;
		position: relative;
		background: $color-A12;

		&:hover {
			background: $color-D7;
		}

		.data,
		.symbol {
			color: $color-A13;
			font-size: 10px;
		}

		.symbol {
			margin-right: 3px;
		}
	}

	.section  {
		.disabled {
			@include transparent();
			span {
				@include transparent();
			}
		}
	}

	.strategy-headers {
		background: $color-A6;
		height: 23px;

		.header {
			color: $color-A2;
		}

		.expander-part {
			background: url("http://compass.dev/css/../images/mm_sprite.png") -140px -308px no-repeat;
			z-index: 1;
			position: absolute;
			margin-left: 0px;
			margin-top: 8px;
			left: -3px;
			&.expanded {
				left: -5px;
			}
			&::before {
				// This psuedo element exists solely for making the target
				// for the strategy expansion arrow larger.
				// border: 1px solid orange;
				height: 21px;
				width: 40px;
				content: " ";
				display: block;
				top: -8px;
				position: absolute;
				left: -28px;
			}
		}

		.icon-arrow {
			@include sprite-icon($left: 5, $down: 11, $width: 12px, $height: 12px);
		}

		.expanded.icon-arrow {
			@include sprite-icon($left: 4, $down: 11, $width: 12px, $height: 12px);
		}

		.loading {
			background: none !important;
		}

		.name-part {
			@include calc("width", "76% - 68px");
		}

		.type-part {
			width: 25px;
			float: right;
			text-align: left;
		}

		.id-part {
			width: 32px;
			float: right;
			text-align: right;
			padding-right: 12px;
		}

		.id-label {
			padding-right:40px;
		}

		.pacing-label {
			width: 15.8%;
		}

		.ydy-label {
			width: 10.1%;
		}

		.bid-price-label {
			width: 10%;
		}
		.imp-pacing-label {
			width: 18%;
		}

		.imp-ydy-label {
			width: 7%;
		}

		.imp-bid-price-label {
			width: 9.5%;
		}
	}

	.days-left {
		.header-c {
			margin-right: -40px;
			margin-left: 40px;
		}
	}

	.column {
		.header-c {
			white-space: nowrap;
		}
	}

	.alloc-header-c {
	    margin-left: 28px !important;
	}
}
