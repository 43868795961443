data-item {
    .group-name {
        font: 12px/14px $custom-font-bold;
        color: $color-D10;
        letter-spacing: .3px;
    }

    .item {
        @extend .stack;
        position: relative;
        padding: 10px 20px;
        font: 11px/14px $unified-font-regular;
        cursor: pointer;
        border-bottom: 1px solid $color-A11;

        &:hover {
            background-color: $color-E8;
        }
    }
}

.w-DataGrid {
    background-color: $color-A12;
    @extend data-item;

    &.expandable {
        overflow-x: auto;
        overflow-y: hidden;

        .content {
            float: left;
            overflow-x: hidden;
            overflow-y: hidden;
            width: 100%;
        }

        .content-c {
            display: inline-block;
        }

        .headers {
            float: left;
        }

        .headers, .item {
            white-space: nowrap;
            min-width: 100%;
        }

        .header, .detail {
            float: none;
            display: inline-block;
            white-space: normal !important;
            vertical-align: top;
        }
    }

    .group-name {
        margin: 20px 0 5px 60px;
    }

    .w-head {
        position: relative;
        padding: 10px 10px 0 0;
    }

    .w-body {
        height: 100%;
    }

    .headers {
        background: $color-A7;
        padding: 7px 0 6px 20px;

        .info-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: 0;
        }

    }

    .header {
        color: $color-A3;
        font: 10px/14px $custom-font-semi-bold;
        text-transform: uppercase;
    }

    .date-label {
        text-transform: capitalize;
    }

    .selected {
        background-color: $color-D13;
        border-top: 1px solid $color-D2;
        border-bottom: 1px solid $color-D2 !important;
        margin-top: -1px;
    }

    .children {
        .no-results {
            margin-top: 10px;
        }
    }

    .drag-selected > .item,
    .drag-selected > .item > .item-row {
        background-color: $color-E7;
    }

    .parent-selected {
        background-color: $color-D14;
        .item-row {
            background-color: $color-D14;
        }
    }

    .content {
        background: $color-A12;
    }

    .content-holder {
        position: relative;
        overflow: hidden;
        height: 100%;
    }

    .no-results {
        font: 12px/18px $custom-font-regular;
        color: $color-A4;
        width: 100%;
        margin-top: 125px;
        text-align: center;
    }

    .active {
        color: $color-A3;
    }

    .arrow {
        margin: 3px 0 0 0;
        position: relative;

        .icon-arrow {
            @include sprite-icon($left: 5, $down: 9, $width: 14px, $height: 11px);
            margin-left: 11px;
        }

        .icon-arrow.expanded {
            @include sprite-icon($left: 1, $down: 0, $width: 14px, $height: 11px);
            padding-top: 2px;
            margin-bottom: -2px;
        }

        .icon-arrow.hide {
            opacity: 0;
        }

        &.loading {
            .icon-arrow {
                visibility: hidden;
            }

            .icon-spinner {
                position: absolute;
                top: 3px;
                left: 12px;
            }
        }

        &.expanded {
            .icon-arrow {
                @include sprite-icon($left: 1, $down: 0, $width: 15px, $height: 12px);
            }
        }
    }

    .page-controls {
        padding: 10px 10px 3px;
        color: $color-A4;
        font-size: 12px;
        font-style: italic;

        .count {
            float: left;
        }

        .more-control {
            width: 60px;
            margin: 0 auto;
        }

        .more {
            @extend .btn-primary;
            padding: 1px;
            width: 60px;
        }

        .list-size {
            float: right;

            .label {
                display: block;
                float: left;
                margin-right: 10px;
            }

            .chzn-container {
                display: block;
                float: right;
            }
        }
    }

    .drag {
        @extend .grab_cursor;

        position: absolute;
        right: 0;
        width: 45px;
        height: 60%;
        top: 0;
        padding-top: 10px;

        .icon {
            @include sprite-icon($left: 7, $down: 5, $width: 14px, $height: 14px);
            @include transparent(30);

            &:hover {
                @include sprite-icon($left: 7, $down: 5, $width: 14px, $height: 14px);
                @include transparent(100);
            }

            &:active {
                @include transparent(100);
            }
        }
    }

    .icons {
        position: absolute;
        width: 40px;
        margin-left: 10px;
        left: 0;
        z-index: 1;
    }

    .icon {
        float: left;
        .icon-spinner {
            margin-top: -10px;
            margin-left: 5px;
        }

        .show {
            opacity: 1;
        }
        .hide {
            opacity: 0;
        }
    }

}
