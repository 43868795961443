[class^="icon-"] {
	vertical-align: text-top;
}

.icon-opac {
	@include transparent($percent: 60);

	&:hover {
		@include transparent($percent: 100);
	}
}

.icon-edit {
	@include sprite-icon($left: 9, $down: 1, $width: 18px, $height: 18px);
}

.icon-close {
	@include sprite-icon($left: 7, $down: 0, $width: 18px, $height: 18px);
}

.icon-campaign {
	@include sprite-icon($left: 6, $down: 10, $width: 15px, $height: 15px);
}