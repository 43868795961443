@import 'mixins';

.learn-more-tool-tip-content {
  margin-left: -8px;
  margin-top: -6px;

  .tipsy-inner { padding: 4px; }
}

.launch-app-tool-tip-content {
  margin-left: -3px;
  margin-top: -5px;

  .tipsy-inner { padding: 4px; }
}

.app-list-container { margin-right: 22px; }

.custom-sort-marker {
  display: none;
  margin: 13px 0 0 318px;
  position: absolute;
  z-index: 99;

	.arrow-left {
    border-bottom: 8px solid transparent;
    border-right: 8px solid $color-A20;
    border-top: 8px solid transparent;
    float: left;
    height: 0;
    margin-top: 7px;
    width: 0;
  }

  .marker-label {
    background-color: $color-A20;
    border-radius: 4px;
    color: $color-A12;
    float: left;
    font-family: $unified-font-regular;
    font-size: 11px;
    margin-top: -30px;
    padding: 9px;

    .title {
      font-family: $unified-font-bold;
      padding-bottom: 4px;
    }

    p {
      clear: both;
      float: left;
      margin: 0;
    }

    .hide-marker {
      padding-top: 12px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

#appHeight {
  box-sizing: border-box;
  height: 100%;

  .w-AppList-tabset { background-color: $color-D12; }
  .w-Admin { padding-top: 26px; }

  .main-column {
    box-sizing: border-box;
    height: 98%;
    min-height: 600px;
  }

  ._wrapper,
  .routed-view:not(:empty) {
    box-sizing: border-box;
    height: 99%;
   }

  .w-body {
    background: #fff;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    top: 64px;
  }

  .app-window { top: 0; }

  .w-box {
    box-sizing: border-box;
    min-height: 100%;
   }

  .w-App {
    margin-bottom: 0;

    strand-icon.exit-app {
      right: -10px;
      top: -10px;
    }
  }

  .list-search ._wrapper { min-height: 0; }
}

.exit-app-modal {
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  strand-header {
    margin-bottom: 14px;

    span { font-size: 18px; }
  }

  .content-top { padding: 20px; }

  strand-icon {
    color: $color-E2;
    margin-right: 20px;
  }

  .hideButton { display: none; }

  #modalCheckbox {
    left: 15px;
    position: absolute;
    top: 20px;
  }
}

.t1-apps-container {
  .optional {
    color: #525252;
    font-family: $unified-font-regular;
    font-size: 13px;
    font-style: italic;
  }

  .app-name { float: left; }

  .organization_id {
    width: 430px;

    .single-org {
      font-family: $custom-font-regular;
      font-size: 13px;
      font-weight: normal !important;
    }

    .single-org-input { display: none; }
  }

  .app-name-counter {
    display: inline-block;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 7px;
  }

  .grid-header-container {
    .title-bar {
      margin-left: 24px;
      padding-bottom: 38px;
      strand-button { margin-top: -7px; }
    }

    .add-app {
      color: $color-D16;
      display: none;
      margin-right: 25px;
    }
  }

  #date-picker-apps {
    right: 140px;
    width: 420px;
  }

  .overlay-view .tab-level-1, .overlay-view .tab-level-2 { top: 0; }
  .overlay-view { top: 80px; }
  .w-Admin .title { padding-top: 0; }

  .date-picker-holder {
    float: right;
    margin-right: 26px;
    margin-top: 15px;
    position: relative;
    z-index: 999;

    .onhover {
      .start-date,
      .end-date {
        @extend .isEditable;
      }
    }

    .controls {
      float: right;
      text-align: left;
    }

    .title {
      float: left;
      font: 12px/14px $custom-font-regular;
      margin-left: 24px;

      .select-line-data,
      .select-bar-data {
        width: 19em;
      }
    }

    .selected-date {
      padding: 4px 0 0;
      position: relative;
    }

    .select-dates {
      margin-left: 12px;
      width: 132px;

      .chzn-single {
        margin: 3px 0 0;
        padding: 0 0 0 7px;
        width: 132px;
      }

      .chzn-drop { width: 100% !important; }
    }

    .dates {
      float: left;
      font: 13px/30px $custom-font-regular !important;
    }
  }

  .tooltip-icon {
    background-color: $color-A23;
    display: inline-block;
    height: 15px;
    position: relative;
    width: 15px;
    z-index: 9999;
  }

  .grid-header-section {
    position: relative;
    top: 10px;
  }

  .loader-holder, .app-loader-holder {
    height: 28px;
    left: 40%;
    position: absolute;
    top: 52%;
    width: 250px;
    z-index: 2;
  }

  .loader-container {
    height: 24px;
    left: 50%;
    margin: -70px 0 0 -170px;
    position: absolute;
    top: 230px;
    width: 150px;
  }

  .search-loader {
    width: 125px;

    .spinner {
      left: 5px !important;
      margin: 0;
      padding: 0;
      top: 8px !important;
    }
  }

  .loader-text { margin-left: 24px; }

  .chart-holder {
    height: 270px;
    position: relative;
    width: 100%;

    ._wrapper { min-height: 0; }
    strand-header { margin: 26px 0 20px 22px; }

    .no-data-chart {
      top: 0;
      position: absolute;
      width: 100%;

      .app-icon { color: $color-A7; }
    }

    .chart-main-container { height: 260px; }

    .chart-secondary-container {
      height: 200px;
      margin: 0 5px;
      position: absolute;
      top: 60px;
      width: calc(100% - 10px);
    }

    #apps-chart { height: 100%; }

    // makes legend scrollable
    .amcharts-legend-div {
      overflow-y: auto !important;
      max-height: 160px;
    }
  }

  .grid-container {
    min-height: 1000px;

    .no-data-grid {
      left: 45%;
      margin-top: 70px;
      position: absolute;
      top: 42px;
      z-index: 1;
    }

    .deploy-status-icon {
      color: $color-A3;
      left: 48.9%;
      top: 23px;
      width: 15px;
      z-index: 1;
    }
  }

  .grid-holder {
    position: relative;
    strand-checkbox { visibility: hidden; }

    #data-grid {
      min-height: 1000px;
      overflow: visible;
      width: 100%;

      ._mm_column.checkbox {
        visibility: hidden;
        width: 0;
      }

      div[field="name"] {
        line-height: 19px;

        span { padding-right: 12px; }
      }

      .settings-icon {
        color: $color-A3;
        cursor: pointer;
      }

      strand-grid-column.status { padding-left: 30px; }
    }

    .app_color {
      height: 16px;
      left: -10px;
      line-height: 16px;
      position: absolute;
      width: 16px;

      strand-icon {
        color: $color-A12;
        margin-left: 2px;
      }
    }

    div[field="status"], div[field="deploy_status"] { text-transform: capitalize; }
    div[field="status"], div[field="actions"] { padding-left: 20px; }

    .app-grid-status .rejected-app-tooltip {
      text-transform: none;
      white-space: normal;

      strong {
        display: block;
        margin-bottom: 8px;
      }

      .reject-all {
        cursor: pointer;
        margin-top: 8px;
        text-decoration: underline;
      }
    }

    div[field="total_views"] { padding-right: 24px; }
    div[field="version_id"] { padding-right: 38px; }

    .status-live, .status-offline, .status-beta, .status-pending {
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin: 0 5px 0 0;
      width: 10px;
    }

    .status-rejected {
      color: $color-C1;
      cursor: pointer;
      margin-right: 5px;
      margin-top: -1px;
    }

    .status-live, .status-beta { background-color: $color-A21; }
    .status-offline { background-color: $color-A18; }
    .status-pending { background-color: $color-E2; }
    .app_type_align { text-align: left; }

    strand-grid-column {
      margin-top: 1px;
      padding: 10px;
    }

    #availability-icon { color: $color-A23; }
  }
}

.apps-spinner-container {
  width: 235px;
  margin: 0 auto;

  .spinner {
    left: 0 !important;
    top: 9px !important;
  }

  span {
    color: $color-A3;
    font-family: $unified-font-regular;
    font-size: 14px;
    margin-left: 18px;
  }
}

.apps-header {
  color: $color-A1;
  font-family: $unified-font-bold;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: normal;
}

.w-Apps,
.t1-apps-container {
  .app-loader-holder {
    display: none;
    height: 28px;
    left: 45%;
    position: fixed;
    top: 50%;
    width: 180px;
    z-index: 2;
  }

  strand-modal.terms-modal {
    min-width: 800px;

    strand-checkbox { visibility: visible !important; }

    p.main-text {
      color: #333;
      display: inline-block;
      font-family: $unified-font-regular;
      font-size: 13px;
      font-smoothing: antialiased;
      font-weight: 400;
      line-height: 15px;
    }

    p.spacer {
      clear: both;
      display: inline;
      position: relative;
      top: 1px;
    }

    p.header-text {
      font-family: $unified-font-bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    #terms-link-alt,
    #terms-link {
      color: $color-A20;
      cursor: pointer;
      font-family: $unified-font-regular;
      font-size: 13px;
      font-smoothing: antialiased;
      font-weight: 400;
      line-height: 15px;
      text-decoration: underline;
    }

    #terms-link-alt {
      display: inline-block;
      margin-left: -8px;
      position: relative;
      top: 1px;
    }

   .column-a {
      box-sizing: border-box;
      padding: 30px;
      width: 100% !important;

      .terms-text-container {
        margin-bottom: 22px;

        .margin-top-10 { margin-top: 10px; }

        .wrap-link {
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
      }

      .billing-checkbox {
        position: relative;
        top: 7px;
      }

      .checkbox-spacer {
        display: block;
        height: 22px;
      }
    }
  }
}

.w-Apps {
  padding: 0;
  position: absolute !important;
  width: 100%;

  .w-head {
    background: linear-gradient($color-A1, $color-D12);
    height: 32px;
    padding: 25px 0 15px;

    h1 {
      @extend .apps-header;
      font-family: $unified-font-bold;
    }
  }

  .ribbon-holder {
    margin: 0;

    .list-filters { left: 0; }
  }

  .command-ribbon {
    background-color: $color-A1;
    display: block;
    height: 39px;
    margin-top: -1;
    width: 100%;
  }

  .ribbon-strip {
    margin: 0;
    padding: 0;
    position: relative;
    zoom: 1;

    .ribbon-nav {
      border-bottom: 1px solid $color-A7;
      border-radius: 0;
      cursor: default;
      height: 48px;
      margin: 0;
      padding: 0 0 0 16px;
      position: relative;

      .search-filter {
        float: right;
        font-style: normal;
        margin-right: 15px;
        margin-top: 13px;
        width: 300;
      }

      > li:first-child {
        font: 12px/14px $unified-font-bold;
        margin-right: 10px;
      }

      > li:last-child {
        float: right;
        margin-top: 0;
      }

      > li {
        float: left;
        margin-right: 20px;
        margin-top: 17px;

        span {
          color: #323232;
          font: 12px/14px $unified-font-regular;
          text-transform: capitalize;
        }
      }

      > li.sort-by {
        margin-left: 8px;
        margin-top: 19px;
      }

      > li.sort-by span {
        font: 12px/14px $unified-font-bold;
      }

      > li.sort-by span:hover {
        cursor: default;
        text-decoration: none;
      }

      > li span:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      li.selected span {
        text-decoration: underline;
      }
    }
  }

  .basic-info {
    float: left;
    margin-left: 26px;
    width: 45%;
  }

  strand-modal.learn-more-modal,
  strand-modal.learn-more-modal-labs {
    min-width: 800px;

    .column-b {
      float: left;
      margin-right: 36px;
      margin-top: 24px;
      width: 304px;
    }

    h3 {
      letter-spacing: -1px;
      line-height: 23px;
      padding-bottom: 3px;
    }

    p { line-height: 16px; }
    hr { margin-left: -1px; }

    .buttons {
      background-color: $color-D12;
      font-size: 14px;
      height: 55px;
      margin-top: -2px;
      width: 100%;

      .buttons-c {
        float: right;
        margin-right: 24px;
        margin-top: 15px;
        padding: 5px;

        .launch {
          @extend .btn-primary;
          float: left;
          height: 12px;
          margin-top: -5px;
          width: 76px;

          div { margin-top: -2px; }
        }

        .close {
          color: $color-D1;
          float: left;
          font-family: $unified-font-regular;
          margin-right: 12px;
          text-decoration: underline;

          a:hover { cursor: pointer; }
        }
      }
    }

    .content-top .modal-header {
      border-bottom: 1px solid #c3c3c3;
      height: 68px;
    }

    .app-icon {
      float: left;
      height: 90px !important;
      margin-left: 32px;
      margin-top: 20px;
      width: 88px;
    }

    strand-header.title {
      margin-left: 14px;
      margin-top: 25px;
    }

    .kb {
      color: $color-D1;
      float: right;
      font-family: $unified-font-regular;
      font-size: 12px;
      margin-right: 35px;
      margin-top: 29px;
    }
  }

  .columns {
    clear: both;
    height: 77%;
    margin-left: 32px;
    overflow: auto;
  }

  .column-a {
    float: left;
    margin-top: 24px;
    width: 385px;

    ul {
      margin-top: 4px;
      padding-left: 16px;
      width: 85%;
    }

    li {
      font-family: $unified-font-regular;
      font-size: 12px;
      line-height: 16px;
      list-style-type: disc !important;
      margin-bottom: 3px;
    }

    h3 {
      color: $color-A2;
      font-family: $unified-font-bold;
      font-size: 14px;
      line-height: 1.2;
      margin-top: 5px;
    }

    p {
      color: $color-A2;
      font-family: $unified-font-regular;
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 2px;
    }
  }

  ul { margin-top: 10px; }

  .header {
    color: $color-A2;
    font-family: $unified-font-bold;
    font-size: 14px;
  }

  .favorites-icon.selected {
    @include sprite-icon($left: 1, $down: 4, $width: 28px, $height: 28px);
  }

  .favorites-icon.not-selected {
    @include sprite-icon($left: 0, $down: 4, $width: 28px, $height: 28px);
  }

  .single-favorite .favorites-icon {
    margin-left: -8px;
    margin-top: -9px;
  }

  .all-favorites {
    margin-top: 10px;

    .favorites-icon {
      float: left;
      margin-top: 16px;
    }

    span {
      color: $color-A4;
      float: right;
      font-family: $unified-font-regular;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      margin-right: -20px;
      margin-top: 18px;
    }
  }

  .no-results {
    margin: 0 auto 64px;
    padding-left: 12px;
    padding-top: 40px;
    width: 500px;
    word-break: break-word;

    .warning-msg {
      font-family: $unified-font-regular;
      font-weight: 400;
      line-height: 20px;
      margin-left: 42px;
      margin-top: 0;
    }

    .warning-icon {
      @include sprite-icon($left: 2, $down: 10, $width: 56px, $height: 50px);
      float: left !important;
      margin-right: 10px !important;
    }

    .msg-header {
      color: $color-A4;
      font-family: $unified-font-bold;
      font-size: 15px;
    }

    .msg-subheader {
      color: $color-A5;
      font-size: 13px;
      margin-top: 11px;
    }

    .warning-msg .strand-warning-icon {
      float: left !important;
      margin-right: 10px !important;
      top: 1px;
      color: $color-E2;
    }
  }

  .spinner-container {
    @extend .apps-spinner-container;
    height: auto;
  }

  .w-Filters input {
    float: right;
    font-style: normal;
    width: 253px;
  }

  hr {
    background-color: $color-A5;
    color: $color-A5;
    height: 1px;
  }

  .selection-item {
    color: $color-D11;
    font-family: $unified-font-regular;
    font-size: 12px;
    line-height: 24px;
    padding-left: 6px;
  }

  .selection-item.selected {
    background-color: $color-D13;
    color: $color-A1;
  }

  .selection-item:hover {
    background-color: $color-D15;
    color: $color-A12;
    cursor: pointer;
    font-family: $unified-font-bold;
   }

  .app-column-a {
    float: left;
    width: 184px;
  }

  .app-column-b-c {
    background-color: #ffffff;
    padding-left: 8px;
  }

  .app-column-b {
    margin-right: 4px;
    margin-top: 52px;

    li:hover { cursor: pointer; }

    #sortable li:hover {
      cursor: -webkit-grab;
      cursor: grab;

      .app-title { cursor: pointer; }
      .learn-more-icon { cursor: pointer; }
    }

    #sortable  li:active {
      cursor: move;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    #sortable li.ui-sortable-helper {
      cursor: move;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    .last-used-message {
      margin: 0 auto;
      width: 270px;
    }

    #lab-notification {
      position: relative;
      margin-left: 13px;
      margin-bottom: 8px;
      width: 97%;
    }

    #lab-notification .box { width: 100%; }

    #terms-link {
      color: $color-A20;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      line-height: 18px;
      text-decoration: underline;
    }

    .apps-list {
      overflow: auto;
      padding-top: 26px;
    }

    .last-used-container {
      margin-top: -10px;
      margin-bottom: 22px;
      overflow: hidden;
    }

    .lab-list {
      padding-top: 16px !important;

      .lab-space .recently-used-title { margin: -4px 0 20px 14px; }
    }

    .extra-apps { margin-top: 15px; }

    .recently-used-title {
      font-family: $unified-font-bold;
      font-size: 15px;
      margin: -17px 0 20px 14px;
    }

    .view-actions {
      float: none;
      margin: 7px 0 -6px 9px;
      position: relative;
      z-index: 9;

      .view-actions-c {
        @extend .box-fix;
        padding: 0;
        position: absolute;

        .action {
          cursor: pointer;
          float: left;
        }
      }
    }

    .list-controls {
      .action {
        @include linear-gradient($from: $color-A12, $to: $color-A8);
        border: 1px solid $color-A5;
        padding: 5px 7px 4px;
      }

      .selected {
        @include linear-gradient($from: $color-A4, $to: $color-A6);
      }
    }

    .list-controls-c {
      margin-top: -2px;

      li:first-child {
        @include border-radius($radius: 2px 0 0 2px);
        height: 14px;
        padding-top: 5px;
        width: 14px;
      }

      li:last-child {
        @include border-radius($radius: 0 2px 2px 0);
      }
    }

    .view-all {
      @include sprite-icon($left: 6, $down: 10, $width: 14px, $height: 14px);
      left: 9px;
      position: absolute;
      top: 7px;
    }

    .view-favorite {
      @include sprite-icon($left: 6, $down: 11, $width: 14px, $height: 14px);
      z-index: 9999;
    }
  }

  .app-item {
    @include rounded-shadow-box($shadow-border: 1px, $border-radius: 0, $border-color: #cccccc, $shadow-color: $color-A5);
    border: 1px;
    float: left;
    height: 261px;
    margin: 0 14px 30px;
    overflow: hidden;
    padding: 12px;
    width: 176px;

    .learn-launch {
      float: right;
      margin-right: -11px;
      margin-top: -18px;
      width: 30px;
    }

    .app-icon {
      height: 175px !important;
      margin: 0 0 12px;
      width: 175px;
      overflow: hidden;
      position: relative;

      .flag-wrapper {
        height: 100px;
        position: absolute;
        right: -15px;
        top: 0;
        width: 100px;
      }

      .flag {
        color: $color-A12;
        display: block;
        font-family: $unified-font-bold;
        font-size: 11px;
        height: 16px;
        margin-top: 13px;
        padding-top: 5px;
        text-align: center;
        text-transform: uppercase;
        transform-origin: center bottom;
        transform: rotate(45deg);
        width: 110px;
      }

      .new { background: $color-F1; }
      .updated { background: $color-F7; }
      .beta { background: $color-F13; }
    }

    .info {
      float: left;
      width: 100%;

      h2 {
        font-family: $unified-font-bold;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 11px;
        overflow: hidden;
        padding: 0 0 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-size: 11px;
        line-height: 14px;
      }

      a {
        color: $color-A20;
        display: inline-block;
        float: right;
        text-decoration: underline;
      }
    }

    .launch {
      padding: 5px;

      &:hover { cursor: pointer; }
    }

    .launch-icon {
      @include sprite-icon($left: 10, $down: 11, $width: 4px, $height: 4px);
      height: 4px;
      margin-left: -18px;
    }

    .learn-more-icon {
      bottom: 10px;
      color: $color-A20;
      display: inline-block;
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      right: 10px;
      text-decoration: underline;
    }

    .learn-more {
      float: right;
      margin-top: -13px;
      padding-left: 5px;

      &:hover { cursor: pointer; }
    }

    .invisible { opacity: .5 !important; }
  }
}

.new-app-modal {
  margin: 30px;
  width: 500px;

  .new-app-title {
    font-size: 12px;
    font-family: $unified-font-bold;
    margin-bottom: 25px;
  }

  dl {
    margin-bottom: 15px;

    dt {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}

.w-App {
  background: $color-A12;
  border: 1px solid $color-A5;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 15px;
  padding: 0;

  .header {
    float: left;

    h1 {
      @extend .apps-header;
      font-family: $unified-font-bold;
    }
  }

  .exit-app {
    color: $color-A0;
    background: $color-A12;
    border-radius: 22px;
    cursor: pointer;
    height: 22px;
    opacity: .7;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 22px;
  }

  .w-app-c {
    display: block;
    height: 100%;
    width: 100%;
    min-height: 560px;
  }

  .spinner {
    float: left;
    padding-top: 2px;
  }

  .loader-text { margin-left: 35px; }

  @media (max-height: 700px) and (max-width: 1180px) {
    .app-loader-c {
      left: 560px;
      position: fixed;
      top: 50%;
    }
  }

  @media (max-height: 700px) and (min-width: 1600px) {
    .app-loader-c {
      left: 770px;
      position: fixed;
      top: 50%;
    }
  }

  @media (max-height: 700px) and (min-width: 1180px) and (max-width: 1600px) {
    .app-loader-c {
      left: 45%;
      position: fixed;
      top: 50%;
    }
  }

  @media (min-height: 701px) {
    .app-loader-c {
      left: 45%;
      position: absolute;
      top: 45%;
    }
  }
}
