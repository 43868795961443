.w-PixelBundlesFilter {

  .select {
	width: 367px;
  }

  .chzn-container {
	width: 100%;
  }

  .chzn-drop {
	width: 100% !important;
  }

  .chzn-single {
	span {
	  overflow: hidden;
	  text-overflow: ellipsis;
	}
  }

  .extra-link {

	&:hover {
	  font-family: $custom-font-semi-bold;
	  width: 72px;
	}
  }
}