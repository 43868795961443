@import "mixins";

.tooltip-details {
	display: none;
}

.tipsy {
	font-size: 11px;
	position: absolute;
	z-index: 100000;
}

.tipsy-inner {
	@include _border-radius($radius: 2px 2px 2px 2px);
	@include _box-shadow($shadow: 1px 1px 2px 0px $color-A5);
	background-color: $color-A1;
	color: $color-A12;
	padding: 2px;
	text-align: left;
	white-space: nowrap;

	dl {
		padding: 5px;
	}

	dt {
		text-transform: uppercase;
		color: $color-A6;
		margin-right: 2px;
	}

	dd,dt {
		display: inline;
	}

	dd {
		font-family: $unified-font-bold;
		font-size: 12px;
	}
}

/* Uncomment for shadow */
/*.tipsy-inner { box-shadow: 0 0 5px $color-A1000; -webkit-box-shadow: 0 0 5px $color-A1000; -moz-box-shadow: 0 0 5px $color-A1000; }*/

.tipsy-arrow {
	position: absolute;
	width: 0;
	height: 0;
	line-height: 0;
	border: 12px dashed $color-A1;
}

.tipsy-arrow-c {
	@extend .box-fix;
	height: 12px;
}

/* Rules to colour arrows */
.tipsy-arrow-n {
	border-bottom-color: $color-A1;
}

.tipsy-arrow-s {
	border-top-color: $color-A1;
}

.tipsy-arrow-e {
	border-left-color: $color-A1;
}

.tipsy-arrow-w {
	border-right-color: $color-A1;
}

.tipsy-n {
	.tipsy-arrow-c {
		position: absolute;
		height: 0;
		@include calc("top", "0% - 12px");
		@include calc("left", "50% - 12px");
	}

	.tipsy-arrow {
		top: 0px;
		left: 50%;
		border-bottom-style: solid;
		border-top: none;
		border-left-color: transparent;
		border-right-color: transparent;
	}
}

.tipsy-nw .tipsy-arrow {
	top: 0;
	left: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent;
}

.tipsy-ne .tipsy-arrow {
	top: 0;
	right: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent;
}

.tipsy-ne,
.tipsy-nw {
	.tipsy-inner {
		margin-top: 12px;
	}
}

.tipsy-s {
	@extend .box-fix;
	margin-bottom: 12px;

	.tipsy-arrow-c {
		position: relative;
	}
	.tipsy-arrow {
		@include calc("left", "50% - 12px");
		bottom: 0;
		border-top-style: solid;
		border-bottom: none;
		border-left-color: transparent;
		border-right-color: transparent;
	}
}

.tipsy-sw .tipsy-arrow {
	bottom: 0;
	left: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
	bottom: 0;
	right: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent;
}

.tipsy-e {
	.tipsy-arrow-c {
		position: absolute;
		height: 0;
		@include calc("top", "50% - 8px");
		right: 0;
	}

	.tipsy-arrow {
		right: 0;
		top: 50%;
		border-width: 8px;
		margin-right: -8px;
		border-left-style: solid;
		border-right: none;
		border-top-color: transparent;
		border-bottom-color: transparent;
	}
}

.tipsy-w {
	.tipsy-arrow-c {
		position: absolute;
		height: 0;
		@include calc("top", "50% - 8px");
	}

	.tipsy-arrow {
		left: 0;
		top: 50%;
		margin-left: -8px;
		border-right-style: solid;
		border-left: none;
		border-top-color: transparent;
		border-bottom-color: transparent;
	}
}
