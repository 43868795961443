@import "mixins";

.w-DragDropHelper {
	@extend .grabbing_cursor;

	float: left;

	.content{
		@include rounded-shadow-box($padding: 7px, $background: $color-A12, $border-radius: 0, $border-color: $color-A5, $shadow-color: $color-A5, $shadow-border: 0, $horizontal: 0, $vertical: 0, $blur: 3px);

		position: relative;
		width: 300px;
		background-color: $color-A12;
		font: 12px/12px $custom-font-regular;

		.label {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.bg1 {
		top: 32px;
		left: -4px;
	}

	.count {
		@include rounded-shadow-box($padding: 4px, $background: $color-C1, $border-radius: 999px, $border-color: $color-A12, $shadow-color: $color-A5, $shadow-border: 0, $horizontal: 0, $vertical: 0, $blur: 5px);

		position: absolute;
		top: -10px;
		right: -10px;
		width: 12px;
		height: 12px;
		left: auto;
		font: 10px/12px $custom-font-bold;
		background-color: $color-C1;
		color: $color-A12;
		text-align: center;
	 }
}
