/*
  The creatives tab inherits from these stylesheets:
  >  creative-list
  >  creative-create-edit
*/

.w-CreativeGrid {
    .w-Filters {
      .entities {
        height: 26px;
        bottom: 3px;
        .chzn-single {
          height: 26px;
        }
      }
      .text {
        height: 24px;
        bottom: 3px;
      }
      
      .status-select {
        width: 92px;
        bottom: 3px;
      }
      .type-select {  
        width: 125px;
        bottom: 3px;
      }
    }
    .fbx {
        .w-DataGrid {
            .column {
                padding-right: 1%;

                .editable-content {
                    word-wrap: break-word;
                }
            }

            .w-head {
              padding-right: 20px;
              .checkbox {
                  top: -1px;
              }
            }

            .headers {
                .info-icon {
                    // Fix for info tooltip
                    margin-left: 4px;
                    display: inline-block;
                    top: initial;
                    right: initial;
                    bottom: initial;
                }
            }

            .edit {
                visibility: hidden;
            }

            .item-wrapper {
                 &:hover, &.hover {
                    .edit {
                        visibility: visible;
                    }
                }

              .edit-icon {
                color: #000000;
              }
            }

            .loader-overrides {
                @include transparent($percent: 90);
                @include calc(top, "50% - 12px");
                @include calc(left, "50% - 80px");
                width: 160px;
                position: absolute;

                &.creative-infinite {
                    bottom: 0;
                    top: auto;
                }
            }
            
            .column {
                padding-right: 1%;
            }

            .checkbox-input, .toggle-arrow {
                padding-right: 15px;
            }

            .toggle-arrow {
                width: 5px;
                top: 3px;

                .icon-spinner {
                  margin: -3px 0 0 -3px;
                }
            }
            .name {
                width: 18%;
            }
            .id {
                width: 5%;
            }
            .advertiser {
                width: 10%;
            }
            .concept {
                width: 10%;
            }
            .third-pas-id {
              width: 5%;
            }
            .type {
                width: 5%;
            }
            .fb-status {
                width: 8%;
            }
            .last-modified {
              width: 5%;
            }
            .start-date,
            .end-date
            {
                width: 7%;
            }
            .config {
                width: 14px;
            }
            .image,
            .click-through-url,
            .ad-server-ppa {
              width: 35%;
            }
            .link-domain,
            .message {
              width: 26%;
            }
            .title,
            .body,
            .ad-server-standard,
            .cta-type,
            .description {
              width: 26%;
            }
            .view-tag {
              width: 26%;
              dl {
                display: block;
              }
            }
            .link-title {
              width: 26%;
              dl {
                display: block;
              }
            }
            .indent {
              margin-left: 51px;
            }
            .editable {
              float: none;
            }
            .fbx-grid-tip {
              bottom: 2px;
              color: #e63a00;

          		&.fbx-grid-tip-hover-more-info {
          		  color: #D1D1D1; 
                &:hover {
                  color: #676767;
                } 
              }

            }
            strand-tooltip {
              text-transform: none;
            }

            .checkbox-input,
            .name,
            .id,
            .advertiser,
            .concept,
            .type,
            .fb-status,
            .last-modified,
            .title,
            .body,
            .click-through-url,
            .view-tag,
            .ad-server
            {
              float: left;
              word-wrap: break-word;
            }

            .toggle-arrow, .toggle-arrow .header-c {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .checkbox-input, .toggle-arrow {
                padding-right: 15px;
            }

            .toggle-arrow {
                width: 5px;
                top: 3px;
            }

            .item {
                .icon-arrow {
                    @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);

                    &.expanded {
                        @include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
                        margin-top: 2px;
                    }
                }
            }
        }
      .children {
        .item {
            background: $color-A10;
            border-bottom: 1px solid $color-A7;
        }
        dt {
            color: $color-A4;
            font-family: PNLB;
            font-size: 10px;
            text-transform: uppercase;
        }
        .w-InlineEditForm {
          top: 15px;
        }

        input.inline-image-upload {
          max-width: 290px;
        }
      }
      // CSS Truncate
      .advertiser, .editable-content dd {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
      }
    }
}

// create/edit
.w-FbxAdd  {
  .w-body {
    top: 90px !important;
  }

  .fbx-template-download {
    margin-top:22px;
  }

  .upload-assets {
    border:none;
    margin-bottom:0px;

    .form-list {
      width:450px;
      float:left;
      margin-right:15px;

      &.fbx-create-edit-bulk-upload {
        width:auto;
        margin-top:27px;
      }

      .fbx-bulk-upload-info-icon {
	color: #666666;
      }
    }
  }

  .table-review {
    margin-bottom: 40px;

    span.title {
      font-family: 'PNLB';
      font-size: 15px;
      font-weight: bold;
    }

    .table {
      margin-top: 10px;
    }

    .create-status {
      display: flex;
      
      .create-status-progress {
        margin-top: 3px;
        padding-right: 2px;
      }
    }
  }

  .create-status-icon {
    padding-bottom: 1px; // firefox fix
    padding-right: 5px;
  }

  .fbx-bulk-error-msg {
    color: red;
  }
  
  .tpas {
    .optional {
      margin-left: 20px;
    }
  }

  .image-upload {
    .file-selector {
      padding-top: 0;
    }
  }

  .loader-holder {
    @include transparent($percent: 90);
    @include calc(top, '50% - 12px');
    @include calc(left, '50% - 80px');
    position: absolute;
  }

  .view-tag-list {
    dt {
      margin-bottom: 12px;

      label {
        color: $color-A3;
      }
    }

    .form-input {
      margin-bottom: 10px;
      
      strand-input {
        @include width-colx($x: 6);
      }
    }

    hr {
      padding-top: 0;
    }

    .remove {
      cursor: pointer;
      float: right;
      opacity: .5;
    }

    .add {
      color: $color-D1;
      cursor: pointer;
      font-size: 11px;
      line-height: 25px;
      vertical-align: bottom;
    }
  }
}

// must be outside w-CreativesGrid because JQuery.tipsy adds the tooltip HTML to body
.fbx-icon-error-tip {
  letter-spacing: .3px;
  margin: -9px 0 0 -5px;

  .tipsy-inner { padding: 6px; }
}

.create-edit-warning-1 {
  .fbx-bulk-image-upload-warning {
    left: 465px;
  }
}

.create-edit-warning-2 {
  float: left;

  .fbx-bulk-image-upload-warning {
    left: 154px;
  }
}

.fbx-bulk-upload-warning {
  .info-box {
    &.error {
      width: 100%;
    }
  }
}

.fbx-bulk-uploadable-review, .fbx-bulk-not-uploadable-review {
  .fbx-bulk-success-icon {
    color: #9FCB66;
  }

  .fbx-bulk-submit-failure-icon {
    color: #EC4F53;
  }

  .fbx-bulk-not-uploadable-icon {
    color: #ED4E54;
  }

  .fbx-bulk-pending-icon {
    color: #989898;
  }
}

.exit-fbx-bulk-modal {
    
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  strand-header {
    margin-bottom: 14px;

    .warning-icon {
      color: #FED01A;
    }

    span {
      font-size: 18px;
    }
  }

  .content-top {
    padding: 20px;
  }

  strand-icon {
    margin-right: 20px;
  }

  .hideButton {
    display: none;
  }

  #modalCheckbox {
    left: 15px;
    position: absolute;
    top: 20px;
  }

        
}
