@import "mixins";

.bulk-create-wrapper {

  .w-box {
    padding: 16px 0 0 !important;
  }

  .w-box .w-head{
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 5px !important; 
  }

  .bulk-header {
    bottom: 15px;
  }

  .w-body {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: visible;
    background: rgb(255, 255, 255);

    .info {
      padding: 10px 20px;

      #template-error {
        display: none;
        position: absolute;
        margin-top: -1px;
      }

      #submit-bulk-segments {
        width: 66px;
      }

      #file-browser-display {
        width: 341px;
      }

      .bulk-segments-directions {
        padding-top: 33px;
        font: normal 13px/14px "AR";
        line-height: 18px;
        display: block;
        clear: left;

        li {
          list-style-type: disc;
          list-style-position: inside;
        }

        li a {
          color: $color-D16;
          text-decoration: underline;
        }

        .notes {
          li {
            padding-left: 20px;
            list-style-type: none;
          }
        }
      }
    }

    .results-container {

      #bulk-results-grid {
        padding-bottom: 60px;
      } 
    }

  }  
  
  .bulk-unsaved-segment {
    color: $color-C3;
  }

  .bulk-saved-segment {
    color: $color-B6;
  }
}

.bulk-segment-errors-body {
  padding: 30px;

  .instruction {
    font-size: 12px;
    padding-bottom: 20px;
    padding-top: 10px;
  }
}
