/* @group Base */

.chzn-container {
  font-size: 13px;
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-weight: normal;
  
	.search-loader-no-bg { 
		@extend search-loader-no-bg;
	}
}

.chzn-container .chzn-drop {
  background: $color-A12;
  border: 1px solid $color-A6;
  border-top: 0;
  position: absolute;
  top: 29px;
  left: 0;
  z-index: 2;
}

/* @end */

/* @group Single Chosen */
.chzn-container-single .chzn-single {
  @include linear-gradient($from: $color-A12, $to: $color-A8);
  @include border-radius($radius: 3px);

  border: 1px solid $color-A5;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  height: 23px;
  line-height: 23px;
  padding: 0 0 0 7px;
  color: $color-A1;
  text-decoration: none;
  margin-right: -2px;
}

.chzn-container-single .chzn-single span {
  margin-right: 26px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; /* ellipses dont work without hidden overflow */
}

.chzn-container-single .chzn-single abbr {
  @include sprite-at-position($left: 5, $down: 5); 
  display: block;
  position: absolute;
  right: 26px;
  top: 0px;
  width: 12px;
  height: 13px;
  font-size: 1px;
  margin-top: 10px;
}

.chzn-container-single .chzn-single div {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 18px;
}

.chzn-container-single .chzn-single div b {
  @include sprite-at-position($left: 1, $down: 7);
  display: block;
  margin: 9px 0 auto;
  width: 10px;
  height: 100%;
  margin-top: 9px;
}

.chzn-container-single .chzn-search {
  padding: 3px 6px 3px 4px;
  position: relative;
  margin: 0;
  white-space: nowrap;
  z-index: 1010;
}

.chzn-container-single .chzn-search input {
  @include border-radius($radius: 3px);
  background: #fff url('../images/chosen-sprite.png') no-repeat 100% -22px;
  background: url('../images/chosen-sprite.png') no-repeat 100% -22px, linear-gradient(to bottom, #ffffff 85%, #eeeeee 99%);
  margin: 1px 0;
  padding: 4px 0;
  outline: 0;
  border: 1px solid $color-A6;
  font-family: sans-serif;
  font-size: 1em;
  width: 100%;

  &:focus {
  	outline: none;
  	box-shadow: 0px 0px 4px $color-D8;
  	border-color: $color-D8;
  }
}

.chzn-container-single .chzn-drop {
  @include _border-radius($radius: 0 0 4px 4px);
}
/* @end */

.chzn-container-single-nosearch .chzn-search input {
  position: absolute;
  left: -9000px;
}


.chzn-container .chzn-results {
  margin: 0 0 1px 0;
  max-height: 540px;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.chzn-container .chzn-results li {
  display: none;
  line-height: 15px;
  padding: 5px 6px;
  margin: 0;
  list-style: none;
  float: none;
}

.chzn-container .chzn-results .active-result {
  cursor: pointer;
  display: list-item;
}

.chzn-container .chzn-results {
	.highlighted {
		background-color: $color-A9 !important;
		color: $color-E1;
	}
	.result-selected {
		background-color: $color-A12;
		color: $color-A4;
	}
}

.chzn-container .chzn-results li em {
  background: #feffde;
  font-style: normal;
}

.chzn-container .chzn-results .highlighted em {
  background: transparent;
}

.chzn-container .chzn-results .no-results {
  background: #f4f4f4;
  display: list-item;
}

.chzn-container .chzn-results-scroll {
  background: white;
  margin: 0px 4px;
  position: absolute;
  text-align: center;
  width: 321px; /* This should by dynamic with js */
  z-index: 1;
}
.chzn-container .chzn-results-scroll span {
  display: inline-block;
  height: 17px;
  text-indent: -5000px;
  width: 9px;
}
.chzn-container .chzn-results-scroll-down {
  bottom: 0;
}
.chzn-container .chzn-results-scroll-down span {
  background: url('../images/chosen-sprite.png') no-repeat -4px -3px;
}
.chzn-container .chzn-results-scroll-up span {
  background: url('../images/chosen-sprite.png') no-repeat -22px -3px;
}
/* @end */

/* @group Active  */

.chzn-container-active .chzn-single-with-drop {
  border: 1px solid $color-A5;
  border-bottom-left-radius : 0;
  border-bottom-right-radius: 0;
}
.chzn-container-active .chzn-single-with-drop div {
  background: transparent;
  border-left: none;
}
.chzn-container-active .chzn-single-with-drop div b {
  @include sprite-at-position($left: 1, $down: 8); 
}
.chzn-container-active .chzn-choices {
  box-shadow        : 0 0 5px rgba(0,0,0,.3);
  border: 1px solid #5897fb;
}
.chzn-container-active .chzn-choices .search-field input {
  color: #111 !important;
}
/* @end */

/* @group Disabled Support */
.chzn-disabled {
  cursor: default;
  opacity:0.5 !important;
}
.chzn-disabled .chzn-single {
  cursor: default;
}
.chzn-disabled .chzn-choices .search-choice .search-choice-close {
  cursor: default;
}

.chzn-container-active .chzn-single {
   z-index: 2;
}
.chzn-container-active .chzn-drop {
   z-index: 1;
}

.extra-link {
	float: right;
	padding: 0 5px 5px 0;
	cursor: pointer;
  	white-space: nowrap;
}

.extra-link-add {
	@include calc(width, "100% - 12px");
	border-top: 1px solid $color-A6;
	color: $color-D1;
	font-size: 13px;
	padding: 6px 4px 4px 6px;
	cursor: pointer;
}
