.w-UserFeedback {
	.t1-upcast-left {
		border-right: 1px solid $color-A0;
		width: 1px;
		height: 50px;
	}

	.kb-action {
		border-left: 1px solid $color-A3;
		padding-left: 20px;
	}

	.t1-upcast-action {
		border-right: 1px solid $color-A0;
		border-left: 1px solid $color-A3;
		width: 130px;
		height: 50px;

		.t1-upcast-logo {
			background: url('../images/mm_social_logo.svg') no-repeat -20px 2px;
			background-size: 170px 47px;
			color: transparent;
			padding: 0;
			height: 50px;
			width: 130px;
			position: absolute;
			z-index: 10;
		}

		.t1-upcast-logo:hover + .t1-background-hover {
			position: absolute;
			z-index: 2;
			top: 6px;
			left: 15px;
			width: 105px;
			height: 38px;
			background-color: #808080;
			opacity: 0.45;
			border-radius: 3px;
		}

		.t1-upcast-ribbon {
			background: url('../images/ribbon.svg') no-repeat 6px 0;
			background-size: 130px 35px;
			padding: 0;
			height: 50px;
			width: 88px;
			position: absolute;
			z-index: 3;
			top: 0;
			left: 45px;
		}
	}

  #charityIcon {
    color: #de396e;
		margin: 0 0 0 12px;
		&:hover {
			cursor: pointer;
		}
	}

	.charity-popover {
		width: 315px;

		&:before,
		&:after {
			border-bottom: 10px solid #000;
		}

		#container.strand-popover {
			background: #000;
			color: #fff;
			font-family: Arimo,sans-serif;
			border: 1px solid #000;
			line-height: 15px;
			// font-smoothing set to match strand-tooltip styling
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-smoothing: antialiased;

			a {
				color: #fff;
			}
		}
	}

	.charity-popover-delete {
		color: #666;
		position: absolute;
		right: 8px;
		top: 8px;
		
		&:hover {
			color: #fff;
			cursor: pointer;
		}
	}

  .charity-link {
    float: left;
    text-align: left;

    a {
      line-height: 15px;
      padding: 0;
      margin: 0;
      cursor: pointer;
      
      &:hover {
        background: none;
      } 
    }
  }
}

.w-UserFeedbackDialog {
	@extend .dialog-panel-form;

	.chzn-container, .component-select .select {
		width: 110px;
	}

	[name=feedbackForm] {
		p {
			margin: 0 0 10px;
			line-height: 14px;
		}
	}
}
