@import "mixins";

.w-PixelBundlesList {
	position: relative;
	background-color: $color-A9;

	.close {
		padding: 10px 0;
		position: absolute;
		right: 10px;
		top: -40px;
		cursor: pointer;

	  	&:hover{
			.collapse-icon{
			  opacity: 1;
			}
			span{
			  opacity: 1;
			}
		  }

		span {
			font: 13px/13px $custom-font-semi-bold;
			color: $color-A3;
			text-transform: capitalize;
			margin: 5px 10px 0px 0px;
			float: left;
		  	opacity: .7;
		}

		.collapse-icon {
			@include sprite-icon($left: 0, $down: 3, $width: 20px, $height: 20px);
			float: right;
		  	opacity: .5;
		}
	}

	.filters-box .text-input{
	  	@include search-icon();
	}

	.name {
		width: 35%;

		.icon {
			float: left;
		}

		.text {
			margin-left: 42px;
		}
	}

	.id {
		width: 8%;
	}

	.loads {
		width: 18%;
	}

	.referrer {
		width: 39%;
	}

	.w-foot {
		background-color: $color-A10;
	}

	.eligible {
		width: 100%;
		padding: 0 0 0 15px;

		label {
			float: right;
			display: block;
			margin-right: 5px;
			margin-top: 6px;

			input[type="checkbox"] + span {
				font: normal 11px/12px $custom-font-regular;
				color: $color-A2;
			}
		}
	}

	.item {
		padding-top: 6px;
		padding-bottom: 6px;
		border-bottom: 1px solid $color-A7;

		&.selected-item {
			border: none;
		}
	}
}