.pmpe-modal-background {
  @include  radial-gradient($pos: 50% 50%,
  $shape-size: circle farthest-corner,
  $color-from: $color-A2,
  $color-to: $color-A0,
  $color-bg: $color-A2,
  $swidth: 193%);

  @include transparent(60);
  background-color: $color-A1;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.w-DealModal {
  @extend .dialog-panel-form;
  @include calc-important("left", "50% - 415px");
  @include calc-important("top", "50% - 268px");

  background-color: $color-A12;
  font-family: $custom-font-regular;
  left: 0;
  position: absolute !important;
  right: 0;
  width: 850px !important;
  z-index: 5;

  .w-head {
    .basic-info {
      margin-left: 0;
      width: 100%;
    }

    .title {
      font-family: $custom-font-semi-bold;
      font-size: 22px;
      letter-spacing: normal;
      padding-top: 5px;
      text-transform: capitalize;
    }
  }

  .editInfo {
    color: $color-A6;
    float: right;
    font-family: $custom-font-regular;
    font-size: 11px;
    margin-top: -18px;
  }

  .w-body {
    .search-loader {
      display: none;
      width: 170px;
      top: 200px;
    }

  	.form-list {
  	  dt { font-family: $custom-font-semi-bold; }
  	}

  	.form-body { padding: 20px 0 0; }

    .description {
      margin-right: 25px;
      width: 410px;
    }

  	.status {
  	  width: 300px;

  	  .form-radio {
        float: left;
        padding-right: 30px;
        padding-top: 6px;
  	  }
  	}

  	.publisher {
  	  width: 233px;

  	  .chzn-container { width: 234px; }

  	  .chzn-drop {
        width: 234px !important;
        z-index: 1 !important;
  	  }

  	  .chzn-results { max-height: 200px; }
  	}

  	.form-radio {
  	  float: left;
  	  font: 13px/14px $custom-font-regular;
  	}

  	.form-label { font: 13px/14px $custom-font-regular; }
  	.use-never-date { padding-left: 10px; }

  	.column-A {
  	  .create-deal-id {
        margin-top: 13px;

    		.form-radio {
    		  float: left;
    		  padding-right: 20px;
    		}
  	  }
  	}
  }

  .date-display { font: 13px/14px $custom-font-regular; }

  .provider {
    margin-right: 12px;
    width: 185px !important;

  	.chzn-container { width: 185px; }

  	.chzn-drop {
      width: 185px !important;
      z-index: 1 !important;
  	}
  }

  .deal-id { margin-top: 25px; }

  .price {
    width: 350px;

    dt { padding-left: 15px; }

    .symbol {
      float: left;
      font-size: 12px;
      padding-right: 3px;
      padding-top: 7px;
    }

    .input {
      float: left;
      padding-right: 5px;
      width: 100px;

      .currency {
        height: 23px;
        margin: 0;
        padding: 0;
      }
    }

    .price-floor {
      float: left;
      margin-left: 10px;

      .chzn-drop { z-index: 1 !important; }
    }

    .cpm {
      float: left;
      font: 13px/14px $custom-font-regular;
      padding-left: 9px;
      padding-top: 8px;
    }
  }

  .form-stack {
    float: left;
    padding-bottom: 20px;
    width: 100%;
  }
}

#date-picker-createEditdeal {
  @extend .datePickerCreator;

  .chzn-drop { z-index: 9000 !important; }
}
