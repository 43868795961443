.w-CreativesDisplayPreview {
	padding: 0;
	background: none;
	width: 100%;

	.chrome-warning {
		padding-left: 25px;
		padding-top: 25px;
	}

	.w-head {
		width: 100%;
		.title {
			font: 17.2px/28px $custom-font-semi-bold;
			color: $color-A3;
			padding:18px 26px;
			margin: 0;
		}

		.count {
			position: absolute;
			right: 35px;
			font-size: 11px;
			font-family: $custom-font-semi-bold;
			margin-top: -35px;
		}
	}

	.view-loader-preview-list {
		.loader {
			@include transparent(60);
		}
	}

	.main-content-preview, .information-column {
		position: absolute;
		left: 0;
		right: 0;
		top: 60px;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.main-content-preview {
		width: 75%;
		background: $color-A12;
		border-top: 1px solid $color-A7;
	}

	.information-column {
		left: auto;
		background: $color-A12;
		border-left: 1px solid $color-A7;
		border-top: 1px solid $color-A7;
		width: 21.80%;
		padding: 20px;

		p,
		span {
			font-size: 11px;
			font-family: $custom-font-regular;
			color: $color-A4;
		}

		p {
			padding-bottom: 20px;

			b {
				font-family: $custom-font-semi-bold;
			}

			a {
				color: $color-D1;
			}
		}

		ul {
			margin-top: -13px;
			margin-left: 0;
			padding-left: 1em;
			text-indent: -1em;

			li {
				color: $color-A4;
				list-style-type: disc;
				list-style-position: inside;

				ul {
					margin: 10px 0 10px 0;
				}
			}
		}

	}
}

.w-DisplayPreview {
	.w-head {
		.basic-info {
			width: 100%;
		}

		.display-info {
			position: absolute;
			right: 35px;

			.display-info-c {
				font-size: 12px;
				float: right;
				margin: 0;

				dl.inline dt, dl.inline dd {
					display: inline;
					float: left;
					margin: 0 5px 0 0;
				}

				dl.inline dt {
					margin-top: 2px;
					color: $color-A3;
					text-transform: capitalize;
					font-size: 10px;
				}

				dl.inline dd {
					font-family: $custom-font-semi-bold;
					margin: 1px 0 5px 0;
					max-width: 190px;
					// CSS Truncate
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				dl.inline dd + dt, dl.inline dd + dd {
					clear: left;
				}
			}
		}

		.name, .label {
			max-width: 65%;
			// CSS Truncate
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.name {
			font-family: $custom-font-semi-bold;
			letter-spacing: normal;
			width: 90%;
			margin-top: 5px;
			clear: both;
		}
	}

	.preview-item {
		padding: 20px 0 20px 0;
		border-bottom: 1px solid $color-A7;
		.form-body {
			float: left;
			margin-top: 50px;
			margin-left: 26px;
		}
	}
}

.w-PreviewT1ASDialog {
	@extend .dialog-panel;
	@extend .w-DisplayPreview;

	position: fixed;
	font-family: $custom-font-regular;
	min-width: 730px;
	width: auto;
	height: auto;
	z-index: 2;
	@include calc-important("left", "50% - 375px");

	.note {
		font-size: 11px;
		color: $color-A4;
		padding-top: 4px;
	}

	.notify {
		text-align: center;
		padding-top: 15px;
	}

	.ajax-loader {
		text-align: left;
		opacity: 0.8;
	}

	.w-head {
		.basic-info {
			margin: 0;
		}
		.name {
			font-size: 13px;
			padding-bottom: 10px;
		}
		padding-bottom: 10px;
	}

	.w-body {
		margin-bottom: 15px;
		overflow-x: scroll;
		overflow-y: hidden;
	}

	.strand-close {
		position: absolute;
        width: 14px;
        height: 14px;
        text-align: center;
        right: 10px;
        top: 8px;
        z-index: 100;
        cursor: pointer;

        .modalClose {
        	color: #aaaaaa;
        }

        .modalClose:hover {
        	color: #333333;
        }
	}

	.hybrid-block {
		padding-right: 50px;
		padding-top: 12px;
		display: inline-block;

		.frame-title {
			font-size: 13px;
			font-family: $custom-font-semi-bold;
			margin-left: 8px;

			.hybridIcon {
				color: #c3c3c3;
			}

			.hybridIcon:hover {
				color: #666666;
			}
		}

		.ad-status {
			font-size: 12px;
			line-height: 14px;
			font-style: italic;
			font-family: $custom-font-regular;
			display: inline-block;
			width: 50px;
		}

		.ff-ajdust {
			width: auto;
		}

		.ad-status:first-letter {
			text-transform: uppercase;
		}
	}

	.form-body {
		float: left;
	}

	.form-footer {
		#refreshHybrid {
			margin-top: 5px;
		}

		#approveHybrid {
			margin-left: 10px;
			float: right;
		}

		#rejectHybrid {
			float: right;
		}
	}
}
