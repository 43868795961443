.w-ReadonlyDaypart {

	.sub-head-hasdata{
		padding-top: 2px;
	}

	.heading-icon{
		@include sprite-icon($left: 7, $down: 6, $width: 18px, $height: 18px);
	}

	.col1{
		width: 58%;
	}
	.col2{
		width: 8%;
	}
	.col3{
		width: 8%;
	}
	.col4{
		width: 13%;
	}
	.col5{
		width: 1%;
	}
}