.w-PMPE {
  .strategy-supply-pmpe-grid-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .media-type-switcher {
    font-size: 14px;
    margin-right: 20px;

    label[disabled=disabled] {
      color: $color-A4;
      cursor: default;
    }
  }

  .hideAddAllCheckbox {
    visibility: hidden;
  }
  
  .deal-group-grid {
    .headers { padding: 10px 0 6px 60px; }
  
    .drag {
      float: right;
      margin-right: 13px;
    }
  }

  .w-DataGrid {
    .item-row { display: flex; }

    .expansion {
      background: $color-A12;
      border-bottom: 1px solid $color-A7;
      font: 11px/14px "AR";
      margin: 10px 0 -7px -20px;
      position: relative;
    }
  }

  .icon-arrow {
    background: url('../images/mm_sprite.png') -140px -252px no-repeat;
    display: block;
    height: 12px;
    margin: 0 4px;
    width: 12px;
  }

  .search-filter { margin-top: 4px; }
  .filter { width: 348px; }

  .clear-filter {
    // Overridding the _mixins.scss .w-Filter .clear-filter style
    margin-left: 0;
    right: 5px;
    top: 5px;
  }

  .deal-group-id {
    text-align: center;
    width: 156px;
  
    .truncate { text-align: center; }
  }
}


