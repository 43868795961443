.w-BulkeditMyData {

  .w-DataGrid.segment {
    .header.name {
      width: 69%;
    }
  }

  .w-DataGrid {

    .pixel.id {
      margin-left: -6px;
    }

    .name {
      width: 29%;
    }

    .loads {
      width: 12%;
    }

    .uniques {
      width: 12%;
    }

    .referrer {
      width: 18%;
    }

    .id {
      width: 12%;
    }

    .content-holder {
      height: 100%;
    }
  }

  .w-Pixel-Bins {

    .include-bin {
      #container {
        #tooltipText {
          text-transform: initial !important;
          font-size: 12px !important;
        }
      }

      .content-container {
        height: 82%;
      }
    }

    .exclude-bin {
      height: 42%;
    }
  }

  .bins-column {
    top: -38px !important;

    .drop-area {
      margin-top: 17px;
    }

    #totalLoadsToolTip {
      padding-bottom: 1px;
      margin-right: 2px;

      #iconGlyph {
        opacity: 0.5;
      }
    }

    .edit-options {
      height: 30px;
      background: $color-A7;

      .header-text {
        position: absolute;
        top: 10px;
      }

      .info-tooltip {
        margin-left: 113px;
        margin-top: 4px;

        #addRemoveTooltip {
          color: $color-A1;
        }

        #addRemoveTooltip:hover {
          color: $color-A0;
        }

        strand-icon {
          opacity: 0.5;
        }
      }

      .bulk-edit-options {
        position: absolute;
        top: 10px;
        left: 138px;
      }
    }

    .targeting-operator {
      position: relative;
      z-index: 1;
      height: initial;
    }
  }

  .w-body {
    .column-A {

    }
  }
}