.behavior-dialog {
	padding: 0;
	overflow: visible;

	.ui-dialog-content {
		padding: 0;
	}

	.w-Behavior {

		.w-box {
			width: 1100px;
			background-color: $color-A12;
			border-radius: 4px;

			.w-head {
				padding: 30px;

				.behavior-name {
					font: 11px/21px $unified-font-bold;
					color: $color-D16;
					text-transform: uppercase;
				}

				.title {
					font: 21px/21px $unified-font-bold;
					color: $color-A2;
					height: 25px;
				}
			}

			.w-body {
				padding: 0 30px 30px;

				.behaviors {

					&:after {
						clear: both;
						content: "";
						display: block;
					}

					.label {
						font: 13px/21px $unified-font-bold;
						color: $color-A2;
						margin-bottom: 7px;
					}

					.section {
						float: left;
						padding-right: 20px;

						.options-select {
							width: 150px;
							display: block;
						}
					}

					.frequency-selector {
						width: 275px;

						.label {

							#more-frequency-info {

								cursor: pointer;
								color: #999999;
							}

							#more-frequency-info:hover {
								color: #666666;
							}

							#more-frequency-info-tooltip {
								margin-left: 5px;
								margin-top: -1px;
							}
						}

						#frequency-options {
							float: left;
							margin-right: 10px;
						}

						.frequency-inputs {
							display: inline;

							.frequency-text {
								margin-left: 5px;
								font-size: 14px;
								margin-right: 5px;
								float: left;
								margin-top: 7px;
							}

							.frequency-number {
								float: left;
								width: 50px;
							}
						}
					}

					.time-interval-selector {

						.time-interval {

							.input-time-number {
								width: 60px;
								margin-left: 5px;
								margin-right: 5px;
							}

							.input-time-unit {
								width: 107px;
								margin-right: 5px;
							}

							.time-text {
								font-size: 14px;
								vertical-align: -1px;
							}

							.select-date-button {
								border-bottom: 1px dotted $color-A2;
								margin-right: 5px;
								vertical-align: -3px;

								&:hover {
									cursor: pointer;
									border-bottom: 2px dotted $color-A2;
								}
							}

							#select-time-hidden-datepicker {
								height: 24px;
								display: inline-block;
								visibility: hidden;
								border-width: 0;
								width: 0;
								margin: 0;
								padding: 0;
							}

							#select-time-button-2 {
								margin-left: -2px;
							}
						}

						.select-date-range {
							height: 29px;

							.time-text {
								display: inline;
								vertical-align: -3px;
								margin-right: 5px;
							}

							#select-time-1-hidden-datepicker, #select-time-2-hidden-datepicker {
								height: 24px;
								display: inline-block;
								visibility: hidden;
								border-width: 0;
								width: 0;
								margin: 0;
								padding: 0;
							}

							#back-to-relative-button {
								color: $color-A2;
								display: inline;
								vertical-align: -5px;

								&:hover {
									cursor: pointer;
									color: $color-A0;
								}
							}
						}
					}
				}

				.event-filters {

					.event-filter-labels {

						&:after {
							clear: both;
							content: "";
							display: block;
						}

						.event-attribute-label {
							width: 275px;
						}

						.event-operator-label {
							width: 150px;
						}
					}

					#event-filter-rows {
						max-height: calc(100vh - 400px);

						.event-filter-row {
							margin-bottom: 7px;

							&:after {
								clear: both;
								content: "";
								display: block;
							}

							.attribute-options {
								width: 275px !important;
							}

							.value-textarea-container {
								width: 500px;
							}
							.value-ziparea-container {
								width: 500px;
							}

							.value-multiselect-container {
								width: 300px;

								.value-multiselect {
									width: 300px;
								}


								.chzn-container {
									width: 300px;

									.chzn-drop {
										z-index: 1010;
										-webkit-box-sizing: border-box;
										-moz-box-sizing: border-box;
										box-sizing: border-box;
										border: 1px solid #ccc;
										border-top: 0;
										background: $color-A12;
										box-shadow: 0 0 12px 0 rgba(0,0,0,.18);
										border-radius: 0 0 4px 4px;
										width: 100%;
									}


									.chzn-results {
										max-height: 200px;
										li {
											-webkit-touch-callout: none;
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											font-smothing: antialiased;
											font-weight: 400;

											&.highlighted {
												color: $color-A12;
												background-color: $color-D15 !important;
											}
										}
									}

									&.chzn-container-multi {

										.chzn-choices {
											-webkit-box-sizing: border-box;
											-moz-box-sizing: border-box;
											box-sizing: border-box;
											border: 1px solid #CCC;
											border-radius: 4px;
											background-color: $color-A12;
											box-shadow: inset 1px 1px 3px rgba(0,0,0,.125);
											color: $color-A1;
											font-family: Arimo,sans-serif;
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											font-smoothing: antialiased;
											font-weight: 400;
											font-size: 13px !important;
											line-height: 15px !important;
											overflow: hidden;
											outline: 0;
											cursor: text;
											width: 100%;
											padding-left: 5px;
											max-height: 80px;
											overflow: auto;

											li {
												float: left;

												&.search-field {
													margin: 0;
													padding: 0;
													white-space: nowrap;

													input[type="text"] {
														margin: 1px 0;
														padding: 5px;
														height: 15px;
														outline: 0;
														border: 0 !important;
														background: transparent !important;
														box-shadow: none;
														color: $color-A4;
														font-size: 100%;
														line-height: normal;
														border-radius: 0;
														width: 100%;
														font-family: $unified-font-regular;
														font-smoothing: antialiased;
														font-style: italic;
														font-weight: 400;
													}

													.default {
														color: #999;
													}
												}

												&.search-choice {
													position: relative;
													margin-right: 5px;
													padding: 5px 20px 5px 5px;
													margin: 1px 1px 1px 5px;
													height: 15px;
													border-radius: 3px;
													color: $color-A1;
													background: $color-A19;
													line-height: 15px;
													cursor: default;

													.search-choice-close {
														position: absolute;
														top: 7px;
														right: 3px;
														display: block;
														width: 12px;
														height: 12px;
														background: url('../images/chosen-sprite.png') -48px 0 no-repeat;
														font-size: 1px;
													}

												}

												&.search-choice-focus {
													background: $color-A6;

													.search-choice-close {
														background-position: -48px -11px;
													}
												}
											}
										}

										.chzn-results {
											margin: 0;
											padding: 0;
										}

										.chzn-drop {
											font-family: Arimo,sans-serif;

											.result-selected {
												display: list-item;
												cursor: default;
												color: $color-A5;
											}
										}
									}

									&.chzn-container-active {

										.chzn-choices {
											outline: 0;
											box-shadow: 0 0 6px rgba(171,210,246,.83);
											border: 1px solid rgb(171,210,246);

											li.search-field input[type="text"] {
												color: $color-A1 !important;
											}
										}
									}
								}

							}

							.value-datepicker-container, .value-dual-datepicker-container {
								.value-datepicker {
									height: 24px;
									margin: 0;
									padding: 0;
									border-width: 0;
									display: inline-block;
									visibility: hidden;
									width: 0;
								}
								.value-datepicker-button {
									border-bottom: 1px dotted $color-A2;
									margin-right: 5px;
									vertical-align: -3px;
									font-size: 14px;

									&:hover {
										cursor: pointer;
										border-bottom: 2px dotted $color-A2;
									}
								}

							}

							.value-date-between-container {
								.input-time-number {
									width: 50px;
									margin-left: 5px;
									margin-right: 5px;
								}

								.input-time-unit {
									width: 87px;
									margin-right: 5px;
								}

								.time-text {
									font-size: 14px;
									vertical-align: -1px;
								}

								.between-datepicker-container {
									display: inline;
									vertical-align: -3px;

									.select-date-button {
										border-bottom: 1px dotted $color-A2;
										margin-right: 5px;
										vertical-align: -3px;
										font-size: 14px;

										&:hover {
											cursor: pointer;
											border-bottom: 2px dotted $color-A2;
										}
									}

									.back-to-relative {
										color: $color-A2;
										display: inline;								
										vertical-align: -5px;
										font-size: 14px;

										&:hover {
											cursor: pointer;
											color: $color-A0;
										}								
									}
								}
							}


							.remove-event-row {
								float: right;
								margin-top: 5px;
								margin-right: 5px;
								cursor: pointer;
								strand-icon {
									color: #c8c8c8;
								}
							}
						}
					}
				}

				hr {
					border: 0;
					margin: 13px -30px;
					height: .7px;
					color: $color-A19;
					background-color: $color-A19;
				}

				#add-event-filter {
					font: 12px/15px $unified-font-bold;
					color: rgb(0, 60, 113);

					&:hover {
						cursor: pointer;
						color: darken(rgb(38, 73, 100), 30%);
					}
				}
			}

			.w-footer {
				border-top: 1px solid rgb(200,200,200);
				background-color: rgb(241,244,249);
				padding: 15px;
				border-radius: 0 0 4px 4px;

				&:after {
					clear: both;
					content: "";
					display: block;
				}

				#cancel-button {
					border-bottom: 1px dotted rgb(0, 60, 113);
					margin-right: 5px;
					margin-top: 8px;
					float: right;
					font-size: 14px;
					color: rgb(0, 60, 113);

					&:hover {
						cursor: pointer;
						border-bottom: 2px dotted $color-A2;
					}
				}

				#apply-button {
					margin-left: 5px;
					float: right;
				}

				#error-container {
					float: left;
				}
			}
		}
	}
}

