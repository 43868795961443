
.budget-meter {
    float: right;
    position: relative;

    .bar {
      @extend .progress-bar;
      @include _border-radius($radius: 4px);
      float: right;
      height: 24px;

      .colored-bar .highlight {
        position: relative;
      }

      .pacing {
          .solid {
              background: $color-B2;
           }

          .highlight {
            @include _linear-gradient($from: $color-B3, $to: $color-B4);
           }
       }

      .out-pacing {
          .solid {
              background: $color-C3;
           }

          .highlight {
            @include _linear-gradient($from: $color-C3, $to: $color-C4);
           }
       }

    }

	.remaining-amount {
		position: absolute;
		width: 93px;
		text-align: right;
		font-size: 12px;
		margin-top: -2px;
		color: $color-A0;
		white-space: nowrap;
		text-transform: none;
	}

	.pointer {
		position: absolute;
		margin-top: 1px;
	}

	.arrow {
		@include sprite-icon($left: 1, $down: 8, $width: 11px, $height: 6px);
		position: absolute;
		margin-left: -3px;
		top: 23px;
		cursor: pointer;
	}

	.line {
		@include transparent($percent: 40);
		width: 1px;
		height: 24px;
		margin-top: -1px;
		margin-left: 1px;
		position: absolute;
		background-color: $color-A4;
	}
}

.tipsy-strategy-bar-date {
	.tipsy-arrow-s {
		left: 47%;
	}
}
