.w-CampaignExportTags {

    .header-export-data {

        .sub-header {
            font-weight: 700;
            text-transform: uppercase;
            font-family: $unified-font-regular;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            font-size: 11px;
            line-height: 14px;
            color: #2e6c9e; //$color-D2 New Color Palette

            a {
                color: #2e6c9e; //$color-D2 New Color Palette
            }
        }
    }

    .footer-export {
        left: 113px;

        .form-stack {
            margin-bottom: 0px;

            .footer-export-spacing {
                padding: 0px 0px 0px 15px;
            }

            .footer-export-spacing-cancel {
                padding: 7px 0px 0px 15px;
            }
        }
    }

    .form-export {
        padding: 25px;
    }

    .search-dropdown {
        width: 150px;
    }

    .search-exports {
        width: 300px;
    }

    .status-label {
        font-size: 13px;
        line-height: 29px;
    }

    .status-select {
        min-width: 90px;
    }

    .w-head {
        height: 20px;

        .basic-info {
            margin-bottom: 10px;

            .export-header-spacing {
                padding: 10px 0px 0px 25px;
            }
        }
    }

    .grid-content {

        .w-export-details {

            .w-body {
                background-color: #fff;
                
                .exportLoader {
                    width: 100%;
                    height: 200px;
                    text-align: center;
                    padding: 100px 0px 0px 0px;
                }
            }
        }

        .strandExportGrid {
            width: 100%;
            height: 319px;

            .no-response-wide {
                text-align: right;
                height: 16px;
                width:100%;
                position: relative;
                z-index: 999;
            }

            .no-response-skinny {
                width: 0px;
            }
        }

        .no-results-found {
            text-align: center;
            top: 150px;
            position: absolute;
            width: 100%;
        }

        margin-top: 20px;
        display: table;
        width: 100%;
        height: 100%;

        .tabs-content {
            height: 100%;
            display: table-row;

            .export-tab {
                height: 100%;

                .w-box {

                    height: 100%;
                    form {
                        height: 100%;
                    }
                }
            }
        }
    }

    .w-body {

        .move-right {
            padding: 15px 0px 15px 25px;
        }

        .move-right-select {
            padding: 0px 0px 15px 25px;

            .form-stack {

                .spacing-warning {
                    margin: 5px 10px 10px 0px;
                    min-height: 35px;
                }
            }
        }

        .move-tag-delivery {
            padding: 25px 0px 0px 25px;
        }

        .move-tag-radio {
            padding: 15px 0px 0px 25px;
        }

        .tag-radio-spacing {
            margin-right: 25px;
        }

        .click-tracker-stack {
            padding: 15px 0px 15px 25px;
            display: table;
            width: 100%;

            .column {
                width: 100%;
            }
        }

        .form-click-tracker-stack-height {
            min-height: 30px;
        }

        .form-email-alignment {
            display: table;
            width: 100%;
        }

        .form-email-to {
            padding: 0px 0px 0px 25px;
        }

        .form-email-input {
            padding: 2px 0px 0px 25px;

            .column {
                width: 50%;

                .to-field {
                    width: 100%;
                }
            }
        }

        .form-email-message {
            padding: 15px 0px 0px 25px;
        }

        .form-email-text-area {
            padding: 2px 0px 0px 25px;
            margin-bottom: 100px;

            .column {
                width: 50%;

                .email-message {
                    width: 100%;
                }
            }
        }
    }

    .w-toToolTipError {

        .tipsy-inner {
            color: $color-A12;
            background-color: $color-C1;
            padding: 8px;
        }

        .tipsy-arrow-s {
            border-top-color: $color-C1;
        }
    }
}
