$column-merge: 50px;
$column-width: 50px;

.w-Pixels {
  // icon stylings
  .pixel-edit {
    color: $color-A1;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .pixels-head {
    .basic-info {
      max-width: 500px;
      float: left;
      margin-left: 26px;
      margin-top: 10px;
      width: 45%;

      .title {
        font: 14px/21px 'AB';
        color: #383838;
        height: 21px;
        float: left;
      }
    }
  }

  .inline-edit-loader {
    .ajaxSpinner {
      height: 30px;
    }
  }

  .w-wrapper {
    background-color: #fff;
  }

  .list-filters {
    padding: 10px 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;

    .form-list {
      margin-right: 10px;
    }

    .filter-action {
      width: 170px;
    }

    .center-fix {
      margin-top: 4px;
    }

    &.disabled {
      @include transparent();
    }

    .status {
      line-height: 23px;
    }

    .search {
      .w-Filters {
        margin-top: 2px;
        margin-bottom: 0;
      }

      .chzn-container span {
        max-width: 143px;
        min-width: 130px;
      }

      .chzn-results {
        max-height: 200px;
      }
    }

    .clear-filter {
      margin-left: -30px;
    }
  }

  strand-dropdown {
    &.select-status {
      min-width: 90px;
    }
    &.select-type {
      min-width: 80px;
    }
  }

  .filter {
    display: inline;
    &.form-list {
      display: inherit;
      margin: 0;
    }

    .search {
      .w-Filters {
        .column.form-list {
          width: 370px;
        }
        margin-top: 2px;
        margin-bottom: 0;
      }
      bottom: 7px;
      margin-left: 10px;

      .chzn-container-single .chzn-single span {
        margin-right: 26px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 97px;
      }
    }

    .chzn-container-single {
      .chzn-single {
        @include linear-gradient($from: $color-A12, $to: $color-A8);
        border: 1px solid $color-A5;
        @include border-radius($radius: 3px);

        div {
          padding-right: 2px;
        }

        b {
          @include sprite-at-position($left: 1, $down: 7);
        }

        span {
          color: $color-A1;
          font-family: $custom-font-regular;
          font-weight: normal;
          margin-right: 40px;
        }
      }
    }
  }

  .w-DataGrid {
    .column {
      padding-right: 30px;
    }
    .headers {
      .id {
        width: 3%;
      }
      .advertiser {
        width: 17.5%;
      }
      .number-of-loads {
        width: 8%;
        text-align: right;
      }
      .number-of-uniques {
        width: 8%;
        text-align: right;
      }
      .data-provider {
        width: 17.5%;
      }
      .name {
        width: 17.5%;
      }
      .frequent-referrer {
        width: 15%;

        .order-by {
          right: 14px;
        }
      }
      .modified {
        width: 6%;
      }
      .checkbox-input {
        width: 2.5%;
      }

      .sortable {
        cursor: pointer;
      }
      .order-by-pixel {
        margin-left: 5px;
        top: 1px;
        height: 11px;
        display: inline-block;
      }
    }

    .pixel-grid {
      @include box-fill($top: 117px);
      overflow: hidden;
      height: auto;
    }

    .loader-overrides.pixels {
      @include transparent($percent: 90);
      bottom: 36px;
      top: auto;
      left: 50%;
      position: absolute;
      width: 130px;
    }

    .toggle-arrow,
    .toggle-arrow .header-c {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .checkbox-input,
    .toggle-arrow {
      padding-right: 1.2%;
      width: 1%;
    }

    .toggle-arrow {
      width: 5px;
      top: 3px;
    }

    .icon-arrow {
      @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);
      margin-left: 4px;
    }

    .icon-arrow.expanded {
      @include sprite-icon($left: 1, $down: 0, $width: 14px, $height: 11px);
      margin-top: 3px;
    }

    .w-foot {
      margin-top: 5px;
    }

    .item-wrapper {
      .name {
        width: 17.5%;

        span.container {
          word-wrap: break-word;
          white-space: normal;
          max-width: 90%;
          display: inline-block;
        }
      }
      .id {
        width: 3%;
      }
      .advertiser.section {
        width: 17.5%;
      }
      .load-count {
        width: 8%;
        text-align: right;
      }
      .uniques.section {
        width: 8%;
        text-align: right;
      }
      .data-provider.section {
        width: 17.5%;
      }
      .frequent-referrer.section {
        width: 15%;
        word-wrap: break-word;
        .container {
          display: block;
          width: 164px;
        }
      }
      .modified {
        width: 3%;
      }
      .settings-icon {
        @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
      }
      .item {
        padding: 10px 0 10px 20px;
      }
      .pixel-edit-holder {
        position: absolute;
        right: 55px;
        display: none;

        // not an issue under shadow dom
        // but the background is overwritten in shady
        // TODO: This was handled in strand
        .icon-edit {
          background: none;
        }

        &.hover {
          display: block;
        }
      }
      .config {
        position: absolute;
        right: 25px;
      }

      &:hover {
        .pixel-edit-holder {
          display: block;

          &.suppress-hover {
            display: none;
          }
        }
      }
    }

    .expansion {
      background: $color-A10;
      border-bottom: 1px solid $color-A7;
      position: relative;
      font: 11px/14px 'AR';
      box-shadow: inset 0 2px 2px $color-A8, inset 0 -2px 2px $color-A8;
      padding-bottom: 10px;
      padding-top: 30px;
      padding-left: 30px;

      .editable-cursor {
        cursor: text !important;
      }

      .non-editable-cursor {
        cursor: pointer;
      }

      dt {
        color: $color-A4;
        font-family: PNLB;
        font-size: 10px;
        text-transform: uppercase;
      }

      .expansion-row {
        clear: both;
        padding-bottom: 60px;
      }

      .column {
        width: 175px;
        display: inline-block;
      }

      .pixel-code {
        width: 950px;
      }

      .info {
        width: 40px;
      }

      dd {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.tag-type {
          width: 180px;
        }

        &.pixel-tags {
          white-space: normal;
        }

        &.input {
          width: 80px;

          .form-input {
            width: 70px;
          }
        }

        &.info {
          .info-c {
            font-size: 12px;
          }
        }

        &.editable-c {
          overflow: visible;
          .select {
            overflow: visible;
            width: 140px;
            .chzn-drop {
              width: 140px !important;
            }
          }

          dl.form-list {
            width: 260px;
          }

          &:hover {
            width: 100px;
            .cost-ediable {
              display: block;
            }
          }

          .w-GroupInlineEditForm {
            padding-top: 5px;
          }
        }
      }

      .pixel-url {
        margin-left: 25px;

        dd {
          width: 700px;
          span {
            max-width: 700px;
            display: inline-block;
            white-space: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
          }
        }
      }

      .cost {
        margin-left: 25px;
      }

      .button-copy {
        width: 100px;

        .button {
          width: 100px;
          span {
            font-size: 10px;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
          }
        }

        .btn-secondary.active {
          @include inner-shadow-box($blur-radius: 5px, $sradius: 2px, $scolor: $color-A6, $background: $color-A8);
        }
      }
    }
    .w-foot {
      strand-popover {
        .body {
          min-width: 390px;
          min-height: 100px;
          font-size: 0;

          .right {
            float: right;
          }
          .left {
            float: left;
          }
          .clear {
            clear: both;
          }

          .event-url {
            margin-bottom: 10px;
            margin-right: 10px;

            .extra-space {
              margin-left: 85px;
            }

            .remove {
              cursor: pointer;
            }

            strand-action {
              margin-left: 10px;
            }

            strand-input {
              width: 230px;
            }
          }
        }
      }
    }
  }

  .bulk-email-modal {
    display: none;
  }
}

.pixels-export-email-modal {
  width: 600px !important;

  .head {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .optional {
    font-family: $custom-font-regular;
    font-size: 13px;
    font-style: italic;
    color: $color-A3;
  }

  .export-pixels {
    overflow: scroll;
    max-height: 360px;
    padding-right: 15px;
    margin-bottom: 38px;

    .pixel {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;

      .header-wrap {
        padding-bottom: 30px;

        .right {
          float: right;
          max-width: 400px;
        }

        .left {
          float: left;
          width: 400px;
        }
      }

      .pixel-tag {
        clear: both;
      }
    }
  }

  .export-settings {
    .field {
      margin-bottom: 10px;
    }
  }

  .messages {
    .error-message {
      b {
        @include sprite-icon($left: 8, $down: 10, $width: 28px, $height: 28px);
        float: left;
      }
    }
  }

  .footer-message {
    float: left;
    width: 190px;
  }
}

.pixels-export-download-modal {
  .head {
    margin-left: 0;
  }
  .info-message {
    color: $color-A4;
    font-size: 12px;
    font-style: italic;
    font-family: $unified-font-regular;
  }
}

.w-PixelsCreateEdit {
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;

  .check-radio-forms {
    .form-body {
      margin-bottom: 50px;
    }
  }

  .disabled {
    @include transparent($percent: 50);
    cursor: default;
    border-bottom: none;
  }

  .w-head {
    .title {
      padding: 18px 0 0 20px;

      strand-header {
        margin-left: 7px;
        margin-top: 2px;
      }
      .pixel-title {
        width: 550px;
      }
    }

    .advertiserName {
      margin: 10px 0 -17px 47px;
      display: block !important;
    }
  }

  .w-body {
    background: $color-A12;
    border-top: 1px solid $color-A7;
    position: relative;

    .data-attribute-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($color-A3, 0.1);
      z-index: 11;

      .search-loader {
        width: 170px;
        top: 200px;
      }
    }

    .implementation-keywords-row {
      width: 252px;
    }

    .implementation-keywords {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .form-stack {
    padding: 22px 32px 0;
    margin: 0;

    &.name {
      padding: 10px 32px !important;
    }
  }

  .form-list {
    box-sizing: border-box;
  }

  hr {
    border-bottom: 1px solid $color-A5;
    padding-top: 20px;
  }

  dd {
    width: 100%;

    // CSS Truncate
    &.agency,
    &.advertiser {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .section-header {
    .title {
      font-family: $custom-font-regular;
      font-weight: 100;
      font-size: 17px;
      color: $color-A3;
    }
  }

  .tab-info {
    margin: 2px 0 0 4px;
    position: absolute;
    display: inline-block;
  }

  /* Four Column Grid (ie: 1/4, 2/4, 3/4, 4/4)
	   dolson - Much clearer than Column-A/B/C thats happening elsewhere
	   Could easily change these to be fluid width */
  .col-1-4,
  .col-2-4,
  .col-3-4,
  .col-4-4 {
    @extend .column;
  }
  .col-1-4 {
    @include width-colx($x: 3);
  }
  .col-2-4 {
    @include width-colx($x: 6);
  }
  .col-3-4 {
    @include width-colx($x: 9);
  }
  .col-4-4 {
    @include width-colx($x: 12);
  }

  .status {
    .form-radio {
      width: auto;
      padding-right: 15px;
    }
  }

  .advertiser {
    &.form-label {
      padding-top: 5px;
    }

    label {
      cursor: default;
    }
  }

  .advertiser-select,
  .agency-select,
  .dataprovider-select {
    .form-list {
      min-width: 206px;
    }
  }

  .implementation {
    .form-radio {
      width: inherit;
    }
  }

  .attributionPair {
    label input[type='checkbox'] + span {
      font-family: $unified-font-regular;
    }

    .select-currency-dynamic {
      top: -2px;
    }

    .notes {
      position: absolute;
    }

    .pixel-info-icon {
      color: #999;
      &:hover {
        color: #666;
      }
    }
  }

  .pricing {
    .form-radio {
      width: auto;
    }

    .cost_cpm,
    .cost_pct_cpm {
      width: 38%;
      padding-right: 5px;

      input {
        width: 38%;
      }
    }

    .cost_pct_cpm {
      label {
        position: absolute;
        margin: 6px;
        width: 100px;
      }
    }
  }

  .eventUrlListContainer,
  .customAttributesContainer {
    padding-bottom: 100px;
  }

  .attributes-list {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 700px;

    .override-attributes {
      height: 25px;
    }

    .override-adv-custom-attributes {
      height: 15px;
      margin-bottom: 10px;
    }

    .standard-attributes-container {
      width: 700px;
      height: 30px;
      margin-bottom: 10px;

      .standard-attribute-tooltip-holder {
        width: 4%;
        height: 30px;
        line-height: 30px;
      }

      .standard-attribute-tooltip {
        color: #9a9a9a;
      }

      .tooltip-label-holder label {
        cursor: default;
      }

      .tooltip-label-holder a {
        cursor: pointer;
      }

      .standard-attributes-label {
        float: left;
        width: 16%;
        padding-right: 20px;
        margin: 0;
        font-family: PNR;
        font-size: 13px;
        font-weight: normal;
        line-height: 30px;
      }

      .standard-attributes-select {
        float: left;
        width: 80%;
        padding: 0;
        margin: 0;
      }

      .standard-attributes {
        width: 100%;
      }

      .chzn-container {
        .chzn-drop {
          z-index: 1010;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-top: 0;
          background: $color-A12;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18);
          border-radius: 0 0 4px 4px;
          width: 100%;
        }

        .chzn-results {
          max-height: 200px;
          li {
            -webkit-touch-callout: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-smoothing: antialiased;
            font-weight: 400;

            &.highlighted {
              color: $color-A12;
              background-color: $color-D15 !important;
            }
          }
        }

        &.chzn-container-multi {
          .chzn-choices {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: $color-A12;
            box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.125);
            color: $color-A1;
            font-family: Arimo, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-smoothing: antialiased;
            font-weight: 400;
            font-size: 13px !important;
            line-height: 15px !important;
            overflow: hidden;
            outline: 0;
            cursor: text;
            width: 100%;
            padding-left: 5px;

            li {
              float: left;

              &.search-field {
                margin: 0;
                padding: 0;
                white-space: nowrap;

                input[type='text'] {
                  margin: 1px 0;
                  padding: 5px;
                  height: 15px;
                  outline: 0;
                  border: 0 !important;
                  background: transparent !important;
                  box-shadow: none;
                  color: $color-A4;
                  font-size: 100%;
                  line-height: normal;
                  border-radius: 0;
                  width: 100%;
                  font-family: $unified-font-regular;
                  font-smoothing: antialiased;
                  font-style: italic;
                  font-weight: 400;
                }

                .default {
                  color: #999;
                }
              }

              &.search-choice {
                position: relative;
                margin-right: 5px;
                padding: 5px 20px 5px 5px;
                margin: 1px 1px 1px 5px;
                height: 15px;
                border-radius: 3px;
                color: $color-A1;
                background: $color-A19;
                line-height: 15px;
                cursor: default;

                .search-choice-close {
                  position: absolute;
                  top: 7px;
                  right: 3px;
                  display: block;
                  width: 12px;
                  height: 12px;
                  background: url('../images/chosen-sprite.png') -48px 0 no-repeat;
                  font-size: 1px;
                }

                .search-choice-close:hover {
                }
              }

              &.search-choice-focus {
                background: $color-A6;

                .search-choice-close {
                  background-position: -48px -11px;
                }
              }
            }
          }

          .chzn-results {
            margin: 0;
            padding: 0;
          }

          .chzn-drop {
            font-family: Arimo, sans-serif;

            .result-selected {
              display: list-item;
              cursor: default;
              color: $color-A5;
            }
          }
        }

        &.chzn-container-active {
          .chzn-choices {
            outline: 0;
            box-shadow: 0 0 6px rgba(171, 210, 246, 0.83);
            border: 1px solid rgb(171, 210, 246);

            li.search-field input[type='text'] {
              color: $color-A1 !important;
            }
          }
        }
      }
    }

    .pull-left {
      float: left;
    }

    .tooltip {
      color: $color-A4;
      margin: 0 7px !important;
    }

    .attributes-title {
      margin-bottom: 15px;
    }

    .delete-loader {
      display: none;
      position: fixed;
      z-index: 1000;
      left: 50%;
      top: 50%;
    }

    .data-key {
      padding-right: 9px;
    }

    .name {
      width: 409px;
    }

    .data-type {
      text-align: left;
    }

    .attribute {
      font: $unified-font-regular;
      font-size: 13px;
      font-weight: normal;
      display: inline-block;
      height: 29px;
      -webkit-font-smoothing: antialiased;
      &.name {
        width: 402px;
      }
      &.key {
        width: 83px;
      }
      &.read-only {
        margin-right: 8px;
      }
    }

    .error-msg {
      font: PNR;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .attributes-warning {
    p {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }

    .header {
      margin-bottom: 20px;
    }

    .segment-link {
      display: block;
      margin: 10px;
    }

    .content-top {
      padding: 15px;
    }
  }

  .w-itemList {
    padding: 0;

    .title {
      margin-bottom: 0;
      width: initial;
    }

    .list-item-row {
      border-bottom: none;

      .name {
        width: 430px;
        .input {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }

    .remove {
      top: 12px;
    }
  }

  .notes {
    margin-right: 0;

    p,
    ul {
      font-size: 12px;
      line-height: 14px;
      font-family: $custom-font-regular;
      color: $color-A4;
      padding-bottom: 15px;

      b {
        font-family: $custom-font-bold;
      }

      a {
        color: $color-D1;
      }
    }

    ul {
      margin-top: -10px;
      padding-left: 10px;

      li {
        list-style-type: disc;
        list-style-position: inside;
      }
    }
  }

  .form-footer {
    .action {
      .disabled,
      .save-disabled,
      .saveAndContinue-disabled,
      .save-action,
      .saveAndContinue-action {
        pointer-events: none;
      }
    }
  }
}

.w-toToolTipError {
  .tipsy-inner {
    color: $color-A12;
    background-color: $color-C1;
    padding: 8px;
  }

  .tipsy-arrow-s {
    border-top-color: $color-C1;
  }
}
