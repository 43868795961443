/***
* Base Colors
***/
$color-A0: #000;
$color-A1: #323232;
$color-A2: #383838;
$color-A3: #525252;
$color-A4: #9a9a9a;
$color-A5: #c3c3c3;
$color-A6: #d5d5d5;
$color-A7: #e0e0e0;
$color-A8: #e7e7e7;
$color-A9: #f0f0f0;
$color-A10: #f5f5f5;
$color-A11: #edf1f4;
$color-A12: #fff;
$color-A13: #191919;
$color-A14: rgba(223, 222, 227, 1);
$color-A15: rgba(255, 255, 255, 0);
$color-A16: #f3f4f8;
$color-A-17: #e1e1e1;
$color-A18: #aaaaaa;
$color-A19: #e5e5e5;
$color-A20: #438dca;
$color-A21: #9ecb5c;
$color-A22: #ea5151;
$color-A23: #ea5151;
$color-A24: #f1f3f7;

$color-B1: #86c633;
$color-B2: #adcf52;
$color-B3: #b9dc5a;
$color-B4: #c4ea5e;
$color-B5: #8ab516;
$color-B6: #98d826;
$color-B7: #d2e995;

$color-C1: #e10707;
$color-C2: #f85252;
$color-C3: #cb5f5f;
$color-C4: #dc6d6d;
$color-C5: #ff9999;
$color-C6: #fcbebf;
$color-C10: #fdeeee;

$color-D1: #6681a8;
$color-D2: #c5d2e5;
$color-D3: #40587c;
$color-D4: #5d769c;
$color-D5: #395173;
$color-D6: #344967;
$color-D7: #eaf5fb;
$color-D8: #acd3f5;
$color-D9: #98cbf3;
$color-D10: #7fa9cf;
$color-D11: #264964;
$color-D12: #f2f4f8;
$color-D13: #d5eefa;
$color-D14: #e9f6fb;
$color-D15: #3094c9;
$color-D16: #013d71;

$color-E1: #fc8f00;
$color-E2: #fdb600;
$color-E3: #c96004;
$color-E4: #e1c140;
$color-E5: #e8c849;
$color-E6: #f6d551;
$color-E7: #ffffcc;
$color-E8: #fcf2ca;

// Chart Colors
$color-F1: #ea661e;
$color-F2: #00a8cc;
$color-F3: #1ca591;
$color-F4: #eac616;
$color-F5: #7f87d0;
$color-F6: #9c8369;
$color-F7: #c32921;
$color-F8: #4a89fa;
$color-F9: #71873e;
$color-F10: #dedb08;
$color-F11: #fd9303;
$color-F12: #90245c;
$color-F13: #c6186d;
$color-F14: #1860c2;
$color-F15: #b2b453;
$color-F16: #f0bf2a;
$color-F17: #b04299;
$color-F18: #c28a69;
$color-F19: #d5384d;
$color-F20: #4694a8;
$color-F21: #6cc068;
$color-F22: #f2981e;
$color-F23: #bc96be;
$color-F24: #ce842d;
$color-F25: #fc6062;
$color-F26: #4dc1e7;
$color-F27: #1ca591;
$color-F28: #ffd527;
$color-F29: #3e537b;
$color-F30: #d49719;

$chart-colors: $color-A0, $color-F1, $color-F2, $color-F3, $color-F4, $color-F5, $color-F6, $color-F7, $color-F8,
  $color-F9, $color-F10, $color-F11, $color-F12, $color-F13, $color-F14, $color-F15, $color-F16, $color-F17, $color-F18,
  $color-F19, $color-F20, $color-F21, $color-F22, $color-F23, $color-F24, $color-F25, $color-F26, $color-F27, $color-F28,
  $color-F29, $color-F30;
