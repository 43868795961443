.w-BulkeditLocationTargeting {

  .browser-c {

    .w-DataGrid .content {
      bottom: 61px;
    }

    .content-holder {
      height: 100%;

      .type {
        width: 100%;
      }
    }

    .item-wrapper {
      position: relative;
    }

    .empty-spacer {
      width: 25px;
    }

    .children {

      .children {
        position: relative;
        padding-left: 30px;
        margin-left: -30px;

        .detail .icons {
          margin-left: 10px;
        }

        .item-row {
          padding-left: 30px;
        }

        .children {
          .item {
            padding-left: 60px;
          }
        }
      }

      .item {
        padding-left: 55px;
      }

      .detail .icons, .detail .type {
        margin-left: 40px;
      }

      .item-wrapper {
        position: static;
      }
    }

    .w-foot {
      @include box-fill($top: auto, $right: -18px, $bottom: 36px);

      .browse-path {
        bottom: 0;
        background: $color-A7;
        padding: 7px 15px 6px 13px;
        font-family: $custom-font-regular;
        font-size: 12px;
        color: $color-A3;
        line-height: 13px;
      }
    }

    .search {
      .item-row {
        margin-left: 50px;
      }

      .arrow {
        display: none !important;
      }

      .w-foot {
        visibility: hidden;
      }
    }
  }

  .browser-c,
  .postal-codes {
    .icons {
      position: absolute;
      width: 40px;
      margin-left: 10px;
      left: 0;
      z-index: 1;
    }

    .icon {
      float: left;
      .icon-spinner {
        margin-top: -10px;
        margin-left: 5px;
      }
      .show {
        opacity: 1;
      }
      .hide {
        opacity: 0;
      }
    }
  }

  .postal-codes {
    @include box-fill($top: auto);

    background-color: $color-D12;
    height: 26px;
    padding: 0 18px 10px 20px;
    margin-left: -20px;
    margin-right: -14px;

    .icons {
      float: left;
      width: 150px;
      padding-top: 10px;
      margin-left: 30px;

      span {
        margin-left: 5px;
        font: 12px/12px $custom-font-regular;
        vertical-align: top;
      }
    }

    .file-uploader-c {
      margin-left: 150px;
    }

    .uploadSpinner {
      position: absolute;
      right: -4px;
      top: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .w-Locations-Bins {
    bottom: 42px;

    .group-name {
      border-bottom: 1px solid $color-A7;
    }

    .segment {
      width: 98% !important;
    }

    .download-label {
      display: inline !important;
      float: left !important;
      width: 80% !important;
    }

    .download {
      @include sprite-icon($left: 11, $down: 2, $width: 11px, $height: 16px);
      @include transparent($percent: 30);
      float: right;
      margin: 2px 23px 0 0;
    }

    .bin-default {
      font-family: $unified-font-bold-italic;
      font-size: 10px;
    }

    .edit-options {
      height: 30px;
      background: $color-A7;
    }

    .header-text {
      position: absolute;
      top: 10px;
    }

    .info-tooltip {
      margin-left: 113px;
      margin-top: 4px;

      #addRemoveOverwriteInfoIcon {
        opacity: 0.5;
        color: #666666
      }

      #addRemoveOverwriteInfoIcon:hover {
        color: #000000;
      }
    }

    .bulk-edit-options {
      position: absolute;
      top: 10px;
      left: 138px;
    }
  }

  .hidden {
    display: none;
  }

  .disabled {
    @include transparent();

    .select-action {
      cursor: text !important;
    }
  }

  strand-modal .postal-code-modal {
    width: 300px;
    height: 300px;
  }

  strand-modal p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  strand-modal .postal-modal-header {
    margin-bottom: 20px;
  }

  strand-modal .content-top {
    padding: 15px;
  }

  strand-modal .warning-stand {
    background: url(../images/mm_sprite.png) -224px -112px no-repeat;
    display: block;
    height: 28px;
    width: 28px;
    float: left;
  }
  strand-modal .postal-grid {
    width: 500px;
    height: 350px;

  }
  strand-modal .total-len {
    margin-top: 15px;
  }
}