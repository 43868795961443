.w-AdvertiserFilter {
	@extend .select-filter;

	.chzn-container {
		width: 100%;

		.chzn-single {
			z-index: 3;
		}
	}

	.chzn-drop {
		min-width: 100%;
	}

	.chzn-container-active .chzn-drop {
		z-index: 2;
	}
}
