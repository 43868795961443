.w-Creatives {

	.browse-c {
		@include box-fill($top: 50px, $bottom: 80px);
	}

	.w-concepts-Bins {
		bottom: 50px;
	}

  .jumpToConcept, #jumpToCreative {
	  margin-left: 6px;
	  color: #666666
	}

	#inactiveConceptIconStrategies {
    opacity: 0.5;
    color: #666666
  }

  #inactiveConceptIconStrategies:hover {
    color: #000000;
  }

  .non-secure-warning {
  	color: #9ecb5c;
  }
	
  .nonSecureWarningIconTooltipBox {
  	text-align: left;
  }

  #inactiveConceptIcon {
		color: #FED01A;
  }

	.concept-link-icon-hold, 
	.creative-link-icon-hold {
    	display: none;
    }

	.w-DataGrid {
		@include box-fill();

		.type {
			width: 90%;
			margin-left: 10px;
		}

		.icons {
			position: absolute;
			width: 40px;
			margin-left: 10px;
			left: 0;
			z-index: 1;
		}

		.text {
			height: 20px;
			width: 92%;
		}

		.icon {
			float: left;
		}

		.arrow-icon-list {
			margin-left: 5px;
		}
		
		.exclude {
			margin-left: 0;
		}

		.concept {
			width: 63%;
		}

		.date-added {
			width: 8%;
		}

		.headers{

			.name {
				width: 28.5%;
			}

			.creative-id {
				width: 10%;
				text-align: right;
			}

			.secure {
				width: 10.3%;
				text-align: right;

				.header-label {
					margin-right: 9px;
				}

				.info-icon {
					margin-right: -5px;
				}
			}

			.size,
			.date-added,
			.start-date,
			.end-date {
				width: 10.3%;
				text-align: right;
			}
		}

		.item {
			border-bottom: 1px solid $color-A11;
		}

		.children {
			.item {
				background: $color-A12;
				margin-left: -15px;
				padding-left: 80px;
			}

			.creative {
				width: 25%;
			  	margin-top:-2px;

			  	.text{
					display:inline-block;
					width:100%;

					&.hasErr{
					  width:90%;
					}

					// CSS Truncate
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				 }

				.adx-error
				{
					position: absolute;
					@include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
				}

			  	.errorSignOff{
					display:none;
				 }

				.item-c {
					white-space: nowrap;
				}
				.adx-warning
				{
					position: absolute;
					@include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
				}

				.adx-error, 
				.adx-warning
				{
					top: 0px;
					right: -20px;
					z-index: 1;
				}
			}

			.creative-id {
				width: 10%;
				text-align: right;
			}

			.date-added {
				width: 11%;
				text-align: right;
			}
			.size,
			.secure,
			.start-date,
			.end-date {
				width: 11%;
				text-align: right;
			}
		}

		.parent-selected {
			.item {
				background-color: $color-D14;
			}
		}
	}

	.drag {
		padding-top: 12px;
	}

	.bin {
		height: 100%;

		.creatives-list-name {
			width: 40%;
			margin-left: 5px;
		}

		.input-container {
			left: 105px;
		}

		.weighting {
			width: 31%;
			
			&.header {
				top: -2px;
			}

			strand-dropdown {
				text-transform: initial;
				z-index: 1;
				top: -2px;
			}

			//FF
			strand-dropdown .button {
				z-index: 1; 
			}

			.weighting-input {
				width: 28%;
				cursor: initial;
				height: 16px;
				font-size: 11px;
				font-family: Arimo;
			}

			.header-c {
				width: 235px;
			}
		}

		.no-weighting {
			display: none;
		}

		.segment {
			width: 40%;
		}

		.error {
			border: 1px solid $color-C2;
  		}

		.title {
			margin-left: 0;
		}

		.header-c span {
			margin-left: 5px;
		}

		.bins-foot {
			.footer-title {
				width: 70%;
				top: 4px;
			}
			.total-weight {
				top: 4px;
			}
		}

		.content-with-footer .bin-content {
			bottom: 21px;
		}
	}

	.disabled {
		@include transparent();

		.select-action {
			cursor: text !important;
		}
	}
}
