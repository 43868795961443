@import 'mixins';
.truncate {
  word-wrap: break-word;
}

.ui-tab-disabled {
  pointer-events: none;
}

.li-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.pmpe-tipsy {
  width: 250px;

  .tipsy-inner {
    white-space: normal;
    word-wrap: break-word;

    dt {
      text-transform: capitalize;
    }
    label {
      text-transform: none;
    }
  }
}

.w-OpenSupply .w-foot input {
  cursor: pointer;
}

.unpermissioned-deals-modal {
  display: block;
  strand-header {
    margin: 25px 0 25px 20px;
  }

  #container {
    height: 210px;
    padding-bottom: 60px;
  }

  .unpermissioned-deals-grid {
    height: 120px;
    margin: 0 20px;
    min-height: 0;
  }

  .unpermissioned-deals-modal-footer {
    bottom: 0;
    position: absolute;

    .save-action label {
      visibility: hidden;
    }

    .left {
      align-items: center;
      display: flex;

      .unpermissioned-deals-info-icon {
        color: $color-A20;
      }

      .unpermissioned-deals-info {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}

.w-PMPE {
  & + .bins-column .drop-area {
    display: none;
  }

  .w-Filters .chzn-container {
    width: 110px;
  }

  .filter,
  .select-status,
  .deal-groups-select-status,
  .select-status .form-label,
  .deal-groups-select-status .form-label {
    float: left;
    position: relative;
  }

  .deal-groups-search-filter {
    display: none;
  }

  .select-status,
  .deal-groups-select-status {
    margin: 8px 30px;
    width: auto;

    .form-label {
      color: $color-A3;
      font-size: 14px;
      font-weight: 100%;
      line-height: 26px;
    }

    .chzn-container {
      float: right;
      margin-left: 12px;
      width: 77px;
    }
  }

  .add-deal {
    float: right;
    margin-top: -10px;

    .add-action {
      @extend .btn-add-primary;
      width: 120px;
    }
  }

  .deals-warning-strat {
    display: block;
    margin: 5px 0;
  }

  .browser-c .w-DataGrid,
  .deal-group-grid .w-DataGrid {
    left: 18px;
    top: 87px;

    .t1-tooltip.tooltip-tabs {
      border-style: none;
      padding: 0;
    }

    .item-wrapper {
      border-bottom: 1px solid $color-A11;
    }

    .selected {
      border-style: none;
      margin-top: 0;
    }

    .icons {
      margin-right: 12px;
    }
  }

  .deal-group-grid {
    display: none;

    .w-DataGrid {
      .icons {
        margin-right: 8px;
      }
    }

    .expansion {
      .column.header {
        margin-right: 8px;
      }
      .item-row .column {
        margin-right: 8px;
      }

      .no-results {
        align-items: center;
        display: flex;
        height: 33px;
        justify-content: center;
        margin-top: 0;
      }

      .name {
        width: 24%;
      }

      .id {
        width: 14%;
      }

      .supply-source {
        width: 12%;
      }

      .deal-type-header {
        width: 16%;
      }

      .start,
      .end {
        width: 9%;
      }

      .cpm.header,
      .deal-cpm {
        margin-right: 0;
      }
    }
  }

  .pmpe-list--no-edit {
    .w-DataGrid {
      top: 90px;
    }
    .deal-edit {
      visibility: hidden;
    }
  }

  .headers {
    cursor: default;
    padding: 10px 0 6px;

    .deal-name,
    .deal-group-name {
      margin-left: 38px;
      width: 24%;
    }
  }

  .deal-name,
  .deal-group-name {
    cursor: pointer;
    padding-left: 0;
    width: 24%;

    .truncate {
      width: 100%;
    }
    .item {
      max-height: 26px;
    }
  }

  .deal-id {
    width: 14%;
  }

  .deal-group-id {
    width: 12%;
  }

  .deal-id,
  .deal-group-id {
    cursor: pointer;
    margin-right: 8px;
  }

  .deal-type {
    cursor: pointer;
    width: 16%;
  }

  .deal-supply-source,
  .deal-group-supply-source {
    cursor: pointer;
    width: 10%;
  }

  .deal-start,
  .deal-group-start {
    cursor: pointer;
    width: 9%;
  }

  .deal-end,
  .deal-group-end {
    cursor: pointer;
    width: 9%;
  }

  .deal-price,
  .deal-group-price {
    cursor: pointer;
    width: 10%;
  }

  .deal-edit {
    margin: -6px 0 -2px;

    .edit-actions {
      @include sprite-icon($left: 4, $down: 9, $width: 28px, $height: 22px);
    }
  }

  .order-by {
    left: -10px;
    right: auto;
  }
}

.w-Supply .warning .w-DataGrid {
  bottom: 157px;
}

.w-OpenSupply.warning {
  .w-DataGrid {
    bottom: 58px;
  }

  .column-A {
    top: 100px;
    bottom: 0;
    height: auto;
  }
}

.w-OpenSupply {
  .w-Bins .data-row .group-name .group-count {
    color: $color-D10;
    font: 12px/14px $custom-font-semi-bold;
    letter-spacing: 0.3px;
  }

  .w-DataGrid .content-holder {
    height: 94%;
  }

  .run-all-radio-2 {
    .w-DataGrid {
      top: 130px;
    }
  }

  .run-all-radio-3 {
    .w-DataGrid {
      top: 160px;
    }
  }

  .run-all-radio-2-and-video {
    .w-DataGrid {
      top: 160px;
    }
  }

  .run-all-radio-3-and-video {
    .w-DataGrid {
      top: 190px;
    }
  }

  .run-all-checkbox {
    .w-DataGrid {
      top: 76px;
    }
  }

  .run-all-checkbox-and-video {
    .w-DataGrid {
      top: 105px;
    }
  }

  .tabWarning {
    background: $color-E7;
    border-radius: 3px;
    border: 1px solid rgba(226, 123, 8, 0.86);
    height: 50px;
    margin: 12px;
    padding: 12px;
    width: 71% !important;
    z-index: 5;

    .icon {
      @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 22px);
      margin-right: 8px;
    }

    .mainText {
      color: #111111;
      font: 18px/22px $custom-font-semi-bold;
    }

    .infoText {
      color: $color-A3;
      font: 11px/14px $custom-font-semi-bold;
      padding: 8px 0 0 36px;
    }

    .hideIcon {
      @include sprite-icon($left: 7, $down: 0, $width: 18px, $height: 18px);
      cursor: pointer;
      float: right;
      right: 10px;
    }
  }

  .checkbox {
    cursor: pointer;
  }
  .column-A {
    width: 75% !important;
  }
  .bins-column {
    width: 25% !important;
  }

  .w-Bins {
    .bin {
      height: 100%;

      .segment {
        width: 60%;
      }
    }

    .warning {
      top: 27px;
      left: 0;
      right: 0;
      width: inherit;
    }

    .unpermissioned-deals-box {
      width: 100%;

      .open-unpermissioned-deals-modal {
        text-decoration: underline;
        display: inline;
      }

      .open-unpermissioned-deals-modal:hover {
        cursor: pointer;
      }
    }

    .unpermissioned-deals-box.empty {
      position: absolute;
      top: 40px;
      z-index: 1;
    }

    /* Hide Drag and Drop Logo */
    .drop-area {
      border: 0;
    }

    .drop-area-c .drop-icon,
    .drop-area-c .drop-text {
      display: none;
    }
  }

  .w-DataGrid {
    bottom: 55px;
    left: 0;
    position: absolute;
    right: 0;
    top: 76px; // default match .run-all-checkbox style

    .item {
      cursor: default;

      &.can-toggle {
        cursor: pointer;
      }
    }

    .icons {
      width: auto;

      .icon {
        cursor: pointer;
      }
      .icon + .icon {
        margin-left: 8px;
      }

      .icon-arrow-down {
        transform: rotate(90deg);
        background-position: -136px -252px;
      }
    }

    .column {
      position: relative;

      &.type {
        margin-left: 15px;
        width: 90%;
      }

      &.type.col-1 {
        margin-left: 5px;
        width: 81%;
      }

      &.type.col-3 {
        margin-left: 5px;
        width: 61%;
      }

      &.type.col-4 {
        margin-left: 5px;
        width: 51%;
      }

      &.type.deal-name,
      &.type.deal-group-name {
        // this style overrides the $.type style above.
        // it's specific for pmpe and is necessary to make
        // drag n drop on pmpe work properly.
        margin-left: 0;
        width: 24%;
      }

      &.drag {
        padding-top: 0;
        width: 15px;
      }

      &.deal-edit,
      &.deal-edit--empty {
        // the gear on pmpe
        margin-right: 13px;
      }

      &.deal-edit--empty {
        height: 20px;
        width: 27px;
      }
    }

    .children.hide {
      display: none !important;
    }
  }

  .w-OpenSupply-Bins {
    .group-name {
      border-bottom: 1px solid $color-A7;
    }

    .warning {
      background: $color-E7;
      border-bottom: 1px solid $color-A7;
      padding: 8px;
      position: absolute;
      width: 94%;
      z-index: 10;

      .icon {
        @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 22px);
        margin-right: 8px;
      }

      .size {
        right: 20px !important;
      }

      .text {
        color: $color-A3;
        font: 11px/14px $custom-font-semi-bold;
        padding-top: 5px;
      }
    }
  }

  .item-wrapper {
    position: relative;

    .item {
      padding: 10px 0 6px;

      .item {
        padding-left: 60px;
      }
    }

    .site_placement-item {
      padding-left: 20px;
    }
  }

  .fptHolder {
    background-color: $color-D12;
    border-top: 1px solid $color-A7;
    bottom: -59px;
    height: 47px;
    left: -18px;
    padding-left: 18px;
    padding-right: 18px;
    position: absolute !important;
    width: 100%;
    z-index: 1;

    .form-label {
      float: left;
      font-size: 13px !important;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 14px;
    }

    .checkboxholder {
      float: left;
      padding-top: 13px;

      .exclusivenessCheckBoxLabel {
        font-size: 10px !important;
      }
    }
  }

  .icons {
    left: 0;
    width: 40px;
    z-index: 1;
  }

  .icon {
    float: left;
  }

  .search-result {
    .w-foot {
      visibility: hidden;
    }
  }

  .run-all-opts-holder {
    .run-all-opts-header {
      display: block;
      margin-bottom: 10px;
    }
  }
}
