.w-DayPartTargeting {
    .w-head,
    .w-body{
      margin-left: 20px;
      position: relative;
    }

	.btn-add-primary {
		float: right;
		margin: 15px;
	}

    .add-action-disabled {
    	@extend .btn-add-primary;
    	@include transparent($percent:50);
    }

	.run-all-day-parts {
		float: left;
		margin: 20px 0 20px 0;
	}

	.w-panel-body {
		top: 57px !important;
	}

	.headers {
		background: $color-A7;
		padding: 7px 0 6px 20px;
	}
	
	.header {
		color: $color-A3;
		background: $color-A7;
		font: 10px/14px $custom-font-semi-bold;
		text-transform: uppercase;
	}

	.days {
		width: 28%;

		.select-interval, .day {
			float: left;
		}

		.chzn-container {
			width: 30%;
			float: left;
		}

		.chzn-drop ul li:first-child {
			display: none; // 'Select One' should not be selectable
		}
	}

	.startHour, .endHour {
		width: 17%;
	}

	.style-hour .chzn-container {
		width: 34%;

		.chzn-drop ul {
			height: 200px;
			overflow-y: scroll;
		}
	}

	.style-ampm .chzn-container {
		width: 54px; // px instead of % for correct rendering on large screens
	}

	.allDay {
		width: 11%;
	}

	.relativeTo {
		width: 22%;
	}

	.remove {
		width: 5%;
	}

	.w-DayPartItem {
		font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid $color-A5;

		.days,
        .startHour,
        .endHour,
        .style-hour .chzn-container,
        .style-ampm .chzn-container,
        .allDay,
        .relativeTo,
        .remove {
			float: left;
            position: relative;
		}

        .remove-icon {
          @include sprite-icon($left: 5, $down: 10, $width: 10px, $height: 10px);
          @include transparent(50);
          position: absolute;
          top: 8px;
          right: 6px;

          &:hover {
            @include transparent(100);
          }
        }
	}

    .select-days {
        position: relative;
        left: 10px;
        top: 1px;

        .title {
          @include linear-gradient($from: $color-A12, $to: $color-A8);
          border: 1px solid $color-A5;
          border-right: none;
          padding: 4px 8px 5px;

          cursor: pointer;
          cursor: hand;
        }

        .checked {
          background: $color-B1;
        }

        .start.title {
          @include border-radius($radius: 3px 0 0 3px);
        }

        .end.title {
          @include border-radius($radius: 0 3px 3px 0);
          border-right: 1px solid $color-A5;
        }
    }

    .select-relative-to{
        top: 4px;
    }
    .select-checkbox-all-day label{
        position: relative;
        top: 6px;
    }
}
