.create-export-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 60px;
    left: 0;
    
    .title-box {
        background: $color-D12;
    }

    .title {
        float: left;
        height: 17px;
        padding-left: 20px;
        margin: 25px 0px 25px 0px;
    }

    .segment-export {
        background: $color-A12;
        height: calc(100% - 150px);
    }

    .list-of-segments {
        width: 74%;
        display: inline-block;
        height:100%;
    }

    .segments-bin-list {
        height:100%;
        width:25.5%;
        display: inline-block;
    }

    .frequency-text {
        font-family: $unified-font-regular;
        font-size: 14px;
        margin-top: 5px;
    }

    .option {
        display: inline-block;
        margin: 1em;
        width: 300px;
        strand-header {
            display:block;
        }
    }

    .grid-bin-container {
        background: $color-A12;
        height: 100%;
    }

    .segment-message {
        padding: 20px;
        display: none;
    }

    .form-footer {
        left: 113px;
    }

    .footer {
        .add-button {
            width: 125px;

            .spinner {
                left: 50px !important;
            }
        }
    }

}

.exports-list-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 60px;
    left: 0;

    .segment-message {
        display: none;
        padding: 20px;
    }

    .title-box {
        background: $color-D12;
    }

    .title {
        float: left;
        height: 17px;
        padding-left: 20px;
        margin: 25px 0px 25px 0px;
    }

    .segment-export {
        background: $color-A12;
        height: calc(100% - 136px);
    }

    .extendActionModal {
        strand-header {
            margin-bottom: 20px;
            display: block;
        }

        .content-top {
            padding: 15px;
        }

        .extend-button {
            width: 60px;
        }

        .spinner {
            left: 19px !important;
        }
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .form-footer {
        left: 113px;
        height: 60px
    }
}


.running-exports {
    background: $color-A12;
    height: 100%;

    .grid-container {
        background: $color-A12;
        height: 100%;
    }

    .active-exports-action-bar {
        min-height: 30px;
        padding: 20px 20px 0px 20px;
    }

    .segments-search-container {
        float: left;

        .segments-search {width: 350px;}
    }

    .filter-status-container {
        float: left;
        width: 120px;
        margin-left: 10px;
    }

    .selector-container {
        float: right;
        margin-right: 10px;
        width: 150px;
    }

    .add-export-container {
        float: right;
        width: 120px;
    }

    .exports-list {
        width: 100%;
        height: 100%;
        display: inline-block;
        padding-top: 20px;
    }
}

strand-icon#selected {
	color: $color-B1;
	&:hover {
		color: $color-B1;
	}
}
strand-icon#deselected {
	color: $color-A5;
	&:hover {
		color: $color-A13;
	}
}