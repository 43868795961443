.w-OpenSupply {
  .supply-source-1 {
    width: 84%;
  }

  .supply-source-3 {
    width: 64%;
  }

  .supply-source-4 {
    width: 54%;
  }

  .fraud-filtered,
  .mobile,
  .desktop,
  .connected-tv {
    width: 10%;
    text-align: center;

    .icon {
      @include rounded-box(
        $padding: 0,
        $background: $color-A4,
        $border-radius: 20px,
        $border-rule: 1px,
        $border-color: $color-A4
      );

      width: 8px;
      height: 8px;
      margin-left: 45px;
    }
  }
}
