.w-CampaignListItem {

	.headers {
		@extend .box-fix;
		cursor: pointer;
	}

	.header {
		color: $color-A4;
		font-size: 9px;
		font-weight: normal;
	}

	.section {
		cursor: pointer;
	}

	.section-c {
		margin-right: -10px;
		padding-right: 10px;
		display: block;
		cursor: pointer;
		clear: both;
	}

	.data {
		font-size: 12px;
		line-height: 22px;
		color: $color-A1;
		text-align: right;

		.symbol {
			font-size: 12px;
			margin-right: 3px;
			position: relative;
			vertical-align: top;
		}
	}

	.date-range {
		.data {
			font-size: 11px;
		}
	}

	.budget-flight-status-holder {
		position: absolute;
		right: 66px;
		top: 21px;

		span.t1-tooltip {
			@include sprite-icon($left: 9, $down: 11, $width: 15px, $height: 15px);
		}
	}

	.campaign-edit-holder {
		position: absolute;
		right: 42px;
		top: 21px;

	    .campaign-edit {
		  color: #676767;
		}
	}

	.actions {
		position: absolute;
		right: 15px;
		top: 16px;
		width: 60px;

		.status,
		.settings {
			@include linear-gradient($from: $color-A12, $to: $color-A8);
			display: inline-block;
			margin-left: -3px;
			padding: 5px 6px;
			border: 1px solid $color-A6;
		}

		.settings {
			position: relative;
		}

		.action {
			float: none;
		}
	}

	.accordion .actions {
		width: 25px;

		.status,
		.settings {
			background: transparent;
			display: inline-block;
			margin-left: -3px;
			padding: 5px 2px;
			border: 0;
		}
	}

	.alloc-chart-icon {
		margin: -20px -17px 0 0 !important;
	 }

	.alloc-currency-code {
		margin-right: -21px !important;
	}

	.chart-icon {
		float: right;
		margin: -20px -18px 0 0;

		&.disabled {
			@include transparent();
		}

		span {
			@include sprite-icon($left: 8, $down: 8, $width: 18px, $height: 18px);
			display: inline-block;
			float: left;
		}
	}

	.currency-code {
	  float: right;
	  margin-top: -32px;
	 margin-right: -21px;

	}

  .settings-open {
		display: block;
	}

	.permission-locked {
		.start-date,
		.end-date {
			border-bottom: 0;
		}
	}

	.raised-amount {
		background: $color-A5;
		padding: 0 8px;
		margin-right: 10px;
		font-size: 12px;
		float: right;
	}

	.type {
		text-transform: uppercase;
		font-size: 9px;
	}

	.settings-icon {
		@include sprite-icon($left: 4, $down: 9, $width: 15px, $height: 15px);
	}

	.status-icon {
		@include sprite-icon($left: 3, $down: 9, $width: 12px, $height: 13px);
		margin-left: 3px;
		margin-top: 2px;
	}

	.settings {
		@include border-radius($radius: 0 3px 3px 0);

		cursor: pointer;
		width: 15px;
		height: 15px;

		.permission-locked {
			@include transparent();
		}
	}

	.status {
		@include border-radius($radius: 3px 0 0 3px);
	}

	.status-single {
		@include border-radius($radius: 3px);
		margin-right: 30px;
	}

	.performance-alert {
		.actual .data,
		.strategy-type {
			color: $color-C2;
		}
		.symbol {
			color: $color-A13;
		}
	}

	.ecpa,
	.ecpc,
	.ecpm {
		.header-c,
		.title {
			text-transform: none;
		}
	}

	.inactive {
		.header,
		h2,
		h3,
		span:not(.sb-section-content-item-text):not(.sb-section-content-header):not(.boldTextSummaryBadge):not(.header-span):not(._mm_icon):not(.strand-icon),
		dd,
		div:not(#container) {
			color: $color-A4 !important;
		}

		.date-range {
			.days-remaining {
				border-color: $color-A6 !important;
			}
		}

		.w-StrategyDrawer {
			.section-c {
				border-color: $color-A6 !important;
			}
		}

		.status-icon {
			@include sprite-at-position($left: 2, $down: 9);
		}

		.strategy-actions .status-icon {
			@include sprite-at-position($left: 2, $down: 9);
		}

		.budget-meter {

			.label .currency,
			.label .symbol {
				color: $color-A4 !important;
			}

			.bar {
				@include transparent();

				.colored-bar .highlight {
					position: relative;
				}

				.colored-bar {
					.solid {
						background: $color-A5;
					}

					.highlight {
						@include _linear-gradient($from: $color-A5, $to: $color-A6);
					}
				}
			}
		}

		.budget-meter .arrow,
		.frequency-cap-icon,
		.drawer-expander {
			@include transparent(50);
		}
	}

}

.campaign-filters {
	float: left;
	margin-top: 3px;

	.w-Filters .auto-complete {
		width: auto;
	}

	.w-body {
		float: left;
	}

	.auto-complete-results {
		margin-left: -2px;

		.ui-menu-item {
			.ui-state-hover,
			:hover,
			:focus {
				color: white;
				background-color: $color-D15;
				border-radius: 0;
			}
		}
	}

	.chzn-container .chzn-results {
		.highlighted,
		:hover {
			color: white !important;
			background-color: $color-D15 !important;
		}

		.result-selected {
			font-family: $custom-font-semi-bold;
			background-color: $color-D7;
			color: $color-D11;

			&:hover {
				color: white;
				background-color: $color-D15;
			}
		}
	}
}

.campaign-headers {
	.campaign-edit-holder,
	.campaign-actions-holder {
		display: none;

		&.hover  {
			display: block;
		}
	}

	&:hover {
		.campaign-edit-holder,
		.campaign-actions-holder  {
			display: block;

			&.suppress-hover  {
				display: none;
			}
		}
	}

}

strand-modal[class^="deactivate"] .warning-icon {
	color: $color-E5;
}
