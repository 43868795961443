@import "mixins";

clear {
  &:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
}

body {
  @include radial-gradient($color-from: $color-D4, $shape-size: circle farthest-corner, $color-to: $color-D3, $color-bg: $color-D3, $swidth: 85%);
}

.layout {
  @extend clear;
}

.column {
  @extend clear;
  display: inline;
  float: left;
  position: relative;
  zoom: 1;
}

.column-empty {
  @extend clear;
  line-height: 0;
  margin: 0 0 1px;
  padding: 0;
}

.stack {
  @extend clear;
  clear: both !important;
  display: block !important;
  zoom: 1;
}

.last { margin-right: 0 !important; }

.page-box {
  margin: 0 auto;
  text-align: left;
}

.page-head,
.page-body,
.page-foot {
  @extend clear;
  clear: both !important;
  display: block !important;
  text-align: left;
  zoom: 1;
}

.page-head-c,
.page-body-c,
.page-foot-c  {
  @extend clear;
}

.w-box {
  @extend clear;
}

.w-body {
  @extend clear;
}

.w-head {
  @extend clear;
}

.w-head-c,
.w-body-c {
  @extend clear;
}

.w-foot {
  @extend clear;
}

.w-foot-c {
  @extend clear;
  padding-left: $unit;
  padding-right: $unit;
}

.controls {
  @extend clear;
  text-align: right;

  .control {
    display: block;
    float: right;
    margin-left: $unit;
  }
}

.actions {
  @extend clear;
  display: block;
  text-align: right;
  zoom: 1;

  .action {
    cursor: pointer;
    float: left;

    a { display: block; }
  }
}

.action,
.control {
  a {
      display: block;
      float: left;
      text-decoration: none;
  }
}

.no-list-items {
  color: $color-A4;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
}
