.w-Filters {

	margin: 8px 0 9px;
	width: 100%;
	position: relative;

	dd {
		margin-bottom: 0;
		height: 22px;
		width: auto;
	}

	.chzn-single {
		@include border-radius($radius: 3px 0 0 3px);
		border: none;
		height: 23px;
		line-height: 23px;
		margin-right: 0;
	}

	.chzn-single-with-drop {
		@include border-radius($radius: 3px 0 0 3px);
		z-index: 3;
	}

	.chzn-drop {
		margin-left: -1px;
		margin-top: 2px;
	}

	.term,
	.entities {
		height: 23px;
	}

	.entities {
		@include border-radius($radius: 3px 0 0 3px);
		border-right: 0;
		z-index: 3;
		display: inline;
		float: left;
	}

	&.no-entities {
		.term, .text {
			@include border-radius($radius: 3px 3px 3px 3px);
		}
	}


	.term {
		@include border-radius($radius: 0 3px 3px 0);
		z-index: 4;
	}

	.text {
		@include border-radius($radius: 0 3px 3px 0);
		@include search-icon();

		border: 1px solid $color-A5;
		font-size: 12px;
		height: 21px;
		width: 210px;
		margin-left: -1px;

		&::-webkit-input-placeholder { font-style: italic; }
		&::-moz-placeholder { font-style: italic; } /* firefox 19+ */
		&:-ms-input-placeholder { font-style: italic; } /* ie */
		&:-moz-placeholder { font-style: italic; }

		&:focus {
			outline: none;
			box-shadow: 0px 0px 4px $color-D8;
			border-color: $color-D8;
		}

		&.hide-magnifying-glass {
			background: none;
		}
	}

	.no-select {
		.term,
		.text {
			@include border-radius($radius: 3px);
		}
		.entities {
			display: none;
		}
	}

	.select-input {
		width: 100px;
	}

	.auto-complete-results {
		z-index: 2;
		width: 99.85%;
	}

	.ui-autocomplete-category b {
		@include sprite-icon($left: 6, $down: 10, $width: 10px, $height: 10px);
		margin: 2px 2px 0 0;
		float: left;
	}

	.entities {
		border: 1px solid $color-A5;

		.select-input {
			display: none !important;
		}

		.chzn-container {
			.chzn-results {
				word-wrap: break-word;
			}
		}
	}

	.term {
		width: 305px;
	}

	.actions {
		margin-top: 6px;
		float: left;
	}

	.filter-show-active {
		position: absolute;
		bottom: 3px;
		right: 0px;
	}

	&.disabled {
		@include transparent();
	}
	
	.search-vid {
		width: 250px;
	}
}

.w-Filters,
.search-box {
	.clear-filter {
		@include sprite-icon($left: 4, $down: 5, $width: 15px, $height: 15px);

		cursor: pointer;
		display: none;
		margin-left: -20px;
		position: absolute;
		z-index: 4;
	}

	a.clear-filter {
		color: $color-A4;
		font-size: 12px;
		left: 5px;
		position: relative;
		top: 8px;
		visibility: hidden;
		margin-left: 0px;
		background: none;
	}
}
