.w-ReadonlyContextual {

    .heading-icon{
        @include sprite-icon($left: 1, $down: 3, $width: 22px, $height: 18px);
    }

    .col1{
        width: 80%;
    }
    .col3{
        width: 12%;
    }

    .warning-holder {
       position: relative;
       right: -6px;
       top: -1px;
       float: left;
       padding-right: 5px;
       top: 1px;
     }

    .warning-icon {
      @include sprite-icon($left: 4, $down: 10, $width: 15px, $height: 15px);
    }
}