/* Generated by Font Squirrel (http://www.fontsquirrel.com) on December 22, 2011 03:05:37 PM America/New_York */
$font-url: "fonts/p";/* Proxima */
$mono-font-url: "fonts/i";/* Interstate */
$font-url-arimo: "fonts/a"; /* Arimo */

@font-face {
	font-family: 'PNR';/* ProximaNovaRegular */
	src: url($font-url + '/prwf.eot');
	src: url($font-url + '/prwf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/prwf.woff') format('woff'),
	url($font-url + '/prwf.ttf') format('truetype'),
	url($font-url + '/prwf.svg#PNR') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PNLB';/* ProximaNovaLtBold */
	src: url($font-url + '/psbowf.eot');
	src: url($font-url + '/psbowf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/psbowf.woff') format('woff'),
	url($font-url + '/psbowf.ttf') format('truetype'),
	url($font-url + '/psbowf.svg#PNLB') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PNB';/* ProximaNovaBold */
	src: url($font-url + '/pbowf.eot');
	src: url($font-url + '/pbowf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/pbowf.woff') format('woff'),
	url($font-url + '/pbowf.ttf') format('truetype'),
	url($font-url + '/pbowf.svg#PNB') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PNRI';/* ProximaNovaRegularItalic */
	src: url($font-url + '/priwf.eot');
	src: url($font-url + '/priwf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/priwf.woff') format('woff'),
	url($font-url + '/priwf.ttf') format('truetype'),
	url($font-url + '/priwf.svg#PNRI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PNLBI';/* ProximaNovaLtBoldItalic */
	src: url($font-url + '/psboiwf.eot');
	src: url($font-url + '/psboiwf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/psboiwf.woff') format('woff'),
	url($font-url + '/psboiwf.ttf') format('truetype'),
	url($font-url + '/psboiwf.svg#PNLBI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PNBI';/* ProximaNovaBoldItalic */
	src: url($font-url + '/pboiwf.eot');
	src: url($font-url + '/pboiwf.eot?#iefix') format('embedded-opentype'),
	url($font-url + '/pboiwf.woff') format('woff'),
	url($font-url + '/pboiwf.ttf') format('truetype'),
	url($font-url + '/pboiwf.svg#PNBI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'IMR';/* InterstateMonoRegular */
	src: url($mono-font-url + '/fbimr.eot');
	src: url($mono-font-url + '/fbimr.eot?#iefix') format('embedded-opentype'),
	url($mono-font-url + '/fbimr.woff') format('woff'),
	url($mono-font-url + '/fbimr.ttf') format('truetype'),
	url($mono-font-url + '/fbimr.svg#IMR') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'IMB';/* InterstateMonoBold */
	src: url($mono-font-url + '/fbimbo.eot');
	src: url($mono-font-url + '/fbimbo.eot?#iefix') format('embedded-opentype'),
	url($mono-font-url + '/fbimbo.woff') format('woff'),
	url($mono-font-url + '/fbimbo.ttf') format('truetype'),
	url($mono-font-url + '/fbimbo.svg#IMB') format('svg');
	font-weight: normal;
	font-style: normal;
}

/*
	Arimo fonts

	woff fonts are converted at http://ttf2woff.com (other conversions had rendering issues in PC: COMPASS-5975)
	ttf fonts are from http://fontpro.com/arimo-font-16510 (smaller file size than Google Arimo font)
	eot and svg are Google Arimo fonts converted via Font Squirrel
*/

@font-face {
	font-family: 'AR';/* Arimo Regular */
	src: url($font-url-arimo + '/arimo-regular.eot');
	src: url($font-url-arimo + '/arimo-regular.eot?#iefix') format('embedded-opentype'),
	url($font-url-arimo + '/arimo-regular.woff') format('woff'),
	url($font-url-arimo + '/arimo-regular.ttf') format('truetype'),
	url($font-url-arimo + '/arimo-regular.svg#AR') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AB';/* Arimo Bold */
	src: url($font-url-arimo + '/arimo-bold.eot');
	src: url($font-url-arimo + '/arimo-bold.eot?#iefix') format('embedded-opentype'),
	url($font-url-arimo + '/arimo-bold.woff') format('woff'),
	url($font-url-arimo + '/arimo-bold.ttf') format('truetype'),
	url($font-url-arimo + '/arimo-bold.svg#AB') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ABI';/* Arimo Bold Italic*/
	src: url($font-url-arimo + '/arimo-bolditalic.eot');
	src: url($font-url-arimo + '/arimo-bolditalic.eot?#iefix') format('embedded-opentype'),
	url($font-url-arimo + '/arimo-bolditalic.woff') format('woff'),
	url($font-url-arimo + '/arimo-bolditalic.ttf') format('truetype'),
	url($font-url-arimo + '/arimo-bolditalic.svg#ABI') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AI';/* Arimo italic */
	src: url($font-url-arimo + '/arimo-italic.eot');
	src: url($font-url-arimo + '/arimo-italic.eot?#iefix') format('embedded-opentype'),
	url($font-url-arimo + '/arimo-italic.woff') format('woff'),
	url($font-url-arimo + '/arimo-italic.ttf') format('truetype'),
	url($font-url-arimo + '/arimo-italic.svg#AI') format('svg');
	font-weight: normal;
	font-style: normal;
}

/*
	Issue:
	https://issues.mediamath.com/browse/RPTG-479

	Solution:
	http://www.fontspring.com/blog/smoother-web-font-rendering-chrome
	http://stackoverflow.com/questions/13674808/chrome-svg-font-rendering-breaks-layout

	The font renders smoothly in Windows Chrome only when SVG font is used.
	Most of time, SVG font renders 1px off from how WOFF font renders, so I made separate font names
	so that these can be used only for the special cases (i.e. text is rotated, or looks too jagged in Windows Chrome).
*/

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'PNRS';
		src: url($font-url + '/prwf.svg#PNR') format('svg');
	}

	@font-face {
		font-family: 'PNBS';
		src: url($font-url + '/pbowf.svg#PNB') format('svg');
	}

	/*
		When hash(#) was used, the font didn't render properly in PC Chrome,
		so hash declaration after the svg file name has been deleted to fix the problem
		for ARS and ABS.

		Reference:
		- http://stackoverflow.com/questions/17198903/chrome-not-rendering-svg-for-fonts-when-hash-included
		- Comments in http://www.adtrak.co.uk/blog/font-face-chrome-rendering
	*/

	@font-face {
		font-family: 'ARS';
		src: url($font-url-arimo + '/arimo-regular.svg') format('svg');
	}

	@font-face {
		font-family: 'ABS';
		src: url($font-url-arimo + '/arimo-bold.svg') format('svg');
	}
}
