.w-Audiences {
  .basic-info {
    float: left;
    margin-left: 26px;
    width: 45%;
  }

  .title {
    padding-top: 1px;
    height: 17px;
    float: left;
    margin: 26px 0 21px;
  }

  .audiences-main-tabs {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
