@import 'mixins';

// NewT1 font
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/m/mulish-extralight.woff2') format('woff2');
}

// Replicate Polymer hidden attribute styling
[hidden] {
  display: none !important;
}

.inactive {
  color: $color-A5;
}

.page-body {
  margin: 0;
  top: 82px !important;
}

.page-body,
.page-body-c,
.app-container,
.main-content {
  @include box-fill();
}

.app-container {
  left: 113px !important;

  .overlay-view-bg {
    @include box-shadow($swidth: -5px, $scolor: $color-A4, $horizontal: -5px, $vertical: -5px, $blur: 20px);
    position: fixed !important;
    //left: 113px;
    right: 0;
    top: 0;
    //height: 2000px;
    bottom: 0;
    background-color: $color-A12;
  }

  .overlay-view {
    @include box-fill();

    > ._wrapper > .w-box {
      @include box-fill();

      > .w-body {
        @include box-fill($top: 64px);
      }
    }
  }
}

.layout-404,
.index-layout,
.logout-layout {
  .page-body {
    margin: 0 auto;
    width: 565px;
    min-width: 380px;
  }
  .page-body-c {
    position: absolute;
    top: 28%;
  }
}

.index-layout .initial-loading-body {
  width: 565px;
}

.main-layout {
  .head-title,
  .head-actions {
    @extend clear;
  }

  .head-title {
    margin-left: 125px;
    float: left;
  }

  .head-actions {
    float: right;
    padding-right: 20px;
    position: relative;
    left: -4px;
  }

  .head-title-c {
    float: left;
    padding: 0 8px;
    line-height: 1em;

    .ajaxSpinner {
      margin: 18px !important;
    }
  }

  .head-actions-c {
    line-height: 3.7em;
  }

  .head-actions-c,
  .head-title-c {
    height: 51px;
  }

  .head-title-c,
  .user-badge {
    border-left: 1px solid $color-A3;
  }

  .user-badge,
  .user-feedback {
    height: 100%;
  }

  .user-feedback {
    border-right: 1px solid $color-A0;
  }

  .user-badge {
    padding-left: 20px;
  }

  .page-body-c {
    padding-top: 0;
    padding-left: 5px;
  }

  .side-bar {
    @include width-colx(1);
    position: fixed;
    height: 100%;
  }
}

body {
  .index-layout .page-body {
    top: 0 !important;
  }

  .page-box {
    background-color: #3f6997;
    background-position: center;
    background-size: cover;
    height: 100%;

    &.fallback {
      background-image: url('../images/login-background.png');
    }
    &.webp {
      background-image: url('../images/login-background.webp');
    }
    &.avif {
      background-image: url('../images/login-background.avif');
    }
  }

  .page-head {
    background: url('../images/pg_bkgd_tile.png') repeat-x;
    height: 35px;
    width: 100%;
  }

  .page-box-c {
    background: none;
  }

  .initial-loading {
    animation: pulsing ease 2s infinite;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    color: $color-A12;
    font-family: 'Mulish', sans-serif;
    font-size: 31px;
    font-weight: 200;
    left: auto;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 180px;
    width: 565px;
  }

  .initial-loading-body {
    height: 130px;
  }
}

body.app {
  background-image: url('../images/left_nav_pattern.png');
  background-color: $color-D12;
  background-position: left top, left top;
  background-repeat: repeat-y, repeat;

  .page-box {
    background: none;
  }

  .page-box-c {
    padding: 0;
    height: 100%;
  }

  .page-head {
    height: 82px;
    width: 100%;
    background: url('../images/pg_bkgd_tile.png') repeat-x;
    padding: 0;
    position: fixed;
    top: 0;
    z-index: 999 !important;

    .page-head-c {
      background: url('../images/mm_site_logo.svg') no-repeat 10px 12px;
      background-size: 103px 28px;
      color: $color-A4;
      padding: 0;
    }
  }

  .top-container {
    padding: 0 0 0;
    margin-right: 16px;
  }

  .campaign-list-container {
    margin-right: 16px;
  }

  .mm-modal {
    position: fixed;
    z-index: 999;
  }

  .campaign-chart-container {
    margin-top: 0px;
    clear: both;
  }

  .filter-control-container {
    background-color: $color-D12;
    z-index: 6; //update this zindex with care!!
    margin-top: 2px;
    height: 42px;
  }

  search-loader {
    background: url('../images/ajax-loader-white.gif') no-repeat 99% $color-A12 !important;
  }

  search-loader-no-bg {
    background: url('../images/ajax-loader-white.gif') no-repeat 99% transparent !important;
  }
}

body.app-lite {
  @extend body, .app;

  background: none;

  .page-head {
    height: 52px !important;
  }
  .main-layout {
    .head-actions {
      padding-right: 0;
    }

    .user-feedback {
      border-right: none !important;
    }
  }

  .page-body {
    top: 52px !important;
  }

  .app-container {
    left: 0 !important;
  }
}

.monitor-nav {
  margin: 12px 0 0 2px;

  .nav-item {
    width: 80px;
    height: 64px;
    padding: 16px 0 0;
    position: relative;

    .nav-label {
      font-size: 10px;
      text-transform: uppercase;
      color: $color-A5;
      text-align: center;
      position: absolute;
      bottom: 12px;
      left: 0;
      width: 100%;
    }
  }

  .nav-icon {
    display: block;
    height: 28px;
    width: 27px;
    margin: 0 auto 4px;
    text-decoration: none;
  }
  .nav-icon-campaigns {
    @include sprite-at-position($left: 0, $down: 1);
    @extend .nav-icon;
  }
  .nav-icon-reports {
    @include sprite-at-position($left: 1, $down: 1);
    @extend .nav-icon;
  }
  .nav-icon-appstore {
    @include sprite-at-position($left: 0, $down: 10);
    @extend .nav-icon;
  }
  .nav-icon-creatives {
    @include sprite-at-position($left: 2, $down: 1);
    @extend .nav-icon;
  }
  .nav-icon-pixels {
    @include sprite-at-position($left: 2, $down: 12);
    @extend .nav-icon;
  }
  .nav-icon-admin {
    @include sprite-at-position($left: 0, $down: 12);
    @extend .nav-icon;
  }
  .nav-icon-audiences {
    @include sprite-at-position($left: 4, $down: 12);
    @extend .nav-icon;
  }
  .nav-icon-media {
    @include sprite-at-position($left: 0, $down: 14);
    @extend .nav-icon;
  }

  .nav-icon-secondary-audiences {
    @include sprite-icon($left: 9, $down: 12, $width: 52px, $height: 30px);
    position: absolute;
    top: 0;
    right: -20px;
  }
  .selected {
    @include sprite-at-position($left: 5, $down: 1);

    .nav-icon-campaigns {
      @include sprite-at-position($left: 4, $down: 1);
    }
    .nav-icon-reports {
      @include sprite-at-position($left: 1, $down: 2);
    }
    .nav-icon-appstore {
      @include sprite-at-position($left: 1, $down: 10);
    }
    .nav-icon-creatives {
      @include sprite-at-position($left: 3, $down: 1);
    }
    .nav-icon-pixels {
      @include sprite-at-position($left: 3, $down: 12);
    }
    .nav-icon-media {
      @include sprite-at-position($left: 1, $down: 14);
    }
    .nav-icon-admin {
      @include sprite-at-position($left: 1, $down: 12);
    }
    .nav-icon-audiences {
      @include sprite-at-position($left: 5, $down: 12);
    }
    .nav-icon-secondary-audiences {
      @include sprite-icon($left: 7, $down: 12, $width: 52px, $height: 30px);
    }
  }
}

.w-VersionConflictDialog {
  @extend .dialog-panel;
  width: _grid_column_width(5) !important;

  .ui-dialog-titlebar {
    display: none;
  }

  .ui-dialog-content {
    padding: 0 0.2em;
  }

  .icon {
    float: left;
    width: 36px;
    height: 36px;
  }

  b {
    @include sprite-icon($left: 8, $down: 4, $width: 30px, $height: 25px);
    margin: 0;
  }

  .message {
    margin-left: 0;
  }

  .head {
    font-family: $custom-font-semi-bold;
    font-size: 22px;
    line-height: 24px;
    color: $color-A1;
    padding-top: 3px;
  }

  .message-c {
    margin-top: 23px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
  }

  .confirm {
    @extend .ui-submit;
    float: right;
  }

  .info-icon {
    margin: 0 0 0 4px;
    position: absolute;
    top: 56px;
    left: 41px;
  }
}

.versionConflict-tipsy {
  width: 150px;

  .tipsy-inner {
    white-space: normal;
    word-wrap: break-word;
    padding: 10px;
  }
}

.w-InitialLoader,
.w-AuthInline {
  animation: fadeIn ease 3s;
  background: url('../images/mediamath-logo-white.png');
  background-position: center;
  background-size: 565px auto;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulsing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
