@import "mixins";

.w-UserBadge {
  color: $color-A4;
  font-size: 14px;
  
  a {
    color: $color-A4;
    &:hover {
      color: $color-A6;
    }
  }
  
  .actions {
    @extend .clear;
    
    .action {
      margin-left: $unit * 2;
      background: none;
      border: none;
    }
  }

  .user-name {
    float: left;
  }
  
  .action,
  .user-name {
    line-height: 20px;
    padding-top: 16px;
  }
}

.w-UserFeedback,
.w-UserTools {
  font-size: 12px;
  padding-right: 20px;
  line-height: 51.2px;
  hover {
    @include border-radius($radius: 3px);
    background: $color-A3;
    color: $color-A5;
  }

  a {
    color: $color-A4;
    line-height: 20px;
    margin: 9px 0;
    padding: 7px 8px;

    &:hover {
      @extend hover;
    }
  }

  .active {
    a {
      @extend hover;
    }
  }
}