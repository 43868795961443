// temp override for mm-group where clicked button should turn blue instead of grey.
// Component will be updated in a future release. Once native, remove mixin.
@mixin blue-component-btn() {
  background-image: linear-gradient(#77bdf5, #96d4f8);
  box-shadow: inset 0 5px 1px #77bdf5;
}

@keyframes gridRowHighlight {
  from {
    background-color: $color-E8;
  }
  to {
    background-color: $color-A10;
  }
}

//TODO: remove class below when Segments feature flag is removed
// It is literally a flag that covers the tabs
.segments-flag {
  background-color: $color-D12;
  border-bottom: 1px solid $color-A7;
  float: right;
  height: 49px;
  position: relative;
  width: 150px;
  z-index: 1;
}

// Main Reporting Tabs
.w-ReportingSections-tabset {
  margin-top: 10px;

  > .ui-tabs-nav {
    padding-right: 10px;

    > .ui-tab {
      float: right;
      width: 50px;
      z-index: 1;

      // Campaigns Tab
      &.tab-2-level-1 {
        &.ui-state-default {
          &:before {
            @include sprite-icon($left: 0, $down: 1, $width: 27px, $height: 27px);
            @include transparent(50);
            content: '';
            left: 14px;
            pointer-events: none;
            position: absolute;
            top: 6px;
          }

          > a {
            padding: 16px 25px;
          }
        }

        &.ui-state-active {
          &:before {
            @include sprite-icon($left: 4, $down: 1, $width: 27px, $height: 27px);
            @include transparent(100);
            content: '';
            left: 13px;
            pointer-events: none;
            position: absolute;
            top: 8px;
          }

          > a {
            padding: 16px 25px;
          }
        }
      }

      // Segments Tab
      &.tab-1-level-1 {
        border-right: solid 1px $color-A7;

        &.ui-state-default {
          &:before {
            @include sprite-icon($left: 4, $down: 12, $width: 27px, $height: 27px);
            @include transparent(50);
            content: '';
            left: 13px;
            pointer-events: none;
            position: absolute;
            top: 5px;
          }

          > a {
            padding: 16px 25px;

            &:before {
              @include transparent(50);
              content: '';
              left: 19px;
              pointer-events: none;
              position: absolute;
              top: -2px;
            }
          }
        }

        &.ui-state-active {
          &:before {
            @include sprite-icon($left: 5, $down: 12, $width: 27px, $height: 27px);
            @include transparent(100);
            content: '';
            left: 13px;
            pointer-events: none;
            position: absolute;
            top: 7px;
          }

          > a {
            padding: 16px 25px;

            &:before {
              @include transparent(100);
              content: '';
              left: 19px;
              pointer-events: none;
              position: absolute;
              top: -2px;
            }
          }
        }
      }
    }
  }
}

.w-CampaignReporting-tabset,
.w-SegmentReporting-tabset {
  margin-top: -40px;

  .ui-tabs-selected a {
    font-family: $unified-font-bold !important;
  }
}

.w-Reporting {
  font-family: $unified-font-regular;
  font-size: 11px;

  @media only screen and (min-width: 1600px) {
    .report-grid .headers {
      width: 1487px;
    }
  }
  @media only screen and (max-width: 1180px) {
    .report-grid .headers {
      width: 1147px;
    }
  }

  :-moz-placeholder {
    color: $color-A4;
    font-style: italic;
  }

  ::-webkit-input-placeholder {
    color: $color-A4;
    font-style: italic;
  }

  .reporting-tabs {
    .w-performance-panel,
    .w-site-transparency-panel,
    .w-geo-performance-panel,
    .w-technology-panel,
    .w-contextual-panel {
      border-top: 1px solid $color-A7;
      margin-top: 55px;

      .report-grid .w-DataGrid .content-c {
        width: 100%;
      }
    }
  }

  .header-info {
    height: 41px;
    position: relative;

    .basic-info {
      margin-top: -4px;
    }
  }

  .ui-resizable {
    position: relative;
  }

  .ui-resizable-handle {
    display: block;
    font-size: 0.1px;
    position: absolute;
  }

  .ui-resizable-disabled .ui-resizable-handle,
  .ui-resizable-autohide .ui-resizable-handle {
    display: none;
  }

  .ui-resizable-e {
    cursor: e-resize;
    height: 100%;
    right: -5px;
    top: 0;
    width: 7px;
  }

  .resizable-helper {
    border: 2px dotted $color-A5;
    border-bottom: 0;
    border-top: 0;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .chzn-container {
    left: 0;
    position: absolute;
    top: 0;
  }

  .chzn-drop {
    border-radius: 4px;
    border-top: 1px solid $color-A7;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font: normal 11px/13px $unified-font-regular;
    letter-spacing: 0.4px;
    margin-left: 10px;
    width: 210px !important;
    z-index: 5 !important;
  }

  .chzn-single {
    font: normal 11px/13px $unified-font-bold;
    height: 25px;
    letter-spacing: 0.4px;
    margin: 11px 10px 0;
    padding: 0 0 0 10px;
    width: 200px;
    z-index: 6;

    span {
      padding-top: 6px;
    }
    b {
      margin-top: 10px;
    }
  }

  .chzn-results {
    li em {
      background-color: transparent;
    }
  }

  .item {
    padding-left: 10px;
  }

  .clear-filter {
    color: $color-A1;
    cursor: pointer;
    float: left;
    font: normal 12px/14px $unified-font-regular;
    height: 25px;
    margin-left: -10px;
    margin-top: -7px;
    padding-top: 7px;
    width: auto;
    z-index: 3;

    .label {
      color: $color-D4;
      float: left;
      margin-right: 16px;
      margin-top: 1px;
      text-decoration: underline;
      text-transform: none;
    }
  }

  .x-icon {
    @include sprite-icon($left: 5, $down: 10, $width: 7px, $height: 8px);
    @include transparent(50);
    float: left;
    margin: 4px 15px 0 10px;
  }

  .campaign-selector-container {
    .agency-selector,
    .advertiser-selector,
    .campaign-selector {
      width: 180px;
    }

    .campaign-selector {
      width: 200px;

      span.left-items.strand-pulldown-button {
        label.campaign-label {
          font-weight: 300;
          width: 210px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .campaigns-container {
        width: 200px;
        background: $color-A12;
        padding-bottom: 10px;
      }
    }

    .advertiser-selector,
    .campaign-selector {
      margin-left: 15px;
    }

    .active-campaigns-checkbox {
      margin-left: 10px;
    }

    .campaign-checkbox,
    .all,
    .all-search-results {
      margin-left: 10px;
      margin-top: 10px;

      label {
        font: normal 12px/14px $unified-font-regular;
        letter-spacing: 0.4px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
      }
    }

    .all,
    .all-search-results {
      label {
        font: normal 11px/14px $unified-font-bold;
      }
    }

    .campaign-checkbox.inactive {
      label {
        color: $color-A4;
      }
    }

    .no-result {
      background-color: $color-A8;
      font: normal 12px/14px $unified-font-bold;
      margin: 5px 3px 5px 5px;
      padding: 5px 10px;
    }

    .empty-message {
      font: normal 12px/14px $unified-font-bold;
      padding-left: 10px;
      padding-top: 10px;
      cursor: text;
    }

    .campaign-selector-footer {
      margin-top: 10px;
    }

    .edit-campaign-link {
      margin-left: 10px;

      .edit-campaign {
        padding-right: 0;
      }
    }

    .agency-spinner {
      position: absolute;
      left: 138px;
      top: 5px;
    }

    .advertiser-spinner {
      position: absolute;
      left: 335px;
      top: 5px;
    }

    .campaign-spinner {
      position: absolute;
      left: 550px;
      top: 5px;
    }

    .selected-date {
      font: normal 12px/14px $unified-font-regular;
      margin-right: 5px;
      vertical-align: middle;
    }

    .interval-ddl {
      width: 120px;
      margin-right: 5px;
    }

    .onhover {
      .start-date,
      .end-date {
        @extend .isEditable;
      }
    }
  }

  .select-campaign {
    margin-top: 2px;
    position: absolute;
    z-index: 5;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .performance-panel,
  .technology-panel {
    height: 36px;
    position: absolute;

    // Despite Linter complaints, DO NOT move this line up, it will break Performance
    @include linear-gradient($from: $color-A12, $to: $color-D12);

    .w-report-panel {
      border-top: 1px solid $color-A7;
      margin-top: 36px;
      position: absolute;
      width: 100%;
    }
  }

  .technology-panel {
    .report-chart {
      .w-chart-panel {
        width: 70%;
      }
    }

    .w-report-panel {
      .view-loader {
        width: 70%;
      }

      &.chart-hidden {
        .view-loader {
          width: 100%;
        }
      }
    }
  }

  .report-chart-filters {
    @include inner-shadow-box($horizontal: 4px, $vertical: 1px, $blur-radius: 9px, $sradius: -6px, $scolor: $color-A6);
    width: 30%;
    position: absolute;
    right: 0;
    height: 359px;
    z-index: 0;
    border-left: 1px solid $color-A8;

    .metric-controls {
      right: 20px;
    }
    .select-metric {
      display: none;
    }

    .w-chart-filters {
      position: static;
      background-color: transparent;

      .metric-controls {
        right: 30px;
      }
    }

    .w-chart-filters-body {
      position: relative;
      color: $color-A2;
      margin: 66px auto;
      width: 330px;
      font: normal 11px/13px $unified-font-bold;
    }

    .filter-c {
      float: left;
      position: relative;
      margin: 0 6px;
    }

    .msg {
      position: absolute;
      width: 300px;
      margin: 0 auto;
      text-align: center;
      top: 256px;
      left: 0;
      right: 0;
      color: $color-A4;
      font: normal 13px/15px $unified-font-regular;
    }
  }

  .donutChart {
    position: relative;
    width: 152px;

    .chart-label {
      text-align: center;
    }
    .chart-label-icons {
      position: absolute;
      top: 1px;
      right: 24px;
    }

    .icon-filter-c {
      right: -4px;
      width: 16px;
      height: 16px;
    }

    .icon-filter {
      opacity: 0.6;
    }
    .icon-delete-c {
      position: absolute;
      top: 2px;
      right: -14px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      opacity: 0.4;
    }

    .icon-delete-c:hover {
      opacity: 1;
    }

    .percent {
      color: $color-A3;
      font-size: 20px;
      height: 160px;
      position: absolute;
      width: 155px;
    }

    .donut {
      cursor: pointer;
      height: 160px;
      margin-top: -4px;
    }

    .legend {
      color: $color-A3;
      height: 110px;
      width: 155px;
    }
  }

  .donutChart1 .chart-label-icons {
    right: 16px;
  }

  .performance-panel .report-grid .spinner {
    left: 42px !important;
  }

  .gradient-line {
    @include linear-gradient($from: $color-A12, $to: $color-A7);
    content: '';
    float: left;
    height: 33px;
    margin-top: 3px;
    position: relative;
    width: 1px;
    z-index: 4;
  }

  .tabs-panel {
    position: relative;
  }
  .tabs-control-focus {
    float: left;
    position: relative;
  }

  .tabs-control-dimensions {
    float: left;
    position: relative;
  }

  .tabs-control-site-filter {
    float: left;
    position: relative;
    z-index: 2;
  }

  .tabs-control-interval {
    float: right;
    position: relative;
  }

  .w-Focus {
    color: $color-A1;
    float: left;
    font: normal 12px/14px $unified-font-bold;
    height: 33px;
    margin-top: 12px;
    position: relative;
    z-index: 2;

    b {
      margin-top: 10px;
    }

    .current-option {
      margin-left: 10px;
      min-width: 52px;
    }

    .focus-on,
    .current-option {
      float: left;
    }

    .icon {
      position: absolute;
    }
    .name {
      font-family: $unified-font-regular;
      letter-spacing: 0.4px;
      padding-left: 24px;
    }

    .display {
      @include sprite-icon($left: 9, $down: 9, $width: 17px, $height: 11px);
      margin-top: 2px;
    }

    .threepas {
      @include sprite-icon($left: 10, $down: 9, $width: 13px, $height: 12px);
      margin: 1px 0 0 2px;
    }

    .video {
      @include sprite-icon($left: 9, $down: 10, $width: 16px, $height: 8px);
      margin: 3px 0 0 1px;
    }

    .all {
      @include sprite-icon($left: 10, $down: 10, $width: 15px, $height: 15px);
      margin-left: 1px;
    }
  }

  .w-Interval {
    color: $color-A1;
    float: left;
    font: normal 12px/14px $unified-font-bold;
    height: 33px;
    margin-top: 12px;
    position: relative;
    width: 162px;
    z-index: 2;

    b {
      float: right !important;
      margin-right: 10px;
      margin-top: 10px;
    }

    .head-label {
      margin-right: 1px;
      padding-left: 0;
      width: 161px;
    }

    .current-option {
      float: left;
      min-width: 100px;
      padding-left: 18px;
      padding-top: 1px;
    }

    .icon {
      position: absolute;
    }

    .name {
      font-family: $unified-font-regular;
      letter-spacing: 0.5px;
    }

    .display {
      @include sprite-icon($left: 9, $down: 9, $width: 17px, $height: 11px);
      margin-top: 2px;
    }

    .threepas {
      @include sprite-icon($left: 10, $down: 9, $width: 13px, $height: 12px);
      margin: 1px 0 0 2px;
    }

    .video {
      @include sprite-icon($left: 9, $down: 10, $width: 16px, $height: 8px);
      margin: 3px 0 0 1px;
    }

    .all {
      @include sprite-icon($left: 10, $down: 10, $width: 15px, $height: 15px);
      margin-left: 1px;
    }
  }

  .focus-on,
  .current-focus {
    float: left;
    margin-top: 1px;
  }

  .current-focus {
    margin-left: 10px;
    min-width: 52px;
  }

  .current-interval {
    float: left;
    min-width: 95px;
    padding-left: 18px;
    padding-top: 1px;
  }

  .focus-list,
  .interval-list,
  .attribution-list,
  .geo-level-list {
    @include rounded-box(
      $padding: 0,
      $background: $color-A12,
      $border-radius: 0 0 4px 4px,
      $border-rule: 1px,
      $border-color: $color-A8
    );
    border-top: 0;
    border-top-right-radius: 0;
    font-family: $unified-font-regular;
    padding: 5px 0;

    li {
      cursor: pointer;
      padding: 8px 0 8px 18px;
      position: relative;

      &.selected {
        background-color: $color-D14;

        .icon {
          @include transparent($percent: 30);
        }
        .name {
          color: $color-D3;
          font-family: $unified-font-bold;
        }
      }

      &:hover,
      &.highlighted {
        background-color: $color-E8;
      }

      &.disabled {
        @include transparent($percent: 70);
        background-color: $color-A12;
        cursor: default;
      }
    }
  }

  .focus-list {
    border-left: 0;
  }

  .interval-list {
    border-left: 1px $color-A8 solid;
  }

  .w-Dimension,
  .w-Attribution,
  .w-GeoLevel {
    color: $color-A1;
    float: left;
    font: normal 12px/14px $unified-font-bold;
    height: 33px;
    margin-top: 12px;
    position: relative;
    z-index: 2;

    .w-body {
      position: absolute;
    }

    .view-by {
      float: left;
      margin-top: 1px;
    }

    .dim2 {
      height: 25px;

      b {
        margin-right: 0;
      }

      .add-dim2 {
        color: $color-D4;
        float: left;
        margin-top: 1px;
      }

      .x-icon {
        display: none;
        margin-right: 16px;
      }
    }

    .remove-dimension {
      float: left;

      &:after {
        @extend .gradient-line;
        margin-top: -8px;
      }
    }

    .selection-state {
      color: $color-A4;
      font: normal 12px/14px $unified-font-bold;
    }

    .w-FilterList {
      .txt {
        width: 190px !important;
      }
      .form-footer-style {
        width: 223px;
      }
    }
  }

  .w-Attribution .attribution-list {
    margin-left: -1px;
    width: 206px;
  }

  .w-GeoLevel {
    .head-label {
      height: 25px;
    }
    .view-by {
      width: 50px;
    }

    .current-option {
      margin-left: 5px !important;
      min-width: 48px;
    }

    .w-body {
      left: -1px;
      width: 158px;
    }
  }

  .w-GeoBreadCrumb {
    float: left;
    font: normal 12px/14px $unified-font-regular;
    margin: 13px 0 0 12px;
    position: relative;
    z-index: 2;

    &.fade-in {
      @include animate($animationType: fadeIn, $duration: 250ms);
    }

    .icon-nav-up {
      @include sprite-icon($left: 1, $down: 11, $width: 9px, $height: 11px);
    }

    .item-icon {
      @include transparent(30);
      float: left;
      margin: 2px 2px 0 3px;

      &:hover {
        @include transparent(100);
        cursor: pointer;
      }
    }

    .level {
      color: $color-A2;
      font-family: $unified-font-bold;
      margin-left: 7px;
      text-transform: capitalize;
    }

    .link {
      color: $color-D4;
      cursor: pointer;
      font-family: $unified-font-regular;
      text-decoration: underline;
    }

    .arrow {
      color: $color-A4;
      margin-left: 5px;
    }
  }

  .w-FilterList {
    background-color: $color-A12;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-left: -1px;
    padding: 47px 10px 0;
    width: 212px;
    z-index: 0;

    .search {
      border-top: 1px solid $color-A7;
      margin: 0 -9px;
    }

    .form-footer-style {
      border-left: 0;
    }
    .txt {
      padding-left: 5px;
    }

    .list {
      margin: 4px -3px 0;
    }
    .list input[type='checkbox'] + span {
      padding: 6px 0 4px 4px;
    }

    .ps-container .ps-scrollbar-y {
      @include transparent($percent: 30);
    }
    .ps-container:hover .ps-scrollbar-y {
      @include transparent($percent: 60);
    }
    .ps-container .ps-scrollbar-y.in-scrolling {
      @include transparent($percent: 90);
    }
  }

  .w-SiteFilter {
    color: $color-A1;
    float: left;
    font: normal 12px/14px $unified-font-bold;
    height: 33px;
    margin-top: 12px;
    position: relative;
    z-index: 2;

    .head-label {
      height: 24px;
    }

    .view-by {
      float: left;
      margin-top: 1px;
    }

    .w-body {
      @include rounded-box(
        $padding: 0,
        $background: $color-A12,
        $border-radius: 4px,
        $border-rule: 1px,
        $border-color: $color-A7
      );
      background-color: $color-A12;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-left: 0;
      margin-left: 0;
      margin-top: 1px;
      min-width: 300px;
    }

    .range-list,
    .metric-list {
      float: left;
      height: 192px;
      padding: 6px 0;
      position: relative;

      li {
        font: normal 11px/14px $unified-font-regular;
        letter-spacing: 0.2px;
        padding: 5px 0 5px 18px;
      }
    }

    .range-list {
      width: 40%;
    }

    .metric-list {
      border-left: 1px solid $color-A7;
      margin-left: -1px;
      overflow: hidden;
      width: 60%;
    }

    .item {
      cursor: pointer;

      &.selected {
        background-color: $color-D14;

        .name {
          color: $color-D3;
          font-family: $unified-font-bold;
        }
      }

      &.highlighted {
        background-color: $color-E8;
      }
    }

    .exclude-zero-values {
      left: 19px;
      position: absolute;
      top: 221px;
      z-index: 5;

      span {
        font-family: $unified-font-regular;
        vertical-align: middle;
      }
    }

    .form-footer-style {
      @include rounded-box(
        $padding: 0,
        $background: $color-D12,
        $border-radius: 0 0 4px 4px,
        $border-rule: 0,
        $border-color: $color-A8
      );
      background-color: $color-D12;
      border-top: 1px solid $color-A8;
      clear: both;
      padding: 10px 9px 10px 0;

      .actions {
        margin-right: 2px !important;
      }

      .ui-cancel {
        margin-right: 0;
      }
      .ui-submit {
        &.disabled:hover {
          @include _linear-gradient($color-D8, $color-D9);
        }
        &.disabled:active {
          behavior: none !important;
          box-shadow: none;
        }
      }
    }

    .ps-container .ps-scrollbar-y {
      @include transparent($percent: 30);
      margin-right: 0;
      margin-top: 6px;
    }

    .ps-container:hover .ps-scrollbar-y {
      @include transparent($percent: 60);
    }
    .ps-container .ps-scrollbar-y.in-scrolling {
      @include transparent($percent: 90);
    }
  }

  .divider-after {
    &:after {
      @extend .gradient-line;
      margin-top: -8px;
    }
  }

  .divider-before {
    &:before {
      @extend .gradient-line;
      margin-top: -8px;
    }
  }

  .head-label {
    cursor: pointer;
    float: left;
    height: 25px;
    margin-top: -7px;
    padding: 7px 0 0 16px;

    .current-option.detail {
      float: left;
      font: normal 12px/14px $unified-font-regular;
      letter-spacing: 0.1px;
      margin: 1px 5px 0;
    }

    b {
      @include sprite-icon($left: 1, $down: 7, $width: 8px, $height: 10px);
      float: left;
      margin: 6px 16px 0 5px;
    }
  }

  .white-bg {
    background-color: $color-A12;
  }

  .tabs-control-dates {
    color: $color-A2;
    float: right;
    margin: 14px 16px 0 0;
    position: relative;
    text-align: left;
    z-index: 6;

    .dates {
      font-size: 12px;
    }

    .onhover {
      .start-date,
      .end-date {
        @extend .isEditable;
      }
    }
  }

  .date-picker-calendar {
    height: 264px;
    width: 418px;
  }

  .tabs-settings {
    float: right;
    position: relative;
    right: 1px;
    top: 0;
    width: 45px;
    z-index: 2;

    .action {
      cursor: pointer;
    }
  }

  .tab-settings-menu {
    float: left;
    height: 37px;
    position: relative;
    width: 45px;
    z-index: 2;

    .settings-icon {
      @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
      margin: 12px 0 0 16px;
    }

    &.selected {
      background-color: $color-A12;
      border-right: 1px solid $color-A7;
    }

    &:before {
      @extend .gradient-line;
    }
  }

  .tabs-info {
    float: right;
    position: relative;
    right: 1px;
    top: 0;
    width: 45px;
    z-index: 3;

    strand-icon {
      color: #999; // $color-A6 from new color palette (_color.scss)
      margin: 12px 0 0 16px;

      &:hover {
        color: #666;
      } // $color-A4 from new color palette (_color.scss)
    }

    &:before {
      @extend .gradient-line;
    }
  }

  .menu-panel {
    @include border-radius($radius: 0 0 3px 3px);
    background-color: $color-A12;
    border: 1px solid $color-A7;
    padding-bottom: 12px;
    position: absolute;
    right: -1px;
    top: 36px;
    white-space: nowrap;
    z-index: 1;

    .menu-column {
      display: inline-block;
      vertical-align: top;

      &:before {
        background-color: $color-A8;
        bottom: 0;
        content: '';
        float: left;
        position: absolute;
        top: 0;
        width: 1px;
      }

      &:first-child {
        &:before {
          width: 0;
        }
      }
    }
    .menu-column-title {
      color: $color-A2;
      font: normal 12px/14px $unified-font-bold-svg, $unified-font-bold;
      margin: 16px 20px 12px;
    }

    .menu-column-item {
      color: $color-D6;
      cursor: pointer;
      font: normal 12px/14px $unified-font-regular;
      margin: 0 20px 10px;

      a {
        text-decoration: none;

        &.highlighted {
          color: $color-D4;
        }
      }

      a:link,
      a:visited {
        color: $color-D6;
        text-decoration: none;
      }

      a:hover {
        color: $color-D4;
      }
      &:hover {
        color: $color-D4;
      }
    }

    .menu-column-item-selected {
      color: $color-A4;
      cursor: default;

      &:hover {
        color: $color-A4;
      }
    }
  }

  .basic-info {
    .label {
      float: left;
    }
    .arrow {
      margin: 0 5px;
    }

    .select-campaign-marker {
      display: inline-block;
      margin: 13px 0 0 70px;
      position: absolute;

      .arrow-left {
        border-bottom: 8px solid transparent;
        border-right: 8px solid $color-D10;
        border-top: 8px solid transparent;
        float: left;
        height: 0;
        margin-top: 7px;
        width: 0;
      }

      .marker-label {
        background-color: $color-D10;
        color: $color-A12;
        float: left;
        font-family: $unified-font-regular;
        font-size: 13px;
        padding: 9px;
      }
    }

    .w-Filters {
      margin-left: 0;

      .term .text {
        width: 284px;

        &:focus {
          border-color: $color-D8;
          box-shadow: 0 0 4px $color-D8;
          outline: none;
        }
      }

      .auto-complete-results {
        .ui-autocomplete {
          .ui-autocomplete-category {
            color: $color-D10;
            font-family: $unified-font-bold;
            font-size: 10px;
            margin: 3px 0 -2px -10px;
            text-transform: uppercase;

            b {
              background: none !important;
              margin: 0 !important;
            }
          }

          .ui-menu-item a {
            padding: 4px 6px;
          }
        }
      }

      .w-foot {
        display: none;
      }
    }
  }

  .metric-controls-c {
    .metric-controls {
      margin-top: 5px;
      right: 15px;

      .bar {
        margin: 0 10px;
      }

      .metric-label {
        letter-spacing: 0.3px;
        margin-right: 0;
      }

      .chzn-single,
      .chzn-single-with-drop {
        padding-left: 0 !important;

        div {
          width: 8px !important;
        }
        span {
          margin-right: 8px !important;
        }
      }

      .chzn-container {
        margin-right: 0;
      }

      .metric-icon {
        margin: 0;
      }
      .metric-icon2 {
        margin-right: 10px;
      }

      .metric-icon,
      .metric-icon2 {
        @include transparent(30);
        cursor: pointer;

        &.selected {
          @include transparent(100);
          cursor: default;
        }

        &:hover {
          @include transparent(100);
        }
      }
    }

    .chzn-container {
      display: inline-block;
      margin-right: 10px;
      position: relative;
    }
  }

  .report-chart {
    @include box-fill();
    background-color: $color-A12;
    height: 225px;

    .w-chart-panel {
      overflow: visible;
      position: absolute;
      width: 100%;
    }

    .w-chart-box {
      height: 300px;
    }

    .w-chart-body {
      height: 100%;

      .descriptor {
        fill: $color-A2;
        font-family: 'AR';
        font-size: 11px;
        pointer-events: none;
      }
    }

    .w-head {
      padding: 0;
      z-index: auto;
    }

    .chart-title {
      font-size: 18px;
      margin-top: 0;
    }

    .campaign-benchmark-line {
      stroke: $color-A2 !important;
    }

    .benchmark-label {
      width: 140px;
      z-index: 1;

      .title {
        float: right;
        font-family: $unified-font-bold;
        font-size: 11px;
        text-transform: capitalize;
      }
    }

    .campaign-benchmark-label {
      .title {
        color: $color-A2;
        float: right;
      }

      .narrow-text {
        text-align: right;
        width: 90px;
      }

      .value {
        float: right;
        font-family: $unified-font-regular;
        font-size: 11px;
        margin-left: 3px;

        &.low {
          color: $color-B1;
        }
        &.high {
          color: $color-C1;
        }
      }
    }

    .ybar.axis {
      text {
        fill: $color-A2;
      }
    }
  }

  .chart-header {
    .chzn-results {
      max-height: 280px;
    }

    .chzn-single,
    .chzn-single-with-drop {
      font: normal 11px/13px $unified-font-regular;
      margin-left: 0;
      padding-left: 19px;
      width: auto;
      z-index: auto;

      span {
        padding-top: 0;
      }
    }
  }

  .report-controls {
    background-color: $color-A12;
    float: left;
    height: 51px;
    margin-top: 358px;
    margin-bottom: -4px;
    position: relative;
    width: 100%;
    z-index: 1;

    &.collapsed {
      margin-top: 4px;
    }

    .w-GridControls {
      .actions {
        float: right;
        margin: 11px 20px 15px 0;
      }

      .columns,
      .site-list {
        @extend .btn-secondary;
        padding: 2px !important;

        span {
          font-family: $unified-font-bold;
          font-size: 11px;
        }
      }

      .columns {
        width: 68px;
      }

      .site-list {
        margin-right: 10px;
        width: 100px;
      }

      .disabled {
        @include transparent($percent: 50);
        cursor: default;

        &:hover {
          @include linear-gradient($color-A10, $color-A8);
        }

        &:active {
          @include inner-shadow-box($blur-radius: 0, $sradius: 0, $scolor: $color-A4, $background: $color-A9);
          @include linear-gradient($color-A10, $color-A8);
        }
      }
    }

    .chart-cover {
      background-color: $color-A12;
      height: 340px;
      left: 0;
      position: absolute;
      right: 0;
      top: 70px;
    }
  }

  .report-grid {
    background-color: $color-A12;
    float: left;
    min-height: 100px;
    position: relative;
    width: 100%;
    z-index: 1;

    .currency,
    .integers,
    .percent,
    .date,
    .float,
    .ratio {
      &.text {
        color: $color-A2;
        font: normal 11px/13px $unified-font-regular;
        letter-spacing: 0.3px;
        padding-top: 2px;
      }
    }

    .no-results {
      margin-bottom: 20px;
      margin-top: 50px !important;
    }

    .w-body {
      margin-top: 42px;
    }

    .legend-chart {
      @include sprite-icon($left: 14, $down: 4, $width: 14px, $height: 14px);
      background-color: $color-A4;
      border: 1px solid $color-A5;
      border-radius: 2px;
      float: left;
      height: 14px;
      margin-bottom: -2px;
      width: 14px !important;

      &.hide {
        background-color: $color-A19 !important;
      }
    }

    .legend-chart + .column.header.name {
      margin-right: 5px;

      &.strategy-quick-edit {
        padding-left: 30px;
      }
    }

    .header {
      cursor: pointer;
      font-size: 10px;
      font-family: $unified-font-bold;
      margin-right: 11px;
      text-transform: none;
      width: 105px;

      &.strategy-quick-edit {
        margin-left: 30px;
      }
    }

    .campaign-row .row,
    .total-row {
      .currency,
      .integers,
      .percent,
      .date,
      .float,
      .ratio {
        &.text {
          font-family: $unified-font-bold;
        }
      }

      .detail {
        width: 105px;
      }

      .name {
        font: normal 11px/13px $unified-font-bold;
        padding-top: 1px;
        text-transform: capitalize;
        width: 214px;

        &.strategy-quick-edit {
          width: 244px;
        }
      }
    }

    .total-row {
      background-color: $color-A10;

      &:hover {
        cursor: default;
      }
      &.highlight {
        @include animate($animationType: gridRowHighlight, $duration: 1400ms);
      }

      .text {
        font: normal 11px/13px $unified-font-regular !important;

        &.total {
          font-style: italic !important;
        }
      }
    }

    .spinner {
      left: 26px !important;
      position: absolute !important;
      top: 14px !important;
    }

    .icon.arrow {
      left: 35px;
      position: absolute;
      top: 8px;
    }

    .campaign-row .icon.arrow {
      top: 9px;
    }
    .row {
      position: relative;
    }

    .item {
      padding: 6px 0 7px 18px;

      .icon-strategy-quickedit {
        margin-right: 15px;
        float: left;
        &.hide {
          visibility: hidden;
        }
        &.show {
          visibility: visible;
        }
      }

      &:hover {
        .icon-edit-strategy {
          @include sprite-icon($left: 10, $down: 11, $width: 14px, $height: 11px);
        }

        .icon-nav-into {
          @include sprite-icon($left: 0, $down: 11, $width: 9px, $height: 11px);
          margin-top: 4px;
        }

        .item-icon {
          @include transparent(30);
          float: left;
          margin: 3px 0 0 6px;

          &:hover {
            @include transparent(100);
            cursor: pointer;
          }
        }
      }
    }

    .campaign-row.item,
    .total-row.item {
      .icon-strategy-quickedit {
        visibility: hidden;
      }
    }

    .child-row {
      display: none;
      margin-left: -18px;

      .date,
      .name {
        margin-right: 17px;
        text-align: left;
        width: 208px;
      }

      .date.text {
        float: left;
        text-indent: 33px;
      }

      .blank-strategy {
        padding-left: 6px;
        width: 202px;
      }

      li {
        border-top: 1px solid $color-A9;
        margin-top: 7px;
        padding: 6px 6px 0 18px;
      }
    }

    .strategy-quick-edit {
      .child-row .start_date {
        padding-left: 30px;
      }
    }

    .filter-out {
      display: none !important;
    }

    .arrow {
      float: left;
      left: 12px;
      top: 8px;
      z-index: 1;

      .icon-arrow {
        @include sprite-icon($left: 5, $down: 9, $width: 14px, $height: 11px);
        float: left;
        margin: -8px 0 0 -12px;

        &.strategy-quick-edit {
          margin-left: 18px;
        }
      }

      &.loading {
        .icon-arrow {
          visibility: visible;
        }
      }

      &.is-ctd {
        @include transparent(20);
        pointer-events: none;
      }
    }

    .expanded {
      .child-row {
        display: block;
      }
      .icon-arrow {
        @include sprite-icon($left: 1, $down: 0, $width: 14px, $height: 11px);
        margin: -6px 3px 0 -15px;

        &.strategy-quick-edit {
          margin-left: 15px;
        }
      }
    }

    .headers {
      height: 42px !important;
      left: 0;
      min-width: 1147px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
    }

    .header-container {
      padding: 7px 0 7px 18px;
      position: absolute;
    }

    .header,
    .detail {
      text-align: right;
    }

    .header-c {
      float: right;
      letter-spacing: -0.2px;
      line-height: 1;
      width: 105px;
    }

    .detail {
      margin-right: 11px;
      width: 105px;
    }

    .string,
    .date {
      text-align: left;
    }

    .name {
      text-align: left;
      width: 214px;

      &.strategy-quick-edit {
        width: 244px;
      }

      .text {
        float: left;
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 14px;
        max-width: 170px;
        padding: 1px 0 0 7px;
        position: relative;
        width: initial;

        &.total {
          padding-left: 22px;
          width: 100%;
        }

        &.totalSeries {
          font-style: italic !important;
          padding-left: 32px;
          width: 100%;
        }
      }
    }

    .name + .name .text {
      max-width: 184px;
    }

    .header.name {
      margin-left: -4px;
      width: 194px;

      &.strategy-quick-edit {
        width: 224px;
      }

      .header-c {
        float: left;
        width: auto;
      }

      .order-by {
        float: left;
        margin-left: 4px;
        position: relative;
        right: 0;
      }

      .ui-resizable-handle {
        border-right: 1px solid $color-A5;
        height: 42px;
        top: -7px;
      }

      .ui-resizable-e {
        cursor: col-resize;
        right: 0;
        width: 10px;
      }
    }

    // if sibling available (MultiCampaign)
    .header.name + .header.name {
      padding-left: 10px;
      width: 204px;

      .ui-resizable-e {
        right: -5px;
      }
    }

    .detail.name {
      float: left;

      @for $i from 0 through 30 {
        .color#{$i} {
          background-color: nth($chart-colors, $i + 1);
        }
      }
    }

    .w-foot {
      float: left;

      .page-controls {
        padding: 10px 0 10px 17px;
      }
    }

    .msg {
      color: $color-A4;
      font-size: 13px;
      margin: 1px 0 -1px 453px;

      &.blank {
        padding: 7px 0;
      }
    }
  }

  .series-grid {
    .name .text {
      max-width: 160px;
      padding-left: 17px;

      &.no-arrow {
        padding-left: 5px;
      }
    }

    .name + .name .text {
      max-width: 184px;
      padding-left: 6px;
    }

    .no-data .text {
      padding-left: 6px;
    }
  }

  .no-data {
    position: relative;

    .report-chart {
      height: 360px;
      margin-top: 80px;
    }

    .report-grid {
      background: $color-D12;
      box-shadow: inset 0 2px 2px $color-A9;
      margin-bottom: -9px;
      margin-top: 360px;

      .w-DataGrid {
        background: none !important;
      }
      .w-head {
        display: none !important;
      }
      .content {
        background: none !important;
      }

      .item {
        border-bottom: 1px solid $color-A8;
        height: 14px;

        &:last-child {
          border-bottom: 0;
        }
      }

      .first {
        font: normal 12px/14px $unified-font-regular;
        padding-bottom: 16px;
        padding-top: 22px;
      }

      .text {
        width: 8%;

        &:first-child {
          width: 20%;
        }
      }

      .msg {
        margin-left: -15px;
        text-align: center;
      }
    }
  }

  .strategy-quickedit {
    .body {
      width: 460px;
      padding: 10px;
      font: normal 11px/13px $unified-font-regular;

      .form-label {
        font: 11px/21px $unified-font-bold;
      }
      .strategy-header {
        font: 13px/21px $unified-font-bold;
      }
      .campaign-name {
        color: $color-D4;
      }
      .left {
        margin-right: 10px;
      }
      .section {
        margin-bottom: 10px;
      }
      .strategy-info {
        height: 75px;
      }

      .pacing-monetary-ddl {
        margin-left: 17px;
      }
      .impression-pacing-amount-container {
        margin-left: 10px;
      }
      .caps-options {
        margin-left: 15px;
      }
      .cpas-frequency-container {
        margin-left: 62px;
      }
      .pacing-impression-ddl {
        margin-left: 9px;
      }

      .target-allocation-ip {
        margin-right: 9px;
      }
      .target-allocation-ddl {
        margin-right: 9px;
      }
      .pacing-monetary-interval,
      .pacing-impression-interval,
      .caps-frequency-interval,
      .caps-frequency-ddl,
      .target-allocation-ip,
      .target-allocation-ddl {
        margin-left: 8px;
      }
      .frequency-caps-amount-container {
        margin-right: 14px;
      }

      .goal-value-error,
      .bid-error,
      .impression-pacing-error,
      .target-allocation-error {
        margin-top: 7px;
      }

      .pacing-error {
        margin-bottom: 5px;
      }

      .caps-frequency-ip[disabled] {
        opacity: 1;
      }

      .strategy-goal-input,
      .budget,
      .bid-price,
      .min-bid,
      .max-bid,
      .bid-aggressiveness,
      .target-allocation-ip {
        width: 90px;
      }

      .caps-frequency-ip,
      .pacing-monetary-ip,
      .pacing-impression-ip {
        width: 50px;
      }

      .pacing-monetary-ddl,
      .pacing-monetary-interval,
      .pacing-impression-ddl,
      .pacing-impression-interval,
      .caps-frequency-ddl,
      .caps-frequency-interval,
      .target-allocation-ddl {
        width: 100px;
      }

      .column {
        display: inline-block;
        width: 225px;
      }

      .min-column {
        display: inline-block;
        width: 135px;
      }

      strand-radio {
        margin-right: 17px;
      }

      .strategy-loader {
        background-color: $color-A12;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        strand-loader {
          margin-bottom: 45%;
          margin-left: 36%;
          margin-right: 33%;
          margin-top: 48%;
        }
      }

      .overlay {
        opacity: 0.1;
        pointer-events: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }

      .message {
        padding: 10px 0 0;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .w-Chart {
    .report-chart {
      margin-top: 92px;
    }
    .no-data {
      margin-top: 0;
    }
    .view-loader .loader {
      height: 14px;
      top: 182px;
      width: 132px;
    }

    .w-chart-panel-filters {
      .loader {
        top: 117px;
      }

      .spinner,
      .loader-text {
        position: absolute;
      }
    }
  }
}

.rptg-icon-error-tip,
.rptg-icon-edit-tip,
.rptg-icon-info-tip {
  letter-spacing: 0.3px;
  margin: -9px 0 0 -5px;

  .tipsy-inner {
    padding: 6px;
  }
}

.rptg-icon-info-tip {
  margin-left: -23px;

  .tipsy-inner span {
    font-family: $unified-font-bold;
  }
}

.rptg-icon-error-tip {
  .tipsy-inner {
    background-color: #f85252; // $color-C12 from new color palette (_color.scss)
    line-height: 13px;
  }

  .tipsy-arrow {
    left: calc(50% - 15px);
  }
  .tipsy-arrow-s {
    border-top-color: #f85252;
  } // $color-C12 from new color palette (_color.scss)
}

.w-ColumnSortableDialog {
  width: 439px !important;

  h1,
  .label {
    font-family: $unified-font-bold !important;
  }

  li {
    display: flex;
    font-family: $unified-font-regular;
    justify-content: space-between;
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
  }

  .btn-clear {
    float: none;
  }

  .connectedSortable {
    height: 100%;
  }

  .currency-notice {
    float: left;
    font: normal 11px/30px $unified-font-regular;
    letter-spacing: 0.3px;
  }

  .currency-warning {
    display: none;
    margin-top: 30px;
  }

  .no-results {
    color: $color-A4;
    display: none;
    font-family: $unified-font-regular;
    font-size: 12px;
    left: 49px;
    position: absolute;
    top: 206px;
  }

  .search-input {
    margin-top: 15px;
  }
}

.tabs-bar {
  @include box-fill();
  @include linear-gradient($from: $color-A12, $to: $color-D12);
  border-bottom: 1px solid $color-A7;
  height: 36px;
  z-index: 1;
}

.add-to-sitelist-modal {
  .w-box {
    padding: 30px;
  }

  .site-count {
    font-family: $unified-font-bold;
    font-size: 11px;
    position: absolute;
    right: 30px;
    top: 40px;
  }

  .list-search {
    margin: 16px 0 12px;
    width: 100%;
  }

  .show-active {
    float: right;
    margin: 6px 2px 0 0;
  }

  .site-list-grid {
    border: 1px solid $color-A19;
    height: 340px;
    width: 100%;

    .download-icon {
      color: #666; // $color-A4 from new color palette (_color.scss)
      cursor: pointer;

      &:hover {
        color: #999;
      } // $color-A6 from new color palette (_color.scss)
    }
  }

  .new-site-list {
    margin-left: 14px;
    margin-top: 20px;

    strand-input[disabled] {
      opacity: 1;
    }
  }

  .center-msg {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: 298px;
    width: 134px;
  }

  .no-result {
    color: $color-A4;
    font: normal 13px/14px $unified-font-regular;
    text-align: center;
    top: 304px;
  }

  // maintains size while text is removed to show spinner
  .btn-save {
    width: 94px;
  }

  .spinner-btn {
    position: absolute;
    right: 55px;
    top: 21px;
  }
}

.w-performance-panel {
  .performance-chart-panel {
    .metric-controls-c {
      .metric-controls {
        right: 25px;

        .metric-select-c {
          margin-top: -6px;
        }
        .metric-right {
          margin-right: 2px;
        }
        .metric-left {
          margin-left: 2px;
        }

        .line-icon,
        .bar-icon {
          float: left;
          color: #ccc; // $color-A8 from new color palette (_color.scss)

          &.selected {
            color: #333; // $color-A2 from new color palette (_color.scss)
          }
        }
      }
    }

    #performance-chart {
      height: 270px;
      width: 95%;
      margin-left: 20px;
    }

    .no-data-chart-container {
      margin-top: 18px;
    }
  }

  .loader-container {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }

    .load-spinner {
      left: 43%;
      top: 45.5%;
    }
  }
}

.w-site-transparency-panel,
.w-geo-performance-panel {
  .report-chart {
    margin-top: 36px;
  }

  .report-grid {
    .header.name.check {
      width: 170px;
    }
    .name .text {
      max-width: 165px;
    }
  }

  .report-controls {
    margin-top: 346px;

    &.collapsed {
      margin-top: -4px;
    }
  }
}

.w-site-transparency-panel {
  .tabs-control-dimensions {
    display: none;
  }

  .search-container {
    margin-left: 20px;
    margin-top: 10px;
    display: inline-block;
  }

  .report-chart {
    .x.axis text {
      display: block;
      fill: $color-A2;
    }

    .site-transparency-chart-panel {
      .metric-controls-c {
        .metric-controls {
          right: 25px;

          .metric-select-c1,
          .metric-select-c2 {
            margin-top: -6px;
            float: left;
          }
          .metric-right {
            margin-right: 2px;
          }
          .metric-left {
            margin-left: 2px;
          }

          .line-icon,
          .scatter-icon {
            float: left;
            color: #ccc; // $color-A8 from new color palette (_color.scss)

            &.selected {
              color: #333; // $color-A2 from new color palette (_color.scss)
            }
          }
        }
      }

      #site-transparency-chart {
        height: 270px;
        width: 90%;
        margin-left: 40px;
      }

      .no-data-chart-container {
        margin-top: 18px;
      }
    }

    .chart-header .chzn-single {
      padding-left: 10px;
    }
    .chzn-container {
      margin-right: 2px;
    }
  }

  .report-grid {
    label {
      input[type='checkbox'] {
        display: none;
      }

      .checkbox-styled {
        cursor: pointer;
        float: left;
        height: 12px;
        margin-right: 12px;
        margin-top: 2px;
        width: 12px;
      }
    }

    .child-row .date.text {
      text-indent: 60px;
    }
    .icon.arrow {
      left: 60px;
    }
    .name .text {
      max-width: 153px;
      padding-left: 20px;
    }

    .indeterminate {
      &:before {
        content: '\2043';
        padding-left: 4px;
        width: 10px;
      }
    }
  }

  .loader-container {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }

    .load-spinner {
      left: 43%;
      top: 45.5%;
    }
  }
}

.w-geo-performance-panel {
  .report-chart {
    .x.axis {
      text {
        text-transform: capitalize;
      }
    }

    .metric-controls {
      .metric-select-c {
        margin-right: 10px;
      }

      //override for the new component icons. Once all sections have components, remove this
      .metric-icon,
      .metric-icon2 {
        background: none;
        height: auto;
        width: auto;
      }
    }

    g.bar {
      cursor: pointer;
    }

    .region g.bar {
      cursor: auto;
    }
  }

  .report-grid {
    .child-row .date.text {
      text-indent: 34px;
    }
    .name .text {
      max-width: 165px;
      padding-left: 18px;
      text-transform: capitalize;
    }
  }

  .w-FilterList {
    border-left: 0;
    margin-left: 0;
  }

  .loader-container {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }
  }
}

//Select Dimension CSS for Contextual & R/F reports
.w-reach-frequency-panel,
.w-contextual-panel {
  .select-dimension {
    color: $color-A1;
    float: left;
    font: normal 12px/14px $unified-font-bold;
    height: 33px;
    margin-top: 12px;
    position: relative;
    z-index: 2;

    .w-head .view-by {
      float: left;
      margin-top: 1px;
    }

    .w-body {
      background-color: $color-A12;
      border-bottom: $color-A19 solid 1px;
      border-right: $color-A19 solid 1px;
      border-left: $color-A19 solid 1px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.18); // component shadow
      position: absolute;
      width: 235px;

      .dimension-list {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 215px;
      }

      .search {
        border-top: #ccc solid 1px; // $color-A8 from new color palette (_color.scss)
        width: 235px;
      }

      .loading-message-container {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .dimension-container {
        background: $color-A12;
        height: 200px;
        padding: 7px;
        width: 221px;

        strand-checkbox[checked] {
          background-color: $color-D14;
        }
        strand-checkbox:hover {
          background-color: $color-E8;
        }

        .items {
          padding: 5px;
          width: 100%;

          label {
            font: normal 11px/14px $unified-font-regular;
            letter-spacing: 0.4px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 190px;
          }
        }

        .all,
        .all-search-results {
          padding: 10px 5px 5px;
          width: 100%;

          label {
            font: normal 11px/14px $unified-font-bold;
          }
        }

        .no-result {
          background-color: $color-A8;
          font: normal 12px/14px $unified-font-bold;
          margin: 5px 3px 5px 5px;
          padding: 5px 10px;
        }

        .empty-msg {
          color: $color-A3;
          margin: 5px 3px 5px 5px;
        }
      }
    }
  }

  .metrics-ddl {
    .no-result {
      color: $color-A4;
      pointer-events: none;
      white-space: normal;
      overflow: visible;
    }
  }
}

.w-reach-frequency-panel {
  border-top: 1px solid $color-A7;
  margin-top: 55px;

  .tabs-settings,
  .tabs-control-interval,
  .tabs-control-dimensions {
    z-index: 5;
  }

  .report-chart {
    margin-top: 38px;

    strand-drawer {
      z-index: 3;
    }

    .rf-chart-panel {
      overflow: hidden;

      .w-chart-panel {
        position: static;
      }

      .metric-controls {
        right: 95px;

        .metric-label {
          label {
            cursor: default;
          }
        }

        .bar-icon {
          margin-left: 5px;
          margin-right: 5px;
          margin-top: -8px;
          color: #c0c0c0;
        }

        .line-icon {
          float: left;
          color: #8e8e8e;
        }

        .metric-select-c {
          margin-top: -5px;
        }
      }
    }

    .rf-canvas-container {
      width: 90%;
      height: 250px;
      margin-left: 50px;
      margin-top: 10px;

      #rf-chart {
        width: 100%;
        height: 250px;
      }
    }
  }

  .report-controls {
    margin-top: 350px;
    margin-bottom: -13px;

    .controls {
      margin-right: 10px;
    }

    &.collapsed {
      margin-top: 8px;
    }
  }

  .date-picker-container {
    position: relative;
    width: 142px;
    z-index: 2;
  }

  .select-dimension .search {
    margin-top: 7px;
  }

  .rf-grid-container {
    strand-grid {
      // right-aligns multiline text in right-aligned columns
      strand-grid-column[align-column='right'] {
        text-align: right;
      }

      //for right-aligned multiline text, ellipsis should be displayed at right ride.
      strand-grid-column ._mm_container.right.strand-grid-column #label.strand-grid-column {
        direction: ltr;
      }

      strand-grid-item {
        color: $color-A2;
        font: normal 11px/13px $unified-font-regular;

        &.total-row {
          font-family: $unified-font-bold;
        }
        &:hover {
          background-color: $color-E8;
        }
      }
    }

    .footer {
      color: $color-A4;
      font-size: 12px;
      font-style: italic;
      margin: 10px 0 10px 17px;
    }

    .no-data-grid {
      color: $color-A4;
      font-size: 13px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -166px;
      width: 200px;
    }
  }

  .loader-holder {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }

    .load-spinner {
      left: 42%;
      top: 53%;
    }
  }
}

.w-contextual-panel {
  .tabs-control-view-by {
    display: none;
  }

  .select-dimension .w-body {
    margin-left: -1px;
  }

  .report-controls {
    .controls {
      margin-right: 10px;
      margin-top: 5px;
      .search,
      .search-warning {
        float: left;
        margin-left: 10px;
      }
    }
  }

  .report-grid {
    .child-row .date.text {
      text-indent: 34px;
    }

    .msg {
      margin-left: 463px;
    }
  }

  .report-chart {
    margin-top: 38px;

    #toggle {
      z-index: 3;
    }

    .contextual-chart-panel {
      overflow: hidden;

      .w-chart-panel {
        position: static;
      }

      .metric-controls-c {
        .metric-controls {
          right: 25px;

          .metric-select-c {
            margin-top: -6px;
          }
          .metric-right {
            margin-right: 2px;
          }
          .metric-left {
            margin-left: 2px;
          }

          .line-icon,
          .bar-icon {
            float: left;
            color: #ccc; // $color-A8 from new color palette (_color.scss)

            &.selected {
              color: #333; // $color-A2 from new color palette (_color.scss)
            }
          }
        }
      }
    }

    .contextual-canvas-container {
      width: 90%;
      height: 250px;
      margin-left: 35px;
      margin-top: 10px;
    }
  }

  .loader-holder {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }

    .load-spinner {
      left: 42%;
      top: 53%;
    }
  }
}

.w-technology-panel {
  .loader-container {
    height: calc(100vh - 100px); // 100px is distance from viewport top to filter bar
    position: absolute;
    top: -45px; // filter bar height
    width: 100%;
    z-index: 6;

    // blocks any underlying elements from clicks
    .loader-block {
      @include transparent(60);
      background-color: $color-A12;
      height: 100%;
      width: 100%;
    }
  }
}

.w-data-export {
  height: 100%;
  min-width: 1145px;
  position: absolute;
  width: 100%;

  // all wrappers are hidden by default in order to fade in the view
  ._wrapper {
    height: 100%;
    visibility: hidden;

    &.fade-in {
      @include animate($animationType: fadeIn, $duration: 250ms);
    }
  }

  .clear-fix {
    clear: both;
    height: 0;

    // adds some padding to reveal email settings that would otherwise be overlapped by the footer
    &:last-of-type {
      margin-bottom: 60px;
    }
  }

  // remove above padding for A/B test
  .create-edit .transparent-footer .clear-fix {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .unsaved-modal {
    .modal-content {
      padding: 30px;
    }
    .modal-header {
      margin-bottom: 30px;
    }
    .warning-icon {
      margin-right: 10px;
      color: #fdb600; // $color-E12 from new color palette (_color.scss)
    }

    .modal-copy {
      font: normal 14px/14px $unified-font-regular;

      p:first-of-type {
        line-height: 16px;
        margin-bottom: 20px;
        word-break: break-all;
      }
    }
  }

  .manage {
    .title {
      margin: 25px;
    }

    .status {
      float: left;
      margin-left: 25px;
      width: 200px;
    }

    .status-txt {
      float: left;
      font-size: 12px;
      line-height: 30px;
    }

    .status-ddl {
      float: left;
      margin-left: 10px;
      width: 90px;
    }

    .btn-add-report {
      float: right;
      margin-right: 18px;
      width: 110px;

      label {
        padding-top: 1px;
      }
    }

    .grid-holder {
      @include box-fill($top: 115px);
    }

    .manage-grid {
      height: 100%;

      strand-grid-item {
        &:hover {
          background-color: $color-E8;
        }
      }

      .blockConcurrentDownloads {
        @include transparent(50);
        pointer-events: none;
      }

      .actions-icon,
      .download-icon,
      .refresh-icon {
        color: #666; // $color-A4 from new color palette (_color.scss)
        cursor: pointer;

        &:hover {
          color: #999;
        } // $color-A6 from new color palette (_color.scss)
      }

      .warning-icon {
        color: #f85252; // $color-C12 from new color palette (_color.scss)
        cursor: default !important;
      }

      .empty-grid {
        text-align: right;
      }
      .inactive-report {
        color: #ccc;
      } // $color-A8 from new color palette (_color.scss)
    }
  }

  .manage-loader {
    display: none;
    height: 100%;
    position: absolute;
    top: 115px;
    width: 100%;

    .load-spinner {
      left: 45%;
      top: 50%;
    }

    .click-block {
      @include transparent(3);
      background: $color-A0;
      float: left;
      height: 100%;
      width: 100%;
    }
  }

  .create-edit {
    background: $color-A12;
    position: relative;

    .content {
      padding: 25px 25px 0;
    }
    .export-error {
      border: 1px solid $color-C1;
    }
    .filter-item-hide {
      visibility: hidden;
    }
    .search-item-hide {
      display: none;
    }
    .h-separator {
      border-bottom: 1px solid $color-A7;
      margin: 25px 0;
      visibility: visible;
    }

    .v-separator {
      border-right: 1px solid $color-A7;
      float: left;
      height: 440px;
      margin: 0 30px;
      visibility: visible;
    }
  }

  .file-name {
    float: left;

    .file-name-header {
      margin-bottom: 18px;
    }
    .file-name-input {
      margin-top: -3px;

      input {
        padding-right: 28px !important;
      }
    }

    .file-name-extension {
      display: inline-block;
      font: normal 13px/13px $unified-font-regular;
    }

    .error-holder {
      margin-top: 10px;
    }
    .error-box {
      display: none;
    }
  }

  .file-type {
    float: left;
    margin-left: 2px;
    position: relative;
    width: 72px;
    z-index: 6;

    .file-type-title {
      margin-bottom: 15px;
    }
  }

  .report-type {
    float: left;
    margin-left: 40px;
    position: relative;
    width: 175px;
    z-index: 5;

    .report-type-title {
      margin-bottom: 15px;
    }
  }

  .attribution-group {
    float: left;
    margin-left: 32px;
    position: relative;
    width: 175px;

    .attribution-group-title {
      margin-bottom: 15px;
    }
  }

  .date-range {
    float: left;
    margin-left: 32px;

    strand-header {
      margin-bottom: 15px;
    }

    .date-based,
    .interval-based {
      float: left;
      height: 28px;
    }

    .interval-based {
      width: 155px;
    }

    .type-selector {
      float: left;
      width: 100px;
    }

    .dates {
      float: left;
      font: normal 13px/13px $unified-font-regular;

      &:hover {
        border-bottom: 1px dotted $color-A1;
        cursor: pointer;
      }
    }

    .fixed-date-selector,
    .ongoing-date-selector {
      float: left;
      margin-left: 8px;
      margin-top: 8px;
    }

    .rolling-date-selector {
      float: left;
      margin-left: 8px;

      span {
        font-size: 13px;
        vertical-align: middle;
      }

      .rolling-date-input {
        margin-left: 5px;
        margin-right: 5px;
      }

      .rolling-date-input input {
        text-align: center;
      }
    }

    .timewindow-unit-selector {
      width: 79px;
    }

    .time-rollup-selector {
      float: left;
      margin-left: 8px;
      width: 98px;
    }

    .error-holder {
      padding-top: 10px;
      width: 375px;
    }

    .date-range-container {
      height: 30px;
    }

    .info-holder {
      padding-top: 10px;
      width: 375px;
    }
  }

  .filter {
    float: left;
    width: 100%;

    .search-holder {
      margin-bottom: 30px;
      margin-top: 15px;
    }

    .search-group {
      height: 30px;
      margin-top: 2px;

      .search-selector {
        width: 104px;
      }
    }

    .search-warning {
      display: none;
      margin-left: 20px;
    }

    .grid-holder {
      border: 1px solid $color-A7;
      box-sizing: border-box;
      float: left;
      height: 317px;
      position: relative;
      width: 722px;

      .header {
        background-color: $color-A7;
        border-bottom: 1px solid $color-A7;
        box-sizing: border-box;
        height: 23px;
        left: 0;
        padding: 4px 10px;
        position: absolute;
        top: 0;
        width: 95%; // do not fully extend to avoid covering scroll btn
      }

      .title {
        font: normal 10px/13px $unified-font-bold;
        margin-left: 6px;
        vertical-align: middle;
      }

      .select-all,
      .include-icon {
        cursor: pointer;

        &.deselected {
          color: #c2c2c2; // $color-A18 from new color palette (_color.scss)

          &:hover {
            color: #4c4c4c;
          } // $color-A3 from new color palette (_color.scss)
        }

        &.selected,
        &.selected-outline,
        &.selected-partial {
          color: #86c633; // $color-B12 from new color palette (_color.scss)
        }
      }

      &.filter-error {
        border: 1px solid #f85252;
      } // $color-C12 from new color palette (_color.scss)
    }

    .filter-warning-container {
      width: 66%;

      .filter-warning {
        display: none;
        margin-top: 10px;
      }
    }

    .grid-component-holder {
      // Relies upon shady dom - will not work when shadow dom is available in browsers
      // and shady dom polyfill has been removed https://www.polymer-project.org/1.0/blog/shadydom
      .carat {
        margin-top: 2px;
      }
      .carat-icon {
        cursor: pointer;
        transform: matrix(-0, -1, 1, -0, 6, 5);

        &.level-expanded {
          transform: matrix(1, 0, 0, 1, 0, 0);
        }
      }

      .loader-icon {
        display: none;
        position: relative;
      }

      .search-level {
        font: normal 12px/12px $unified-font-bold;
      }
      .highlighted-term {
        background: $color-E8;
      }

      strand-grid-item {
        cursor: pointer;

        &:hover {
          background-color: $color-E8;
        }
        &.selected {
          background-color: $color-D13;
        }
        &.selected-outline {
          background-color: $color-D14;
        }
      }

      strand-grid-item #carat,
      strand-grid-item #icon,
      strand-grid-item #carat .columnContent,
      strand-grid-item #icon .columnContent {
        overflow: visible;
      }
    }

    .bin-holder {
      background-color: $color-D12;
      border: 1px solid $color-A7;
      box-sizing: border-box;
      float: left;
      height: 317px;
      margin-left: 10px;
      position: relative;
      width: 362px;

      .include-icon {
        color: #86c633;
      }

      .remove-icon {
        color: #9a9a9a;

        &:hover {
          color: #333333;
        }
      }

      .header {
        background-color: $color-A12;
        border-bottom: 1px solid $color-A7;
        box-sizing: border-box;
        height: 23px;
        left: 0;
        padding: 4px 10px;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .title {
        font: normal 10px/10px $unified-font-bold;
        margin-left: 2px;
      }
    }

    .bin-component-holder {
      // Relies upon shady dom - will not work when shadow dom is available in browsers
      // and shady dom polyfill has been removed https://www.polymer-project.org/1.0/blog/shadydom
      strand-grid strand-item-recycler {
        background: none !important;
      }

      strand-grid-item {
        .truncate-path {
          direction: rtl; // causes left-ellipsis
          overflow: hidden;

          // force direction back to display numbers properly
          span {
            unicode-bidi: bidi-override;
          }
        }

        .path {
          font: normal 11px/12px $unified-font-regular;
          margin-right: 2px;
        }

        .name {
          direction: ltr;
          font: normal 11px/12px $unified-font-bold;
        }

        .remove-icon {
          cursor: pointer;
        }
      }

      .group-row {
        background: none;
        color: $color-D10;
      }

      strand-grid-item #remove {
        overflow: visible;
      }
      strand-grid-item ._mm_item {
        padding: 6px 0;
      }
    }

    .load-spinner {
      left: 50%;
      margin: -13px 0 0 -61px;
      top: 50%;
    }
  }

  .filter-grid {
    width: 720px;
    height: 315px;
  }

  .filter-bin {
    width: 360px;
    height: 315px;
  }

  .additional-filters {
    clear: both;
    height: 70px;
    padding-top: 30px;

    .additional-filters-title {
      margin-bottom: 15px;
    }

    .exchange-selector {
      width: 300px;

      label {
        width: 260px;
      }
    }

    .exchange-container {
      height: 200px;
    }
  }

  .dimensions-metrics {
    float: left;

    strand-header {
      margin-bottom: 18px;
    }
    .search-input {
      margin-top: -3px;
    }
    .search-input input {
      padding-right: 28px !important;
    }

    .uniques-warning,
    .referrer-warning,
    .hyperlocal-warning,
    .contextual-warning,
    .reach-frequency-warning {
      margin-top: 15px;
    }

    .selector-warning {
      display: none;

      strand-inline-box {
        top: 10px;
      }
    }

    .column-selectors {
      margin-top: 30px;

      ._wrapper {
        visibility: visible;
      }
    }
  }

  .dimensions-selector,
  .metrics-selector {
    float: left;
  }

  .dimensions-selector {
    margin-right: 55px;
  }

  .column-selector {
    .w-head {
      margin: 0 0 34px;

      h1 {
        font-size: 22px;
        letter-spacing: 0.5px;
      }
    }

    .w-foot {
      margin-top: 34px;
    }

    .no-results {
      color: $color-A4;
      display: none;
      font-family: $unified-font-regular;
      font-size: 12px;
      left: 49px;
      position: absolute;
      top: 206px;
    }

    .list-col-A .btn-clear {
      display: none;
    }
    .list-col-B .btn-clear {
      display: block;
    }

    .list-col-A,
    .list-col-B {
      width: 188px;

      .label {
        color: $color-A2;
        font-family: $custom-font-semi-bold;
        font-size: 13px;
        margin: 0 0 14px;
        text-transform: none;
      }

      .btn-clear {
        @include transparent(50);
        float: right;
        margin-top: 1px;

        &:hover {
          @include transparent(100);
          cursor: pointer;
        }
      }

      .list {
        border: 1px solid $color-A7;
        height: 378px;
        overflow: auto;

        &.error-state {
          border: 1px solid $color-C2;
        }
      }

      .connectedSortable {
        height: 100%;
      }

      li {
        @extend .grab_cursor;
        background-color: $color-A12;
        border-bottom: 1px solid $color-A7;
        border-top: 1px solid $color-A7;
        color: $color-A3;
        font-size: 11px;
        letter-spacing: 0.2px;
        margin-top: -1px;
        padding: 7px 5px;

        label {
          @extend .grab_cursor;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 145px;
        }

        &.selected,
        &.ui-sortable-helper {
          background-color: $color-D7;
        }

        &.ui-sortable-helper {
          @extend .grabbing_cursor;
        }
        &.ui-sortable-placeholder {
          border: 4px solid $color-D8 !important;
          margin-bottom: 1px;
          margin-top: 0;
          padding-bottom: 3px;
          padding-top: 3px;
          visibility: visible !important;
        }

        &.highlight {
          @include animate($animationType: mmValueChange, $duration: 1400ms);
        }

        &.deactivate {
          background-color: $color-A9;
          pointer-events: none;

          .btn-clear {
            display: none;
          }
        }
      }
    }

    .buttons-col-AB {
      width: 63px;

      .actions {
        margin: 180px 0 0 12px;

        .action {
          margin: 4px 0;
        }
      }

      button {
        height: 25px;
        width: 39px;

        .arrow-left,
        .arrow-right {
          @include sprite-icon($left: 11, $down: 8, $width: 12px, $height: 11px);
          margin-left: -2px;
        }

        .arrow-right {
          @include flipHorizontally();
          margin-top: 1px;
        }

        &.btn-highlighted {
          @extend .btn-primary;
          width: 39px;

          .arrow-left,
          .arrow-right {
            @include sprite-icon($left: 11, $down: 9, $width: 12px, $height: 11px);
            margin-left: -2px;
          }
        }
      }
    }
  }

  .email-settings {
    .settings-header {
      height: 20px;
    }
    .settings-toggle {
      cursor: pointer;
      float: left;
      transform: matrix(-0, -1, 1, -0, 0, 3);

      &.expanded {
        transform: matrix(1, 0, 0, 1, 0, 4);
      }
    }

    .header {
      float: left;
      margin-left: 5px;
    }

    .align-left {
      float: left;
    }

    .settings-drawer {
      border: 0;
      margin-bottom: 24px;
    }

    .fixed-warning {
      margin-top: 15px;
      width: 100%;
    }

    .blocker {
      @include transparent(50);
      background-color: $color-A12;
      height: 363px;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .settings-container {
      .from {
        width: 365px;
      }

      .from-email {
        font-size: 13px;
        line-height: 29px;
      }

      .to {
        width: calc(100% - 365px);

        .email-address-error {
          display: none;
          top: 10px;
        }
      }

      .selectors {
        margin-left: 5px;
        width: 249px;

        label {
          font-size: 13px;
          line-height: 29px;
        }

        .selector-error {
          border: 1px solid #f85252;
        } // $color-C12 from new color palette (_color.scss)
      }

      .frequency-ddl {
        width: 110px;
      }

      // temporary override until component is updated
      .daily-bl {
        strand-button[disabled] .button.secondary.strand-button {
          @include blue-component-btn();
        }
      }

      .weekly-bl {
        strand-button[selected] .button.secondary.strand-button {
          @include blue-component-btn();
        }
      }

      .monthly-ddl {
        margin: 0 5px;
      }

      .start-end-date {
        cursor: pointer;
        font-size: 13px;
        margin-top: 14px;

        &:hover {
          border-bottom: 1px dotted $color-A1;
        }
      }

      .preferred-time {
        margin-left: 20px;

        #preferred-time-icon {
          color: #ccc; // $color-A8 from new color palette (_color.scss)

          &:hover {
            color: #666;
          } // $color-A4 from new color palette (_color.scss)
        }
      }

      .preferred-time-title {
        margin-right: 3px;
      }
      .time-ddl {
        width: 78px;
      }
      .am-pm-ddl {
        width: 64px;
      }
      .error-holder {
        margin-left: 558px;
        padding-top: 10px;
        width: calc(100% - 558px);
      }

      .error-holder.show {
        display: inline-block;
      }

      .timezone {
        margin-left: 22px;
        width: calc(100% - 728px);
      }
    }

    .from,
    .to,
    .subject,
    .scheduler,
    .message,
    .preview {
      margin-top: 15px;
    }

    .preview {
      margin-left: 24px;
      width: calc(100% - 558px);
    }

    .from-email,
    .to-input,
    .subject-input,
    .frequency-selector,
    .preferred-time-selector,
    .timezone-ddl,
    .message-input {
      display: block;
      margin-top: 5px;
    }

    .message-preview {
      border-radius: 4px;
      border: 1px solid #ccc; // $color-A8 from new color palette (_color.scss)
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.125);
      color: #ccc; // $color-A8 from new color palette (_color.scss)
      display: block;
      font-family: $unified-font-regular;
      font-size: 13px !important;
      line-height: 15px !important;
      margin-top: 5px;
      padding: 6px 10px;
      resize: none;
      width: 96%;
    }

    .message-preview:focus {
      border-color: #abd3f7; // $color-D20 from new color palette (_color.scss)
      box-shadow: 0 0 6px rgba(171, 211, 247, 0.83); // $color-D20 from new color palette (_color.scss)
      outline: none;
    }

    .message-input {
      width: 534px;
    }
  }

  // maintains widths of buttons when replaced with spinner
  .export-footer {
    .save-btn {
      width: 53px;
    }
    .export-btn {
      width: 94px;
    }
    .save-export-btn {
      width: 141px;
    }
    .save-new-btn {
      display: none;
    }
    .modal-save-btn {
      width: 53px;
    }
    .button.strand-button label {
      margin-left: auto;
      margin-right: auto;
      width: inherit;
    }
  }
}
