.side-bar {
	.version-info {
    bottom: 80px;
    left: 2px;
    position: absolute;
    text-shadow: 0.4px 0.4px 0 #383838;
    width: 92px;
    color: $color-A4;
    text-align: left;
    margin: 0;
		font-size: 9px;
	}
}

.index-content {
  .version-info {
    width: 100%;
    color: $color-A8;
    font-size: 12px;
    text-align: center;  
  }
  
  .version-number {
    width: 100%;
  }
  
  .version-number {
    margin-left: 5px;
  }
}
