/*
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

			THIS FILE HAS BEEN MODIFIED FOR T1 PURPOSES, 
				CONSIDER THIS WHEN UPDATING STICKY

+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
.sticky-queue {
	position: fixed;
	z-index: 2000;
}

.sticky-item {
	position: relative;

	.sticky-container {
		float: right;
		margin-top: 5px;

		&.sticky-notify-message {
			@include rounded-box($padding: 0, $background: $color-A12, $border-radius: 3px, $border-rule: 2px, $border-color:$color-B1);
		}

		&.sticky-notify-warning {
			@include rounded-box($padding: 0, $background: $color-A12, $border-radius: 3px, $border-rule: 2px, $border-color:$color-E2);
		}

		&.sticky-notify-error {
			@include rounded-box($padding: 0, $background: $color-A12, $border-radius: 3px, $border-rule: 2px, $border-color:$color-C2);
		}

		.sticky-check-icon, .sticky-warning-icon, .sticky-error-icon {
			float: left;
			margin: 10px;
		}

		.sticky-check-icon {
			@include sprite-icon($left: 8, $down: 1, $width: 24px, $height: 24px);
		}

		.sticky-warning-icon {
			@include sprite-icon($left: 8, $down: 4, $width: 24px, $height: 24px);
		}

		.sticky-error-icon {
			@include sprite-icon($left: 8, $down: 10, $width: 24px, $height: 24px);
		}

		.sticky-notification-msg {
			float: left;
			padding: 14px 0;
			max-width: 500px;
			font: normal 12px/16px $unified-font-regular;
			word-wrap: break-word;
		}

		.sticky-close-icon {
			@include sprite-icon($left: 7, $down: 0, $width: 10px, $height: 10px);
			float: left;
			margin: 17px 12px 17px 10px;
			cursor: pointer;
		}
	}
}

.sticky-top-right, .sticky-top-left {
	top: 20px;
}

.sticky-top-right, .sticky-bottom-right {
	right: 20px;
}

.sticky-top-left, .sticky-bottom-left {
	left: 20px;
}

.sticky-bottom-right, .sticky-bottom-left {
	bottom: -2px;
}