@import "mixins";

.w-BulkeditStrategies {
  margin: 0;

  .w-Targeting,
  .w-Supply,
  .w-Creatives {
    margin: 1px 0 0 !important;
    width: 100%;
    background-color: $color-A12;
    border-radius: 0 !important;

    .ui-tabs-nav {
      background-color: $color-A12;
    }

    .ui-tabs-panel {
      @extend .box-fix;
    }

    .column-A {
      @extend .last;
      position: absolute;
      width: 67%;
      height: 100%;
      overflow: hidden;

      .list-c {
        @include box-fill($top: 9px, $right: 17px, $left: 17px);
      }

      .list-footer {
        @include box-fill($top: auto, $bottom: 1px);
        @extend .box-fix;
        padding: 10px 0;
        background-color: $color-D12;
        border-top: 1px solid $color-A7;

        .actions {
          margin-right: 10px;
        }

        .btn-additional-sites {
          @extend .btn-secondary;
          width: 135px;
          padding: 2px !important;

          span {
            font-size: 12px;

            &.count {
              font-family: $custom-font-semi-bold;
            }
          }
        }
      }
    }

    .bins-column {
      @include box-fill($left: auto !important);
      width: 33%;
    }

    span.strong {
      font-family: $custom-font-semi-bold;
      color: $color-A2;
    }

    .list-container {
      @extend .box-fix;
    }
  }

  .w-Creatives .column-A{
    background-color: $color-A12;
  }

  .w-Supply .tab-info {
    cursor: pointer;
    @include sprite-icon($left: 10, $down: 3, $width: 13px, $height: 13px);
  }

  .include {
    @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);

    &:hover {
      @include sprite-icon($left: 10, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 10, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.partial {
      @include sprite-icon($left: 10, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active {
        @include transparent(30);
      }
    }

      &.parentSelected {
      @include sprite-icon($left: 9, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active {
        @include transparent(30);
      }
      }
  }

  .exclude {
    @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
    @include transparent(30);
    margin-left: 7px;

    &:hover {
      @include sprite-icon($left: 11, $down: 0, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.active {
      @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
      @include transparent(100);
    }

    &.partial {
      @include sprite-icon($left: 11, $down: 7, $width: 15px, $height: 15px);
      @include transparent(100);

      &:active {
        @include transparent(30);
      }

    }
    &.parentSelected {
      @include sprite-icon($left: 11, $down: 1, $width: 15px, $height: 15px);
      @include transparent(50);

      &:active {
        @include transparent(25);
      }
    }
  }

  .create-edit-main-loader {
    position: absolute;
    top: 45%;
    left: 43%;
  }

  .bulkedit-header {
    text-transform: capitalize;
  }
}