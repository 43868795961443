.deals-container,
.deal-form-container,
.deal-analytics-container {
  .form-stack {
    clear: both;
    display: flex;
    margin-bottom: 24px;
  }
}

#viewport.admin-tree-list {
  bottom: 0;
}

.deals-container,
.deal-analytics-container {
  height: 100%;

  .deals-export-loader,
  .bulk-update-loader {
    z-index: 10;
    position: fixed;
    bottom: 5px;
    right: 5px;
  }

  .deals-top-section,
  .deal-analytics-top-section {
    .deals-activity-row {
      margin-bottom: 0;
      padding: 20px 15px 20px 10px;

      .media-type-deal-groups {
        margin-right: 15px;
      }

      .column-deal-search {
        width: 60%;
        white-space: nowrap;

        .deal-search-box-group {
          margin-top: 3px;
        }

        .deal-search-select {
          width: 130px;
        }

        .search-deals {
          width: 190px;
        }

        .search-warning-deals {
          margin-left: 15px;
          white-space: normal;
        }
      }

      .column-deal-active {
        margin-top: 5px;
        text-align: right;
        width: 50%;

        .deal-status-select {
          margin-right: 5px;
          text-align: left;
          width: 100px;
        }

        .choose-action {
          text-align: left;
          z-index: 3;
          margin-right: 5px;

          .option-disabled {
            color: $color-A5;
          }
        }

        .interval-select {
          margin-right: 5px;
          text-align: left;
          width: 130px;
        }

        .status-deal-label,
        .interval-label {
          font-size: 13px;
          margin: 5px 5px 0 0;
        }

        .deal-activate {
          margin-right: 5px;
        }
      }

      .add-deal-select {
        a {
          text-decoration: none;
        }

        strand-list-item {
          text-align: left;
        }
      }
    }
  }

  .deals-grid-section {
    position: absolute !important;
    top: 74px;
    right: 0;
    bottom: 0;
    left: 0;

    .deal-row,
    .deal-group-deal-row,
    .deal-group-row {
      color: $color-A2;
      font: normal 11px/13px $unified-font-regular;

      &.total-row {
        font-family: $unified-font-bold;
      }

      &:hover {
        background-color: $color-E8;
      }

      .deal-item-status-false,
      .deal-item-status-0 {
        color: $color-A5;
      }

      .currency-symbol {
        display: inline-block;
      }
    }

    .deals-grid,
    .deal-groups-grid {
      height: 100%;
      min-width: 1200px; // going below 1195 causes deal group deal grids to wrap
      width: 100%;

      .str-icn-expand-all {
        display: none;
      }

      .deal-group-deal-grid {
        min-height: 60px;

        #viewport {
          min-height: 60px;
        }

        #overlay {
          min-height: 60px;
        }

        #loader {
          margin-top: 20px;
        }
      }

      strand-grid-item {
        .deal-item-edit,
        .deal-group-item-edit {
          a {
            color: $color-A2;
          }

          cursor: pointer;
          visibility: hidden;
        }

        &:hover .deal-group-item-edit {
          visibility: visible;
        }

        &:hover .deal-item-edit {
          visibility: visible;
        }
      }
    }
  }

  .deals-bottom-section {
    background-color: #ffffff;
    bottom: 0;
    left: 113px;
    padding: 10px;
    position: fixed;
    right: 0;

    .deals-bottom-remaining {
      font: 14px $custom-font-semi-bold;
      min-height: 1px;
      text-align: left;
      width: 33%;
    }

    .deals-bottom-more {
      text-align: center;
      width: 33%;
    }

    .deals-bottom-amount {
      text-align: right;
      width: 33%;

      .show-deal-label {
        font: 14px $custom-font-semi-bold;
      }
    }
  }
}

.entity-grid {
  height: 312px;
}

.entity-row {
  #columnContainer.strand-grid-item {
    display: flex;
  }
}

.media-column {
  width: 264px;

  & + .media-column {
    margin-left: 2.5em;
    width: auto;
  }
}

.deal-form-container {
  strand-header {
    display: block;
    margin-bottom: 5px;
  }

  strand-input,
  strand-dropdown {
    width: 264px;
  }

  .deal-header {
    background-color: #f2f4f8;
    height: 40px;
    padding: 15px 0 0 20px;
  }

  .deals-form-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 140px);
    padding-left: 20px;
    position: relative;

    .deal-edit-loader {
      left: 45%;
      position: absolute;
      top: 30%;
      z-index: 1;
    }

    .publisher-deal-id,
    .deal-price-input {
      width: 160px;
    }

    .deal-id-group,
    .deal-price-input {
      margin-right: 10px;
    }

    .deal-status-row {
      margin-bottom: 5px;
    }

    .deal-PG-checkbox {
      margin-left: 155px;
      margin-top: 25px;
    }

    .deal-PG-info-icon {
      margin-top: 28px;
      color: $color-A18;
    }

    .deal-PG-inactive-info-row {
      width: 100%;
    }

    .deal-date-PG-info-inline,
    .deal-PG-inactive-info-inline {
      width: 98%;
    }

    .deal-dates,
    .deal-targeting,
    .deal-owner-row,
    .deals-description,
    .deals-search-tree,
    .deal-status-select-row {
      flex-direction: column;
    }

    .deals-edit-warning {
      margin-top: 8px;
    }

    .deals-search-tree-row {
      .deal-tree-search-input {
        width: 70%;

        .deal-search-box-group-tree {
          height: 32px;

          .search-tree {
            vertical-align: top;
            width: 300px;
          }

          .search-warning-ad-agency-org {
            margin-left: 10px;
          }
        }
      }

      .deal-tree-checkbox {
        margin-top: 10px;
        text-align: right;
        width: 30%;

        .show-selected-checkbox {
          margin-right: 20px;
        }
      }
    }

    .deals-tree-row {
      display: flex;
      margin: 16px 0 0 -20px;

      .tree-container {
        width: 100%;

        admin-tree-list {
          height: 312px;
        }

        strand-header {
          margin-bottom: -2px;
        }
      }
    }

    .selected-items-container {
      background: #f2f4f8; // $color-A19;
      border: 1px solid #e0e0e0; // $color-A16;
      font-size: 11px;
      height: 312px;
      margin-left: 10px;
      text-transform: uppercase;
      width: 300px;
    }

    .selected-items-heading {
      background: #fff;
      border-bottom: 1px solid #e0e0e0;
      box-sizing: border-box;
      font-size: inherit;
      padding: 0 8px;
    }

    .deal-owner-row {
      .entity-type-dropdown {
        width: 125px;
      }

      .search-advertisers-dropdown {
        width: 175px;
      }
    }

    .deal-date-btn-column {
      width: 100%;

      .deals-date-btn {
        cursor: pointer;
        display: inline-block;
        font: normal 13px $unified-font-regular;
        height: 20px;
        margin-top: 15px;
        visibility: hidden;

        p:hover {
          border-bottom: 1px dotted $color-A0;
        }
      }

      .date-error {
        padding: 1px 2px 0;
        border: solid 1px #ff0000;
        border-radius: 4px;
      }
    }

    .deal-currency-row {
      display: flex;
    }

    .deal-currency-label {
      align-items: center;
      display: flex;
      float: left;
      font-size: 13px;
      height: 29px;
      justify-content: center;
      padding: 0 5px 0 0;
      width: 20px;
    }

    .deal-price {
      margin-left: 58px;
    }

    .deal-cpm {
      font-size: 13px;
    }

    .deal-floor {
      margin-left: 5px;
    }

    .deal-targeting-loader {
      position: static;
      width: 130px;
    }
  }

  .deals-footer {
    margin: auto 0 0 -20px;

    .strand-header {
      font-size: 12px !important;
      font-weight: normal;
      text-transform: none;
    }

    .save-action label {
      visibility: hidden;
    }
  }
}

.media-tabs-container {
  .media-tabs-error {
    .tabs-content {
      top: 0;
    }
  }

  .media-tabs-warning {
    margin: 1em;
    width: calc(100% - 2em);
  }
}

.select-group-owner-type {
  margin-right: 10px;
}
