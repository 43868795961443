.w-OpenSupply .w-PMPD .w-DataGrid {
	top: 48px;
	bottom: 30px;

	.content-holder {
		height: 100%;
	}

	.headers {
		padding-left: 28px;
		padding-right: 15px;
	  	cursor: pointer;

		.publisher {
			margin-left: 10px;
		}
		.site {
			margin-left: 7px;
		}

	  .icon-placement{
		float: left;
		width: 12px;
		height: 16px;
	  }
	}

	.placement, .publisher, .site {
		position: relative;
		float: left;
	}

	.placement {
		width: 40%;
	}
	.publisher {
		width: 30%;
	}
	.site {
		width: 24%;
	}

	.icons {
		margin-left: -5px;
		margin-right: 10px;
	}

	.truncate {
		word-wrap: break-word;
	}
}

.w-PMPD {
	.w-Filters .chzn-container {
		width: 145px;
	}

	/* Hide Drag and Drop Logo */
	.w-Bins {
		.drop-area {
			border: 0;
		}

		.drop-area-c .drop-icon,
		.drop-area-c .drop-text {
			display: none;
		}
	}
}
