.w-Summary {
  font-size: 13px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 0 0 30px;
  width: 96%;

  .section-heading {
    border-bottom: 1px solid $color-A5;
    font-size: 22px;
    height: 30px;
    margin: 10px 20px 30px 0;
    width: 100;

    span {
      display: inline;
      float: left;
      margin: 2px 8px 0 0;
    }

    .no-selection {
      display: none;
      float: right;
      font-size: 11px;
      padding-top: 5px;
    }
  }

  .summary-heading-icon {
    @include sprite-icon($left: 2, $down: 4, $width: 18px, $height: 18px);
  }

  .data-row {
    overflow: auto;
    padding: 8px 0;
    width: 100%;
  }

  .no-data-item {
    margin: 0 0 25px;
    overflow: visible;
    padding: 0;
  }

  .summary-block-left,
  .summary-block-right {
    display: inline;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;

    .col-label {
      @include transparent(50);
      display: inline;
      float: left;
      font-size: 11px;
      margin: 2px 30px 0 0;
      text-align: right;
      width: 29%;
    }

    .col-value {
      display: inline;
      float: left;
      text-align: left;
      width: 59%;
    }
  }

  .summary-block-left {
    div:nth-child(7) .tab-info {
      margin-top: 2px;
      margin-left: 7px;
    }
    margin-left: 10px;
    width: 49%;

    .col-label {
      width: 25%;
    }
    .col-value {
      width: 65%;
    }
  }

  .summary-block-right {
    width: 48%;

    .tab-info {
      margin: 0 7px !important;
    }
  }

  .sub-section-heading .col-label {
    @include transparent(70);
    font-family: $custom-font-bold;
    font-size: 11px;
  }

  .spacer {
    clear: both;
    font-size: 1px;
    height: 10px;
  }

  .reduced {
    height: 1px;
  }

  .column-header {
    div,
    span {
      font-size: 11px;
    }
  }

  .section-sub-head {
    @include transparent(50);
    display: inline;
    float: left;
    font-size: 11px;
    margin-right: 30px;
    text-align: right;
    vertical-align: top;
    width: 13.5%;
  }

  .budget-flight-no-heading {
    width: 11%;
    margin-right: 20px;
  }

  .budget-flight-section-label {
    width: 11%;
    margin-right: 20px;
  }

  .secondary-has-section-heading {
    padding-top: 15px;
  }
  .secondary-no-section-heading {
    padding-top: 8px;
  }

  .section-content {
    display: inline;
    float: left;
    overflow: hidden;
    vertical-align: top;
    width: 83%;

    .single-column {
      padding: 5px 0 7px;
      width: 98%;
    }

    .section-head {
      font-family: $custom-font-bold;
      padding-top: 14px;
    }

    .first-item {
      padding-top: 8px;
    }

    .col-head {
      @include transparent(50);
      font-size: 11px;
      padding-top: 0;
    }

    .column-header {
      padding-top: 0;

      .col1,
      .col2,
      .col3,
      .col4,
      .col6,
      .col5,
      .col7,
      .col8,
      .col345,
      .single-column {
        color: $color-A18;
        white-space: nowrap;
      }

      .budget-flight-header-col2,
      .budget-flight-header-col3 {
        width: 9%;
        margin-right: 15px;
        white-space: normal;
      }

      .budget-flight-header-col2 {
        width: 12%;
      }

      .budget-flight-header-col5 {
        width: 13%;
        margin-left: 55px;
        margin-right: 15px;
      }

      .budget-flight-header-col6 {
        width: 5%;
        margin-right: 15px;
      }

      .budget-flight-header-col7 {
        width: 8%;
        margin-right: 0;
        white-space: normal;
      }
    }

    .col1,
    .col2,
    .col3,
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col345 {
      display: inline;
      float: left;
      margin-right: 20px;
      text-align: left;
    }

    .budget-flight-report-icon {
      width: 2%;
    }
  }

  .section-budget-flights,
  .section-vendor-agreements {
    margin: 5px 0 0 -2px;
    overflow: auto;

    .col1 {
      text-align: left;
      width: 58%;
    }

    .budget-flight-col1,
    .budget-flight-dates-coll {
      width: 38%;
      margin-right: 15px;
      white-space: normal;
    }

    .col2 {
      width: 11%;
    }

    .budget-flight-col2 {
      width: 12%;
      min-height: 1px;
    }

    .col3 {
      width: 10%;
    }

    .budget-flight-col3 {
      width: 12px;
      margin-right: 0;
    }

    .section-sub-head {
      @include transparent(70);
      font-family: $custom-font-bold;
    }

    .budget-flight-col5 {
      width: 13%;
      margin-right: 15px;
      margin-left: 10px;
    }

    .budget-flight-col6 {
      width: 5%;
      margin-right: 15px;
    }

    .budget-flight-col7 {
      width: 8%;
      margin-right: 0;
      white-space: normal;
    }
  }

  .section-budget-flights {
    .column-header {
      padding-top: 2px;

      .col1 {
        @include transparent(99);

        .form-checkbox {
          margin-left: 3px;
        }
      }
    }

    .section-sub-head {
      padding-top: 2px;
    }

    .list-content {
      .col1 {
        &.current-flight {
          font: 13px $custom-font-semi-bold;
        }
        span {
          font: 13px $custom-font-italic;
        }
      }

      .budget-ended {
        @include transparent(60);
      }

      .col3 {
        span {
          @include sprite-icon($left: 10, $down: 11, $width: 18px, $height: 18px);
          @include transparent($percent: 60);
          display: inline-block;

          &:hover {
            @include transparent($percent: 99);
          }
        }
      }
    }
  }

  .content-no-tableheaders {
    margin-top: -8px;
  }
  .sections-container {
    margin-bottom: 30px;
  }
  .tabs-content {
    background-color: $color-A12;
  }

  .icon-edit {
    border: 6px solid $color-A12;
    float: right;
  }
}

.w-ReadonlyContextual .heading-icon {
  @include sprite-icon($left: 1, $down: 3, $width: 22px, $height: 18px);
}

.w-ReadonlyLocation .heading-icon {
  @include sprite-icon($left: 6, $down: 6, $width: 18px, $height: 18px);
}

.w-ReadonlyTechnology .heading-icon {
  @include sprite-icon($left: 6, $down: 7, $width: 18px, $height: 18px);
}

.w-ReadonlyVideoTargeting .heading-icon {
  @include sprite-icon($left: 9, $down: 10, $width: 16px, $height: 10px);
  margin-top: 7px !important;
}

.dba-tooltip-link {
  color: $color-A12;
}
