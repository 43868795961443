.w-StrategySearch {
	position: relative;

	.w-body {
		height: 32px;
		position: absolute;
		right: 8px;
		top: 52px;
		z-index: 1;
	}

	.field {
		@include _box-shadow($shadow: 0 1px 2px 1px $color-A5);
		@include border-radius($radius: 0 0 2px 2px);

		width: 35px;
		height: 31px;
		background: $color-A6;
		border-right: 1px solid $color-A4;
		border-bottom: 1px solid $color-A4;
		border-left: 1px solid $color-A4;

		.search-input {
			display: none;
			float: left;
			margin: 5px 0 5px 5px;
			width: 170px;
			overflow: hidden;
			height: 15px;
		}
	}

	.field-open {
		width: 214px;
	}

	.search-button {
		position: absolute;
		width: 17px;
		height: 10px;
		float: right;
		right: 0;
		top: 0;
		padding: 10px;
		z-index: 1;

		&:hover {
			cursor: pointer;
		}

		.icon {
			@include sprite-icon($left: 8, $down: 0, $width: 19px, $height: 19px);
			margin: -3px 0 0;
		}

		.icon-open {
			@include sprite-at-position($left: 7, $down: 0);
			margin: 0 0 0 5px;
		}
	}
}
