.add-behavior-container-v2 {

  .panels {
    height: calc(100% - 70px);

    .left-panel {
      width: 73%;
      display: inline-block; 
      float: left;

      &.standard {
        margin: 10px;         
        height: calc(100% - 72px);
      }

      &.adaptive {
        margin: 15px 10px; 
        height: calc(100% - 83px);

        .headers {
          padding-left: 15px;
          padding-bottom: 15px;
        }
      }

      .behavior-tabs {
        height: 100%;
      }

      strand-tab {
        height: 100%;
      }

      .behavior-selector-controls {
        position: relative;
        top: 15px;

        &.standard {
          top: 5px;
        }

        .selector-container {
          position: relative;
          display: inline-block; 
          float: left; 
          margin-right: 10px;

          &.advertiser {
            width: 160px;
          }

          &.campaign {
            width: 160px;
          }

          &.search {
            width: 205px;
          }

          &.active {
            top: 3px;
            float: right;
            margin-right: 0px;
          }

          #behavior-type-dropdown {
            width: 100px;
            display: inline-block;
            float: left;
            margin-right: 10px;          
          }

          .column-selector-item {
            pointer-events: none;
            .column-selector-checkbox {
              pointer-events: all;
            }

            .select-all {
              pointer-events: all;
            }

            .campaign-checkbox {
              pointer-events: all; 
            }  
          }
        }
      }

      .behavior-grid-v2 {
        width: 100%; 
        height: calc(100% - 55px);
        top: 30px;        

        &.standard {
          height: calc(100% - 10px);
          top: 10px;
        }

        strand-grid-item {
          &:hover {
            background-color: $color-E8; 
            cursor: pointer;
          }
        }
      }
    }

    .right-panel {
      display: inline-block; 
      width: 25%; 
      height: 100%;    
      padding-top: 10px; 
      border-left: 1px solid $color-A7;      
    }
  }

  #selector-footer {
    position: absolute; 
    bottom: 0;
    left: 0;
    right: 0;
  }

  .select-all {
    color: $color-A5;
    &:hover {
      color: $color-A1;
    }

    &.selected {
      color: #86c633; //$color-B12 of components palette
      &:hover {
        color: #86c633; //$color-B12 of components palette
      }
    }

    &.disabled.deselected {
      opacity: .25;
      pointer-events: none;
      &:hover {
        color: $color-A5;
      }
    }

  }

  strand-icon[id^="disabled"]{
    color: $color-A5;
    opacity: .25;
    &:hover {
      color: $color-A5;
    }    
  }

  strand-icon#selected {
    color: #86c633; //$color-B12 of components palette
    &:hover {
      color: #86c633; //$color-B12 of components palette
    }
  }

  strand-icon#deselected {
    color: $color-A5;
    &:hover {
      color: $color-A1;
    }
  }

  .disabled#deselected {
    opacity: .25;
    pointer-events: none;
    &:hover {
      color: $color-A5;
    }
  }
}
