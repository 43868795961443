.w-CampaignList {
  font-family: $unified-font-regular;

  .ribbon-holder {
    margin: 0 -15px 0 -15px;
  }

  .command-ribbon {
    display: block;
    width: 100%;
    height: 39px;
    background-color: white;
    border-top: 1px solid $color-A7;
    border-bottom: 1px solid $color-A6;
    margin-top: -1;

    /* 165 used below is from 2 spacers on both sides (16 each), activateCol of 53 and 2 fixed-width buttons (40 each) */
    .cmd-activate {
      width: 53px;
      min-width: 53px;
      max-width: 53px;
    }

    .cmd-sort-by,
    .cmd-view-by,
    .cmd-compare-against,
    .cmd-interval,
    .cmd-filter,
    .cmd-hide-expired {
      @include calc('width', '(100% - 165px) * 0.17');
    }

    %list-or-favorite {
      position: absolute;
      left: 10px;
    }

    .cmd-list-mode {
      width: 40px;
      min-width: 40px;
      max-width: 40px;

      & > span {
        @include sprite-icon($left: 0, $down: 2, $width: 20px, $height: 20px);
        top: 10px;
        @extend %list-or-favorite;
      }
    }

    .cmd-favorite-mode {
      width: 40px;
      min-width: 40px;
      max-width: 40px;

      #ribbonFavoriteStar {
        top: 7px;
        color: #666666;
        @extend %list-or-favorite;
      }

      .orange-color-icon {
        color: #ff9700 !important;
      }
    }
  }

  .sort-order-icon {
    @extend .order-icon;
  }

  .change-on-interval-trigger .change-on-interval {
    @include animate($animationType: mmValueChange);
  }

  &:after {
    font-family: $unified-font-bold;
    content: '.';
    display: block;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .layout-loading {
    position: absolute;
    top: -4px;
    width: 100%;
    z-index: 3;
  }

  .title {
    color: $color-A4;
    font-size: 9px;
    line-height: 21px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 0.1em;
  }

  .w-list-head {
    height: 95px;
    width: 100%;

    .title {
      color: $color-A3;
      margin-top: 1px;
    }

    .w-head-c {
      @include box-shadow($swidth: -5px, $scolor: $color-A6, $horizontal: 0, $vertical: 12px, $blur: 8px);
      position: fixed;
      z-index: 4; // update this zindex with care!!
      background-color: $color-D12;
      padding: 45px 15px 0;
      margin: 0 -15px;
      height: 40px;
    }
  }

  .campaigns {
    font-family: $unified-font-regular;
    font-size: 12px;
    position: relative;

    .checkbox-input .checkbox {
      padding: 1px;
    }

    .chzn-results {
      color: $color-A2;
    }
  }

  .cmd-activate {
    .select-all {
      position: relative;
    }

    .menu {
      display: none;
      float: left;
      .items {
        @extend .box-fix;

        .item {
          @include linear-gradient($from: $color-A12, $to: $color-A10);
          border: 1px solid $color-A5;
          border-radius: 3px;
          padding: 7px 15px;
          float: left;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }
        .final {
          @include _border-radius($radius: 0 3px 3px 0);
          margin-left: -5px;
        }
      }
    }

    .handle {
      background: transparent;
      width: 44px;
      height: 20px;
      padding: 6px 2px 0 6px;
      z-index: 3;
      position: absolute;

      .checkbox-input {
        float: left;
        margin: -2px 0 0 3px;
      }
    }

    .arrow-icon {
      margin-left: 29px;
      padding-top: 5px;
    }

    .arrow {
      @include sprite-icon($left: 1, $down: 7, $width: 10px, $height: 10px);
    }

    .options {
      display: none;
      z-index: 2;
      cursor: pointer;
      font: normal 12px/14px $unified-font-regular;
    }

    .active {
      .arrow {
        @include sprite-at-position($left: 1, $down: 0);
      }

      .handle {
        height: 24px;
        background: white;
      }

      .options {
        display: block;
        background: $color-A12;
        border: 1px solid $color-A8;
        position: absolute;
        top: 28px;
        left: -1px;
        width: 80px;
        padding: 0;
        z-index: 2;
        font-size: 12px;
      }

      .option {
        padding: 8px 0 8px 10px;
        &:hover {
          color: white;
          background-color: $color-D15;
        }

        &:first-child {
          margin-top: 3px;
        }

        &:last-child {
          margin-bottom: 2px;
        }
      }
    }
  }

  .cmd-hide-expired {
    .checkbox-input {
      margin: 8px 0 0 10px !important;
    }

    .hide-expired-action {
      float: left;
      cursor: pointer;
    }

    .hide-ended-text {
      display: block;
      width: 100px;
      margin: 0 0 0 23px;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;
    }
  }

  .sort {
    font-size: 10px;
    color: $color-A3;
    text-align: center;
    float: left;
    width: 280px;
    margin-top: 2px;

    .sort-by-title {
      text-transform: uppercase;
      float: left;
      margin-top: 0;
    }

    .chzn-container {
      text-align: left;
      width: 100% !important;
    }

    .field-list {
      min-width: 142px;
    }

    .list-order-by,
    .active-only {
      margin: 2px 0 0 8px;
      cursor: pointer;
    }

    .list-order-icon {
      @extend .order-icon;
      margin-top: 2px;
    }

    .sort-select-c {
      cursor: pointer;
      margin-left: 5px;
      margin-top: -1px;
    }

    .active-only {
      margin-top: 0;
    }

    .hide-expired-action {
      float: left;
      margin-top: 1px;
    }

    .hide-ended-text {
      display: block;
      width: 100px;
      text-transform: capitalize;
      margin: 0 0 0 8px;
      font-size: 11px;
      line-height: 18px;
      cursor: auto;
    }

    .chzn-drop {
      margin: 1px 0 0 0;
    }

    &.sort-transparent {
      .chzn-container-single .chzn-single {
        background-color: transparent;
        border: none;
        border-radius: 0;
        background-image: none;
        box-shadow: none;
        padding-left: 0px;
        border-bottom: 1px dotted transparent;
        line-height: 16px;

        span {
          margin-right: 0;
          padding-top: 2px;
          line-height: 18px;

          &:hover,
          &:focus {
            border-bottom: 1px dotted;
          }
        }

        div {
          display: none;
        }
      }

      .chzn-container-active .chzn-single-with-drop {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 0;
      }
    }
  }

  .sort-by {
    padding: 5px 12px;
    background: $color-A8;
    width: 100%;

    &:hover {
      border-bottom: 1px dotted;
    }
  }

  .sort-select {
    z-index: 1;
    color: $color-A1;
    font-size: 9px;
    text-transform: capitalize;
    margin-top: -3px;
  }

  .performance-controls,
  .list-size-controls {
    display: block;
    float: right;
    height: 22px;
    margin-top: 2px;
    margin-right: 2px;

    .control-select-title {
      text-transform: uppercase;
      color: $color-A3;
      margin-top: -1px;
      float: left;
    }

    .control-select-c {
      float: right;
      margin-left: 5px;
      margin-top: 1px;
      width: 148px;
    }

    .chzn-container {
      text-align: left;
      width: 100% !important;
      font-size: 13px;
      position: relative;
    }

    .chzn-drop {
      margin: 1px 0 0 0;
      width: 100% !important;
    }

    .chzn-single {
      //ie fix - gradient not applied for the report interval drop down
      @include linear-gradient($from: $color-A12, $to: $color-A8);
      margin-top: -5px;
    }

    .chzn-single-with-drop {
      border-color: $color-A5;
    }

    .control-field-list {
      min-width: 150px;
    }

    .list-view-select,
    .strategy-goal-select {
      margin-right: 16px;
    }

    .strategy-goal-select,
    .list-view-select {
      .control-select-c {
        width: 130px;
      }
    }
  }

  .w-foot {
    margin-top: 5px;

    .list-actions {
      display: block;
      margin: 0 auto 0;
      width: 10%;
    }

    .list-size {
      float: right;
      margin-right: 2px;

      .label {
        margin: 6px 10px 0 0;
        font-size: 12px;
        color: $color-A4;
        display: inline-block;
        float: left;
      }

      .chzn-container {
        width: 58px;
      }

      .chzn-single {
        //ie fix - gradient not applied for the drop down
        @include linear-gradient($from: $color-A12, $to: $color-A8);
      }

      .list-size-list {
        width: 60px;
      }
    }
  }

  .date-picker-calendar {
    width: 418px;
    height: 264px;
  }

  .campaigns_remaining,
  .strategies_remaining {
    color: $color-A4;
    font-size: 12px;
    font-style: italic;
    float: left;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .more-campaigns,
  .more-strategies,
  .show-all {
    @extend .btn-primary;
    float: left;

    span {
      visibility: hidden;
    }
  }

  .more-campaigns-action,
  .more-strategies-action,
  .show-all-action {
    span {
      visibility: visible;
    }
  }

  .more-campaigns {
    margin: 0 0 5px 0;
  }

  .more-strategies,
  .show-all {
    margin: 1px 0 7px 0;
  }

  .accordion-open,
  .strategy-list-open {
    background: $color-D7;
    color: black;

    .campaign-headers {
      background: $color-D7 !important;
    }
    .w-StrategyList {
      background: $color-D7;
    }
  }

  .strategy-list-open .accordion-open {
    @include box-shadow($swidth: -5px, $scolor: $color-A5, $horizontal: 0, $vertical: 10px, $blur: 8px);
  }

  .read-only-view {
    .read-only-icon {
      @include sprite-icon($left: 6, $down: 9, $width: 10px, $height: 10px);
      position: absolute;
      right: 4px;
      top: 18px;
    }
  }

  //toggle spend/performance subviews
  .spendMode0 .spend0,
  .spendMode1 .spend1,
  .spendMode7 .spend7,
  .spendMode14 .spend14,
  .spendMode30 .spend30,
  .spendModeFTD .spendFTD,
  .spendModeCTD .spendCTD,
  .impressionMode1 .impression1,
  .impressionMode7 .impression7,
  .impressionMode14 .impression14,
  .impressionMode30 .impression30,
  .impressionModeFTD .impressionFTD,
  .impressionModeCTD .impressionCTD,
  .performanceMode1 .performance1,
  .performanceMode7 .performance7,
  .performanceMode14 .performance14,
  .performanceMode30 .performance30,
  .performanceModeFTD .performanceFTD,
  .performanceModeCTD .performanceCTD {
    display: block;
  }

  //toggles for strategy list headers
  .spendMode1,
  .spendMode7,
  .spendMode14,
  .spendMode30,
  .spendModeFTD,
  .spendModeCTD {
    .spend-mode-header {
      display: block;
    }
  }

  .impressionMode1,
  .impressionMode7,
  .impressionMode14,
  .impressionMode30,
  .impressionModeFTD,
  .impressionModeCTD {
    .impression-mode-header {
      display: block;
    }

    .header-c {
      .data {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .remaining-amount {
      padding-left: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .amount {
      padding-left: 2px;
    }
  }

  .performanceMode1,
  .performanceMode7,
  .performanceMode14,
  .performanceMode30,
  .performanceModeFTD,
  .performanceModeCTD {
    .performance-mode-header {
      display: block;
    }
  }
}
