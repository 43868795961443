.w-StrategyCreateEdit {

	.w-InfiniteScrollSearchBox {
		z-index: 5;
		width: 155px;

		.strategy-name {
			width:76%;
		}

		.spend-ydy {
			width: 22%;
			text-align: right;
			white-space: nowrap;
		}

		.search-box-holder {
			width: 100%;
		}

		.w-Filters {
			input {
				width: 143px;
			}
		}
		.search-result-holder {
			float: right;
			width: 330px;
		}

		.item:hover {
			.strategy-name {
				width: 80%;
			}

			.spend-ydy {
				width: 18%;
			}
		}

		.header-label {
			white-space: nowrap;
		}

		.headers {
			padding-right: 20px;
		}
	}
	@media only screen and (min-width: 1380px) {
		.w-InfiniteScrollSearchBox {
			width: 250px;
			.w-Filters {
				input {
					width: 238px;
				}
			}
		}
	}
	@media only screen and (min-width: 1480px) {
		.w-InfiniteScrollSearchBox {
			width: 337px;
			.w-Filters {
				input {
					width: 325px;
				}
			}
		}
	}
}

