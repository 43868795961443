.w-CreativesCreateEditSingle {

	.w-body {
		top: 90px !important;
	}

	hr {
		border-bottom: 1px solid $color-A5;
		padding-top: 20px;
	}

	.section-header {

		.title {
			font-family: $custom-font-regular;
			font-weight: 100;
			font-size: 17px;
			color: $color-A3;
		}

		.adx-error,
		.adx-warning {
			margin: 2px 0 0 5px !important;
		}
	}

	.non-secure-calls {
		#icon.strand-inline-box {
			margin-top: 10px;
		}
	}

	/* Four Column Grid (ie: 1/4, 2/4, 3/4, 4/4)
	   dolson - Much clearer than Column-A/B/C thats happening elsewhere
	   Could easily change these to be fluid width */
	.col-1-4,
	.col-2-4,
	.col-3-4,
	.col-4-4 {
		@extend .column;
	}
	.col-1-4 {
		@include width-colx($x: 3);
	}
	.col-2-4 {
		@include width-colx($x: 6);
	}
	.col-3-4 {
		@include width-colx($x: 9);
	}
	.col-4-4 {
		@include width-colx($x: 12);
	}

	.form-body {
		padding-bottom: 25px;
	}

	.security {
        .non-secure-warning {
            color: #FED01A;
        }

        .secure-warning {
            color: #9ecb5c;
        }
    }

	.status,
	.security,
	.html-code {
		.form-radio {
			width: auto;
			padding-right: 15px;
		}
	}

	.mraid {
		margin-right: 0;
		.form-radio {
			width: auto;
			padding-right: 15px;
		}	
	}

	.advertiser,
	.ad-format {
		&.form-label {
			padding-top: 5px;
		}

		label {
			cursor: default;
		}
	}

	.tpas-adtag-name {
		padding-top: 5px;
		word-wrap: break-word;
	}

	.file-type {
		text-transform: uppercase;
	}

	#mraidIcon {
	  color: #CACACA;
	  bottom: 2px;
	  &:hover {
	    color: #676767;
	  }
	}

	.tab-info {
		margin: 2px 0 0 4px;
		position: absolute;
		display: inline-block;
	}

	.dimension {
		.form-select, {
			width: 90px;
			position: absolute;
			margin-left: 20px;
			left: 0px;
		}

		.form-input {
			width: 50%;
			position: absolute;
			right: -20px;
		}

		.custom-width,
		.custom-height {
			width: 43%;
			float: left;
		}
		
		.width {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.height {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}

	.additional-info {
		margin: 25px 0 0 0;
	}

	.expandable-c {
		.form-stack {
			margin-bottom: 10px;
			padding: 20px 13px 20px 48px;
		}

		.expansion-trigger {
			padding-bottom: 0px;
		}

		.expansion-direction {
			dd {
				width: auto;
			}

			.column-A {
				width: 100%;
			}

			.form-checkbox {
				padding-right: 20px;

				&.disabled {
					@include transparent($percent: 50);
				}
			}
		}
	}

	.date-picker,
	.vendor-list {
		padding: 0;
	}

	.auto-vendor-list,
	.vendor-list {
		.w-boxList {
			dt {
				margin-bottom: 5px;
				.vendor-list {
					font-family: $custom-font-semi-bold;
					font-weight: 100;
					font-size: 14px;
					color: $color-A3;
					margin-bottom: 10px;
				}
			}

			.add {
				margin-left: 0;
			}
		}
	}

	.adx-error {
		@include sprite-icon($left: 7, $down: 10, $width: 19px, $height: 16px);
	}

	.adx-warning {
		@include sprite-icon($left: 7, $down: 4, $width: 19px, $height: 16px);
	}

	.adx-error,
	.adx-warning {
		margin: 2px 0 0 20px;
		position: absolute;
		display: inline-block;
	}

	.asset-upload {
		dt {
			margin-bottom: 5px;

			.icon {
				@include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
				@include transparent($percent: 30);
				display: inline-block;
				vertical-align: middle;
				cursor: pointer;

				&:hover {
					@include transparent(100);
				}
			}
		}

		dd {
			margin-bottom: 20px;
		}

		.upload-error {
			display: none;
		}
	}

	.asset-review {
		dt {
			margin-bottom: 5px;
		}

		dd {
			margin-bottom: 20px;
		}

		.hybrid-icon {
			color: #c3c3c3;
		}

		.hybrid-icon:hover {
			color: #666666;
		}

		.icon-group {
			margin-top: 3px;
			.view {
				margin: 3px 0 0 0;
				@include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
				@include transparent($percent: 30);
				display: inline-block;
				vertical-align: middle;
			}

			.alert {
				margin: 3px 0 0 1px;
				@include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
				@include transparent($percent: 30);
				display: inline-block;
				vertical-align: middle;
			}
			cursor: pointer;
		}

		.icon-group:hover .view {
			@include sprite-icon($left: 11, $down: 3, $width: 16px, $height: 13px);
			@include transparent($percent: 100);
			display: inline-block;
			vertical-align: middle;
		}
		.icon-group:hover .alert {
			@include sprite-icon($left: 2, $down: 13, $width: 16px, $height: 13px);
			@include transparent($percent: 100);
			display: inline-block;
			vertical-align: middle;
		}

		.upload-error {
			display: none;
		}
	}

	.textarea.html-code,
	.textarea.tpas-ad-tag,
	.textarea.click-thru-url,
	.textarea.click-url {
		resize: vertical;
	}

	.textarea.html-code {
		height: 242px;

		&.disabled {
			@include transparent($percent: 50);
			pointer-events: none;
		}
	}

	.textarea.tpas-ad-tag,
	.textarea.click-thru-url,
	.textarea.click-url {
		height: 75px;
	}

	.info-tip {
		opacity: 0.3;
		&:hover {
			opacity: 1;
		}
	}

	.non-secure-warning {
		&:hover {
			cursor: default;
		}
	}
	.ksize-warning {
	    color: #f85252;
	}

	.non-secure-call {
		overflow-y: hidden;
		overflow-x: hidden;
		
		div {
            height: auto;
            max-height: 100px;
            overflow: auto;
            font: normal 11px/12px $unified-font-regular;
        }
        li {
            list-style-type: disc;
            list-style-position: inside;
        }
        .vendor-name {
            font-family: $unified-font-bold;
        }
        .vendor-urls {
        	background: rgba(255,255,255,0.5);
        	margin: 5px 0;
        	padding: 5px;
        }
        .title {
	        color: #111111;
			font: 18px/22px $custom-font-semi-bold;
        }
	}

	.ad-status {
		font-size: 12px;
		line-height: 14px;
		font-style: italic;
		font-family: $custom-font-regular;
		display: inline-block;
	}

	.ad-status:first-letter {
		text-transform: uppercase;
	}

	.notes {
		margin-right: 0;

		p,
		ul {
			font-size: 12px;
			line-height: 14px;
			font-family: $custom-font-regular;
			color: $color-A4;
			padding-bottom: 15px;

			b {
				font-family: $custom-font-bold;
			}

			a {
				color: $color-D1;
			}
		}

		ul {
			margin-top: -10px;
			padding-left: 10px;

			li {
				list-style-type: disc;
				list-style-position: inside;
			}
		}
	}
}

.w-CreativesCreateEditBulk {
	.w-body {
		top: 72px !important;
	}
}