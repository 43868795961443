.w-InfiniteScrollSearchBox {
	font-family: $unified-font-regular;

	position: relative;
	z-index: 1999;

	.truncate {
		word-wrap: break-word;
	}

	.w-Filters{
		padding: 0;
		margin: 0;
		.text {
			&.hide-magnifying-glass {
				background: #ffffff !important;
			}
		}
	}

	.search-box-holder{
		width:337px;
		height: 27px;
		input {
			padding:0 0 0 4px !important;
			margin:0 !important;
			height: 25px;
			width: 325px;
			background-position-y: 138%;
		}
	}

	.search-result-holder {
		@include border-radius($radius: 3px);
		border:1px solid $color-A4;
		border-top-style: none;
		width: 330px;
		position: relative;
		display: none;
		z-index: 2000;
		overflow: hidden;

		.w-body {
			height: 405px;
		}

		.headers {
			background-color: $color-D12;
			border-bottom: 1px solid $color-A5;
			border-top: 1px solid $color-A5;
		}

		.header-c {
			font-family: $unified-font-bold;
			color: $color-D10;
			text-transform: none;
			font-size: 11px;
		}

		.inactive {
			@include transparent(50);
			color: $color-A0 !important;
		}

		.item {
			font-family: $unified-font-regular;
			color: $color-D11;
			padding-top: 4px;
			padding-bottom: 4px;
		}

		.currentItem {
			font-family: $unified-font-bold;
			background-color: $color-D7;
			border-top: 1px solid $color-D9;
			border-bottom: 1px solid $color-D9;
			color: $color-D11;

			.symbol {
				font-weight: 600;
			}
		}

		.item:hover {
			font-family: $unified-font-regular;
			background-color: $color-D15;
			color: $color-A12;
		}

		.no-results {
			position: absolute;
			top: -50%;
		}

		.w-head,
		.content-holder {
			padding-right: 1px;
		}
	}
}