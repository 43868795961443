.w-AudienceTargeting {
  .browse-list {
    .drag {
      padding-top: 8px !important;
    }
  }

  .list-body {
    transition: top 0.2s;
    bottom: 1px !important;

    &.info-message-on {
      top: 105px;
    }
  }

  .info-message-on {
    .browse-list {
      bottom: 17px !important;
    }
  }

  .usage-warning-info {
    margin: 8px 0 10px;
    width: 99%;
    display: none;

    .message {
      width: 90%;
    }

    .icon-close {
      display: block;
      width: 16px;
      height: 13px;
      margin-right: 0 6px 0 0;
    }
  }

  .view-loader {
    position: absolute;
    left: 44%;
    top: 50%;
  }

  .entities {
    width: 190px;
  }

  .chzn-results {
    max-height: 305px;
  }

  .browser-c,
  .search-c {
    height: 100%;
    max-height: 362px;
    background-color: $color-A12;

    .w-DataGrid {
      .content {
        bottom: 26px;
      }
    }

    .item-wrapper {
      position: relative;
    }

    .headers {
      padding-left: 0;

      .segment {
        width: 75%;
        padding-left: 12px;
      }
    }

    .group-name {
      margin: 20px 0 5px 65px;
      font-family: $custom-font-bold;
      color: $color-D10;
      font-size: 12px;
      letter-spacing: 0.3px;
    }

    .item {
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      .arrow {
        margin: 4px 0 0;
        position: relative;

        .icon-arrow {
          @include sprite-icon($left: 5, $down: 9, $width: 12px, $height: 12px);
          margin-left: 4px;
        }

        &.loading {
          .icon-arrow {
            visibility: hidden;
          }

          .icon-spinner {
            top: 15px;
            left: 1px;
          }
        }

        &.expanded {
          .icon-arrow {
            @include sprite-icon($left: 1, $down: 0, $width: 12px, $height: 12px);
            margin: 2px 0 0 2px;
            padding-right: 2px;
          }
        }
      }

      .item-row {
        float: left;
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px solid $color-A7;

        &:hover {
          background-color: $color-E8;
        }

        &:selected {
          background-color: $color-D7;
        }
      }

      .drag {
        padding-top: 12px;
      }

      .segment {
        padding-left: 12px;

        .item-c {
          float: left;
        }

        .icon {
          margin-top: 2px;

          &.arrow {
            margin-top: 4px;
          }
        }

        .text {
          margin-left: 12px;
          color: $color-A3;
          line-height: 18px;
          width: 90%;

          &.browse {
            margin-left: 1px;
            width: 88%;
          }
        }
      }

      .size,
      .cpm {
        margin-top: 2px;

        .item-c {
          font-family: $custom-font-regular;
        }
      }
    }

    .browse-list {
      .item {
        .item-row {
          padding: 4px 0;
        }
      }
    }

    .segment {
      width: 75%;
    }

    .size {
      width: 6%;
      text-align: right;
    }

    .cpm {
      width: 9%;
      text-align: right;
    }

    .w-foot {
      @include box-fill($top: auto);

      .browse-path {
        background: $color-A7;
        padding: 7px 15px 6px 13px;
        font-family: $custom-font-regular;
        font-size: 12px;
        color: $color-A3;
        line-height: 13px;
      }

      .page-controls {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .grid-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .list-c {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .tabWarning {
    height: 125px;
    position: absolute;
    top: 10px;
    bottom: 12px;
    padding: 12px 12px 22px;
    background: $color-E7;
    border: 1px solid rgba(226, 123, 8, 0.86);
    border-radius: 3px;
    z-index: 2;
    margin-top: -12px;

    .icon {
      @include sprite-icon($left: 8, $down: 4, $width: 28px, $height: 22px);
      margin-right: 8px;
      float: left;
    }

    .mainText {
      color: #111111;
      font: 16px/20px $custom-font-semi-bold;
    }

    .infoText {
      color: $color-A3;
      font: 11px/14px $custom-font-semi-bold;
      padding: 8px 0 4px 36px;
    }

    .infoText li {
      list-style: circle;
      list-style-position: inside;
    }

    .hideIcon {
      @include sprite-icon($left: 7, $down: 0, $width: 10px, $height: 10px);
      float: right;
      cursor: pointer;
    }

    .retired-segments-holder {
      @include bg-color-rgba();
      overflow: auto;
      padding-left: 15px;
      margin-left: 30px;
      margin-right: 11px;
      font: 11px/14px $custom-font-regular;
      height: 42px;
    }
  }

  .w-foot-c {
    background: $color-A12;
  }

  .w-foot {
    @include box-fill($top: auto);
    z-index: 1;

    .disabled {
      @include transparent();
      background: $color-A8;
      z-index: 99;
      position: absolute;
      width: 52%;
      height: 30px;
      top: -5px;
    }

    .open {
      background-color: transparent;
    }

    .maturity {
      width: 110px;
    }

    .maturity-selected {
      width: 180px;
      margin-top: -5px;
    }

    .ugc {
      width: 200px;
    }

    .ugc-selected {
      width: 120px;
      margin-top: -5px;
    }

    .maturity-settings,
    .ugc-settings {
      padding: 15px 0 10px 20px;
    }

    .maturity-settings {
      width: 355px;

      .disabled {
        left: 110px;
      }
    }

    .ugc-settings {
      width: 354px;

      .disabled {
        left: 200px;
        width: 35%;
      }
    }

    .info-icon {
      width: 13px;
      margin: 0 0 0 10px;
    }

    .safety-advance-settings {
      @extend .box-fix;
      background: $color-A8;
      border: 1px solid $color-A5;
      margin: 0 -21px;
      padding: 10px 0 0;
    }

    .browse-path {
      background: $color-A7;
      padding: 7px 15px 6px 13px;
      font-family: $custom-font-regular;
      font-size: 12px;
      color: $color-A3;
      line-height: 13px;
      margin-bottom: 5px;
    }

    .advanced-settings-toggle {
      @extend .box-fix;
      float: none !important;
      width: 100% !important;

      .toggle-button.close {
        padding: 6px 12px;
      }

      .toggle-button.open {
        background: transparent;
      }

      dl {
        @extend .box-fix;
        width: 100%;
        padding-top: 5px;
        margin-top: -5px;
        margin-bottom: 5px;
      }
      dd {
        float: right;
      }
      margin-right: -16px;
      cursor: pointer;
    }
  }
}

.w-Audience-Bins {
  .data-row {
    .item-row {
      padding: 3px 0 2px;
    }

    .segment {
      .text {
        span {
          background-color: transparent;
        }
      }
    }

    .warning-holder {
      float: left;
      margin-top: 3px;
      padding-left: 3px;
    }

    .warning-icon {
      @include sprite-icon($left: 4, $down: 10, $width: 15px, $height: 15px);
    }
  }

  .icon-spinner {
    left: 140px !important;
  }

  .headers {
    padding-right: 9px !important;

    .segment {
      width: 68.1%;
    }
  }

  .segment {
    width: 67%;
  }

  .size {
    width: 11%;
    text-align: right;
  }

  .cpm {
    width: 15%;
    text-align: right;

    .editable {
      &:hover {
        @extend .isEditable;
      }
    }
  }

  .bins-foot {
    .segment {
      width: 69%;
    }

    .cpm {
      white-space: nowrap;
    }
  }
}

.w-InlineEditPopoverContainer {
  width: 230px;
}
.w-AudienceSegmentPriceEdit {
  padding: 0 !important;

  .form-stack {
    margin-bottom: 15px;
  }

  .form-body {
    padding: 15px 0 1px 20px;

    .form-input {
      position: absolute;
      right: 29px;
      width: 50px;

      .symbol {
        position: absolute;
        left: -32px;
        top: 6px;
        text-align: center;
        width: 30px;
      }
    }
  }

  .form-radio {
    width: 100% !important;
  }

  .info-icon {
    position: absolute !important;
    margin: 0 0 0 4px !important;
    top: 5px;
    right: 18px;
  }

  .form-footer {
    background-color: $color-A9;
    padding: 10px 0;

    .messages {
      padding-left: 10px;
    }

    .error-message {
      width: 100%;
    }

    .action {
      margin-right: 0;
    }
  }
}

.audienceSegmentEdit-tipsy {
  width: 200px;

  .tipsy-inner {
    white-space: normal;
    word-wrap: break-word;
    padding: 10px;
  }
}

.confirm-blank-modal {
  .content-top {
    padding: 15px;
    strand-header {
      margin-bottom: 20px;
      padding-top: 5px;
      .warning-icon {
        color: $color-E5;
        margin-right: 3px;
        margin-top: -3px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      padding-bottom: 8px;
    }
  }
}
