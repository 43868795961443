.w-Admin {
  padding: 16px 0 0;

  .w-OrgCreateEditTabs-tabset {
    .form-footer {
      left: 113px;
    }

    .verify-delete-org {
      .content-top {
        padding: 25px;
      }

      strand-icon {
        color: #fed01a;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin: 10px 0;
      }

      .delete-org-input {
        .delete-org-entry {
          margin: 10px 0 5px 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .delete-org-inline-box {
        display: none;
        padding-top: 5px;
      }
    }
  }

  .w-tiny-header {
    font: normal 13px/12px $unified-font-regular;
    font-smoothing: antialiased;
    color: $color-A1;
  }

  .w-role {
    padding: 0 3px 0 30px;
  }

  .w-head {
    padding-right: 0;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .default-text {
    text-transform: none;
  }

  .strand-icon-admin {
    color: $color-A6;
    &:hover {
      color: $color-A3;
    }
  }

  .strand-edit {
    color: $color-A1;
  }

  .advanced-options {
    margin: -70px -5px 0 0;

    .settings {
      @include linear-gradient($from: $color-A12, $to: $color-A8);
      @include border-radius($radius: 3px);

      float: right;
      cursor: pointer;
      width: 15px;
      height: 15px;
      padding: 5px 5px;
      border: 1px solid $color-A6;
      magin-top: 1px;
    }

    .settings-icon {
      @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
    }
  }

  .basic-info {
    float: left;
    margin-left: 26px;
    width: 45%;
  }

  .title {
    padding-top: 1px;
    height: 17px;
    float: left;
  }

  .subtitle {
    min-height: 10px;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .pull-reset {
    clear: both;
  }

  .add-action {
    margin: 0 15px 0 10px;
    width: 150px;
  }

  .list-body {
    @include box-fill($top: 55px);
  }

  .list-filters {
    @include box-fill($left: 20px, $top: 2px, $bottom: auto);
    width: auto;

    .clearfix {
      display: inline-block;
    }

    .form-list {
      display: inherit;
    }

    .filters-left {
      // margin-top: -5px;
      .search-box {
        top: 0px;
        width: auto;
        padding: 0;
        margin: 0 0 10px 0;
      }
    }

    .filters-right {
      float: right;
      margin-top: 3px;
    }

    .list-search {
      margin-top: 3px;

      .select-filter {
        width: 120px;
      }

      .search-input {
        width: 250px;
      }

      .show-active,
      .sub-filter-name {
        margin-left: 28px;
      }

      .select-sub-filter {
        width: 150px;
      }
    }
  }

  .view-loader .ajax-loader {
    height: auto;
  }

  .progress-message {
    .message {
      padding-top: 10px !important;
    }
  }

  .w-DataGrid {
    .ps-container .ps-scrollbar-y {
      @include transparent($percent: 40);
    }

    .ps-container:hover .ps-scrollbar-y {
      @include transparent($percent: 65);
    }

    .ps-container .ps-scrollbar-y.in-scrolling {
      @include transparent($percent: 90);
    }

    .w-body {
      @include box-fill($top: 27px);
      overflow: hidden;
      height: auto;
    }

    .loader-overrides {
      @include transparent($percent: 90);
      position: absolute;
      bottom: 36px;
    }

    .settings-icon {
      @include sprite-icon($left: 4, $down: 9, $width: 14px, $height: 14px);
    }

    .export {
      text-align: center;

      .export-icon {
        @include transparent($percent: 30);
        &:hover {
          @include transparent($percent: 100);
        }
        margin: 0 auto;
      }
    }

    .item-c {
      padding-right: 20px;
      word-wrap: break-word;

      &.org {
        width: 390px;
      }
      &.ag {
        width: 375px;
      }
      &.adv {
        width: 365px;
      }
    }

    .arrow {
      margin-top: 2px;
      padding-right: 0;
    }
    .admin-edit-holder,
    .admin-preview-holder {
      position: absolute;
      right: 55px;
      display: none;

      &.hover {
        display: block;
      }
    }
    .admin-preview,
    .admin-edit {
      color: #666666;
    }
    .config {
      position: absolute;
      right: 25px;
    }
    .item:hover {
      .admin-edit-holder,
      .admin-preview-holder {
        display: block;

        &.suppress-hover {
          display: none;
        }
      }
    }
  }

  .terminated-org-modal {
    .content-top {
      padding: 25px;

      p {
        font-size: 13px;
        line-height: 18px;
        margin: 10px 0;
      }
    }
  }

  .w-OrgsGrid {
    .admin-infinite {
      @include calc(left, '50% - 87px');
      width: 174px;
    }

    .headers {
      padding-right: 20px;
    }
    .entity {
      width: 40%;
      .header-c {
        padding-left: 7px;
      }
    }
    .null-data-row {
      margin-left: 40%;
      font-size: 11px;
      color: $color-A4;
    }
    .id {
      width: 10%;
    }
    .shared-pixel-targeting {
      width: 16%;
    }
    .details {
      width: 29%;
    }
    .arrow.loading .icon-spinner {
      top: 15px;
      left: 8px;
    }
    .text.browse {
      float: left;
    }
    .arrow {
      width: 23px;
      height: 10px;
    }
    .icon-arrow {
      position: absolute;
    }
    .arrow.expanded > .icon-arrow {
      top: 2px;
      left: -3px;
    }
    .settings-icon.disabled {
      @include transparent($percent: 30);
    }
  }

  .w-UsersGrid {
    .admin-infinite {
      @include calc(left, '50% - 65px');
      width: 130px;
    }
    .headers {
      padding-right: 20px;
    }
    .checkbox-input {
      width: 2%;
      height: 1px;
    }
    .checkbox {
      position: absolute;
      top: -2px;
    }
    .email {
      width: 27%;
      & > .header-c,
      & > .item-c {
        padding-left: 2px;
      }
    }
    .id {
      width: 10%;
    }
    .first_name {
      width: 12%;
    }
    .last_name {
      width: 12%;
    }
    .role {
      width: 12%;
    }
    .last_login {
      width: 20%;
    }
    .settings-icon.disabled {
      @include transparent($percent: 30);
    }
  }

  .w-SiteListUsage {
    padding-bottom: 100px;

    .list-filters {
      position: relative !important;
      left: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .w-UsageGrid {
      width: 690px !important;

      .view-loader > .ajax-loader {
        top: 155px;
      }

      .ajax-loader {
        z-index: 1;
      }

      .admin-infinite {
        @include calc(left, '50% - 95px');
        width: 170px;
      }

      .headers,
      .item {
        padding-left: 10px !important;
        padding-right: 15px !important;
      }

      .name {
        width: 88% !important;
      }

      .type {
        width: 12% !important;
        .item-c {
          text-transform: capitalize;
        }
      }

      .no-results {
        margin-top: 25px !important;
      }
    }
  }

  &.w-UsersCreateEdit {
    .grid-loader {
      @include calc(left, '50% - 62px');
      top: 35px;
      height: 15px;
    }

    .w-box {
      .user-action {
        margin-bottom: 5px;
      }

      .user-checkbox {
        width: 34px;
      }

      .campMarginLB {
        padding-left: 27px;
      }

      .usernameTxt {
        position: absolute;
        margin-top: -23px;
        margin-left: 40px;
        font: normal 12px/14px $unified-font-italic;
      }

      .column-B {
        width: 258px;
      }

      .edit-password {
        margin-top: 28px;
        vertical-align: bottom;
      }

      .reset-password-link {
        color: #344967;
      }

      .reset-password-message-spinner {
        position: relative;
        top: -2px;
        left: 25px;
        display: none;
      }

      .reset-password-message-success {
        display: none;
        position: relative;
        top: -7px;
        left: 25px;
      }

      .show-allowed-entities {
        margin-top: 28px;
      }

      .agencyLb {
        font: normal 12px/14px $unified-font-regular;
      }

      .email-label {
        position: absolute;
        top: 6px;
        font: normal 12px/14px $unified-font-regular;
        white-space: nowrap;
      }

      .list-filters {
        position: relative !important;
        top: 0;
        left: 0;
        margin-bottom: 16px;

        &.hidden {
          margin-bottom: 0;
          height: 0;
        }
      }

      .permission-grid {
        margin-bottom: 70px;

        .arrow {
          &.hidden {
            display: none;
          }
        }

        .headers {
          padding-bottom: 7px;
        }

        .header-label {
          margin-left: 2px;
        }

        .w-body {
          position: relative !important;
          height: 350px;
        }

        .item {
          padding: 9px 0 7px 22px;
        }

        .entity {
          width: 75%;
          .header-c {
            padding-left: 8px;
            padding-top: 2px;
          }
        }

        .arrow.loading .icon-spinner {
          top: 15px;
          left: 8px;
        }
        .children .text.browse {
          float: left;
        }
      }
    }
  }

  .w-SiteListsGrid {
    .admin-infinite {
      @include calc(left, '50% - 75px');
      width: 150px;
    }
    .headers {
      padding-right: 20px;
    }
    .site_list {
      width: 38%;
      .header-c,
      .item-c {
        padding-left: 8px;
      }
    }
    .id {
      width: 12%;
    }
    .type {
      width: 10%;
    }
    .num_domains,
    .num_apps {
      width: 10%;
      text-align: right;
      .item-c {
        padding-right: 0;
      }
    }
    .export {
      width: 16%;
    }
  }

  .vendorForm {
    .display-none {
      display: none;
    }
  }

  .special_detection_input {
    display: none;
  }

  .w-VendorsGrid {
    .w-DataGrid {
      .w-body {
        top: 40px;
      }
    }

    .admin-infinite {
      @include calc(left, '50% - 75px');
      width: 150px;
    }

    text-align: left;

    .headers {
      padding-right: 20px;
    }

    .checkbox-input {
      width: 3%;
    }

    .vendor {
      width: 12%;
    }
    .id {
      width: 5%;
    }
    .type {
      width: 7%;
    }
    .adx_ssl_app {
      width: 7%;
    }
    .adx_app {
      width: 8%;
    }
    .adx_video_ssl_approved {
      width: 10%;
    }
    .adx_video_approved {
      width: 12%;
      text-align: left;
      .item-c {
        text-align: left;
      }
    }
    .adx_declare {
      width: 7%;
    }
    .mm_resell {
      width: 6%;
    }
    .pricing_type {
      width: 5%;
      text-align: left;
    }
    .rate_card_price {
      width: 5%;
      text-align: right;
    }
    .wholesale_price {
      width: 7%;
      text-align: right;
    }

    .rate_card_price,
    .wholesale_price {
      .item-c {
        text-align: right;
        padding-right: 0;
      }
    }
  }

  &.w-CreateEditForm {
    .column-C {
      margin-right: 0;
    }

    .no-display {
      display: none !important;
    }

    .edit-header {
      border-bottom: 1px solid $color-A7;
      border-radius: 0;
      height: 1px;
    }

    .edit-panel {
      @include box-fill($top: 2px);
      padding: 25px 25px 45px 25px;
      position: relative !important;
      background-color: $color-A12;
    }

    .active,
    .pixel {
      label {
        width: 50px !important;
      }
    }

    .create-edit-head {
      .header {
        font-family: $custom-font-semi-bold;
        font-weight: 100;
        font-size: 15px;
        color: $color-A3;
      }
    }

    .form-select {
      width: 250px;
      .select {
        width: 230px !important;
      }
    }

    .agencyForm,
    .advertiserForm {
      .safe-supp-dropdown {
        margin-top: 5px;
        width: 245px;
      }

      .form-stack .cross-device-graph {
        width: 280px;
      }

      .provider-ids {
        .account-id-lbl,
        .account-id-list {
          margin-top: 10px;
        }

        .provider-id-txt,
        .provider-id-error-container {
          margin-top: 5px;
        }

        strand-list-item {
          pointer-events: none;
        }
      }

      .name {
        width: 310px;
      }

      .tooltip {
        color: $color-A4;
        margin: 0 7px !important;
      }

      .attributes-list {
        padding-top: 25px;
        padding-bottom: 50px;
        width: 700px;

        .standard-attributes-container {
          width: 700px;
          height: 30px;
          margin-bottom: 10px;

          .standard-attribute-tooltip-holder {
            width: 4%;
            height: 30px;
            line-height: 30px;
          }

          .standard-attribute-tooltip {
            color: #9a9a9a;
          }

          .tooltip-label-holder label {
            cursor: default;
          }

          .tooltip-label-holder a {
            cursor: pointer;
          }

          .standard-attributes-label {
            float: left;
            width: 16%;
            padding-right: 20px;
            margin: 0;
            font-family: PNR;
            font-size: 13px;
            font-weight: normal;
            line-height: 30px;
          }

          .standard-attributes-select {
            float: left;
            width: 80%;
            padding: 0;
            margin: 0;
          }

          .standard-attributes {
            width: 100%;
          }

          .chzn-container {
            .chzn-drop {
              z-index: 1010;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid #ccc;
              border-top: 0;
              background: $color-A12;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18);
              border-radius: 0 0 4px 4px;
              width: 100%;
            }

            .chzn-results {
              max-height: 200px;
              li {
                -webkit-touch-callout: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-smoothing: antialiased;
                font-weight: 400;

                &.highlighted {
                  color: $color-A12;
                  background-color: $color-D15 !important;
                }
              }
            }

            &.chzn-container-multi {
              .chzn-choices {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid #ccc;
                border-radius: 4px;
                background-color: $color-A12;
                box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.125);
                color: $color-A1;
                font-family: Arimo, sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-smoothing: antialiased;
                font-weight: 400;
                font-size: 13px !important;
                line-height: 15px !important;
                overflow: hidden;
                outline: 0;
                cursor: text;
                width: 100%;
                padding-left: 5px;

                li {
                  float: left;

                  &.search-field {
                    margin: 0;
                    padding: 0;
                    white-space: nowrap;

                    input[type='text'] {
                      margin: 1px 0;
                      padding: 5px;
                      height: 15px;
                      outline: 0;
                      border: 0 !important;
                      background: transparent !important;
                      box-shadow: none;
                      color: $color-A4;
                      font-size: 100%;
                      line-height: normal;
                      border-radius: 0;
                      width: 100%;
                      font-family: $unified-font-regular;
                      font-smoothing: antialiased;
                      font-style: italic;
                      font-weight: 400;
                    }

                    .default {
                      color: #999;
                    }
                  }

                  &.search-choice {
                    position: relative;
                    margin-right: 5px;
                    padding: 5px 20px 5px 5px;
                    margin: 1px 1px 1px 5px;
                    height: 15px;
                    border-radius: 3px;
                    color: $color-A1;
                    background: $color-A19;
                    line-height: 15px;
                    cursor: default;

                    .search-choice-close {
                      position: absolute;
                      top: 7px;
                      right: 3px;
                      display: block;
                      width: 12px;
                      height: 12px;
                      background: url('../images/chosen-sprite.png') -48px 0 no-repeat;
                      font-size: 1px;
                    }

                    .search-choice-close:hover {
                    }
                  }

                  &.search-choice-focus {
                    background: $color-A6;

                    .search-choice-close {
                      background-position: -48px -11px;
                    }
                  }
                }
              }

              .chzn-results {
                margin: 0;
                padding: 0;
              }

              .chzn-drop {
                font-family: Arimo, sans-serif;

                .result-selected {
                  display: list-item;
                  cursor: default;
                  color: $color-A5;
                }
              }
            }

            &.chzn-container-active {
              .chzn-choices {
                outline: 0;
                box-shadow: 0 0 6px rgba(171, 210, 246, 0.83);
                border: 1px solid rgb(171, 210, 246);

                li.search-field input[type='text'] {
                  color: $color-A1 !important;
                }
              }
            }
          }
        }

        .attributes-headers {
          clear: both;
        }

        .attributes-title {
          margin-bottom: 15px;
        }

        .data-key {
          padding-right: 9px;
        }

        .name {
          width: 409px;
        }

        .data-type {
          text-align: left;
        }

        .delete-loader {
          display: none;
          position: fixed;
          z-index: 1000;
          left: 50%;
          top: 50%;
        }

        .attribute {
          font: $unified-font-regular;
          font-size: 13px;
          font-weight: normal;
          display: inline-block;
          height: 29px;
          position: relative;
          left: 2px;
          -webkit-font-smoothing: antialiased;
          &.name {
            width: 400px;
          }
          &.key {
            width: 83px;
          }
          &.read-only {
            margin-right: 8px;
          }
        }
      }

      .form-stack.link-accounts {
        strand-box {
          padding-bottom: 10px;
        }

        .what-is-this-action {
          padding-left: 30px;
          padding-top: 4px;
        }

        .drop-down-holder {
          width: 150px;
          margin-right: 10px;
        }
      }

      .form-stack.frequency-cap-stack {
        .frequency-cap-list {
          width: 320px;

          .frequency_interval {
            right: 12px;
          }
        }
        .column-B.minimize-multi-ads {
          top: 30px;

          .tooltip-holder {
            margin-top: 2px;
          }
        }
      }

      .column-B.minimize_multi_ads {
        right: 95px;
        top: 28px;
      }
    }

    .download,
    .delete {
      padding: 15px 10px 0 0;
    }

    .domain-rubric {
      font-family: $unified-font-italic;
    }

    .chzn-container .chzn-results .result-selected {
      background-color: $color-A12;
      color: $color-E1;
    }

    .freqCap,
    .freqCap-disabled {
      strand-dropdown {
        width: 90px;
        margin-right: 5px;
      }

      .per {
        margin: 10px;
        width: 30px;
      }
    }

    .freqCap-disabled {
      @include transparent(50);

      * {
        cursor: default !important;
      }
    }

    .addFreq {
      display: none;
    }

    .tab-info {
      margin: 0 7px !important;
    }

    .restriction {
      max-width: 225px;
    }

    .file-uploader {
      width: 100%;

      .file-selector {
        padding-top: 0 !important;
      }

      .file-name {
        width: 542px !important;
        max-width: 100% !important;
        padding-right: 10px !important;

        .text-input {
          font-style: italic;
          opacity: 0.75 !important;
          max-width: 100% !important;
        }
      }

      .file-input {
        display: none;
      }

      .file-upload-btn {
        width: 47px;
      }
    }

    .sitelist-entry {
      width: 530px;
      height: 85px;
      min-height: 85px;
      max-height: 620px;
      resize: vertical;
      font-family: $unified-font-italic;
    }

    .vendor-description {
      width: 515px;
      height: 85px;
      min-height: 85px;
      max-height: 620px;
      resize: vertical;
      font-family: $unified-font-italic;
      margin-bottom: 4%;
    }

    .w-DataGrid {
      .w-body {
        @include box-fill($top: 0px);
        position: relative !important;

        .content-holder {
          height: 350px;
        }

        .item {
          border-bottom: 1px solid $color-A8 !important;

          .parent {
            padding: 1px;
            display: none !important;
          }
        }

        .item:hover {
          .parent {
            display: inline !important;
            left: -10px !important;
            opacity: 0.5;
          }
          .parent:hover {
            opacity: 1 !important;
          }
        }
      }
    }

    .attributes-warning {
      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
      }

      .header {
        margin-bottom: 20px;
      }

      .segment-link {
        display: block;
        margin: 10px;
      }

      .content-top {
        padding: 15px;
      }
    }
  }

  .pagination {
    margin-top: 5px;
    height: 30px;
    text-align: center;

    .list-actions {
      display: block;
      margin: 0 auto 0;
      width: 10%;
    }

    .entities_remaining {
      font: normal 12px/14px $unified-font-italic;
      color: $color-A4;
      float: left;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .agencies_remaining {
      margin-left: 50px;
    }

    .advertisers_remaining {
      margin-left: 70px;
    }
  }

  .w-OrgDetail {
    .form-stack {
      margin-bottom: 10px;
    }

    .column-A,
    .column-B {
      @include width-colx($x: 5.6);
      float: left;
    }

    .column-A {
      margin-right: 56px;
    }

    .inner-col-A,
    .inner-col-B {
      @include width-colx($x: 2.8);
      float: left;
      margin-right: 0;
    }

    .impression-val {
      padding-top: 7px;
    }

    .inner-col-A {
      text-align: right;
      padding-top: 8px;

      &.tall-label {
        margin-bottom: 0;
        padding-top: 2px;
      }
    }

    .inner-col-B {
      padding-left: 20px;
      width: 211px;
    }

    .form-list {
      dt {
        font: normal 11px/12px $unified-font-regular;
        color: $color-A4;
        text-transform: uppercase;
        margin-bottom: 8px;

        strand-tooltip {
          text-transform: none;
          text-align: left;
        }

        .ad-choices-link,
        .byo-price-link {
          color: #d1d1d1;
        }

        .ad-choices-link:hover,
        .byo-price-link:hover {
          color: #676767;
        }
      }

      .form-radio-service-provider {
        padding-top: 5px;
        label {
          margin-right: 17px;
          /* color-code #333 used from _color.scss */
          color: #333;
          line-height: 16px;
          font-family: $unified-font-regular;
          font-smoothing: antialiased;
          input[type='radio'] {
            display: none;
            + span {
              position: relative;
              padding-left: 23px;
              cursor: pointer;
              &:after,
              &:before {
                content: '';
                position: absolute;
                border-radius: 50%;
              }
              &::before {
                left: 0;
                width: 14px;
                height: 14px;
                /* color-code #999 used from _color.scss */
                border: 1px solid #999;
                background: $color-A12;
                box-shadow: inset 1px 1px 2px rgba($color-A0, 0.125);
                background-image: linear-gradient(to top, $color-A12 0, $color-A19 100%);
              }
            }
            &:checked + span:after {
              left: 2px;
              top: 50%;
              transform: translateY(-50%);
              /* color-code #79a8ce, #96d4f8, #7dbef1 and #c7f1fe used from _color.scss */
              border: 1px solid #79a8ce;
              background-color: #96d4f8;
              box-shadow: inset 0 0 0 1px rgba($color-A12, 0.33);
              background-image: linear-gradient(to top, #7dbef1 0, #c7f1fe 100%);
              width: 10px;
              height: 10px;
            }
            &:disabled + span {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .optional {
      font: normal 11px/12px $unified-font-italic;
      color: $color-A4;
      text-transform: none;
      letter-spacing: 0.4px;
    }

    .chzn-results {
      max-height: 200px;
    }

    &.read-only {
      .form-stack {
        margin-bottom: 7px;
      }
      .inner-col-B {
        padding-top: 7px;
      }
    }

    .stl-link {
      color: #7380a9;
    }
    .stl-info-hide {
      display: none;
    }
    .stl-info-hide2 {
      display: none;
    }
    .stl-message-line {
      height: 30px;
    }
  }

  .w-OrgExchangeSeats {
    .exchange-seat-row {
      padding: 3px;

      .exchange-seats-checkbox {
        width: 18%;
        white-space: nowrap;
        margin-right: 25px;
        display: inline-block;
      }

      .seat-id {
        width: 18%;
        margin-right: 28px;
        display: inline-block;
      }

      .bill-media-checkbox {
        width: 181px;
        white-space: nowrap;
        margin-right: 28px;
        display: inline-block;
      }

      .exchange-cost {
        margin-right: 6px;
        display: inline-block;
      }

      .exchange-cost-type {
        display: inline-block;
      }
    }

    .exchange-seat-info {
      display: none;
    }

    .bill-media-warning {
      margin-top: 15px;
    }

    .w-box {
      .exchange-seats-row {
        padding: 8px;
        .header {
          font: normal 11px/12px $unified-font-regular;
          color: $color-A4;
          display: inline-block;
          width: 23%;
          text-transform: uppercase;
        }
        .data {
          display: inline-block;
          width: 23%;
          font-size: 13px;
          font-weight: normal;
          color: $color-A3;
          word-break: break-word;
        }
        .no-data {
          padding: 5px;
          margin-left: 40%;
          font: normal 11px/12px $unified-font-regular;
          color: $color-A4;
        }
      }
    }
  }

  .w-CreativeComponents {
    .components-list {
      left: 20px;
      top: 30px;
      width: auto;

      ._wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50px;
      }
    }
  }
}

.export-icon-tip,
.edit-entity-icon-tip {
  margin: -9px 0 0 -10px;

  .tipsy-inner {
    padding: 6px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}

.export-modal {
  .modal-info {
    padding: 15px;
    font: normal 12px/12px $unified-font-regular;
  }

  .modal-header {
    margin-bottom: 15px;
  }

  .load-spinner {
    margin: 0 260px 0 0 !important;
  }
}

/*FF*/
.export-modal #blocker {
  z-index: 1000;
}
polyfill-next-selector {
  content: '.export-modal #blocker';
}

/*Chrome*/
.export-modal::shadow #blocker {
  z-index: 1000;
}
