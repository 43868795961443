.inline-forecaster {
    margin-left: 430px;

    .forecaster-section {
        @include linear-gradient($from: rgba(204, 204, 204, 0), $to: rgba(204, 204, 204, 1));
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
        float: left;
        margin: 0 7px 0 0;
        min-height: 35px;
        padding: 0 0 0 7px;

        @media only screen and (min-width: 1300px) {
            margin-right: 10px;
            padding-left: 10px;
        }
    }

    dt {
        font-size: 10px;
        height: 11px;
        margin-bottom: 6px;
        text-align: center;
    }

    .forecast-value, .price-value-currency {
        font-family: $unified-font-bold;
        font-size: 12px;
        height: auto;
        margin-bottom: 5px;
        text-align: center;
    }

    .price-value-currency {
        padding: 5px 5px 0 0;
    }

    .icon-spinner {
        margin-top: 10px;
    }

    .symbol {
        display: inline;
        font-size: 12px;
    }

    .btn-incrementer {
        @extend .btn-secondary;
        width: 20px;
        padding: 2px;
        font-size: 10px;
        line-height: 16px;

        &:first-of-type {
            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &:last-of-type {
            border-left-width: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .price-value-input {
        width: 50px;
    }

    .cpm {
        width: 104px;
        dt {
            margin-bottom: 2px;
        }
        &.ready dd {
            display: flex;
            height: 20px;
            justify-content: center;
            user-select: none;
        }
        .text {
            background-color: $color-A12;
            border: 1px solid $color-A5;
            line-height: 2;
            font-size: 10px;
            padding: 0 5px;
        }
    }

    .tabs-control-dates {
        font-size: 10px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .forecaster-warning {
        float: left;

        .forecaster-warning-icon,
        .forecaster-error-icon {
            margin-top: 11px;
        }

        .forecaster-error-icon span {
            color: #EA5151 !important;
        }

        .forecaster-warning-icon span {
            color: #FED01A !important;
        }
    }
}