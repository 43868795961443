/* ***********************
 * variables for grid setup 1600px
 * */

$unit: 4px;

$gutter: $unit * 6;

$column_unit_count: 17;

$column: $unit * $column_unit_count;

$column_max: 13;

$page-margin-top: 0;

$page-margin-bottom: 0;

$page-margin-left: 0;

$page-margin-right: 0;