.w-MenuContainer{
	@include border-radius($radius: 3px);
	position: absolute;
	display: none;
	left: 200px;
	top: 200px;
	background: $color-A6;
	border: 1px solid $color-A5;
	z-index: 10;
	font-size: 12px;
	font-family: $custom-font-semi-bold;

	.menu-column{
		display: inline-block;
		border-left: 1px solid $color-A5;
		padding: 2px 0px 3px 0px;
		vertical-align: top;

		&.first-column{
			border-left: 0px;
		}
	}

	&.up {

		.arrow {
			bottom: -11px;
		}

		.arrow-border {
			bottom: -12px;
		}

		.arrow,
		.arrow-border {
			border-bottom: none;
		}
	}

	&.down {

		.arrow {
			top: -11px;
		}
		.arrow-border {
			top: -12px;
		}

		.arrow,
		.arrow-border {
			border-top: none;
		}
	}

	.menu-column-title{
		padding: 7px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.menu-item {
		padding: 7px 17px 7px 7px;
		line-height: 12px;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			background: $color-A5;
		}

		b {
			margin-left: 4px;
			margin-top: -2px;
			float: left;
		}

		.menuLabel {
			display: block;
			width: 100%;
			text-align: left;
			margin-left: 0px;
			padding-left: 4px;
			text-transform: uppercase;
			color: $color-A3 !important;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	.menu-beta-header {
	  .menuLabel {
		float: left;
		width: 67%;
	  }
	}

	.menu-item-icon {
		span {
			margin-left: 18px;
			padding-left: 15px;
		}
	}

	.arrow {
		border: 12px solid $color-A6;
		right: 16px;
	}

	.arrow-border {
		border: 13px solid $color-A5;
		right: 15.5px;
	}

	.arrow,
	.arrow-border {
		height: 0;
		position: absolute;
		width: 0;
		line-height: 0;
		border-bottom-style: solid;

		border-left-color: transparent;
		border-right-color: transparent;
	}

	a {
		text-decoration: none;
		color: $color-A3;
		width: 100%;
		cursor: pointer;
	}
}