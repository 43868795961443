// remove once mm-datepicker has been implemented in all charts
#date-picker-mmchart {
  right: 140px;
}

.w-Campaign-Charts {
  @include transition($property: height, $duration: 400ms);

  &.initOpen {
    height: 295px;
  }
  &.open {
    height: 295px;
  }
  &.close {
    height: 0;
  }

  .w-chart-drawer {
    z-index: 10;
  }

  //TODO: remove once all charts have been migrated to new setup
  .no-data {
    top: 76px;
  }

  .chart-info {
    padding-left: 20px;
    padding-top: 22px;
    position: relative;
    top: -295px;

    .report-ddl {
      margin-left: -8px;
    }

    .report-icon {
      color: #999; // $color-A6 from new color palette (_color.scss)

      &:hover {
        color: $color-A0;
      }
      &.selected {
        color: $color-A0;
      }
    }

    .bar {
      background-color: $color-A6;
      display: inline-block;
      height: 20px;
      margin: 0 3px;
      position: relative;
      top: 4px;
      width: 1px;
    }

    .chart-title-box {
      font-smoothing: antialiased;

      .chart-title {
        color: $color-A2;
        font-family: $unified-font-bold;
        font-size: 16px;
        letter-spacing: -0.1px;
        margin-top: -2px;
      }

      .chart-subtitle {
        color: $color-A2;
        font-family: $unified-font-regular;
        font-size: 11px;
        letter-spacing: 0.2px;
        margin-top: 7px;
      }
    }
  }

  .chart-timezone {
    font: 9px/9px $unified-font-regular;
    margin-left: 10px;
    position: relative;
    top: -80px;
    z-index: 10;
  }

  .new-chart-holder {
    .w-head {
      height: 0;

      .chart-header {
        @mixin bar-padding() {
          padding: 0 9px;
        }

        margin-right: 14px;

        .chart-controls {
          float: right;
        }
        .chart-controls-layout {
          display: flex;
        }
        .controls-title {
          font: normal 11px/11px $unified-font-bold;
        }
        .menu-open {
          @include flipVertically();
        }

        .dimensions-menu {
          padding-left: 9px;

          .title-menu {
            margin-top: -4px;
          }
          .dimensions-ddl {
            margin-left: -8px;
          }
        }

        .highest-lowest-menu {
          position: relative;

          .btn-highest-lowest {
            @include bar-padding();
            cursor: pointer;

            .carat {
              top: 1px;
            }
          }

          .list-highest-lowest {
            @include webcomponent-box-shadow(); // revisit, use existing include
            background-color: $color-A12;
            border: 1px solid $color-A6;
            position: absolute;
            right: -1px;
            top: 22px;

            .list-item {
              font: 11px/11px $unified-font-regular !important;
            }
          }

          .list-content {
            display: flex;
          }

          .selected-filter {
            background-color: #d5edf9; // $color-D23 from new color palette (_color.scss)
            color: #2e6c9e; // $color-D2 from new color palette (_color.scss)
          }

          .range-list {
            border-right: 1px solid $color-A6;
            height: 175px;
            width: 144px;
          }

          .metrics-list {
            height: 175px;
            width: 218px;
          }

          .list-footer {
            width: 100%;
          }
        }

        .metrics-menu .metrics-ddl {
          top: -4px;

          .no-result {
            color: $color-A4;
            pointer-events: none;
            white-space: normal;
            overflow: visible;
          }
        }

        .date-picker {
          .btn-date-picker {
            @include bar-padding();
            cursor: pointer;

            .carat {
              top: 1px;
            }
          }

          .dates {
            display: inline-block;

            &:hover {
              border-bottom: 1px dotted $color-A1;
            }
          }
        }

        .interval-menu .interval-ddl {
          top: -4px;
        }

        .export-menu {
          position: relative;

          .btn-export {
            @include bar-padding();
            cursor: pointer;

            .carat-export {
              top: 1px;
            }
          }

          .list-export {
            @include webcomponent-box-shadow();
            background-color: $color-A12;
            border: 1px solid $color-A6;
            position: absolute;
            right: -1px;
            top: 22px;
            width: 84px;

            .list-item {
              font: 11px/11px $unified-font-regular !important;
            }
          }

          .title-export {
            display: block;
            padding: 8px;
          }

          .chart-export {
            margin-top: 6px;
          }
        }

        .info {
          @include bar-padding();

          .report-info strand-icon {
            color: #999; // $color-A6 from new color palette (_color.scss)

            &:hover {
              color: #666;
            } // $color-A4 from new color palette (_color.scss)
          }
        }

        .legend-toggle {
          @include bar-padding();
          cursor: pointer;

          strand-icon {
            color: #4c4c4c;
          } // $color-A3 from new color palette (_color.scss)
          span {
            font: 11px/11px $unified-font-bold;
          }
        }
      }
    }

    //TODO: once all charts are ported to new setup, remove overflow in _.chart.scss
    .w-chart-box {
      overflow: visible;
    }

    // overrides main chart config to fit new chart setup
    .no-data {
      background-color: $color-A12;
      height: 100%;
      top: 0;

      .grid {
        height: 218px;
      }
    }
  }
}

.w-CampaignChart {
  overflow: hidden; // prevents scrollbar on empty chart

  .dates-selector {
    background: $color-A7;
    border: 0;
    font-size: 14px;
    margin-left: 12px;
    padding: 0;
    position: relative;
    top: 6px;
    width: 120px;
  }

  .date-picker-calendar {
    height: 264px;
    width: 418px;
  }

  .w-box-c {
    background-color: $color-A12;
    border-left: 1px solid $color-A7;
    border-right: 1px solid $color-A7;
    z-index: 5; //update this zindex with care!!
  }

  .w-head {
    padding-top: 0;
    z-index: 15;

    .chzn-results {
      max-height: 203px;
    }

    .controls {
      float: right;
      text-align: left;
    }

    .title {
      float: left;
      margin-left: 24px;
      font: 12px/14px $custom-font-regular;

      .select-line-data,
      .select-bar-data {
        width: 19em;
      }
    }

    .selected-date {
      padding: 4px 0 0;
      position: relative;
    }

    .select-dates {
      margin-left: 12px;
      width: 132px;

      .chzn-single {
        margin: 3px 0 0;
        padding: 0 0 0 7px;
        width: 132px;
      }

      .chzn-drop {
        width: 100% !important;
      }
    }

    .onhover {
      .start-date,
      .end-date {
        @extend .isEditable;
      }
    }

    .active {
      .caption {
        @include rounded-shadow-box($border-radius: 3px 3px 0 0, $shadow-border: 0, $border-color: $color-A5);
        border-bottom: 0;
        padding: 5px 3px 0 5px;
      }

      .campaign-names {
        display: block;
        text-overflow: ellipsis;
      }
    }

    .chart-icon {
      @include sprite-icon($left: 8, $down: 8, $width: 18px, $height: 18px);
      float: left;
    }

    .source-name {
      margin-left: 30px;
      font: 13px/25px $custom-font-regular;
      color: $color-A2;
      margin-top: -3px;
    }

    .campaign-names {
      @include _border-radius($radius: 0 3px 3px 3px);
      border: 1px solid;
      margin-top: -1px;
      color: $color-A5;
      padding: 7px 5px;
      background-color: $color-A12;
      font: 12px/15px $custom-font-regular;
      max-height: 190px;
      overflow-y: auto;
      display: none;

      .ul {
        padding-bottom: 5px;
      }
    }

    .campaign-name {
      padding: 7px 0 0;
    }
  }

  .search-toggle {
    float: left;
    position: relative;
    padding-top: 5px;
    padding-left: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    cursor: hand;
  }

  .icon-arrow {
    @include sprite-icon($left: 1, $down: 7, $width: 14px, $height: 11px);
    float: left;
    padding-right: 2px;

    .expanded {
      @include sprite-icon($left: 1, $down: 8, $width: 14px, $height: 11px);
      padding-top: 2px;
      margin-top: 4px;
    }
  }

  .chart-report-box {
    font-smoothing: antialiased;

    .chzn-single {
      margin-top: -5px;
      padding-left: 0;
      padding-right: 3px;
      font-family: $unified-font-bold;
    }

    .chzn-drop {
      margin-top: -10px;
      width: 120px !important;
    }

    .report-type-single {
      float: left;
      font: 11px/13px $unified-font-bold;
      margin-right: 8px;
      text-transform: capitalize;
    }
  }

  .chart-title-box {
    position: absolute;
    margin-top: 20px;
    width: 580px;
  }

  .metric-controls {
    height: 20px;

    .chzn-single {
      font-family: $unified-font-bold;
    }

    .attribution-select-c,
    .by-hour-interval-select-c {
      float: left;
      margin-top: -8px;
    }

    .by-hour-interval-select-c .chzn-drop {
      width: 85px !important;
    }

    .attribution-select-c {
      #attributionInfo {
        color: #999; // $color-A6 from new color palette (_color.scss)

        &:hover {
          color: #666;
        } // $color-A4 from new color palette (_color.scss)
      }

      .chzn-drop {
        width: 128px !important;
      }
    }

    .attribution-title {
      float: left;
      font: 11px/13px $unified-font-bold;
      margin-left: 7px;
      margin-top: 9px;
    }

    .report-export-menu {
      float: left;
      margin: -8px 8px 0 0;

      .btn-export {
        height: 25px;
        cursor: pointer;

        .icon-download {
          @include sprite-icon($left: 11, $down: 2, $width: 11px, $height: 13px);
          float: left;
          margin: 8px 0 0 8px;
        }

        .icon-arrow {
          margin: 13px 0 0 4px;
          padding-right: 0;
          width: 9px;
          height: 4px;

          &.flip-v {
            @include flipVertically();
          }
        }
      }

      .export-options {
        margin-top: 5px;
        margin-left: -53px;
        padding: 8px 0 9px;
        width: 83px;
        height: 62px;
        font: 11px/11px $unified-font-regular;
        border: 1px solid $color-A8;
        border-radius: 3px;
        background-color: $color-A12;

        .export-title {
          font-family: $unified-font-bold;
          margin-bottom: 12px;
          padding: 0 8px;
        }

        a {
          text-decoration: none;
        }

        .export-current-view,
        .export-all-metrics {
          padding: 6px 8px;
          letter-spacing: 0.2px;
          color: $color-A2;

          &:hover {
            background-color: $color-A9;
            color: $color-E1;
          }
        }
      }
    }
  }

  .date-picker-holder {
    margin-left: 8px;

    .dates {
      float: left;
    }

    .icon-arrow {
      padding-left: 0;
      margin: 4px 0 0 3px;
    }
  }

  .report-info-holder {
    float: left;
    margin: 0 8px 0 10px;

    .report-info {
      line-height: 0;

      strand-icon {
        color: #999; // $color-A6 from new color palette (_color.scss)

        &:hover {
          color: #666;
        } // $color-A4 from new color palette (_color.scss)
      }
    }
  }

  .chart-multi-marker-tooltip {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;

    .tooltip-trigger {
      height: 15px;
      width: 30px;
    }

    .chart-multi-marker-tooltip-list {
      height: 65px;
      margin: -15px -15px -14px;
      width: 90px;
      z-index: 1;

      .list-item {
        background-color: #191919; // new color palette
        border-bottom: 1px solid #ccc; // new color palette
        color: $color-A12;
        cursor: pointer;
        padding: 2px 10px;

        &:hover {
          background-color: #4c4c4c; // new color palette
        }
      }
    }
  }

  .chart-change-log-holder {
    float: left;
    margin: 0 16px 0 0;

    .bar {
      margin-right: 10px;
      margin-top: 1px;
    }

    .btn-toggle {
      cursor: pointer;
      margin-top: -2px;

      strand-icon {
        color: #4c4c4c;
      } // $color-A3 from new color palette (_color.scss)
      span {
        font: 11px/11px $unified-font-bold;
      }
    }

    .log {
      border-left: 1px solid #e5e5e5; //$color-A9 from new color palette
      height: 220px;
      margin-left: -341px;
      margin-top: 20px;
      width: 385px;
    }

    .change-date {
      float: left;
      margin: 5px 10px 0 4px;
      width: 140px;

      .cycle-arrow {
        cursor: pointer;
        float: left;
        height: 10px;
        margin: 3px;
        width: 10px;

        strand-icon {
          float: left;
          margin-left: 2px;
          margin-top: 2px;

          &:hover {
            color: #b2b2b2;
          } // $color-A7 from new color palette (_color.scss)
        }

        &.flip {
          @include flipHorizontally();
        }
      }

      .main-date {
        float: left;
        text-align: center;
        width: 105px;
      }
    }

    .search-input {
      width: 215px;
    }

    .list {
      height: 180px;
      margin-top: 10px;
      padding: 0 10px;
      font: 13px/15px $unified-font-regular;

      .empty-msg {
        text-align: center;

        .icon-empty {
          color: #ccc; //$color-A9 from new color palette (_colors.scss)
          margin-top: 3px;
        }
      }

      .list-item {
        border-bottom: 1px solid #e5e5e5; //$color-A9 from new color palette
        cursor: pointer;
        padding: 6px 10px;

        &.entity-row {
          border-bottom: 1px solid #b2b2b2; //$color-A7 from new color palette
          margin-left: -10px;
          padding-top: 5px;
          padding-bottom: 4px;
        }

        &:hover {
          background: #2f93c8; //$color-D12 from new color palette (_colors.scss)
          color: $color-A12;
        }
      }

      .metadata-toggle {
        color: $color-A0;
        float: left;
        transform: matrix(0, 1, 1, 0, 1, 3);

        &.expanded {
          transform: matrix(1, 0, 0, 1, 0, 5);
        }
      }

      .change-info,
      .metadata {
        font-size: 11px;
      }

      .change-info {
        overflow: hidden;
        padding-left: 3px;
        text-overflow: ellipsis;
      }

      .field,
      .from,
      .to {
        font-family: $unified-font-bold;
      }

      .metadata {
        color: #ccc; //$color-A8 from new color palette
        padding-left: 11px;
      }
    }

    .disabled {
      pointer-events: none;
    }

    .change-loader {
      margin: -96px auto 0;
      width: 130px;
    }
  }

  .charts-container-winloss {
    display: flex;

    .chart-holder {
      height: 220px;
      width: 100%;

      #chartCanvas {
        height: 220px;
        padding-left: 3px;
      }
    }

    // WinLoss Funnel table/chart
    .chart-holder2 {
      width: 35%;

      .funnel-container {
        padding-right: 20px;
      }

      // table styles
      .search-input {
        position: absolute;
        top: -20px;
      }

      .table {
        cursor: pointer;
        height: 220px;
        min-height: 220px;
        width: 100%;
      }

      .tableNoDataGrid {
        position: relative;
        top: -177px; // overlay component
        width: 100%;

        .border-bottom {
          border-bottom: 1px solid #e5e5e5; //$color-A9 from new color palette
          padding: 18px;

          &.no-data-msg {
            font: normal 12px/12px $unified-font-regular;
            padding: 12px 0;
            text-align: center;
          }
        }
      }

      // funnel styles
      .funnel-holder {
        padding-top: 10px;
        position: relative;
      }

      .entity-header {
        font: normal 15px/15px $unified-font-bold;
        height: 20px;
        overflow-x: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: -14px;
        white-space: nowrap;
        width: 95%;
      }
    }

    // makes legend scrollable
    .amcharts-legend-div {
      overflow-y: auto !important;
      max-height: 220px;
    }

    .split-chart {
      width: 65%;
    }

    #guideTarget0 {
      height: 15px;
      left: 93px;
      pointer-events: none;
      position: absolute;
      top: -45px;
      width: 40px;
    }

    #guideTarget1 {
      height: 140px;
      left: 110px;
      pointer-events: none;
      position: absolute;
      top: 25px;
      width: 52%;
    }

    #guideTarget2 {
      height: 135px;
      right: 23px;
      pointer-events: none;
      position: absolute;
      top: 32px;
      width: 33%;
    }
  }

  .tooltip-trigger {
    display: inline-block;
    height: 7px;
    position: absolute;
    width: 7px;
  }
}

.rptg-icon-info-tip {
  font-family: $unified-font-regular;
  letter-spacing: 0.3px;
  margin: -9px 0 0 -23px;

  .tipsy-inner {
    padding: 6px;
    span {
      font-family: $unified-font-bold;
    }
  }
}
