/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer {
	overflow: hidden;
	position: relative;
}

.jspPane {
	position: absolute;
}

.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 100%;
}

.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 16px;
}

.jspVerticalBar *,
.jspHorizontalBar * {
	margin: 0;
	padding: 0;
}

.jspCap {
	display: none;
}

.jspHorizontalBar .jspCap {
	float: left;
}

.jspTrack {
	position: relative;
}

.jspDrag {
	position: relative;
	top: 0;
	left: 0;
	cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
	float: left;
	height: 100%;
}

.jspArrow {
	text-indent: -20000px;
	display: block;
	cursor: pointer;
}

.jspArrow.jspDisabled {
	cursor: default;
}

.jspVerticalBar .jspArrow {
	height: 16px;
}

.jspHorizontalBar .jspArrow {
	width: 16px;
	float: left;
	height: 100%;
}

.jspVerticalBar .jspArrow:focus {
	outline: none;
}

.jspCorner {
	float: left;
	height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
	margin: 0 -3px 0 0;
}

.jScrollPaneContainer {
	position: relative;
	overflow: hidden;
	z-index: 5;
	border: 1px solid $color-A8;
	padding: 2px 0;
	background: $color-A12;
}

.jScrollPaneTrack {
	position: absolute;
	cursor: pointer;
	right: 0;
	top: 0;
	height: 100%;
	background: $color-A12;
	padding: 1px;
	margin-top: 2px;
}

.jScrollPaneDrag {
  @include rounded-box($padding: 0, $background: $color-A4, $border-radius: 5px, $border-rule:0);
	position: absolute;
	background: $color-A4;
	cursor: pointer;
	overflow: hidden;
}

.jScrollPaneDragTop {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.jScrollPaneDragBottom {
	position: absolute;
	bottom: 0;
	left: 0;
	overflow: hidden;
}

a.jScrollArrowUp {
	display: block;
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	text-indent: -2000px;
	overflow: hidden;
	/*background-color: #666;*/
	height: 9px;
}




a.jScrollArrowDown {
	display: block;
	position: absolute;
	z-index: 1;
	bottom: 0;
	right: 0;
	text-indent: -2000px;
	overflow: hidden;
	/*background-color: #666;*/
	height: 9px;
}

a.jScrollArrowDown:hover {
	/*background-color: #f60;*/
}

a.jScrollActiveArrowButton, a.jScrollActiveArrowButton:hover {
	/*background-color: #f00;*/
}